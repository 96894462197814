(function() {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('ResultRangeDetailController', ResultRangeDetailController);

    ResultRangeDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'ResultRange'];

    function ResultRangeDetailController($scope, $rootScope, $stateParams, previousState, entity, ResultRange) {
        var vm = this;

        vm.resultRange = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('dialysisApp:resultRangeUpdate', function(event, result) {
            vm.resultRange = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
