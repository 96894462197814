(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('RptTemplateExportController', RptTemplateExportController);

    RptTemplateExportController.$inject = ['$uibModalInstance', 'entity', 'RptTemplate', 'ClinicSearch'];

    function RptTemplateExportController($uibModalInstance, entity, RptTemplate, ClinicSearch) {
        var vm = this;

        vm.rptTemplate = entity;
        vm.selectClinics = null;
        vm.clinics = ClinicSearch.query({
            query: {
                bool: {
                    must: [{term: {delStatus: false}}]
                }
            }
        });

        vm.clear = clear;
        vm.confirmExport = confirmExport;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmExport(id) {
            RptTemplate.export({clinics: vm.selectClinics, id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
