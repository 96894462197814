(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('env-order-tpl', {
                parent: 'entity',
                url: '/env-order-tpl',
                ncyBreadcrumb: {
                    label: 'Site Order Templates'
                },
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_SUPERVISOR', 'ROLE_NURSE', 'ROLE_ENVIRONMENTAL', 'ROLE_DOCTOR'],
                    pageTitle: 'Site Order Templates'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/env-order-tpl/env-order-tpl.html',
                        controller: 'EnvOrderTemplateController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {}
            })
            .state('env-order-tpl.new', {
                url: '/new',
                ncyBreadcrumb: {
                    label: 'New Order Template'
                },
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_SUPERVISOR', 'ROLE_NURSE', 'ROLE_ENVIRONMENTAL', 'ROLE_DOCTOR'],
                    pageTitle: 'New Site Order Template'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/env-order-tpl/env-order-tpl-new.html',
                        controller: 'EnvOrderNewTemplateController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    envOrderTpl: ['$stateParams', function ($stateParams) {
                        return {
                            envOrderJson: {
                                orderType: $stateParams.orderType,
                                id: null,
                                envTests: [],
                                ordStatus: 'SCHEDULED'
                            }
                        };
                    }],
                }
            })
            .state('env-order-tpl.edit', {
                parent: 'env-order-tpl',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_SUPERVISOR'],
                },
                params: {
                    orderType: 'ONE_TIME',
                    clone: false
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/env-order-tpl/env-order-tpl-new.html',
                        controller: 'EnvOrderNewTemplateController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    envOrderTpl: ['$stateParams', 'EnvOrderTpl', function($stateParams, EnvOrderTpl) {
                        return EnvOrderTpl.get({id : $stateParams.id}).$promise;
                    }]
                }
            })
            .state('env-order-tpl.delete', {
                parent: 'env-order-tpl',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_SUPERVISOR']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/env-order-tpl/env-order-tpl-delete-dialog.html',
                        controller: 'EnvOrderTplDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['EnvOrderTpl', function(EnvOrderTpl) {
                                return EnvOrderTpl.get({id : $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function() {
                        $state.go('env-order-tpl', null, { reload: 'env-order-tpl' });
                    }, function() {
                        $state.go('^');
                    });
                }]
            });
    }
})();
