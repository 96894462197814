(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('hospitalization', {
                parent: 'patient-abs',
                url: '/hospitalization',
                ncyBreadcrumb: {
                    label: 'Hospitalizations',
                },
                data: {
                    authorities: ['ROLE_SUPERVISOR', 'ROLE_NURSE', 'ROLE_DOCTOR'],
                    pageTitle: 'Hospitalizations'
                },
                views: {
                    'content@patient-abs': {
                        templateUrl: 'app/entities/hospitalization/hospitalizations.html',
                        controller: 'HospitalizationController',
                        controllerAs: 'vm'
                    }
                },
            })
            .state('hospitalization.view', {
                parent: 'hospitalization',
                url: '/{hid}/view',
                data: {
                    authorities: ['ROLE_SUPERVISOR', 'ROLE_NURSE', 'ROLE_DOCTOR'],
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/hospitalization/hospitalization-dialog.html',
                        controller: 'HospitalizationDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            hospitalization: ['Hospitalization', function (Hospitalization) {
                                return Hospitalization.get({id: $stateParams.hid}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('hospitalization', {id: $stateParams.id}, {reload: 'hospitalization'});
                    }, function () {
                        $state.go('hospitalization', {id: $stateParams.id}, {reload: 'hospitalization'});
                    });
                }]
            })
            .state('hospitalization.new', {
                parent: 'hospitalization',
                url: '/new',
                data: {
                    authorities: ['ROLE_SUPERVISOR', 'ROLE_NURSE', 'ROLE_DOCTOR'],
                },
                onEnter: ['$stateParams', '$state', '$uibModal', 'entity', function ($stateParams, $state, $uibModal, entity) {
                    $uibModal.open({
                        templateUrl: 'app/entities/hospitalization/hospitalization-dialog.html',
                        controller: 'HospitalizationDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            hospitalization: function () {
                                return {
                                    admissionDiagnosis: null,
                                    admissionDate: null,
                                    hospital: null,
                                    admittingPhysician: null,
                                    dischargeDate: null,
                                    dueToBsi: null,
                                    renalRelatedInfection: false,
                                    renalRelatedOther: null,
                                    nonRenalRelatedOther: null,
                                    id: null,
                                    patient: entity
                                };
                            }
                        }
                    }).result.then(function () {
                        $state.go('hospitalization', {id: $stateParams.id}, {reload: 'hospitalization'});
                    }, function () {
                        $state.go('hospitalization', {id: $stateParams.id}, {reload: 'hospitalization'});
                    });
                }]
            })
            .state('hospitalization.delete', {
                parent: 'hospitalization',
                url: '/{hid}/delete',
                data: {
                    authorities: ['ROLE_SUPERVISOR', 'ROLE_NURSE', 'ROLE_DOCTOR'],
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/hospitalization/hospitalization-delete-dialog.html',
                        controller: 'HospitalizationDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['Hospitalization', function (Hospitalization) {
                                return Hospitalization.get({id: $stateParams.hid}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('hospitalization', null, {reload: 'hospitalization'});
                    }, function () {
                        $state.go('^');
                    });
                }]
            });
    }
})();
