(function() {
    'use strict';

    angular
        .module('dialysisApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('requestReset', {
            // parent: 'account',
            url: '/reset/request',
            templateUrl: 'app/account/reset/request/reset.request.html',
            controller: 'RequestResetController',
            controllerAs: 'vm',
            data: {
                authorities: []
            }
            // views: {
            //     'content@': {
            //         templateUrl: 'app/account/reset/request/reset.request.html',
            //         controller: 'RequestResetController',
            //         controllerAs: 'vm'
            //     }
            // }
        });
    }
})();
