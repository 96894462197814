(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('EnvTestIntervalDialogController', EnvTestIntervalDialogController);

    EnvTestIntervalDialogController.$inject = ['envOrderTpl', '$uibModalInstance', 'clinic'];

    function EnvTestIntervalDialogController(envOrderTpl, $uibModalInstance, clinic) {
        var vm = this;

        vm.envOrderTpl = envOrderTpl;
        vm.staringDate = null;
        vm.endDate = null;
        vm.clinic = clinic;

        vm.form = {};
        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.staringDate = false;

        vm.startOptions = {
            minDate: new Date(),
            // dateDisabled: disabled,
            startingDay: 1
        }

        vm.endOptions = {
            minDate: new Date(),
            startingDay: 1
        }

        vm.clear = function () {
            $uibModalInstance.dismiss('cancel');
        }

        vm.openCalendar = openCalendar;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        vm.accept = function () {
            for (var i = 0; i < vm.envOrderTpl.envOrderJson.envTests.length; i++) {
                vm.envOrderTpl.envOrderJson.envTests[i].staringDate = vm.staringDate;
                vm.envOrderTpl.envOrderJson.envTests[i].endDate = vm.staringDate;
            }

            $uibModalInstance.close({tpl: vm.envOrderTpl});
        }

        vm.closeAlert = function(index) {
            vm.alerts.splice(index, 1);
        };
    }
})();
