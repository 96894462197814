(function() {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('CompendiumSpecialPriceDialogController', CompendiumSpecialPriceDialogController);

    CompendiumSpecialPriceDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance',
        'entity', 'CompendiumSpecialPrice', 'Clinic', 'Compendium', 'CoreService'];

    function CompendiumSpecialPriceDialogController ($timeout, $scope, $stateParams, $uibModalInstance,
                                                     entity, CompendiumSpecialPrice, Clinic, Compendium, CoreService) {
        var vm = this;

        vm.compendiumSpecialPrice = entity;

        if (vm.compendiumSpecialPrice.id) {
            Compendium.byClinicSpecialPriceDif({id: vm.compendiumSpecialPrice.clinic.id}, function (results) {
                vm.compendiums = results;
                vm.compendiums.push(vm.compendiumSpecialPrice.compendium);
            });
        } else {
            vm.compendiumSpecialPrice.clinic = CoreService.getCurrentClinic();
            vm.compendiums = Compendium.byClinicSpecialPriceDif({id: vm.compendiumSpecialPrice.clinic.id});
        }

        vm.clear = clear;
        vm.save = save;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.compendiumSpecialPrice.id !== null) {
                CompendiumSpecialPrice.update(vm.compendiumSpecialPrice, onSaveSuccess, onSaveError);
            } else {
                CompendiumSpecialPrice.save(vm.compendiumSpecialPrice, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('dialysisApp:compendiumSpecialPriceUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }
    }
})();
