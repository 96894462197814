(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('BundleDialogController', BundleDialogController);

    BundleDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Bundle',
        'Compendium', 'BillingFrequency', 'CoreService'];

    function BundleDialogController($timeout, $scope, $stateParams, $uibModalInstance, entity, Bundle,
                                    Compendium, BillingFrequency, CoreService) {
        var vm = this;

        vm.bundle = entity;
        vm.compendiums = [];
        vm.billingfrequencies = BillingFrequency.query();

        vm.clear = clear;
        vm.save = save;
        vm.onChangeDialysisModality = onChangeDialysisModality;

        if (vm.bundle.id) {
            Compendium.byClinicBundleDif({
                id: vm.bundle.clinic.id, modality: vm.bundle.dialysisModality
            }, function (result) {
                vm.compendiums = result;
                vm.compendiums.push(vm.bundle.compendium);
            });
        } else {
            vm.bundle.clinic = CoreService.getCurrentClinic();
        }

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            if (vm.bundle.id !== null) {
                Bundle.update(vm.bundle, onSaveSuccess, onSaveError);
            } else {
                Bundle.save(vm.bundle, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('dialysisApp:bundleUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function onChangeDialysisModality() {
            vm.bundle.compendium = null;

            vm.compendiums = [];

            Compendium.byClinicBundleDif({
                id: vm.bundle.clinic.id, modality: vm.bundle.dialysisModality
            }, function (result) {
                vm.compendiums = result;
            });
        }
    }
})();
