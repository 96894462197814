(function() {
    'use strict';

    angular
        .module('dialysisApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('hotfix', {
            parent: 'entity',
            url: '/hotfix',
            ncyBreadcrumb: {
                label: 'HotFix',
            },
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'HotFix'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/hotfix/hotfix.html',
                    controller: 'HotFixController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
            }
        })
    }
})();
