(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('PatientResultAbnormalController', PatientResultAbnormalController);

    PatientResultAbnormalController.$inject = ['clinic', 'PatientResult', 'lodash', 'moment', '$scope'];

    function PatientResultAbnormalController(clinic, PatientResult, _, moment, $scope) {
        var vm = this;

        vm.abnormalNotReviewed = 0;
        vm.labels = [];
        vm.data = [
            []
        ];
        vm.totalCount = 0;

        vm.abnormalNotReviewed = PatientResult.countAbnormalNotReviewed({id: clinic.id});

        vm.getResults = getResults;

        getResults();

        function getResults() {
            PatientResult.countAbnormalNotReviewedLast7Days({
                id: clinic.id
            }, function (results) {
                _.forEach(results, function (result) {
                    vm.labels.push(moment(result[1]).format($scope.displayDateformat));
                    vm.data[0].push(result[0]);
                    vm.totalCount += result[0];
                });
            });
        }

        vm.series = ['Abnormal Results'];
        vm.colors = ['#45b7cd', '#ff6384', '#ff8e72'];
    }
})();
