(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('DashboardEnvOrderScheduleController', DashboardEnvOrderScheduleController);

    DashboardEnvOrderScheduleController.$inject = ['CoreService', '$rootScope', 'hasPrinter', 'DYMO', '$stateParams', 'dialogs',
        '$uibModal', '$compile', '$q', '$scope', 'GUIUtils', 'EnvOrder', 'DTOptionsBuilder', 'DTColumnBuilder',
        'DateUtils', 'date', 'DataTablesService', 'toastr'];

    function DashboardEnvOrderScheduleController(CoreService, $rootScope, hasPrinter, DYMO, $stateParams, $dialogs,
                                                 $uibModal, $compile, $q, $scope, GUIUtils, EnvOrder, DTOptionsBuilder,
                                                 DTColumnBuilder, DateUtils, date, DataTablesService, toastr) {
        var vm = this;
        vm.clinic = CoreService.getCurrentClinic();

        vm.orders = [];
        vm.search = search;
        vm.dtInstance = {};
        vm.barCode = {};
        vm.oDate1 = new Date(date);
        vm.hasPrinter = hasPrinter;

        vm.selected = {};
        vm.selectAll = false;
        vm.toggleAll = DataTablesService.toggleAll;
        vm.toggleOne = DataTablesService.toggleOne;
        vm.getBarcodeLabelsPdf = getBarcodeLabelsPdf2;

        vm.barcodes = [];

        var titleHtml = '<input type="checkbox" ng-model="vm.selectAll" ng-change="vm.toggleAll(vm.selected, vm.selectAll)">';

        vm.dtOptions = DTOptionsBuilder.fromFnPromise(function () {
            var defer = $q.defer();

            vm.selected = {};
            vm.selectAll = false;

            if (vm.clinic) {
                EnvOrder.dashboardSchedule({clinicId: vm.clinic.id, date: vm.oDate1}, function (result) {
                    vm.orders = result;
                    defer.resolve(result);
                });
            } else {
                defer.resolve(vm.orders);
            }

            return defer.promise;
        }).withBootstrap().withDOM('tip')
            .withOption('createdRow', function (row, data, dataIndex) {
                $compile(angular.element(row).contents())($scope);
            })
            .withOption('aaSorting', [[1, 'asc']])
            .withOption('headerCallback', function (header) {
                if (!vm.headerCompiled) {
                    vm.headerCompiled = true;
                    $compile(angular.element(header).contents())($scope);
                }
            });

        vm.dtColumns = [
            DTColumnBuilder.newColumn(null).withTitle(titleHtml).notSortable().renderWith(function (data, type, full, meta) {
                vm.selected[full.orderId] = false;

                return '<input type="checkbox" ng-model="vm.selected[' + data.orderId + ']" ng-click="vm.toggleOne()">';
            }),
            DTColumnBuilder.newColumn(null).withTitle('Machine').renderWith(function (data, type, full) {
                return data.machine;
            }),
            DTColumnBuilder.newColumn(null).withTitle('Barcode').renderWith(function (data, type, full) {
                return data.barcode ? showBarcode(data) : '';
            }),
            DTColumnBuilder.newColumn(null).withTitle('Order ID').renderWith(function (data, type, full) {
                return data.orderId;
            }),
            DTColumnBuilder.newColumn(null).withTitle('Tests').withOption('width', '90px').renderWith(function (data, type, full, meta) {
                return showCompendiumHtml(data.orderId);
            }),
            DTColumnBuilder.newColumn(null).withTitle('Collected').renderWith(function (data, type, full) {
                return GUIUtils.colorHtmlYesNo(data.collected);
            })
        ];

        vm.printLabels = function () {
            var orders = getSelectedIds();

            if (orders.length > 0) {
                vm.isSaving = true;
                EnvOrder.collect({ids: orders, date: vm.oDate1}, function (result) {
                    vm.barcodes = getSelectedBarcodes().concat(result);

                    if (vm.hasPrinter) {
                        if (vm.barcodes.length > 0) {
                            $rootScope.$broadcast('dialysisApp:dashboardRedrawEnvCalendar' + $stateParams.state);

                            EnvOrder.getBarcodeLabels({barcodes: vm.barcodes}, function (result) {
                                DYMO.printEnvBarcodes(result);

                                vm.barcodes = [];

                                vm.dtInstance.reloadData();
                            });
                        }
                    }

                }, function () {
                    alert("Error collecting orders");
                    vm.isSaving = false;
                })
            }
        }

        vm.changeDrawDay = function () {
            var dlg = $dialogs.confirm('Please Confirm', 'Are you sure you want to change the draw day? Items with results will not be changed', {size: 'md'});
            dlg.result.then(function (btn) {
                $uibModal.open({
                    templateUrl: 'app/entities/window-picker/date-picker-dialog.html',
                    controller: 'DatePickerDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'sm',
                    resolve: {
                        date: new Date()
                    }
                }).result.then(function (result) {
                    var envBarCodes = [];

                    for (var id in vm.selected) {
                        if (vm.selected[id]) {
                            var order = $.grep(vm.orders, function (order, i) {
                                return order.orderId == id;
                            })[0];

                            if (order.barcode == null) {
                                envBarCodes.push({
                                    machineId: order.machineId,
                                    barcode: null,
                                    date: order.scheduleDate,
                                    newDate: DateUtils.convertLocalDateToServer(result.date),
                                    orderId: order.orderId
                                });
                            } else {
                                envBarCodes.push({
                                    machineId: order.machineId,
                                    barcode: order.barcode,
                                    newDate: DateUtils.convertLocalDateToServer(result.date),
                                    orderId: order.orderId
                                });
                            }
                        }
                    }

                    if (envBarCodes.length > 0) {
                        vm.isSaving = false;

                        EnvOrder.changeDrawDay({drawDays: envBarCodes}, function (result) {
                            vm.barcodes = result;
                            vm.dtInstance.reloadData();
                            vm.isSaving = false;

                            $rootScope.$broadcast('dialysisApp:dashboardRedrawEnvCalendar' + $stateParams.state);
                        }, function () {
                            alert("Error change draw days orders");
                            vm.isSaving = false;
                        });
                    } else {
                        vm.barcodes = getSelectedBarcodes();
                        vm.dtInstance.reloadData();
                        vm.isSaving = false;
                    }
                }, function () {
                });
            });
        }

        function getBarcodeLabelsPdf2() {
            var orders = DataTablesService.getSelectedIds(vm.selected);
            if (orders.length > 0) {
                vm.isSaving = true;
                var envBarcodes = _.map(orders, function (id) {
                    return {
                        orderId: id,
                        date: DateUtils.convertLocalDateToServer(vm.oDate1),
                        barcode: _.keyBy(vm.orders, 'orderId')[id].barcode
                    }
                });

                EnvOrder.getBarcodeLabelsPdf2({drawDays: envBarcodes}, function () {
                    vm.searchQuery = '';
                    vm.dtInstance.reloadData();
                }, function () {
                    toastr.error('"Error collecting orders"');
                });
            } else {
                toastr.error('There is not requisition selected');
                vm.dtInstance.reloadData();
            }
        }

        // function toggleAll() {
        //     for (var id in vm.selected) {
        //         if (vm.selected.hasOwnProperty(id)) {
        //             vm.selected[id] = vm.selectAll;
        //         }
        //     }
        // }
        //
        // function toggleOne() {
        //     for (var id in vm.selected) {
        //         if (vm.selected.hasOwnProperty(id)) {
        //             if (!vm.selected[id]) {
        //                 vm.selectAll = false;
        //                 return;
        //             }
        //         }
        //     }
        //     vm.selectAll = true;
        // }

        function search() {
            vm.dtInstance.reloadData();
        }

        $scope.$on('dialysisApp:dashboardCalendarUpdate', function (event, result) {
            vm.oDate1 = result;
            vm.selected = {};
            vm.selectAll = false;
            vm.search();
        });

        function showCompendiumHtml(row) {
            return '<a class="btn-sm btn-info" ng-click="vm.showCompendiums(' + row + ')">' +
                '   <i class="fa fa-list-ol"></i></a>';
        }

        vm.showCompendiums = function (index) {
            $uibModal.open({
                templateUrl: 'app/entities/compendium/compendium-list-dialog.html',
                controller: 'CompendiumListDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    compendiums: ['Compendium', function (Compendium) {
                        return Compendium.by_env_order({
                            id: index,
                            date: DateUtils.convertLocalDateToServer(vm.oDate1)
                        }).$promise;
                    }]
                }
            });
        };

        function getSelectedIds() {
            var orders = [];
            for (var id in vm.selected) {
                if (vm.selected[id]) {
                    orders.push(id);
                }
            }

            return orders;
        }

        function getSelectedBarcodes() {
            var barcodes = [];

            for (var id in vm.selected) {
                if (vm.selected[id]) {
                    var order = $.grep(vm.orders, function (order, i) {
                        return order.orderId == id;
                    })[0];

                    if (order.barcode != null) {
                        barcodes.push(order.barcode);
                    }
                }
            }

            return barcodes;
        }

        function showBarcode(row) {
            return '<a ui-sref="' + "clinic-schedule-edetail-barcode({barcode:'" + row.barcode + "'})" + '">' + row.barcode + '</a>';
        }
    }
})();
