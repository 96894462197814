(function () {
    'use strict';

    angular
        .module('udt')
        .directive('embedSrc', embedSrc);

    function embedSrc() {

        var directive = {
            restrict: 'A',
            link: linkFunc
        }

        return directive;

        /* private helper methods*/

        function linkFunc(scope, element, attrs) {
            var current = element;
            scope.$watch(
                function () {
                    return attrs.embedSrc;
                },
                function () {
                    var clone = element
                        .clone()
                        .attr('src', attrs.embedSrc);
                    current.replaceWith(clone);
                    current = clone;
                }
            );
        }
    }
})();
