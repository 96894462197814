(function() {
    'use strict';

    angular
        .module('dialysisApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('result-range', {
            parent: 'entity',
            url: '/result-range',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'ResultRanges'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/result-range/result-ranges.html',
                    controller: 'ResultRangeController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                clinic: ["CoreService", function (CoreService) {
                    return CoreService.getCurrentClinic();
                }]
            }
        })
        .state('result-range-detail', {
            parent: 'entity',
            url: '/result-range/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'ResultRange'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/result-range/result-range-detail.html',
                    controller: 'ResultRangeDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: ['$stateParams', 'ResultRange', function($stateParams, ResultRange) {
                    return ResultRange.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'result-range',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('result-range-detail.edit', {
            parent: 'result-range-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/result-range/result-range-dialog.html',
                    controller: 'ResultRangeDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        resultRange: ['ResultRange', function(ResultRange) {
                            return ResultRange.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('result-range.new', {
            parent: 'result-range',
            url: '/new',
            data: {
                authorities: ['ROLE_SUPERVISOR']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/result-range/result-range-dialog.html',
                    controller: 'ResultRangeDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                        resultRange: ['CoreService', function (CoreService) {
                            return {
                                low: null,
                                high: null,
                                criticalLow: null,
                                criticalHigh: null,
                                description: null,
                                range: null,
                                compendium_code: null,
                                id: null,
                                clinic: CoreService.getCurrentClinic()
                            };
                        }]
                    }
                }).result.then(function() {
                    $state.go('result-range', null, { reload: 'result-range' });
                }, function() {
                    $state.go('result-range');
                });
            }]
        })
        .state('result-range.edit', {
            parent: 'result-range',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/result-range/result-range-dialog.html',
                    controller: 'ResultRangeDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        resultRange: ['ResultRange', function(ResultRange) {
                            return ResultRange.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('result-range', null, { reload: 'result-range' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('result-range.delete', {
            parent: 'result-range',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/result-range/result-range-delete-dialog.html',
                    controller: 'ResultRangeDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['ResultRange', function(ResultRange) {
                            return ResultRange.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('result-range', null, { reload: 'result-range' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
