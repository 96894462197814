(function() {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('EnvOrderDetailController', EnvOrderDetailController);

    EnvOrderDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'order', 'EnvOrder', 'EnvTest', 'machine'];

    function EnvOrderDetailController($scope, $rootScope, $stateParams, previousState, order, EnvOrder, EnvTest, machine) {
        var vm = this;

        vm.order = order;
        vm.machine = machine;
    }
})();
