(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('StandingCurrentOrderController', StandingCurrentOrderController);

    StandingCurrentOrderController.$inject = ['$compile', '$scope', '$state', 'PatientOrder', 'entity', '$uibModal',
        '$q', 'DTColumnBuilder', 'DTOptionsBuilder', 'GUIUtils', '$filter', 'moment'];

    function StandingCurrentOrderController($compile, $scope, $state, PatientOrder, entity, $uibModal,
                                            $q, DTColumnBuilder, DTOptionsBuilder, GUIUtils, $filter, moment) {
        var vm = this;

        vm.patient = entity;
        vm.orders = [];
        vm.search = search;
        vm.dtInstance = {};

        vm.dtOptions = DTOptionsBuilder.fromFnPromise(function () {
            var defer = $q.defer();
            if (!vm.searchQuery || vm.searchQuery == '') {
                PatientOrder.active({id : vm.patient.id, type: "STANDING", status: "SCHEDULED"}, function (result) {
                    vm.orders = result;
                    defer.resolve(result);
                });
            } else {
                defer.resolve($filter('filter')(vm.orders, vm.searchQuery, undefined));
            }
            return defer.promise;
        }).withBootstrap().withDOM('tip').withOption('fnRowCallback',
            function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                $compile(nRow)($scope);
            });

        vm.dtColumns = [
            DTColumnBuilder.newColumn('id').withTitle('Order Number'),
            DTColumnBuilder.newColumn(null).withTitle('Start Date').renderWith(function (data, type, full) {
                return moment(data.startDate).format("MM/DD/YYYY");
            }),
            DTColumnBuilder.newColumn(null).withTitle('End Date').renderWith(function (data, type, full) {
                return moment(data.endDate).format("MM/DD/YYYY");
            }),
            DTColumnBuilder.newColumn(null).withTitle('Signed').renderWith(function (data, type, full) {
                return data.signed ? GUIUtils.getStatusTemplate('Yes', 'success') : GUIUtils.getStatusTemplate('No', 'danger');
            }),
            DTColumnBuilder.newColumn(null).withTitle('Signed By').renderWith(function (data, type, full) {
                return data.signedBy ? 'Dr. ' + data.signedBy.name + ' ' + data.signedBy.lastName : "";
            }),
            DTColumnBuilder.newColumn(null).withTitle('Actions').withOption('width', '155px').notSortable()
                .renderWith(actionsHtml)
        ];

        function actionsHtml(data, type, full, meta) {
            var stButtons = '';

            stButtons += '<a class="btn-sm btn-warning" ui-sref="patient-order-update({oid:' + data.id + '})">' +
                '   <i class="fa fa-edit"></i></a>&nbsp;';

            return stButtons;
        }

        function search() {
            vm.dtInstance.reloadData();
        }

        vm.finishActiveConfirm = finishActiveConfirm;

        function finishActiveConfirm(id) {
            $uibModal.open({
                templateUrl: 'app/entities/patient-order/current-order/patient-order-standing-confirm.html',
                controller: 'PatientOrderStandingFinishController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'sm',
                resolve: {
                    patientOrder: id
                }
            }).result.then(function (result) {
                $state.go("patient-order-current.new", {orderType: 'STANDING'});
            }, function () {
            });
        }
    }
})();
