(function() {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('MachineDetailController', MachineDetailController);

    MachineDetailController.$inject = ['$scope', '$rootScope', 'CoreService', 'previousState', 'machine', 'Machine', 'EnvOrder', 'Clinic'];

    function MachineDetailController($scope, $rootScope, CoreService, previousState, machine, Machine, EnvOrder, Clinic) {
        var vm = this;

        vm.machine = machine;
        CoreService.setCurrentMachine(machine);
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('dialysisApp:machineUpdate', function(event, result) {
            vm.machine = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
