(function() {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('EnvResultDeleteController',EnvResultDeleteController);

    EnvResultDeleteController.$inject = ['dialogs', 'EnvResult', 'toastr'];

    function EnvResultDeleteController($dialogs, EnvResult, toastr) {
        var vm = this;

        vm.barcode = "";

        vm.deleteResult = deleteResult;

        function deleteResult() {
            var dlg = $dialogs.confirm('Please Confirm','Do you want to delete this env result?', {size: 'md'});

            dlg.result.then(function(btn){
                EnvResult.byBarcode({barcode: vm.barcode}, function (result) {
                    EnvResult.delete({id: result.id}, function () {
                        vm.barcode = "";

                        toastr.success('Delete successfully!');
                    });
                });
            },function(btn) {
            });
        }
    }
})();
