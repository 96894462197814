(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('UnsignedOrdersBoxController', UnsignedOrdersBoxController);

    UnsignedOrdersBoxController.$inject = ['clinic', '$state', '$q', 'DTColumnBuilder', 'PatientOrder'];

    function UnsignedOrdersBoxController(clinic, $state, $q, DTColumnBuilder, PatientOrder) {
        var vm = this;

        vm.unsigned = 0;

        vm.unsigned = PatientOrder.countUnsigned({id: clinic.id});
    }
})();
