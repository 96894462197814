(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('CompendiumDialogController', CompendiumDialogController);

    CompendiumDialogController.$inject = ['$timeout', '$scope', '$uibModalInstance', 'entity',
        'Compendium', 'Tube', 'RptMetadata', 'CompendiumReportGroup'];

    function CompendiumDialogController($timeout, $scope, $uibModalInstance, entity,
                                        Compendium, Tube, RptMetadata, CompendiumReportGroup) {
        var vm = this;

        vm.compendium = entity;
        vm.compendiums =  Compendium.query();
        vm.tubes = Tube.query();

        if (vm.compendium.intCode) {
            vm.compendium.intCode = RptMetadata.getByIntCode({intCode: vm.compendium.intCode});
        }

        vm.rptMetadatas = RptMetadata.queryDiff();
        vm.compendiumReportGroups = CompendiumReportGroup.query();

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        vm.clear = clear;
        vm.save = save;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            vm.compendium.intCode = vm.compendium.intCode ? vm.compendium.intCode.intCode : null;
            if (vm.compendium.id !== null) {
                Compendium.update(vm.compendium, onSaveSuccess, onSaveError);
            } else {
                Compendium.save(vm.compendium, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('dialysisApp:compendiumUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }
    }
})();
