(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('PatientResultUpdateDatesController', PatientResultUpdateDatesController);

    PatientResultUpdateDatesController.$inject = ['PatientResult', 'dialogs', 'toastr', '$state'];

    function PatientResultUpdateDatesController(PatientResult, $dialogs, toastr, $state) {
        var vm = this;

        vm.receivedDate = null;
        vm.collectionDate = null;

        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.receivedDate = false;
        vm.datePickerOpenStatus.collectionDate = false;

        vm.openCalendar = openCalendar;
        vm.saveDates = saveDates;
        vm.clear = clear;
        vm.clean = clean;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function saveDates() {
            var dlg = $dialogs.confirm('Please Confirm', 'Do you want to update this result?', {size: 'md'})
            dlg.result.then(function (btn) {
                vm.isSaving = true;
                PatientResult.updateDates({
                    barcode: vm.barcode,
                    startDate: vm.receivedDate,
                    endDate: vm.collectionDate
                }, function () {
                    toastr.success('Update dates operation successfully completed');
                    vm.isSaving = false;
                    vm.clean();
                },function () {
                    vm.isSaving = false;
                })
            })
        }

        function clear() {
            $state.go("latest-result", {});
        }

        function clean(){
            vm.barcode = "";
            vm.receivedDate = null;
            vm.collectionDate = null;
        }
    }
})();
