(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('PatientController', PatientController);

    PatientController.$inject = ['clinic', '$compile', '$scope', 'Patient', 'patient', 'Employee', 'Modality', 'DateUtils',
        'PatientSearch', '$q', 'DTColumnBuilder', 'DTOptionsBuilder', 'GUIUtils', '$filter', 'moment', '$parse', 'CoreService'];

    function PatientController(clinic, $compile, $scope, Patient, patient, Employee, Modality, DateUtils,
                               PatientSearch, $q, DTColumnBuilder, DTOptionsBuilder, GUIUtils, $filter, moment, $parse, CoreService) {
        var vm = this;

        vm.patient = patient;
        vm.patients = [];
        vm.search = search;
        vm.dtInstance = {};
        vm.clinic = clinic;
        vm.physicians = Employee.employeeCorpPhysicians();
        vm.modalities = Modality.query();

        vm.dtOptions = DTOptionsBuilder.fromFnPromise(function () {
            var defer = $q.defer();
            if (vm.isFilter) {
                defer.resolve(vm.patients);
                vm.isFilter = false;
            } else if (!vm.searchQuery || vm.searchQuery == '') {
                Patient.roster({id : CoreService.getCurrentClinic().id, status: true}, function (result) {
                    vm.patients = result;
                    defer.resolve(result);
                });
            } else {
                defer.resolve($filter('filter')(vm.patients, vm.searchQuery, undefined));
            }
            return defer.promise;
        }).withPaginationType('full_numbers').withBootstrap().withOption('aaSorting', [[0, 'asc']]).withDOM('tip').withOption('fnRowCallback',
            function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                 $compile(nRow)($scope);
            });

        vm.dtColumns = [
            DTColumnBuilder.newColumn(null).withTitle('Name').withOption('width', '180px').renderWith(function (data, type, full) {
                return data.lastName + ', ' + data.firstName + ' ' + (data.middleInitial ? data.middleInitial : "");
            }),
            DTColumnBuilder.newColumn(null).withTitle('DOB').renderWith(function (data, type, full) {
                return moment(data.dob).format("MM/DD/YYYY");
            }),
            DTColumnBuilder.newColumn('gender').withTitle('Gender'),
            DTColumnBuilder.newColumn(null).withTitle('Modality').renderWith(function (data, type, full) {
                return data.dialysisModality ? data.dialysisModality.replace("_", " ") : "";
            }),
            DTColumnBuilder.newColumn(null).withTitle('Shift').renderWith(function (data, type, full) {
                return data.shift ? data.shift : '';
            }),
            DTColumnBuilder.newColumn(null).withTitle('Status').renderWith(function (data, type, full) {
                return data.status ? 'Active' : 'Inactive';
            }),
            DTColumnBuilder.newColumn(null).withTitle('Physician').withOption('width', '180px').renderWith(function (data, type, full) {
                return data.physicianFirstName ? (data.physicianLastName + ', ' + data.physicianFirstName) : '';
            }),
            DTColumnBuilder.newColumn(null).withTitle('Diabetic').renderWith(function (data, type, full) {
                return data.diabetic !== undefined ? GUIUtils.colorHtmlYesNo(data.diabetic) : '';
            }),
            DTColumnBuilder.newColumn(null).withTitle("").withOption('width', '200px').notSortable().renderWith(actionsHtml)
        ];

        function actionsHtml(data, type, full, meta) {
            return '<div>' +
                '<div class="btn-group" uib-dropdown>' +
                '<button type="button" class="btn btn-default" ui-sref="patient-detail({id:' + data.id + '})" href="#/patient/' + data.id + '">Demographics</button>' +
                '<button type="button" class="btn btn-default dropdown-toggle" uib-dropdown-toggle ng-disabled="disabled" data-toggle="dropdown" aria-expanded="false">' +
                '<span class="caret"></span>' +
                '<span class="sr-only">Toggle Dropdown</span>' +
                '</button>' +
                '<ul class="dropdown-menu" uib-dropdown-menu role="menu">' +
                '<li><a ui-sref="hospitalization({id:' + data.id + '})">Hospitalizations</a></li>' +
                '<li><a ui-sref="infection({id:' + data.id + '})">Infections</a></li>' +
                '<li><a ui-sref="patient-order-current({id:' + data.id + '})">Orders - Current</a></li>' +
                '<li><a ui-sref="patient-order-history({id:' + data.id + '})">Orders - History</a></li>' +
                '<li><a ui-sref="patient-result({id:' + data.id + '})">Results</a></li>' +
                '<li><a ui-sref="trending({id:' + data.id + '})">Results Trending</a></li>' +
                '<li has-any-authority="ROLE_SUPERVISOR"><a ui-sref="patient-change({id:' + data.id + '})">Change Clinic</a></li>' +
                '</ul>' +
                '</div></div>';
        }

        function search() {
            vm.dtInstance.reloadData();
        }

        vm.popoverFilterEnable = popoverFilterEnable;
        vm.showFilter = false;

        function popoverFilterEnable(){
            vm.showFilter = !vm.showFilter;
        }

        vm.filter = filter;
        vm.isFilter = false;
        vm.patientCopy = {};

        function filter() {
            vm.isFilter = true;

            var must = [];
            must.push({term: {"clinic.id": vm.clinic.id}});
            must.push({term: {"delStatus": false}});
            must.push({term: {"status": true}});

            if (vm.patient.diabetic != null && vm.patient.diabetic.length > 0){
                must.push({match: {diabetic: {query : vm.patient.diabetic}}});
            }

            if (vm.patient.firstName != null && vm.patient.firstName.length > 0){
                must.push({match: {firstName: {query :vm.patient.firstName, fuzziness: 2}}});
            }

            if (vm.patient.lastName && vm.patient.lastName.length > 0){
                must.push({match: {lastName: {query :vm.patient.lastName, fuzziness: 2}}});
            }

            if (vm.patient.gender != null && vm.patient.gender.length > 0){
                must.push({match: {gender: {query :vm.patient.gender}}});
            }

            if (vm.patient.shift){
                must.push({term: {shift: vm.patient.shift}});
            }

            if (vm.patient.treatmentDays && vm.patient.treatmentDays.length > 0){
                must.push({match: {treatmentDays: {query : GUIUtils.daysToWeekCodeString(vm.patient.treatmentDays)}}});
            }

            if (vm.patient.dob && vm.patient.dob.length > 0){
                must.push({match: {dob: {query : moment(vm.patient.dob).format('Y-MM-DD')}}});
            }

            if (vm.patient.physician.id !== undefined){
                must.push({term: {"employee.id": vm.patient.physician.id}});
            }

            if (vm.patient.modality.id !== undefined){
                must.push({term: {"modality.id": vm.patient.modality.id}});
            }

            PatientSearch.query(
                {
                    query: {
                        bool: {
                            must: must
                        }
                    }
                }, function(result) {
                vm.patients = result;
                vm.dtInstance.reloadData();
            });
        }

        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.dob = false;
        vm.days = GUIUtils.getDays();

        vm.openCalendar = openCalendar;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

        vm.clear = clear;

        function clear() {
            vm.patient = {
                firstName: null,
                lastName: null,
                dob: null,
                gender: null,
                shift: null,
                diabetic: null,
                treatmentDays: null,
                status: true,
                physician: {},
                modality: {}};

            vm.isFilter = false;
            vm.dtInstance.reloadData();
        }
    }
})();
