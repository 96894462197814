(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('access-new', {
                parent: 'patient-detail',
                url: '/access-new',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', 'entity', function ($stateParams, $state, $uibModal, entity) {
                    $uibModal.open({
                        templateUrl: 'app/entities/access/access-dialog.html',
                        controller: 'AccessDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            access: function () {
                                return {
                                    aType: 'AVF',
                                    isActive: true,
                                    isPrimary: true,
                                    placedBy: null,
                                    placementDate: null,
                                    noActiveOther: null,
                                    placementSiteOther: null,
                                    placementSite: null,
                                    buttonHole: null,
                                    avfgNoActiveSpecify: null,
                                    cvcGt90: null,
                                    cvcNoActiveSpecifyCvc: null,
                                    cvcType: null,
                                    id: null,
                                    patient: entity
                                };
                            }
                        }
                    }).result.then(function () {
                        $state.go('patient-detail', null, {reload: 'patient-detail'});
                    }, function () {
                        $state.go('patient-detail');
                    });
                }]
            })
            .state('access-view', {
                parent: 'patient-detail',
                url: '/{aid}/aview',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', 'entity', function ($stateParams, $state, $uibModal, entity) {
                    $uibModal.open({
                        templateUrl: 'app/entities/access/access-dialog.html',
                        controller: 'AccessDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            access: ['Access', function (Access) {
                                return Access.get({id: $stateParams.aid}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('patient-detail', null, {reload: 'patient-detail'});
                    }, function () {
                        $state.go('patient-detail');
                    });
                }]
            })
            .state('access-delete', {
                parent: 'patient-detail',
                url: '/{aid}/adelete',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/access/access-delete-dialog.html',
                        controller: 'AccessDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['Access', function (Access) {
                                return Access.get({id: $stateParams.aid}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('patient-detail', null, {reload: 'patient-detail'});
                    }, function () {
                        $state.go('^');
                    });
                }]
            });
    }

})();
