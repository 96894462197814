(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('InvoiceController', InvoiceController);

    InvoiceController.$inject = ['$scope', '$state', 'Invoice', 'InvoiceSearch', 'CoreService', 'DTOptionsBuilder',
        '$q', 'DTColumnBuilder', 'GUIUtils', '$compile'];

    function InvoiceController($scope, $state, Invoice, InvoiceSearch, CoreService, DTOptionsBuilder,
                               $q, DTColumnBuilder, GUIUtils, $compile) {
        var vm = this;

        vm.invoices = [];
        vm.clinic = CoreService.getCurrentClinic();
        vm.dtInstance = {};

        vm.search = search;
        vm.getPdf = getPdf;

        vm.dtOptions = DTOptionsBuilder.fromFnPromise(function () {
            var defer = $q.defer();
            if (!vm.searchQuery || vm.searchQuery == '') {
                InvoiceSearch.query({
                    query: {
                        bool: {
                            must: [
                                {
                                    bool: {
                                        "should": [
                                            {"term": {"clinicId": vm.clinic.id}},
                                            {"term": {"clinic.id": vm.clinic.id}}
                                        ]
                                    }
                                },
                                {term: {"delStatus": false}}
                            ]
                        }
                    }
                }, function (result) {
                    vm.invoices = result;
                    defer.resolve(result);
                });
            } else {
                defer.resolve($filter('filter')(vm.invoices, vm.searchQuery, undefined));
            }
            return defer.promise;
        }).withPaginationType('full_numbers')
            .withBootstrap()
            .withDOM('ltp')
            .withLanguage({
                "paginate": {
                    "first": '<i class="fa fa-angle-double-left" aria-hidden="true"></i>',
                    "previous": '<i class="fa fa-arrow-circle-left" aria-hidden="true"></i>',
                    "last": '<i class="fa fa-angle-double-right" aria-hidden="true"></i>',
                    "next": '<i class="fa fa-arrow-circle-right" aria-hidden="true"></i>'
                }
            })
            .withOption('headerCallback', function (header) {
                if (!vm.headerCompiled) {
                    vm.headerCompiled = true;
                    $compile(angular.element(header).contents())($scope);
                }
            })
            .withOption('fnRowCallback',
                function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                    $compile(nRow)($scope);
                });

        vm.dtColumns = [
            DTColumnBuilder.newColumn('id').withTitle('ID'),
            DTColumnBuilder.newColumn(null).withTitle('Start Date').renderWith(function (data, type, full) {
                return data.startDate ? moment(data.startDate).format("MM/DD/YYYY") : '';
            }),
            DTColumnBuilder.newColumn(null).withTitle('End Date').renderWith(function (data, type, full) {
                return data.endDate ? moment(data.endDate).format("MM/DD/YYYY") : '';
            }),
            DTColumnBuilder.newColumn(null).withTitle('Grand Total').renderWith(function (data, type, full) {
                return '$' + (data.grandTotal ? data.grandTotal : "0");
            }),
            DTColumnBuilder.newColumn(null).withTitle('Status').renderWith(function (data, type, full) {
                return data.status;
            }),
            DTColumnBuilder.newColumn(null).withTitle('Adjustment').renderWith(function (data, type, full) {
                return '$' + (data.adjustment ? data.adjustment : "0");
            }),
            DTColumnBuilder.newColumn('adjustmentComment').withTitle('Adjustment Comment'),
            DTColumnBuilder.newColumn(null).withTitle('Actions').withOption('class', 'col-btn').withOption('width', '155px').notSortable()
                .renderWith(actionsHtml)
        ];

        function actionsHtml(data, type, full, meta) {
            return '<a class="btn btn-default btn-sm details" ui-sref="invoice.edit({id: ' + data.id + '})" uib-tooltip="Edit"><i class="fa fa-external-link" aria-hidden="true"></i></a>' +
                '<a class="btn btn-default btn-sm delete" ui-sref="invoice.delete({id: ' + data.id + '})" uib-tooltip="Delete"><i class="fa fa-trash" aria-hidden="true"></i></a>' +
                '<a class="dt-btn primary" ng-click="vm.getPdf(' + full.id + ')" uib-tooltip="View Invoice"><i class="fa fa-file-pdf-o" aria-hidden="true"></i></a>' +
                '<a class="dt-btn primary" ui-sref="invoice-patient({id: ' + full.id + '})" uib-tooltip="Edit Patient Invoice"><i class="fa fa-user" aria-hidden="true"></i></a>' +
                '<a class="dt-btn primary" ui-sref="invoice-environmental({id: ' + full.id + '})" uib-tooltip="Edit Site Invoice"><i class="fa fa-gear" aria-hidden="true"></i></a>';
        }

        function search() {
            vm.dtInstance.reloadData();
        }

        function getPdf(id) {
            Invoice.getPdf({id: id});
        }
    }
})();
