(function() {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('EnvTestDialogController', EnvTestDialogController);

    EnvTestDialogController.$inject = ['envTest', '$uibModalInstance', 'EnvTestSchedule'];

    function EnvTestDialogController (envTest, $uibModalInstance, EnvTestSchedule) {
        var vm = this;

        vm.envTest = envTest;

        vm.schedules = EnvTestSchedule.by_test({id: envTest.id});

        vm.clear = function () {
            $uibModalInstance.dismiss('cancel');
        }

        vm.accept = function () {
            $uibModalInstance.close();
        }

        vm.cancelOrderTestItem = function (item) {
            if (!item.canceled) {
                EnvTestSchedule.cancel({id: item.id}, function () {
                }, function () {
                    $state.reload("env-order", {oid: vm.patientOrder.id});
                });
            }
        }
    }
})();
