(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('PatientOrderWizardController', PatientOrderWizardController);

    PatientOrderWizardController.$inject = ['$uibModal'];

    function PatientOrderWizardController($uibModal) {
        var vm = this;

        vm.title = 'Orders';
        vm.entityClassHumanized = 'Orders';
        vm.entityStateName = 'order-wizard';

        vm.openWizard = openWizard;

        function openWizard() {
            $uibModal.open({
                templateUrl: 'app/entities/patient-order-wizard/patient-order-wizard-dialog.html',
                controller: 'PatientOrderWizardDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'xl',
                windowTopClass: 'custom-dialog-styles',
                resolve: {

                }
            }).result.then(function () {

            }, function (result) {

            });
        }

    }
})();
