(function() {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('MedLocationDetailController', MedLocationDetailController);

    MedLocationDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'MedLocation', 'Medication', 'Corporation'];

    function MedLocationDetailController($scope, $rootScope, $stateParams, previousState, entity, MedLocation, Medication, Corporation) {
        var vm = this;

        vm.medLocation = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('dialysisApp:medLocationUpdate', function(event, result) {
            vm.medLocation = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
