(function() {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('MedicationDialogController', MedicationDialogController);

    MedicationDialogController.$inject = ['GUIUtils', '$timeout', '$scope', 'medication', '$uibModalInstance', 'Medication', 'MedDosageUnit', 'MedRoute', 'MedFrequencyFrom', 'MedFrequencyTo', 'MedLocation', 'Patient'];

    function MedicationDialogController (GUIUtils, $timeout, $scope, medication, $uibModalInstance, Medication, MedDosageUnit, MedRoute, MedFrequencyFrom, MedFrequencyTo, MedLocation, Patient) {
        var vm = this;

        vm.medication = medication;
        vm.clear = clear;
        vm.save = save;
        vm.meddosageunits = MedDosageUnit.query();
        vm.medroutes = MedRoute.query();
        vm.medfrequencyfroms = MedFrequencyFrom.query();
        vm.medfrequencytos = MedFrequencyTo.query();
        vm.medlocations = MedLocation.query();

        vm.days = GUIUtils.getDays();

        if (vm.medication.days) {
            vm.medication.days2 = GUIUtils.weekCodeStringToDays(vm.medication.days);
        }

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            vm.medication.days = GUIUtils.daysToWeekCodeString(vm.medication.days2);

            if (vm.medication.id !== null) {
                Medication.update(vm.medication, onSaveSuccess, onSaveError);
            } else {
                Medication.save(vm.medication, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('dialysisApp:medicationUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
