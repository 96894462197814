(function() {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('PatientOrderTestController', PatientOrderTestController);

    PatientOrderTestController.$inject = ['canCancel', 'CoreService', '$state', '$compile', '$scope', 'GUIUtils', '$q', '$uibModal', 'PatientOrderTest', 'patientOrder', 'DTOptionsBuilder', 'DTColumnBuilder'];

    function PatientOrderTestController (canCancel, CoreService, $state, $compile, $scope, GUIUtils, $q, $uibModal, PatientOrderTest, patientOrder, DTOptionsBuilder, DTColumnBuilder) {
        var vm = this;

        vm.patientOrder = patientOrder;
        vm.patientOrderTests = [];
        vm.dtInstance = {};

        vm.canCancel = false;

        if (vm.patientOrder.ordStatus == 'SCHEDULED' && canCancel) {
            vm.canCancel = CoreService.getCurrentEmployee().id == vm.patientOrder.signedBy.id;
        }

        vm.dtOptions = DTOptionsBuilder.fromFnPromise(function() {
            var defer = $q.defer();
            PatientOrderTest.order({id: patientOrder.id}, function(result) {
                vm.patientOrderTests = result;
                defer.resolve(result);
            });

            return defer.promise;
        }).withPaginationType('full_numbers').withBootstrap().withDOM('tip')
            .withOption('paging', false).withOption('aaSorting', []).withOption('fnRowCallback',
            function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                $compile(nRow)($scope);
            });

        vm.dtColumns = [
            DTColumnBuilder.newColumn(null).withTitle('Test').renderWith(function (data, type, full) {
                return data.compendium.code;
            }),
            DTColumnBuilder.newColumn(null).withTitle('Description').renderWith(function (data, type, full) {
                return data.compendium.description;
            }),
            DTColumnBuilder.newColumn(null).withTitle('Frequency').renderWith(function (data, type, full) {
                return data.orderFrequency.name;
            }),
            DTColumnBuilder.newColumn(null).withTitle('Diagnosis (ICD10)').renderWith(function (data, type, full, meta) {
                return data.diagnoses != null && data.diagnoses.length > 1 ? data.diagnoses[0].icd10Code + showICD10Html(meta.row): data.diagnoses[0].icd10Code;
            }),
            DTColumnBuilder.newColumn(null).withTitle('Renal').renderWith(function (data, type, full) {
                return GUIUtils.colorHtmlYesNo(data.renal);//colorHtml(data.renal);
            }),
            DTColumnBuilder.newColumn(null).withTitle('Start Date').renderWith(function (data, type, full) {
                return moment(data.staringDate).format("MM/DD/YYYY");
            }),
            DTColumnBuilder.newColumn(null).withTitle('End Date').renderWith(function (data, type, full) {
                return moment(data.endDate).format("MM/DD/YYYY");
            }),
            DTColumnBuilder.newColumn(null).withTitle('Actions').withOption('width', '155px').notSortable()
                .renderWith(actionsHtml)
        ];

        function actionsHtml(data, type, full, meta){
            var stButtons = '';

            if (canCancel) {
                stButtons += '<a class="btn-sm btn-warning" ui-sref="patient-order-update.patient-order-test-detail({tid:' + data.id + '})">' +
                    '   <i class="fa fa-edit"></i></a>&nbsp;';
            } else {
                stButtons += '<a class="btn-sm btn-warning" ui-sref="patient-order-view.patient-order-test-detail({tid:' + data.id + '})">' +
                    '   <i class="fa fa-edit"></i></a>&nbsp;';
            }

            return stButtons;
        }

        function showICD10Html(row) {
            return '&nbsp;&nbsp;<a class="btn-sm btn-info" ng-click="vm.showICD10(' + row + ')">' +
                '   <i class="fa fa-list-ol"></i></a>';
        }

        vm.showICD10 = function (index) {
            $uibModal.open({
                templateUrl: 'app/entities/diagnosis/diagnosis-dialog.html',
                controller: 'DiagnosisDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    entity: vm.patientOrderTests[index]
                }
            });
        }
    }
})();
