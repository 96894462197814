(function() {
    'use strict';

    angular
        .module('dialysisApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('lab-notification', {
            parent: 'entity',
            url: '/lab-notification',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'LabNotifications'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/lab-notification/lab-notifications.html',
                    controller: 'LabNotificationController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
            }
        })
        .state('lab-notification-detail', {
            parent: 'lab-notification',
            url: '/lab-notification/{id}',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'LabNotification'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/lab-notification/lab-notification-detail.html',
                    controller: 'LabNotificationDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: ['$stateParams', 'LabNotification', function($stateParams, LabNotification) {
                    return LabNotification.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'lab-notification',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('lab-notification-detail.edit', {
            parent: 'lab-notification-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/lab-notification/lab-notification-dialog.html',
                    controller: 'LabNotificationDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['LabNotification', function(LabNotification) {
                            return LabNotification.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('lab-notification.new', {
            parent: 'lab-notification',
            url: '/new',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/lab-notification/lab-notification-dialog.html',
                    controller: 'LabNotificationDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                name: null,
                                description: null,
                                startDate: null,
                                endDate: null,
                                active: true,
                                type: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('lab-notification', null, { reload: 'lab-notification' });
                }, function() {
                    $state.go('lab-notification');
                });
            }]
        })
        .state('lab-notification.edit', {
            parent: 'lab-notification',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/lab-notification/lab-notification-dialog.html',
                    controller: 'LabNotificationDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['LabNotification', function(LabNotification) {
                            return LabNotification.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('lab-notification', null, { reload: 'lab-notification' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('lab-notification.delete', {
            parent: 'lab-notification',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/lab-notification/lab-notification-delete-dialog.html',
                    controller: 'LabNotificationDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['LabNotification', function(LabNotification) {
                            return LabNotification.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('lab-notification', null, { reload: 'lab-notification' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
