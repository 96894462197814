(function() {
    'use strict';

    angular
        .module('dialysisApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('infection', {
            parent: 'patient-abs',
            url: '/infection',
            ncyBreadcrumb: {
                label: 'Infections',
            },
            data: {
                authorities: ['ROLE_SUPERVISOR', 'ROLE_NURSE', 'ROLE_DOCTOR'],
                pageTitle: 'Infections'
            },
            views: {
                'content@patient-abs': {
                    templateUrl: 'app/entities/infection/infections.html',
                    controller: 'InfectionController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
            }
        })
        .state('infection.new', {
            parent: 'infection',
            url: '/new',
            data: {
                authorities: ['ROLE_SUPERVISOR', 'ROLE_NURSE', 'ROLE_DOCTOR'],
            },
            onEnter: ['$stateParams', '$state', '$uibModal', 'entity', function($stateParams, $state, $uibModal, entity) {
                $uibModal.open({
                    templateUrl: 'app/entities/infection/infection-dialog.html',
                    controller: 'InfectionDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        infection: function () {
                            return {
                                suspectedDate: null,
                                confirmedDate: null,
                                accessCondition: null,
                                lvAccess: null,
                                hospitalization: false,
                                hospitalizationDate: null,
                                drInfection: null,
                                drInfectionDate: null,
                                aStarted: null,
                                aStartedDate: null,
                                intervention: null,
                                id: null,
                                patient: entity
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('infection', null, { reload: 'infection' });
                }, function() {
                    $state.go('infection', null, { reload: 'infection' });
                });
            }]
        })
        .state('infection.view', {
            parent: 'infection',
            url: '/{iid}/view',
            data: {
                authorities: ['ROLE_SUPERVISOR', 'ROLE_NURSE', 'ROLE_DOCTOR'],
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/infection/infection-dialog.html',
                    controller: 'InfectionDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        infection: ['Infection', function(Infection) {
                            return Infection.get({id : $stateParams.iid}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('infection', {id: $stateParams.id}, { reload: 'infection' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('infection.delete', {
            parent: 'infection',
            url: '/{iid}/delete',
            data: {
                authorities: ['ROLE_SUPERVISOR', 'ROLE_NURSE', 'ROLE_DOCTOR'],
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/infection/infection-delete-dialog.html',
                    controller: 'InfectionDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Infection', function(Infection) {
                            return Infection.get({id : $stateParams.iid}).$promise;
                        }],
                    }
                }).result.then(function() {
                    $state.go('infection', null, { reload: 'infection' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }
})();
