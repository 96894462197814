(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('PdAdequacyPatientsController', PdAdequacyPatientsController);

    PdAdequacyPatientsController.$inject = ['$state', '$scope', 'PdAdequacy', 'CoreService', '$q', 'DTColumnBuilder', 'DTOptionsBuilder', 'GUIUtils', '$filter', '$compile', '$rootScope'];

    function PdAdequacyPatientsController($state, $scope, PdAdequacy, CoreService, $q, DTColumnBuilder, DTOptionsBuilder, GUIUtils, $filter, $compile, $rootScope) {
        $rootScope.$broadcast('dialysisApp:dashboardPdAdequacyCollected', {});

        var vm = this;
        vm.title = 'Kinetics';
        vm.entityClassHumanized = 'Kinetic';
        vm.entityStateName = 'kinetic';

        vm.clinic = CoreService.getCurrentClinic();
        vm.patients = [];
        vm.search = search;
        vm.dtInstance = {};
        vm.addPDAdequacy = addPDAdequacy;

        vm.selected = {};
        vm.selectAll = false;
        vm.toggleAll = toggleAll;
        vm.toggleOne = toggleOne;
        var titleHtml = '<input type="checkbox" ng-model="vm.selectAll" ng-change="vm.toggleAll()">';

        vm.dtOptions = DTOptionsBuilder.fromFnPromise(function () {
            var defer = $q.defer();
            if (!vm.searchQuery || vm.searchQuery == '') {
                PdAdequacy.patients({id: vm.clinic.id}, function (result) {
                    vm.patients = result;
                    defer.resolve(result);
                });
            } else {
                defer.resolve($filter('filter')(vm.patients, vm.searchQuery, undefined));
            }
            return defer.promise;
        }).withPaginationType('full_numbers')
            .withBootstrap()
            .withDOM('ltp')
            .withLanguage({
                "paginate": {
                    "first": '<i class="fa fa-angle-double-left" aria-hidden="true"></i>',
                    "previous": '<i class="fa fa-arrow-circle-left" aria-hidden="true"></i>',
                    "last": '<i class="fa fa-angle-double-right" aria-hidden="true"></i>',
                    "next": '<i class="fa fa-arrow-circle-right" aria-hidden="true"></i>'
                }
            })
            .withOption('aaSorting', [[1, 'asc']])
            .withOption('createdRow', function (row, data, dataIndex) {
                $compile(angular.element(row).contents())($scope);
            })
            .withOption('headerCallback', function (header) {
                if (!vm.headerCompiled) {
                    vm.headerCompiled = true;
                    $compile(angular.element(header).contents())($scope);
                }
            });

        vm.dtColumns = [
            DTColumnBuilder.newColumn(null).withTitle(titleHtml).notSortable().renderWith(function (data, type, full, meta) {
                vm.selected[full.id] = false;

                return '<input type="checkbox" ng-model="vm.selected[' + data.id + ']" ng-click="vm.toggleOne()">';
            }),
            DTColumnBuilder.newColumn(null).withTitle('Name').renderWith(function (data, type, full) {
                return data.lastName + ', ' + data.firstName + ' ' + (data.middleInitial ? data.middleInitial : "");
            }),
            DTColumnBuilder.newColumn(null).withTitle('DOB').renderWith(function (data, type, full) {
                return moment(data.dob).format("MM/DD/YYYY");
            }),
            DTColumnBuilder.newColumn('gender').withTitle('Gender'),
            DTColumnBuilder.newColumn(null).withTitle('Modality').renderWith(function (data, type, full) {
                return data.modality ? data.modality.name : "";
            }),
            DTColumnBuilder.newColumn(null).withTitle('Days').renderWith(function (data, type, full) {
                return data.drawDays ? GUIUtils.expandWeekCodeString(data.drawDays, ',') : "";
            }),
            DTColumnBuilder.newColumn('shift').withTitle('Shift'),
            DTColumnBuilder.newColumn(null).withTitle('Status').renderWith(function (data, type, full) {
                return data.status ? 'Active' : 'Inactive';
            }),
            DTColumnBuilder.newColumn(null).withTitle('Physician').renderWith(function (data, type, full) {
                return data.employee ? 'Dr. ' + data.employee.name + ' ' + data.employee.lastName : "";
            }),
            DTColumnBuilder.newColumn(null).withTitle('FDD Date').renderWith(function (data, type, full) {
                return moment(data.fddDate).format("MM/DD/YYYY");
            }),
            DTColumnBuilder.newColumn(null).withTitle('Diabetic').renderWith(function (data, type, full) {
                return GUIUtils.colorHtmlYesNo(data.diabetic);
            }),
        ];

        function search() {
            vm.dtInstance.reloadData();
        }

        function toggleAll() {
            for (var id in vm.selected) {
                if (vm.selected.hasOwnProperty(id)) {
                    vm.selected[id] = vm.selectAll;
                }
            }
        }

        function toggleOne() {
            for (var id in vm.selected) {
                if (vm.selected.hasOwnProperty(id)) {
                    if (!vm.selected[id]) {
                        vm.selectAll = false;
                        return;
                    }
                }
            }

            vm.selectAll = true;
        }

        function addPDAdequacy() {
            var patients = [];
            for (var id in vm.selected) {
                if (vm.selected[id]) {
                    var _patient = $.grep(vm.patients, function (patient, i) {
                        return patient.id == id;
                    })[0];

                    patients.push(_patient);
                }
            }

            if (patients.length > 0) {
                $state.go('pd-adequacy-add', {patients: patients});
            }
        }
    }
})();
