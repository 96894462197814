(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('machine', {
                parent: 'entity',
                url: '/machine',
                data: {
                    authorities: ['ROLE_SUPERVISOR', 'ROLE_NURSE', 'ROLE_DOCTOR'],
                    pageTitle: 'Sites'
                },
                ncyBreadcrumb: {
                    label: 'Sites'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/machine/machines.html',
                        controller: 'MachineController',
                        controllerAs: 'vm'
                    },
                    // 'select-filter@machine': {
                    //     templateUrl: 'app/entities/machine/machine-select-filter.html',
                    //     controller: 'MachineSelectFilter',
                    //     controllerAs: 'vm'
                    // }
                },
                resolve: {
                    clinic: ['CoreService', function (CoreService) {
                        return CoreService.getCurrentClinic();
                    }]
                }
            })
            .state('machine-detail', {
                abstract: true,
                parent: 'entity',
                url: '/machine/{id}',
                data: {
                    authorities: ['ROLE_SUPERVISOR', 'ROLE_NURSE', 'ROLE_DOCTOR'],
                    pageTitle: 'Site'
                },
                ncyBreadcrumb: {
                    label: 'Orders',
                    parent: 'machine'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/machine/machine-detail.html',
                        controller: 'MachineDetailController',
                        controllerAs: 'vm'
                    },
                    'select-filter@machine-detail': {
                        templateUrl: 'app/entities/machine/machine-select-filter.html',
                        controller: 'MachineSelectFilter',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    machine: ['$state', '$stateParams', 'Machine', function ($state, $stateParams, Machine) {
                        return Machine.get({id: $stateParams.id}).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        return {
                            name: $state.current.name || 'machine',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                    }],
                    clinic: ['CoreService', function (CoreService) {
                        return CoreService.getCurrentClinic();
                    }]
                }
            })
            .state('machine-detail-menu', {
                parent: 'entity',
                url: '/machine-menu/{statem}',
                data: {
                    authorities: ['ROLE_SUPERVISOR', 'ROLE_NURSE', 'ROLE_DOCTOR'],
                    pageTitle: 'Machine'
                },
                views: {
                    'content@': {
                        controller: 'MachineMenuController',
                        controllerAs: 'vm'
                    }
                }
            })
            .state('machine.new', {
                parent: 'machine',
                url: '/new',
                data: {
                    authorities: ['ROLE_SUPERVISOR', 'ROLE_NURSE', 'ROLE_DOCTOR'],
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/machine/machine-dialog.html',
                        controller: 'MachineDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            entity: ['CoreService', function (CoreService) {
                                return {
                                    name: null,
                                    location: null,
                                    mStatus: true,
                                    mType: null,
                                    notes: null,
                                    id: null,
                                    clinic: CoreService.getCurrentClinic()
                                };
                            }]
                        }
                    }).result.then(function () {
                        $state.go('machine', null, {reload: 'machine'});
                    }, function () {
                        $state.go('machine');
                    });
                }]
            })
            .state('machine.edit', {
                parent: 'machine',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_SUPERVISOR', 'ROLE_NURSE', 'ROLE_DOCTOR'],
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/machine/machine-dialog.html',
                        controller: 'MachineDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            entity: ['Machine', function (Machine) {
                                return Machine.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('machine', null, {reload: 'machine'});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('machine.delete', {
                parent: 'machine',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_SUPERVISOR', 'ROLE_NURSE', 'ROLE_DOCTOR'],
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/machine/machine-delete-dialog.html',
                        controller: 'MachineDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['Machine', function (Machine) {
                                return Machine.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('machine', null, {reload: 'machine'});
                    }, function () {
                        $state.go('^');
                    });
                }]
            });
    }
})();
