(function() {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('InvoiceEnvironmentalDetailController', InvoiceEnvironmentalDetailController);

    InvoiceEnvironmentalDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'InvoiceEnvironmental', 'Invoice'];

    function InvoiceEnvironmentalDetailController($scope, $rootScope, $stateParams, previousState, entity, InvoiceEnvironmental, Invoice) {
        var vm = this;

        vm.invoiceEnvironmental = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('dialysisApp:invoiceEnvironmentalUpdate', function(event, result) {
            vm.invoiceEnvironmental = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
