(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('PatientOrdersUnsignedController', PatientOrdersUnsignedController);

    PatientOrdersUnsignedController.$inject = ['ROLES', 'EMPLOYEE_TYPE', '$compile', '$scope', 'PatientOrder', '$q',
        'DTColumnBuilder', 'DTOptionsBuilder', 'CoreService', '$filter', 'moment', '$rootScope', 'GUIUtils',
        '$uibModal', 'DataTablesService', 'toastr'];

    function PatientOrdersUnsignedController(ROLES, EMPLOYEE_TYPE, $compile, $scope, PatientOrder, $q, DTColumnBuilder,
                                             DTOptionsBuilder, CoreService, $filter, moment, $rootScope, GUIUtils,
                                             $uibModal, DataTablesService, toastr) {
        var vm = this;

        vm.orders = [];
        vm.dtInstance = {};
        vm.title = "Unsigned Orders";
        vm.clinic = CoreService.getCurrentClinic();
        vm.selected = {};
        vm.selectAll = false;

        vm.search = search;
        vm.toggleAll = DataTablesService.toggleAll;
        vm.toggleOne = DataTablesService.toggleOne;
        vm.signOrders = signOrders;
        vm.modalSignDialog = modalSignDialog;
        vm.detail = detail;

        var titleHtml = '<input type="checkbox" ng-model="vm.selectAll" ng-change="vm.toggleAll(vm.selected, vm.selectAll)">';
        vm.orderStatuses = {
            'CANCELED': {label: 'Cancelled', template: 'danger'},
            'IN_PROCESS': {label: 'Ongoing', template: 'info'},
            'FINISHED': {label: 'Finalized', template: 'success'},
            'SCHEDULED': {label: 'Schedule', template: 'warning'}
        };

        vm.employee = CoreService.getCurrentEmployee();
        vm.signOrdersAllowed = signOrdersAllowed;

        vm.dtOptions = DTOptionsBuilder.fromFnPromise(function () {
            var defer = $q.defer();
            if (!vm.searchQuery || vm.searchQuery == '') {
                PatientOrder.unsignedOrders({id: vm.clinic.id}, function (result) {
                    vm.selected = {};
                    vm.selectAll = false;
                    vm.orders = result;
                    defer.resolve(result);
                });
            } else {
                defer.resolve($filter('filter')(vm.orders, vm.searchQuery, undefined));
            }
            return defer.promise;
        }).withPaginationType('full_numbers')
            .withBootstrap()
            .withOption('aaSorting', [])
            .withDOM('ltp')
            .withLanguage({
                "paginate": {
                    "first": '<i class="fa fa-angle-double-left" aria-hidden="true"></i>',
                    "previous": '<i class="fa fa-arrow-circle-left" aria-hidden="true"></i>',
                    "last": '<i class="fa fa-angle-double-right" aria-hidden="true"></i>',
                    "next": '<i class="fa fa-arrow-circle-right" aria-hidden="true"></i>'
                }
            })
            .withOption('fnRowCallback',
                function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                    $compile(nRow)($scope);
                }).withOption('headerCallback', function (header) {
                if (!vm.headerCompiled) {
                    vm.headerCompiled = true;
                    $compile(angular.element(header).contents())($scope);
                }
            });

        vm.dtColumns = [
            signOrdersAllowed() ?
                DTColumnBuilder.newColumn(null).withTitle(titleHtml).notSortable().renderWith(function (data, type, full, meta) {
                    vm.selected[full.id] = false;

                    return '<input type="checkbox" ng-model="vm.selected[' + data.id + ']" ng-click="vm.toggleOne(vm.selected, vm.selectAll)">';
                }) : DTColumnBuilder.newColumn(null).withTitle(titleHtml).notSortable().renderWith(function (data, type, full, meta) {
                    vm.selected[full.id] = false;

                    return '<input type="checkbox" ng-model="vm.selected[' + data.id + ']" ng-click="vm.toggleOne(vm.selected, vm.selectAll)">';
                }).notVisible()
            ,
            DTColumnBuilder.newColumn(null).withTitle('Patient').renderWith(function (data, type, full) {
                return data.firstName + ' ' + data.middleInitial + ' ' + data.lastName;
            }),
            DTColumnBuilder.newColumn(null).withTitle('DOB').renderWith(function (data, type, full) {
                return data ? moment(data.dob).format($rootScope.displayDateformat) : "";
            }),
            DTColumnBuilder.newColumn(null).withTitle('Physician').renderWith(function (data, type, full) {
                return data.physician;
            }),
            DTColumnBuilder.newColumn(null).withTitle('Start Date').renderWith(function (data, type, full) {
                return data ? moment(data.startDate).format($rootScope.displayDateformat) : "";
            }),
            DTColumnBuilder.newColumn(null).withTitle('Status').renderWith(orderStatusHtml),
            DTColumnBuilder.newColumn('id').withTitle('Order Number').withOption('width', '120px'),
            DTColumnBuilder.newColumn(null).withTitle('Actions').withOption('width', '90px').withOption('class', 'col-btn').notSortable()
                .renderWith(actionsHtml)
        ];

        function actionsHtml(data, type, full, meta) {
            var stButtons = '';

            stButtons += '<a class="btn btn-sm btn-default" ng-click="vm.detail(' + data.id + ')">' +
                '   <i class="fa fa-eye"></i></a>&nbsp;';

            return stButtons;
        }

        function search() {
            vm.dtInstance.reloadData();
        }

        function signOrders() {
            var orderIds = DataTablesService.getSelectedIds(vm.selected);

            if (orderIds.length > 0) {
                vm.isSaving = true;
                PatientOrder.signOrders({orderIds: orderIds}, function () {
                    vm.dtInstance.reloadData();
                    vm.isSaving = false;
                }, function () {
                })
            }
        }

        function signOrdersAllowed() {
            var isDoctor = $.grep(vm.employee.user.authorities, function (authority, i) {
                return authority.name == ROLES.ROLE_DOCTOR;
            }).length > 0;

            return vm.employee.employeeType.name == EMPLOYEE_TYPE.PHYSICIAN && isDoctor;
        }

        function orderStatusHtml(data, type, full) {
            var orderStatus = vm.orderStatuses[data.ordStatus];
            return GUIUtils.getStatusTemplate(orderStatus.label, orderStatus.template);
        }

        function modalSignDialog(orderId) {
            var selectedOrderIds = orderId ? [orderId] : DataTablesService.getSelectedIds(vm.selected);

            if (selectedOrderIds.length > 0) {
                $uibModal.open({
                    templateUrl: 'app/entities/patient-order/dashboard/patient-orders-sign-dialog.html',
                    controller: 'PatientOrdersSignDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    windowTopClass: 'custom-dialog-styles',
                    resolve: {
                        orderIds: function () {
                            return {values: selectedOrderIds};
                        },
                    }
                }).result.then(function () {
                    vm.dtInstance.reloadData();
                });
            } else toastr.error('No order selected. Please select one to proceed.')
        }

        function detail(orderId) {
            $uibModal.open({
                templateUrl: 'app/entities/patient-order/detail/patient-order-detail-dialog.html',
                controller: 'PatientOrderDetailDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    patientOrder: function () {
                        return PatientOrder.get({id: orderId}).$promise;
                    }
                }
            });
        }
    }
})();
