(function() {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('ModalityDeleteController',ModalityDeleteController);

    ModalityDeleteController.$inject = ['$uibModalInstance', 'entity', 'Modality'];

    function ModalityDeleteController($uibModalInstance, entity, Modality) {
        var vm = this;

        vm.modality = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            Modality.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
