(function() {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('ClinicAdminDeleteDialogController', ClinicAdminDeleteDialogController);

    ClinicAdminDeleteDialogController.$inject = ['$uibModalInstance'];

    function ClinicAdminDeleteDialogController($uibModalInstance) {
        var vm = this;

        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {

        }
    }
})();
