(function() {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('RptCompendiumMessageDeleteController',RptCompendiumMessageDeleteController);

    RptCompendiumMessageDeleteController.$inject = ['$uibModalInstance', 'entity', 'RptCompendiumMessage'];

    function RptCompendiumMessageDeleteController($uibModalInstance, entity, RptCompendiumMessage) {
        var vm = this;

        vm.rptCompendiumMessage = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            RptCompendiumMessage.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
