(function() {
    'use strict';
    angular
        .module('dialysisApp')
        .factory('RptTemplate', RptTemplate);

    RptTemplate.$inject = ['$resource', 'DataUtils', 'DateUtils'];

    function RptTemplate ($resource, DataUtils, DateUtils) {
        var resourceUrl =  'api/rpt-templates/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'getReportPdf': {
                url: 'api/rpt-templates/generate-report',
                method: 'POST',
                responseType: 'arraybuffer',
                transformResponse: function (data, headers) {
                    DataUtils.downloadAndOpenPDFFile(data, headers);
                },
                transformRequest: function (data) {
                    var copy = angular.copy(data);

                    copy.startDate = DateUtils.convertLocalDateToServer(copy.startDate);
                    copy.endDate = DateUtils.convertLocalDateToServer(copy.endDate);

                    return angular.toJson(copy);
                }
            },
            'export': {method: 'POST', url: 'api/rpt-templates/export'}
        });
    }
})();
