(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .factory('PopupService', PopupService);

    PopupService.$inject = ['$uibModal'];

    function PopupService($uibModal) {

        var service = {
            showPatientBarcodePopup: showPatientBarcodePopup,
            showPatientResultDetPopup: showPatientResultDetPopup,
            showEnvResultDetPopup: showEnvResultDetPopup,
            showEnvironmentalBarcodePopup: showEnvironmentalBarcodePopup
        };

        return service;

        function showPatientBarcodePopup(groupNumberId, patientOrder) {
            $uibModal.open({
                templateUrl: 'app/entities/clinic-schedule-detail/dashboard-v2/patient-barcode-detail.html',
                controller: 'PatientBarcodeDetail2Controller',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'xl',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                   barcode: ['PatientBarcode', function (PatientBarcode) {
                       if (groupNumberId) {
                           return PatientBarcode.get({barcode: groupNumberId}).$promise;
                       } else {
                           return groupNumberId;
                       }
                   }],
                   patient: ['Patient', function(Patient) {
                        if (groupNumberId) {
                            return Patient.byBarcode({barcode: groupNumberId}).$promise;
                        } else {
                            return Patient.get({id: patientOrder.patientId}).$promise;
                        }
                   }],
                   patientOrder: patientOrder
                }
            });
        }

        function showEnvironmentalBarcodePopup(envOrder) {
            $uibModal.open({
                templateUrl: 'app/entities/clinic-schedule-detail/dashboard-v2/machine-barcode-detail.html',
                controller: 'MachineBarcodeDetail2Controller',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'xl',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    barcode: ['MachineBarcode', function (MachineBarcode) {
                        if (envOrder.barcode) {
                            return MachineBarcode.get({barcode: envOrder.barcode}).$promise;
                        } else {
                            return envOrder.barcode;
                        }
                    }],
                    machine: ['Machine', function (Machine) {
                        if (envOrder.barcode) {
                            return Machine.byBarcode({barcode: envOrder.barcode}).$promise;
                        } else {
                            return envOrder;
                        }
                    }],
                }
            });
        }

        /**
         *
         * @param idOrPatientResult id or patientResult Object
         */
        function showPatientResultDetPopup(idOrPatientResult) {
            $uibModal.open({
                templateUrl: 'app/entities/patient-result-det/patient-result-dets.html',
                controller: 'PatientResultDetController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'xl',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    patientResult: ['PatientResult', function (PatientResult) {
                        if (isObject(idOrPatientResult)) {
                            return idOrPatientResult;
                        } else {
                            return PatientResult.get({id: idOrPatientResult}).$promise;
                        }
                    }]
                }
            });
        }

        function showEnvResultDetPopup(idOrEnvResult) {
            $uibModal.open({
                templateUrl: 'app/entities/env-result-det/env-result-dets.html',
                controller: 'EnvResultDetController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'xl',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    envResult: ['EnvResult', function (EnvResult) {
                        if (isObject(idOrEnvResult)) {
                            return idOrEnvResult;
                        } else {
                            return EnvResult.get({id: idOrEnvResult}).$promise;
                        }
                    }]
                }
            });
        }

        function isObject(value) {
            return value instanceof Object;
        }
    }
})();
