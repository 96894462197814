/**
 * Created by hermeslm on 3/28/17.
 */
(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('ReportsController', ReportsController);

    ReportsController.$inject = ['OdsParamType', 'CoreService', 'Employee', 'GUIUtils', 'moment', 'Patient', 'RptMetadata',
        'Modality', 'Compendium'];

    function ReportsController(OdsParamType, CoreService, Employee, GUIUtils, moment, Patient, RptMetadata,
                               Modality, Compendium) {

        var vm = this;

        vm.date = new Date();
        vm.lastDate = new Date();
        vm.lastDate.setMonth(vm.date.getMonth() - 1);

        vm.currentClinic = CoreService.getCurrentClinic();

        vm.physicians = [];

        vm.patientsList = [];

        vm.modalities = [];
        vm.compendiums = [];

        Patient.getAllByClinic({id: vm.currentClinic.id}).$promise.then(function (data) {
            for (var i = 0; i < data.length; i++) {
                vm.patientsList.push(data[i]);
            }
        });

        Employee.employeeCorpPhysicians().$promise.then(function (data) {
            for (var i = 0; i < data.length; i++) {
                vm.physicians.push(data[i]);
            }
        });

        vm.modalities = Modality.query();

        vm.compendiums = Compendium.query();

        vm.reportsGroup = {
            title: 'Reports Groups',
            groups: []
        };

        vm.reportsGroup.groups.push({
            id: 5,
            open: false,
            disabled: false,
            title: 'Patient Specifics',
            icon: 'fa fa-dashboard',
            reports: [
              {
                    id: 1,
                    title: 'Monthly Orders',
                    url: '/api/report/monthly-orders',
                    base64: true,
                    modalSize: 'lg',
                    params: [{
                        id: 3,
                        title: 'Patients',
                        hideTitle: true,
                        name: 'patients',
                        required: true,
                        hideInFooter: true,
                        placeholder: 'Select patients',
                        type: OdsParamType.TABLE_SELECT,
                        valueField: 'id',
                        gridOptions: {
                            columnDef: [{
                                id: true,
                                title: 'Name',
                                field: 'null'
                            }, {
                                id: false,
                                title: 'Name',
                                field: 'null',
                                render: function (data, type, full) {
                                    return full.lastName + ', ' + full.firstName + ' ' +
                                        (full.middleInitial ? full.middleInitial : '');
                                }
                            }, {
                                id: false,
                                title: 'DOB',
                                field: 'null',
                                render: function (data, type, full) {
                                    return moment(full.dob).format('MM/DD/YYYY');
                                }
                            }, {
                                id: false,
                                title: 'Gender',
                                field: 'gender'
                            }, {
                                id: false,
                                title: 'Language',
                                field: 'language',
                                render: function (data, type, full) {
                                    return full.language !== null ? full.language : 'No Language';
                                }
                            }],
                            data: vm.patientsList,
                            preSelected: []
                        },
                        value: [],
                        hidden: false
                    }, {
                        id: 2,
                        title: 'End Date',
                        name: 'end_date',
                        required: true,
                        type: OdsParamType.DATE,
                        value: new Date()
                    }]
                },
                {
                    id: 2,
                    title: 'Dietitian Monthly',
                    url: '/api/report/dietitian-monthly',
                    base64: true,
                    modalSize: 'lg',
                    params: [{
                        id: 3,
                        title: 'Patients',
                        hideTitle: true,
                        name: 'patients',
                        required: true,
                        hideInFooter: true,
                        placeholder: 'Select patients',
                        type: OdsParamType.TABLE_SELECT,
                        valueField: 'id',
                        gridOptions: {
                            columnDef: [{
                                id: true,
                                title: 'Name',
                                field: 'null'
                            }, {
                                id: false,
                                title: 'Name',
                                field: 'null',
                                render: function (data, type, full) {
                                    return full.lastName + ', ' + full.firstName + ' ' +
                                        (full.middleInitial ? full.middleInitial : '');
                                }
                            }, {
                                id: false,
                                title: 'DOB',
                                field: 'null',
                                render: function (data, type, full) {
                                    return moment(full.dob).format('MM/DD/YYYY');
                                }
                            }, {
                                id: false,
                                title: 'Gender',
                                field: 'gender'
                            }, {
                                id: false,
                                title: 'Language',
                                field: 'language',
                                render: function (data, type, full) {
                                    return full.language !== null ? full.language : 'No Language';
                                }
                            }],
                            data: vm.patientsList,
                            preSelected: []
                        },
                        value: [],
                        hidden: false
                    }, {
                        title: 'Start Date',
                        name: 'start_date',
                        required: true,
                        type: OdsParamType.DATE,
                        value: new Date()//new Date(1980, 0, 24) way to pass default value in params
                    }, {
                        title: 'End Date',
                        name: 'end_date',
                        required: true,
                        type: OdsParamType.DATE,
                        value: new Date()
                    }]
                },
                {
                    id: 3,
                    title: 'Nursing Monthly',
                    url: '/api/report/nursing-monthly',
                    base64: true,
                    modalSize: 'lg',
                    params: [{
                        id: 3,
                        title: 'Patients',
                        hideTitle: true,
                        name: 'patients',
                        required: true,
                        hideInFooter: true,
                        placeholder: 'Select patients',
                        type: OdsParamType.TABLE_SELECT,
                        valueField: 'id',
                        gridOptions: {
                            columnDef: [{
                                id: true,
                                title: 'Name',
                                field: 'null'
                            }, {
                                id: false,
                                title: 'Name',
                                field: 'null',
                                render: function (data, type, full) {
                                    return full.lastName + ', ' + full.firstName + ' ' +
                                        (full.middleInitial ? full.middleInitial : '');
                                }
                            }, {
                                id: false,
                                title: 'DOB',
                                field: 'null',
                                render: function (data, type, full) {
                                    return moment(full.dob).format('MM/DD/YYYY');
                                }
                            }, {
                                id: false,
                                title: 'Gender',
                                field: 'gender'
                            }, {
                                id: false,
                                title: 'Language',
                                field: 'language',
                                render: function (data, type, full) {
                                    return full.language !== null ? full.language : 'No Language';
                                }
                            }],
                            data: vm.patientsList,
                            preSelected: []
                        },
                        value: [],
                        hidden: false
                    }, {
                        title: 'Start Date',
                        name: 'start_date',
                        required: true,
                        type: OdsParamType.DATE,
                        value: new Date()//new Date(1980, 0, 24) way to pass default value in params
                    }, {
                        title: 'End Date',
                        name: 'end_date',
                        required: true,
                        type: OdsParamType.DATE,
                        value: new Date()
                    }]
                },
                {
                    id: 4,
                    title: 'Physician Rounding Report',
                    url: '/api/report/physician-rounding',
                    base64: true,
                    modalSize: 'lg',
                    params: [{
                        id: 3,
                        title: 'Patients',
                        hideTitle: true,
                        name: 'patients',
                        required: true,
                        hideInFooter: true,
                        placeholder: 'Select patients',
                        type: OdsParamType.TABLE_SELECT,
                        valueField: 'id',
                        gridOptions: {
                            columnDef: [{
                                id: true,
                                title: 'Name',
                                field: 'null'
                            }, {
                                id: false,
                                title: 'Name',
                                field: 'null',
                                render: function (data, type, full) {
                                    return full.lastName + ', ' + full.firstName + ' ' +
                                        (full.middleInitial ? full.middleInitial : '');
                                }
                            }, {
                                id: false,
                                title: 'DOB',
                                field: 'null',
                                render: function (data, type, full) {
                                    return moment(full.dob).format('MM/DD/YYYY');
                                }
                            }, {
                                id: false,
                                title: 'Gender',
                                field: 'gender'
                            }, {
                                id: false,
                                title: 'Language',
                                field: 'language',
                                render: function (data, type, full) {
                                    return full.language !== null ? full.language : 'No Language';
                                }
                            }],
                            data: vm.patientsList,
                            preSelected: []
                        },
                        value: [],
                        hidden: false
                    }, {
                        title: 'Start Date',
                        name: 'start_date',
                        required: true,
                        type: OdsParamType.DATE,
                        value: new Date()//new Date(1980, 0, 24) way to pass default value in params
                    }, {
                        title: 'End Date',
                        name: 'end_date',
                        required: true,
                        type: OdsParamType.DATE,
                        value: new Date()
                    }]
                },
                {
                    id: 5,
                    title: 'Lab Draw Report',
                    url: '/api/report/lab-draw',
                    params: [{
                        title: 'Patient',
                        name: 'patient',
                        required: true,
                        placeholder: 'Type patients name...',
                        type: OdsParamType.SINGLE_SELECT,
                        valueField: 'id',
                        titleField: 'firstName',
                        render: function (full) {
                            if (full.length === 0) {
                                return 'Type patients name...';
                            } else {
                                return full.lastName + ', ' + full.firstName + ' ' +
                                    (full.middleInitial ? full.middleInitial : '');
                            }
                        },
                        value: [],
                        list: vm.patientsList
                    }, {
                        title: 'Start Date',
                        name: 'start_date',
                        required: true,
                        type: OdsParamType.DATE,
                        value: new Date()//new Date(1980, 0, 24) way to pass default value in params
                    }, {
                        title: 'End Date',
                        name: 'end_date',
                        required: true,
                        type: OdsParamType.DATE,
                        value: new Date()
                    }]
                },
                {
                    id: 6,
                    title: 'CIWA',
                    url: '/api/report/ciwa',
                    base64: true,
                    params: [{
                        title: 'Patient',
                        name: 'patient',
                        required: true,
                        placeholder: 'Type patients name...',
                        type: OdsParamType.SINGLE_SELECT,
                        valueField: 'id',
                        titleField: 'firstName',
                        render: function (full) {
                            if (full.length === 0) {
                                return 'Type patients name...';
                            } else {
                                return full.lastName + ', ' + full.firstName + ' ' +
                                    (full.middleInitial ? full.middleInitial : '');
                            }
                        },
                        value: [],
                        list: vm.patientsList
                    }, {
                        title: 'Start Date',
                        name: 'start_date',
                        required: true,
                        type: OdsParamType.DATE,
                        value: new Date()
                    }, {
                        title: 'End Date',
                        name: 'end_date',
                        required: true,
                        type: OdsParamType.DATE,
                        value: new Date()
                    }]
                },
                {
                    id: 7,
                    title: 'AC IDT Quarterly',
                    url: '/api/report/ac-idt-quarterly',
                    base64: true,
                    modalSize: 'sm',
                    params: [{
                        title: 'Patient',
                        name: 'patient',
                        required: true,
                        placeholder: 'Type patients name...',
                        type: OdsParamType.SINGLE_SELECT,
                        valueField: 'id',
                        titleField: 'firstName',
                        render: function (element) {
                            if (element.length === 0) {
                                return 'Type patients name...';
                            } else {
                                return element.lastName + ', ' + element.firstName + ' ' +
                                    (element.middleInitial ? element.middleInitial : '');
                            }
                        },
                        value: [],
                        list: vm.patientsList
                    }, {
                        id: 2,
                        title: 'End Date',
                        name: 'end_date',
                        required: true,
                        type: OdsParamType.DATE,
                        value: new Date()
                    }]
                }
            ]
        });
    }
})();
