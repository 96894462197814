(function() {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('HospitalizationDischargeToDetailController', HospitalizationDischargeToDetailController);

    HospitalizationDischargeToDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'HospitalizationDischargeTo', 'Hospitalization', 'Corporation'];

    function HospitalizationDischargeToDetailController($scope, $rootScope, $stateParams, previousState, entity, HospitalizationDischargeTo, Hospitalization, Corporation) {
        var vm = this;

        vm.hospitalizationDischargeTo = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('dialysisApp:hospitalizationDischargeToUpdate', function(event, result) {
            vm.hospitalizationDischargeTo = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
