(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('MachineBarcodeDetail2Controller', MachineBarcodeDetail2Controller);

    MachineBarcodeDetail2Controller.$inject = ['moment', 'machine', 'EnvTest', 'barcode', '$compile', '$scope', '$q', '$uibModal',
        'DTOptionsBuilder', 'DTColumnBuilder', '$uibModalInstance'];

    function MachineBarcodeDetail2Controller(moment, machine, EnvTest, barcode, $compile, $scope, $q, $uibModal,
                                             DTOptionsBuilder, DTColumnBuilder, $uibModalInstance) {
        var vm = this;
        vm.machine = machine;
        vm.barcode = barcode;
        vm.clear = clear;

        vm.dtOptions = DTOptionsBuilder.fromFnPromise(function () {
            var defer = $q.defer();
            if (vm.barcode) {
                EnvTest.findAllByBarcode2({barcode: vm.barcode.id}, function (result) {
                    defer.resolve(result);
                });
            }
            else {
                EnvTest.findAllWithoutBarcode({id: vm.machine.id, date: vm.machine.scheduleDate, envStatus: vm.machine.status}, function(result) {
                    defer.resolve(result);
                });
            }

            return defer.promise;
        }).withPaginationType('full_numbers').withBootstrap().withDOM('tip')
            .withOption('paging', false).withOption('aaSorting', []).withOption('fnRowCallback',
                function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                    $compile(nRow)($scope);
                });

            vm.dtColumns = [
                DTColumnBuilder.newColumn(null).withTitle('Test').renderWith(function (data, type, full) {
                    return data.code;
                }),
                DTColumnBuilder.newColumn(null).withTitle('Description').renderWith(function (data, type, full) {
                    return data.description;
                }),
                DTColumnBuilder.newColumn(null).withTitle('Frequency').renderWith(function (data, type, full) {
                    return data.frequencyName;
                }),
                DTColumnBuilder.newColumn('tplId').withTitle('Template Id')
            ];

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();
