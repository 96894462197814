(function() {
    'use strict';
    angular
        .module('dialysisApp')
        .factory('Clinic', Clinic);

    Clinic.$inject = ['$resource', 'DateUtils'];

    function Clinic ($resource, DateUtils) {
        var resourceUrl =  'api/clinics/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.startDate = DateUtils.convertLocalDateFromServer(data.startDate);
                        data.contractExp = DateUtils.convertLocalDateFromServer(data.contractExp);
                        data.annualReview = DateUtils.convertLocalDateFromServer(data.annualReview);
                        data.standingEnd = DateUtils.convertLocalDateFromServer(data.standingEnd);
                        data.endPickup = DateUtils.convertDateTimeFromServer(data.endPickup);
                        data.startPickup = DateUtils.convertDateTimeFromServer(data.startPickup);
                    }

                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.startDate = DateUtils.convertLocalDateToServer(copy.startDate);
                    copy.contractExp = DateUtils.convertLocalDateToServer(copy.contractExp);
                    copy.annualReview = DateUtils.convertLocalDateToServer(copy.annualReview);
                    copy.standingEnd = DateUtils.convertLocalDateToServer(copy.standingEnd);

                    return angular.toJson(copy);
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.startDate = DateUtils.convertLocalDateToServer(copy.startDate);
                    copy.contractExp = DateUtils.convertLocalDateToServer(copy.contractExp);
                    copy.annualReview = DateUtils.convertLocalDateToServer(copy.annualReview);
                    copy.standingEnd = DateUtils.convertLocalDateToServer(copy.standingEnd);

                    return angular.toJson(copy);
                }
            },
            'except': {url: "api/clinics/except/:id", method: 'GET', isArray: true},
            'byCorp': {url: "api/clinics/by-corp/:id", method: 'GET', isArray: true}
        });
    }
})();
