(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('PatientResultSignDialogController', PatientResultSignDialogController);

    PatientResultSignDialogController.$inject = ['$uibModalInstance'];

    function PatientResultSignDialogController($uibModalInstance) {
        var vm = this;

        vm.clear = clear;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();
