(function() {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('PatientNewController', PatientNewController);

    PatientNewController.$inject = ['DiagnosisSearch', '$state', 'entity', 'Patient', 'CoreService',
        'Race', 'Language', 'Employee', 'Modality', 'PatientType', 'PatientTemplate',
        'CountryState', 'InsuranceType', 'InsuranceLevel', 'InsuranceRelation',
        'GUIUtils', 'InsuranceCarrierSearch', 'dialogs', '$sessionStorage'
    ];

    function PatientNewController(DiagnosisSearch, $state, entity, Patient, CoreService,
                                     Race, Language, Employee, Modality, PatientType, PatientTemplate,
                                     CountryState, InsuranceType, InsuranceLevel, InsuranceRelation,
                                     GUIUtils, InsuranceCarrierSearch, $dialogs, $sessionStorage) {
        var vm = this;
        vm.patient = entity;
        vm.carriers = [];

        vm.form = {};
        vm.activeTab = 0;

        vm.datePickerOpenStatus = {};
        vm.days = GUIUtils.weekCodeStringToDays(CoreService.getCurrentClinic().drawDays);
        vm.days2 = GUIUtils.getDays();
        vm.editing = true;

        vm.activeTab = $sessionStorage.activePatientTab ? ($sessionStorage.activePatientTab.id == entity.id ?
            ($sessionStorage.activePatientTab.activeTab > 3 ? 0 : $sessionStorage.activePatientTab.activeTab) : 0) : 0;

        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.changeEditingState = changeEditingState;
        vm.cancelEditing = cancelEditing;
        vm.deactivate = deactivate;

        vm.insurancestypes = InsuranceType.query();
        vm.races = Race.query();
        vm.languages = Language.query();
        vm.physicians = Employee.employeeCorpPhysicians();
        vm.modalities = Modality.query();
        vm.patienttypes = PatientType.query();
        vm.patienttemplates = PatientTemplate.query();
        vm.countrystates = CountryState.query();
        vm.insurancelevels = InsuranceLevel.query();
        vm.insurancerelations = InsuranceRelation.query();

        function save () {
            vm.isSaving = true;
            vm.patient.treatmentDays = GUIUtils.daysToWeekCodeString(vm.patient.treatmentDays2);
            vm.patient.drawDays = GUIUtils.daysToWeekCodeString(vm.patient.drawDays2);

            if (vm.patient.id !== null) {
                Patient.update(vm.patient, onSaveSuccess, onSaveError);
            } else {
                Patient.save(vm.patient, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $state.go("patient-detail", {"id": result.id}, {reload: true});
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.dob = false;
        vm.datePickerOpenStatus.fddDate = false;
        vm.datePickerOpenStatus.fDayDialysis = false;
        vm.datePickerOpenStatus.clinicAdmission = false;
        vm.datePickerOpenStatus.placementDate = false;
        vm.datePickerOpenStatus.endDate = false;
        vm.datePickerOpenStatus.insuranceDob = [false, false];

        vm.isOpeninsuranceDatePicker = isOpeninsuranceDatePicker;
        function isOpeninsuranceDatePicker(index) {
            return vm.datePickerOpenStatus.insuranceDob[index];
        }

        vm.openInsuranceCalendar = openInsuranceCalendar;
        function openInsuranceCalendar(index) {
            vm.datePickerOpenStatus.insuranceDob[index] = true;
        }

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function changeEditingState() {
            vm.editing = !vm.editing;
        }

        function cancelEditing() {
            if (vm.patient.id !== null) {
                $state.go("patient-detail", {"id": vm.patient.id}, {reload: true});
            } else {
                $state.go("patient");
            }
        }

        vm.addAddress = function addAddress() {
            vm.patient.addresses[vm.patient.addresses.length] = {};
            vm.patient.addresses[vm.patient.addresses.length - 1].patientAddressType = vm.patient.addresses.length;
        }

        vm.deleteAddress = function (index) {
            if (index == 0) {
                if (vm.patient.addresses.length > 1) {
                    vm.swapAddresses();
                    vm.patient.addresses.splice(1, 1);
                } else {
                    vm.patient.addresses.splice(0, 1);
                }
            } else {
                vm.patient.addresses.splice(1, 1);
            }
        }

        vm.swapAddresses = function () {
            var tempAddress = {};

            angular.copy(vm.patient.addresses[0], tempAddress);
            angular.copy(vm.patient.addresses[1], vm.patient.addresses[0]);
            angular.copy(tempAddress, vm.patient.addresses[1]);

            vm.patient.addresses[0].patientAddressType = 1;
            vm.patient.addresses[1].patientAddressType = 2;
        }

        vm.addInsurance = function() {
            vm.patient.insurances[vm.patient.insurances.length] = {};
            vm.patient.insurances[vm.patient.insurances.length - 1].insuranceOrder = vm.patient.insurances.length;
        }

        vm.deleteInsurance = function (index) {
            if (index == 0) {
                if (vm.patient.insurances.length > 1) {
                    vm.swapInsurances();
                    vm.patient.insurances.splice(1, 1);
                } else {
                    vm.patient.insurances.splice(0, 1);
                }
            } else {
                vm.patient.insurances.splice(1, 1);
            }
        }

        vm.swapInsurances = function () {
            var tempInsurance = {};

            angular.copy(vm.patient.insurances[0], tempInsurance);
            angular.copy(vm.patient.insurances[1], vm.patient.insurances[0]);
            angular.copy(tempInsurance, vm.patient.insurances[1]);

            vm.patient.insurances[0].insuranceOrder = 1;
            vm.patient.insurances[1].insuranceOrder = 2;
        }

        vm.getInsuranceCarrier = function (query) {
            if (query && query.length > 2) {
                InsuranceCarrierSearch.query({query: query}, function (result) {
                    vm.carriers = result;
                });
            }
        }

        vm.keepActiveTab = keepActiveTab;

        function keepActiveTab($index) {
            if (vm.patient.id) {
                $sessionStorage.activePatientTab = {id: vm.patient.id, activeTab: $index};
            }
        }

        vm.diagnoses = [];

        vm.getDiagnoses = getDiagnoses;

        function getDiagnoses(query) {
            if (query && query.length > 2) {
                DiagnosisSearch.query({query: query}, function (result) {
                    vm.diagnoses = result;
                });
            }
        }

        function deactivate() {
            if (vm.patient.id) {
                if (!vm.patient.status) {
                    var dlg = $dialogs.confirm('Please Confirm','Confirm deactivate patient <b>' +
                        vm.patient.lastName + ', ' + vm.patient.firstName + ' ' + vm.patient.middleInitial +
                        '</b>. All scheduled orders will be canceled', {size: 'md'});
                    dlg.result.then(function(btn){
                        Patient.deactivate({patientId: vm.patient.id}, function () {
                            $state.go("patient-detail", {"id": vm.patient.id}, {reload: true});
                        });
                    },function(btn){
                        vm.patient.status = true;
                    });
                }
            }
        }
    }
})();
