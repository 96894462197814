(function () {
    'use strict';
    angular
        .module('dialysisApp')
        .factory('PatientOrderTest', PatientOrderTest);

    PatientOrderTest.$inject = ['$resource', 'DateUtils'];

    function PatientOrderTest($resource, DateUtils) {
        var resourceUrl = 'api/patient-order-tests/:id';

        return $resource(resourceUrl, {}, {
            'order': {url: 'api/patient-order-tests/by-order/:id', method: 'GET', isArray: true},
            'findAllWithoutBarcode': {
                url: 'api/patient-order-tests/without-barcode', method: 'POST', isArray: true,
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.date = DateUtils.convertLocalDateToServer(copy.date);

                    return angular.toJson(copy);
                }
            },
            //TODO remove with dashboard v2
            'findAllByBarcode': {url: 'api/patient-order-tests/by-barcode/:barcode', method: 'GET', isArray: true},
            'findAllByBarcode2': {url: 'api/patient-order-tests/by-barcode2/:barcode', method: 'GET', isArray: true},
            'with_all': {url: 'api/patient-order-tests/with-all/:id', method: 'GET'},
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.staringDate = DateUtils.convertLocalDateFromServer(data.staringDate);
                        data.endDate = DateUtils.convertLocalDateFromServer(data.endDate);
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.staringDate = DateUtils.convertLocalDateToServer(copy.staringDate);
                    copy.endDate = DateUtils.convertLocalDateToServer(copy.endDate);
                    return angular.toJson(copy);
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.staringDate = DateUtils.convertLocalDateToServer(copy.staringDate);
                    copy.endDate = DateUtils.convertLocalDateToServer(copy.endDate);
                    return angular.toJson(copy);
                }
            }
        });
    }
})();
