(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('HospitalizationController', HospitalizationController);

    HospitalizationController.$inject = ['$compile', '$scope', 'Hospitalization', 'entity', '$q', 'DTColumnBuilder', 'DTOptionsBuilder', '$filter', 'moment'];

    function HospitalizationController($compile, $scope, Hospitalization, entity, $q, DTColumnBuilder, DTOptionsBuilder, $filter, moment) {
        var vm = this;

        vm.patient = entity;
        vm.hospitalization = [];
        vm.search = search;
        vm.dtInstance = {};

        vm.dtOptions = DTOptionsBuilder.fromFnPromise(function () {
            var defer = $q.defer();
            if (!vm.searchQuery || vm.searchQuery == '') {
                Hospitalization.getAllByPatientId({id : vm.patient.id}, function (result) {
                    vm.hospitalization = result;
                    defer.resolve(result);
                });
            } else {
                defer.resolve($filter('filter')(vm.patients, vm.searchQuery, undefined));
            }
            return defer.promise;
        }).withPaginationType('full_numbers').withBootstrap().withDOM('tip').withOption('fnRowCallback',
            function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                $compile(nRow)($scope);
            });

        vm.dtColumns = [
            DTColumnBuilder.newColumn('admissionDate').withTitle('Admission Date').renderWith(function (data, type, full) {
                return data ? moment(data).format("MM/DD/YYYY") : "";
            }),
            DTColumnBuilder.newColumn('dischargeDate').withTitle('Discharge Date').renderWith(function (data, type, full) {
                return data ? moment(data).format("MM/DD/YYYY") : "";
            }),
            DTColumnBuilder.newColumn('numDays').withTitle('Number of Days'),
            DTColumnBuilder.newColumn(null).withTitle('Due to BSI').renderWith(function (data, type, full) {
                return data.dueToBsi ? 'Y' : 'N';
            }),
            DTColumnBuilder.newColumn(null).withTitle('Renal Related').renderWith(function (data, type, full) {
                return data.renalRelatedInfection ? 'Y' : 'N';
            }),
            DTColumnBuilder.newColumn(null).withTitle('Actions').withOption('width', '155px').notSortable()
                .renderWith(actionsHtml)
        ];

        function actionsHtml(data, type, full, meta){
            var stButtons = '';

            stButtons += '<a class="btn-sm btn-warning" ui-sref="hospitalization.view({hid:' + data.id + '})">' +
                '   <i class="fa fa-edit"></i></a>&nbsp;';

            if (vm.patient.status) {
                stButtons += '<a class="btn-sm btn-danger" ui-sref="hospitalization.delete({hid:' + data.id + '})">' +
                    '   <i class="fa fa-trash"></i></a>';
            }

            return stButtons;
        }

        function search() {
            vm.dtInstance.reloadData();
        }
    }
})();
