(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('PatientChangeController', PatientChangeController);

    PatientChangeController.$inject = ['clinic', '$compile', '$scope', 'Patient', 'patient', 'Employee', 'Modality', 'Clinic',
        'PatientSearch', '$q', 'DTColumnBuilder', 'DTOptionsBuilder', 'GUIUtils', '$filter', 'moment', 'id', 'CoreService', 'dialogs'];

    function PatientChangeController(clinic, $compile, $scope, Patient, patient, Employee, Modality, Clinic,
                               PatientSearch, $q, DTColumnBuilder, DTOptionsBuilder, GUIUtils, $filter, moment, id, CoreService, $dialogs) {
        var vm = this;

        vm.patient = patient;
        vm.patientId = id;
        vm.search = search;
        vm.dtInstance = {};
        vm.clinic = clinic;
        vm.physicians = Employee.employeeCorpPhysicians();
        vm.modalities = Modality.query();
        vm.clear = clear;
        vm.newClinic = null;
        vm.clinics = Clinic.except({id: vm.clinic.id});
        vm.changeClinic = changeClinic;

        vm.selected = {};
        vm.selectAll = false;
        vm.toggleAll = toggleAll;
        vm.toggleOne = toggleOne;
        var titleHtml = '<input type="checkbox" ng-model="vm.selectAll" ng-change="vm.toggleAll()">';

        vm.dtOptions = DTOptionsBuilder.fromFnPromise(function () {
            var defer = $q.defer();
            if (vm.isFilter) {
                defer.resolve(vm.patients);
                vm.isFilter = false;
            } else if (!vm.searchQuery || vm.searchQuery == '') {
                Patient.roster({id : CoreService.getCurrentClinic().id, status: true}, function (result) {
                    vm.patients = result;
                    defer.resolve(result);
                });
            } else {
                defer.resolve($filter('filter')(vm.patients, vm.searchQuery, undefined));
            }

            return defer.promise;
        }).withPaginationType('full_numbers').withBootstrap().withDOM('tip')
            .withOption('createdRow', function(row, data, dataIndex) {
                $compile(angular.element(row).contents())($scope);
            })
            .withOption('aaSorting', [[1, 'asc']])
            .withOption('headerCallback', function(header) {
                if (!vm.headerCompiled) {
                    vm.headerCompiled = true;
                    $compile(angular.element(header).contents())($scope);
                }
            });

        vm.dtColumns = [
            DTColumnBuilder.newColumn(null).withTitle(titleHtml).withOption('width', '25px').notSortable().renderWith(function (data, type, full, meta) {
                vm.selected[full.id] = (full.id == vm.patientId ? true : false);

                return '<input type="checkbox" ng-model="vm.selected[' + data.id + ']" ng-click="vm.toggleOne()">';
            }),
            DTColumnBuilder.newColumn(null).withTitle('Name').withOption('width', '180px').renderWith(function (data, type, full) {
                return data.lastName + ', ' + data.firstName + ' ' + (data.middleInitial ? data.middleInitial : "");
            }),
            DTColumnBuilder.newColumn(null).withTitle('DOB').renderWith(function (data, type, full) {
                return moment(data.dob).format("MM/DD/YYYY");
            }),
            DTColumnBuilder.newColumn('gender').withTitle('Gender'),
            DTColumnBuilder.newColumn(null).withTitle('Modality').renderWith(function (data, type, full) {
                return data.dialysisModality ? data.dialysisModality.replace("_", " ") : "";
            }),
            DTColumnBuilder.newColumn(null).withTitle('Days').renderWith(function (data, type, full) {
                return data.drawDays ? GUIUtils.expandWeekCodeString(data.drawDays, ',') : "";
            }),
            DTColumnBuilder.newColumn(null).withTitle('Shift').renderWith(function (data, type, full) {
                return data.shift ? data.shift : '';
            }),
            DTColumnBuilder.newColumn(null).withTitle('Status').renderWith(function (data, type, full) {
                return data.status ? 'Active' : 'Inactive';
            }),
            DTColumnBuilder.newColumn(null).withTitle('Physician').withOption('width', '180px').renderWith(function (data, type, full) {
                return data.physicianFirstName ? (data.physicianLastName + ', ' + data.physicianFirstName) : '';
            }),
            DTColumnBuilder.newColumn(null).withTitle('FDD Date').renderWith(function (data, type, full) {
                return data.fDayDialysis ? moment(data.fDayDialysis).format("MM/DD/YYYY") : '';
            }),
            DTColumnBuilder.newColumn(null).withTitle('Diabetic').renderWith(function (data, type, full) {
                return data.diabetic !== undefined ? GUIUtils.colorHtmlYesNo(data.diabetic) : '';
            }),
        ];

        function search() {
            vm.dtInstance.reloadData();
        }

        vm.popoverFilterEnable = popoverFilterEnable;
        vm.showFilter = false;

        function popoverFilterEnable(){
            vm.showFilter = !vm.showFilter;
        }

        vm.filter = filter;
        vm.isFilter = false;
        vm.patientCopy = {};

        function filter() {
            vm.isFilter = true;

            var must = [];
            must.push({term: {"clinic.id": vm.clinic.id}});
            must.push({term: {"delStatus": false}});

            if (vm.patient.diabetic != null && vm.patient.diabetic.length > 0){
                must.push({match: {diabetic: {query : vm.patient.diabetic}}});
            }

            if (vm.patient.firstName != null && vm.patient.firstName.length > 0){
                must.push({match: {firstName: {query :vm.patient.firstName, fuzziness: 2}}});
            }

            if (vm.patient.lastName && vm.patient.lastName.length > 0){
                must.push({match: {lastName: {query :vm.patient.lastName, fuzziness: 2}}});
            }

            if (vm.patient.gender != null && vm.patient.gender.length > 0){
                must.push({match: {gender: {query :vm.patient.gender}}});
            }

            if (vm.patient.shift){
                must.push({term: {shift: vm.patient.shift}});
            }

            if (vm.patient.treatmentDays && vm.patient.treatmentDays.length > 0){
                must.push({match: {treatmentDays: {query : GUIUtils.daysToWeekCodeString(vm.patient.treatmentDays)}}});
            }

            if (vm.patient.dob && vm.patient.dob.length > 0){
                must.push({match: {dob: {query : GUIUtils.daysToWeekCodeString(vm.patient.dob)}}});
            }

            if (vm.patient.status != null && vm.patient.status.length > 0){
                must.push({match: {status: {query : vm.patient.status}}});
            }

            if (vm.patient.physician.id !== undefined){
                must.push({term: {"physician.id": vm.patient.physician.id}});
            }

            if (vm.patient.modality.id !== undefined){
                must.push({term: {"modality.id": vm.patient.modality.id}});
            }

            PatientSearch.query(
                {
                    query: {
                        bool: {
                            must: must
                        }
                    }
                }, function(result) {
                vm.patients = result;
                vm.dtInstance.reloadData();
            });
        }

        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.dob = false;
        vm.days = GUIUtils.getDays();

        vm.openCalendar = openCalendar;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function clear() {
            vm.patient = {
                firstName: null,
                lastName: null,
                dob: null,
                gender: null,
                shift: null,
                diabetic: null,
                treatmentDays: null,
                status: true,
                physician: {},
                modality: {}};

            vm.isFilter = false;
            vm.dtInstance.reloadData();
        }

        function toggleAll() {
            for (var id in vm.selected) {
                if (vm.selected.hasOwnProperty(id)) {
                    vm.selected[id] = vm.selectAll;
                }
            }
        }

        function toggleOne() {
            for (var id in vm.selected) {
                if (vm.selected.hasOwnProperty(id)) {
                    if (!vm.selected[id]) {
                        vm.selectAll = false;
                        return;
                    }
                }
            }
            vm.selectAll = true;
        }

        function getSelectedIds() {
            var orders = [];
            for (var id in vm.selected) {
                if (vm.selected[id]) {
                    orders.push(id);
                }
            }

            return orders;
        }

        function changeClinic() {
            var dlg = $dialogs.confirm('Please Confirm','Move selected patients to <b>' + vm.newClinic.name + '</b>?', {size: 'md'});
            dlg.result.then(function(btn){
                var ids = getSelectedIds();

                if (ids.length > 0) {
                    Patient.change({clinicId: vm.newClinic.id, ids: ids}, function () {
                        vm.searchQuery = null;
                        vm.isFilter = false;

                        search();
                    });
                }
            },function(btn){
            });
        }
    }
})();
