(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('DashboardEnvOrderSchedule2Controller', DashboardEnvOrderSchedule2Controller);

    DashboardEnvOrderSchedule2Controller.$inject = ['PopupService', 'CoreService', '$rootScope', 'OdsReportsService',
        '$uibModal', 'dialogs', '$compile', '$q', '$scope', 'GUIUtils', 'EnvOrder', 'DTOptionsBuilder',
        'DTColumnBuilder', 'DateUtils', 'DataTablesService', 'clinic', 'dateEnv', 'lodash', 'toastr',
        '$filter', 'hasPrinter', 'DYMO'];

    function DashboardEnvOrderSchedule2Controller(PopupService, CoreService, $rootScope, OdsReportsService,
                                                  $uibModal, $dialogs, $compile, $q, $scope, GUIUtils, EnvOrder, DTOptionsBuilder,
                                                  DTColumnBuilder, DateUtils, DataTablesService, clinic, dateEnv, _, toastr,
                                                  $filter, hasPrinter, DYMO) {
        var vm = this;
        vm.clinic = clinic;
        vm.dtInstance = {};
        vm.selectedDate = dateEnv;
        vm.selected = {};
        vm.selectAll = false;
        vm.hasPrinter = hasPrinter;
        vm.search = search;
        vm.modalPendingResults = modalPendingResults;
        vm.getBarcodeLabelsPdf = getBarcodeLabelsPdf;
        var titleHtml = '<input type="checkbox" ng-model="vm.selectAll" ng-change="vm.toggleAll(vm.selected, vm.selectAll)">';
        vm.statuses = {
            'MANIFESTED': {label: 'Manifested', template: 'success'},
            'PRINTED': {label: 'Printed', template: 'warning'},
            'SCHEDULED': {label: 'Scheduled', template: 'info'},
            'CANCELED': {label: 'Cancelled', template: 'danger'}
        };

        vm.showCalendar = showCalendar;
        vm.showChangeDrawDayCalendar = showChangeDrawDayCalendar;
        vm.getManifestPdf = getManifestPdf;
        vm.toggleAll = DataTablesService.toggleAll;
        vm.toggleOne = DataTablesService.toggleOne;
        vm.cancelRequisition = cancelRequisition;

        vm.dtOptions = DTOptionsBuilder.fromFnPromise(function () {
            var defer = $q.defer();

            vm.selected = {};
            vm.selectAll = false;

            if (!vm.searchQuery || vm.searchQuery == '') {
                if (vm.clinic) {
                    EnvOrder.getSchedule({clinicId: vm.clinic.id, date: vm.selectedDate}, function (result) {
                        vm.orders = result;
                        defer.resolve(result);
                    });
                } else {
                    defer.resolve(vm.orders);
                }
            } else {
                defer.resolve($filter('filter')(vm.orders, vm.searchQuery, undefined));
            }

            return defer.promise;
        }).withPaginationType('full_numbers')
            .withBootstrap()
            .withOption('aaSorting', [[1, 'asc'], [2, 'asc']])
            .withDOM('tip')
            .withLanguage({
                "paginate": {
                    "first": '<i class="fa fa-angle-double-left" aria-hidden="true"></i>',
                    "previous": '<i class="fa fa-arrow-circle-left" aria-hidden="true"></i>',
                    "last": '<i class="fa fa-angle-double-right" aria-hidden="true"></i>',
                    "next": '<i class="fa fa-arrow-circle-right" aria-hidden="true"></i>'
                }
            })
            .withOption('fnRowCallback',
                function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                    $compile(nRow)($scope);
                })
            .withOption('headerCallback', function (header) {
                if (!vm.headerCompiled) {
                    vm.headerCompiled = true;
                    $compile(angular.element(header).contents())($scope);
                }
            });

        vm.dtColumns = [
            DTColumnBuilder.newColumn(null).withTitle(titleHtml).withOption('width', '25px').notSortable().renderWith(function (data, type, full, meta) {
                vm.selected[full.id] = false;
                return '<input type="checkbox" ng-model="vm.selected[' + full.id + ']" ng-click="vm.toggleOne(vm.selected, vm.selectAll)">';
            }),
            DTColumnBuilder.newColumn(null).withTitle('Machine').renderWith(function (data, type, full) {
                return data.machineName;
            }),
            DTColumnBuilder.newColumn(null).withTitle('Barcode').renderWith(function (data, type, full) {
                return data.barcode ? data.barcode : '';
            }),
            DTColumnBuilder.newColumn(null).withTitle('Status').renderWith(orderStatusHtml),
            DTColumnBuilder.newColumn(null).withTitle('Actions').withOption('width', '55px').withOption('class', 'col-btn').notSortable().renderWith(actionsHtml)

        ];

        function orderStatusHtml(data, type, full) {
            var patientStatus = vm.statuses[data.status];
            return GUIUtils.getStatusTemplate(patientStatus.label, patientStatus.template);
        }

        function actionsHtml(data, type, full, meta) {
            var stButtons = '';

            stButtons += '<a uib-tooltip="See Details" class="btn btn-default btn-sm details" ' +
                'ng-click="vm.modalPendingResults(' + data.id + ')">' +
                '   <i class="fa fa-external-link" aria-hidden="true"></i></>&nbsp;';

            return stButtons;
        }

        function search() {
            vm.dtInstance.reloadData();
        }

        function modalPendingResults(index) {
            PopupService.showEnvironmentalBarcodePopup(_.filter(vm.orders, {id: index})[0]);
        }

        function showCalendar() {
            $uibModal.open({
                templateUrl: 'app/entities/dashboard/dashboard2/dashboard2-select-day.dialog.html',
                controller: 'DashboardSelectDateDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    date: vm.selectedDate,
                    isPatient: {value: false},
                    clinic: vm.clinic
                }
            }).result.then(function (result) {
                vm.selectedDate = result.value;
                vm.searchQuery = '';
                vm.dtInstance.reloadData();
            });
        }

        function showChangeDrawDayCalendar() {
            $uibModal.open({
                templateUrl: 'app/entities/dashboard/dashboard2/dashboard2-select-day.dialog.html',
                controller: 'DashboardSelectDateDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    date: vm.selectedDate,
                    isPatient: {value: false},
                    clinic: vm.clinic
                }
            }).result.then(function (result) {
                vm.searchQuery = '';
                changeDrawDay(result.value);
            });
        }

        function changeDrawDay(newDrawDay) {
            var newDrawDays = [];

            for (var id in vm.selected) {
                if (vm.selected[id]) {
                    var order = _.keyBy(vm.orders, 'id')[id];

                    if (_.includes(['SCHEDULED', 'PRINTED'], order.status)) {
                        newDrawDays.push(
                            {
                                date: order.scheduleDate,
                                orderId: id,
                                status: order.status,
                                newDate: DateUtils.convertLocalDateToServer(newDrawDay)
                            }
                        );
                    }
                }
            }

            if (newDrawDays.length > 0) {
                var dlg = $dialogs.confirm('Please Confirm', 'Do you want to change the draw day for selected requisitions?', {size: 'md'});

                dlg.result.then(function (btn) {
                    EnvOrder.changeDrawDayV2({drawDays: newDrawDays}, function (results) {
                        if (results.length > 0) {
                            $dialogs.error("Error", "This item can't be change to selected date. " +
                                _.map(results, function (result) {
                                        return "Order: " + result.orderId + " - " + result.machine;
                                    }
                                ).join(', ')
                            );
                        } else {
                            vm.dtInstance.reloadData();
                        }
                    });
                });
            } else {
                toastr.error('There is not requisition selected');
            }
        }

        function getManifestPdf() {
            var barcodes = getSelectedBarcodes();

            if (barcodes.length > 0) {
                EnvOrder.getManifestPdf({clinicId: clinic.id, barcodes: barcodes}, function () {
                    vm.searchQuery = '';
                    vm.dtInstance.reloadData();
                }, function () {
                    toastr.error('"Error manifesting orders"');
                });
            } else {
                toastr.error('There is not requisition selected');
            }
        }

        function getSelectedBarcodes() {
            var barcodes = [];

            for (var id in vm.selected) {
                if (vm.selected[id]) {
                    var order = _.filter(vm.orders, function (order) {
                        return id == '' + order.id && order.barcode && order.status != 'SCHEDULED';
                    })[0];

                    if (order != null) {
                        barcodes.push(order.barcode);
                    }
                }
            }

            return barcodes;
        }

        function getBarcodeLabelsPdf() {
            var orders = DataTablesService.getSelectedIds(vm.selected);
            if (orders.length > 0) {
                vm.isSaving = true;
                var envBarcodes = _.map(orders, function (id) {
                    return {
                        orderId: id,
                        date: DateUtils.convertLocalDateToServer(vm.selectedDate),
                        barcode: _.keyBy(vm.orders, 'id')[id].barcode
                    }
                });

                EnvOrder.getBarcodeLabelsPdf({drawDays: envBarcodes}, function () {
                    vm.searchQuery = '';
                    vm.dtInstance.reloadData();
                    vm.isSaving = false;
                }, function () {
                    toastr.error('"Error collecting orders"');
                    vm.isSaving = false;
                });
            } else {
                toastr.error('There is not requisition selected');
                vm.dtInstance.reloadData();
                vm.isSaving = false;
            }
        }

        vm.printLabels = function () {
            var orders = DataTablesService.getSelectedIds(vm.selected);
            if (orders.length > 0) {
                vm.isSaving = true;
                EnvOrder.collect2({ids: orders, date: vm.selectedDate}, function (result) {
                    vm.barcodes = getSelectedBarcodes().concat(result);
                    vm.isSaving = false;

                    if (vm.hasPrinter) {
                        if (vm.barcodes.length > 0) {
                            EnvOrder.getBarcodeLabels({barcodes: vm.barcodes}, function (result) {
                                DYMO.printEnvBarcodes(result);
                                vm.dtInstance.reloadData();
                                vm.barcodes = [];
                            });
                        }
                    }
                }, function () {
                    alert("Error collecting orders");
                    vm.isSaving = false;
                })
            }
        }

        function cancelRequisition() {
            var envOrderTestIds = DataTablesService.getSelectedIds(vm.selected);

            if (envOrderTestIds.length > 0) {
                var dlg = $dialogs.confirm('Please Confirm', 'Are you sure you want to change Cancel Requisition?', {size: 'md'});
                dlg.result.then(function (btn) {
                    EnvOrder.cancelRequisition({
                        ids: envOrderTestIds,
                        date: DateUtils.convertLocalDateToServer(vm.selectedDate)
                    }, function () {
                        vm.searchQuery = '';
                        vm.dtInstance.reloadData();
                    });
                });
            }
        }
    }
})();
