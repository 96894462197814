(function() {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('CompendiumSpecialPriceDetailController', CompendiumSpecialPriceDetailController);

    CompendiumSpecialPriceDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'CompendiumSpecialPrice', 'Clinic', 'Compendium', 'Employee'];

    function CompendiumSpecialPriceDetailController($scope, $rootScope, $stateParams, previousState, entity, CompendiumSpecialPrice, Clinic, Compendium, Employee) {
        var vm = this;

        vm.compendiumSpecialPrice = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('dialysisApp:compendiumSpecialPriceUpdate', function(event, result) {
            vm.compendiumSpecialPrice = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
