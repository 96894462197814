(function() {
    'use strict';

    angular
        .module('dialysisApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('medication-new', {
            parent: 'patient-detail',
            url: '/medication-new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', 'entity', function($stateParams, $state, $uibModal, entity) {
                $uibModal.open({
                    templateUrl: 'app/entities/medication/medication-dialog.html',
                    controller: 'MedicationDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        medication: function () {
                            return {
                                mType: null,
                                brand: null,
                                dosage: null,
                                physician: null,
                                otherFreq: null,
                                days: null,
                                adminOnDialysis: false,
                                instructions: null,
                                specialInstructions: null,
                                id: null,
                                patient: entity
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('patient-detail', null, { reload: 'patient-detail'});
                }, function() {
                    $state.go('patient-detail');
                });
            }]
        })
        .state('medication-edit', {
            parent: 'patient-detail',
            url: '/{mid}/medit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/medication/medication-dialog.html',
                    controller: 'MedicationDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        medication: ['Medication', function(Medication) {
                            return Medication.get({id : $stateParams.mid}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('patient-detail', null, { reload: 'patient-detail'});
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('medication-delete', {
            parent: 'patient-detail',
            url: '/{mid}/medelete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/medication/medication-delete-dialog.html',
                    controller: 'MedicationDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Medication', function(Medication) {
                            return Medication.get({id : $stateParams.mid}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('patient-detail', null, { reload: 'patient-detail' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }
})();
