(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('MissingPDAdequacyBoxController', MissingPDAdequacyBoxController);

    MissingPDAdequacyBoxController.$inject = ['PdAdequacy', 'clinic'];

    function MissingPDAdequacyBoxController(PdAdequacy, clinic) {
        var vm = this;

        vm.countMissingPDAdequacy = 0;

        PdAdequacy.countMissing({id: clinic.id}, function (result) {
            vm.countMissingPDAdequacy = result.value;
        });
    }
})();
