(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('DashboardAllOrdersScheduleController', DashboardAllOrdersScheduleController);

    DashboardAllOrdersScheduleController.$inject = ['$rootScope', 'hasPrinter', 'EnvOrder', 'date', '$stateParams',
        'PatientOrder', 'CoreService'];

    function DashboardAllOrdersScheduleController($rootScope, hasPrinter, EnvOrder, date, $stateParams,
                                                  PatientOrder, CoreService) {
        var vm = this;
        vm.clinic = CoreService.getCurrentClinic();

        vm.hasPrinter = hasPrinter;

        vm.rmConfig1 = {
            mondayStart: false,
            initState: "month", /* decade || year || month */
            maxState: "decade",
            minState: "month",
            decadeSize: 12,
            monthSize: 42, /* "auto" || fixed nr. (35 or 42) */
            min: new Date("2016-01-01"),
            max: null,
            format: "yyyy-MM-dd",
            markDates: markPatientDates,
            subscribe: 'dialysisApp:dashboardCalendarUpdate1',
            broadcast: 'dialysisApp:dashboardCalendarUpdate2',
            redraw: 'dialysisApp:dashboardRedrawPatientCalendar' + $stateParams.state
        };

        vm.rmConfig2 = {
            mondayStart: false,
            initState: "month", /* decade || year || month */
            maxState: "decade",
            minState: "month",
            decadeSize: 12,
            monthSize: 42, /* "auto" || fixed nr. (35 or 42) */
            min: new Date("2016-01-01"),
            max: null,
            format: "yyyy-MM-dd",
            markDates: markEnvDates,
            subscribe: 'dialysisApp:dashboardCalendarUpdate2',
            broadcast: 'dialysisApp:dashboardCalendarUpdate1',
            redraw: 'dialysisApp:dashboardRedrawEnvCalendar' + $stateParams.state
        };

        vm.oDate1 = new Date(date);
        vm.oDate2 = new Date(date);

        //load active tab from storage
        vm.activeTab = CoreService.getStorage('activeScheduleTab');

        vm.select1 = function (oDate) {
            //save active schedule date
            CoreService.setStorage('activeScheduleDate', oDate);
            $rootScope.$broadcast('dialysisApp:dashboardCalendarUpdate2', oDate);
            $rootScope.$broadcast('dialysisApp:dashboardCalendarUpdate', oDate);
        }

        vm.select2 = function (oDate) {
            CoreService.setStorage('activeScheduleDate', oDate);
            $rootScope.$broadcast('dialysisApp:dashboardCalendarUpdate1', oDate);
            $rootScope.$broadcast('dialysisApp:dashboardCalendarUpdate', oDate);
        }

        function markPatientDates(oDate) {
            return vm.clinic ? PatientOrder.markDates({clinicId: vm.clinic.id, date: oDate}) : [];
        }

        function markEnvDates(oDate) {
            return vm.clinic ? EnvOrder.markDates({clinicId: vm.clinic.id, date: oDate}) : [];
        }

        vm.keepActiveTab = keepActiveTab;

        function keepActiveTab(index) {
            //save current tab
            CoreService.setStorage('activeScheduleTab', index);
            vm.activeTab = index;
        }
    }
})();
