(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('PatientAbstractController', PatientAbstractController);

    PatientAbstractController.$inject = ['entity', 'GUIUtils', '$rootScope', 'CoreService'];

    function PatientAbstractController(entity, GUIUtils, $rootScope, CoreService) {
        var vm = this;

        vm.patient = entity;

        if (CoreService.getCurrentPatient() != vm.patient) {
            CoreService.setCurrentPatient(vm.patient);
            $rootScope.$broadcast('dialysisApp:patientSelected', vm.patient);
        }
    }
})();
