(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('ProblemListController', ProblemListController);

    ProblemListController.$inject = ['$scope', '$state', '$q', 'entity', '$compile', 'moment', 'DiagnosisSearch',
        'ProblemList', 'DTColumnBuilder', 'DTOptionsBuilder', 'GUIUtils', '$sessionStorage', '$http', 'DateUtils'
    ];

    function ProblemListController($scope, $state, $q, entity, $compile, moment, DiagnosisSearch,
                                   ProblemList, DTColumnBuilder, DTOptionsBuilder, GUIUtils, $sessionStorage, $http, DateUtils) {

        var vm = this;
        vm.patient = entity;
        vm.problemList = [];
        vm.problemListDtInstance = {};

        vm.problemListDtOptions = DTOptionsBuilder.fromFnPromise(function () {
            var defer = $q.defer();

            ProblemList.getAllByPatientId({id: vm.patient.id}, function (result) {
                vm.problemList = result;
                defer.resolve(result);
            });
            return defer.promise;
        }).withPaginationType('full_numbers').withBootstrap().withDOM('tip').withOption('aaSorting', []).withOption('fnRowCallback',
            function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                $compile(nRow)($scope);
            });

        vm.problemListDtColumns = [
            DTColumnBuilder.newColumn(null).withTitle('ICD10').renderWith(function (data, type, full) {
                return data.diagnosis ? data.diagnosis.icd10Code : "";
            }),
            DTColumnBuilder.newColumn(null).withTitle('ICD10 Description').renderWith(function (data, type, full) {
                return data.diagnosis ? data.diagnosis.description : "";
            }),
            DTColumnBuilder.newColumn(null).withTitle('Renal').renderWith(function (data, type, full) {
                return data.renal ? 'Y' : 'N';
            }),
            DTColumnBuilder.newColumn(null).withTitle('Is Active?').withOption('width', '155px').notSortable()
                .renderWith(function(data, type, full){
                    return GUIUtils.colorHtmlYesNo(data.active);
                }),
            DTColumnBuilder.newColumn(null).withTitle('Start Date').renderWith(function (data, type, full) {
                return data.startDate ? moment(data.startDate).format("MM/DD/YYYY") : "";
            }),
            DTColumnBuilder.newColumn(null).withTitle('End Date').renderWith(function (data, type, full) {
                return data.endDate ? moment(data.endDate).format("MM/DD/YYYY") : "";
            }),
            DTColumnBuilder.newColumn(null).withTitle('Actions').withOption('width', '155px').notSortable()
                .renderWith(actionsHtml)
        ];

        function actionsHtml(data, type, full, meta){
            var stButtons = '';

            stButtons += '<a class="btn-sm btn-info" ui-sref="problem-list-view({pid:' + data.id + '})">' +
                '   <i class="fa fa-eye"></i></a>&nbsp;';

            if (vm.patient.status) {
                stButtons += '<a class="btn-sm btn-danger" ui-sref="problem-list-delete({pid:' + data.id + '})">' +
                    '   <i class="fa fa-trash"></i></a>';
            }

            return stButtons;
        }

        function problemListActiveActionHtml(data, type, full, meta) {
            if (data.active) {
                return '<span class="label label-success" ng-click="vm.changeProblemStatus(' + data.id + ')" ' +
                    'style="cursor: pointer;">Yes</span></td>';
            } else {
                return '<td><span class="label label-danger" ng-click="vm.changeProblemStatus(' + data.id + ')" ' +
                    'style="cursor: pointer;">No</span>';
            }
        }
    }
})();
