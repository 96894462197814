(function() {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('DashboardEnvController', DashboardEnvController);

    DashboardEnvController.$inject = ['$rootScope', 'Dashboard', 'CoreService', 'EMPLOYEE_TYPE', 'DYMO'];

    function DashboardEnvController ($rootScope, Dashboard, CoreService, EMPLOYEE_TYPE, DYMO) {
        var vm = this;
        vm.clinic = CoreService.getCurrentClinic();

        reload();

        vm.labels = ["Final Results", "Partial Results", "Results Not Reviewed"];
        vm.data = [0, 0, 0];
        vm.colors =  ['#00A65A', '#F39C12', '#FB434A'];

        function reload() {
            if (vm.clinic != undefined) {
                Dashboard.environmental({id : vm.clinic.id}, function(result2) {
                    vm.dashboard = result2;
                    vm.data = [vm.dashboard.finalResult, vm.dashboard.partialResult, vm.dashboard.resultNotReceived];
                });
            }
        }
    }
})();
