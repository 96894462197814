(function() {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('PatientTypeDialogController', PatientTypeDialogController);

    PatientTypeDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'PatientType'];

    function PatientTypeDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, PatientType) {
        var vm = this;

        vm.patientType = entity;
        vm.clear = clear;
        vm.save = save;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.patientType.id !== null) {
                PatientType.update(vm.patientType, onSaveSuccess, onSaveError);
            } else {
                PatientType.save(vm.patientType, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('dialysisApp:patientTypeUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
