(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('patient-order-current', {
                parent: 'patient-abs',
                url: '/current-orders',
                ncyBreadcrumb: {
                    label: 'Current Orders'
                },
                data: {
                    authorities: ['ROLE_SUPERVISOR', 'ROLE_NURSE', 'ROLE_DOCTOR'],
                    pageTitle: 'Orders'
                },
                params: {
                    orderType: 'ONE_TIME'
                },
                views: {
                    'content@patient-abs': {
                        templateUrl: 'app/entities/patient-order/current-order/patient-order-current.html',
                        controller: 'PatientOrderCurrentController',
                        controllerAs: 'vm'
                    },
                    'onetime@patient-order-current': {
                        templateUrl: 'app/entities/patient-order/current-order/patient-order-onetime.html',
                        controller: 'OneTimeCurrentOrderController',
                        controllerAs: 'vm'
                    },
                    'standing@patient-order-current': {
                        templateUrl: 'app/entities/patient-order/current-order/patient-order-standing.html',
                        controller: 'StandingCurrentOrderController',
                        controllerAs: 'vm'
                    },
                    'recurring@patient-order-current': {
                        templateUrl: 'app/entities/patient-order/current-order/patient-order-recurring.html',
                        controller: 'RecurringCurrentOrderController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {}
            })
            .state('patient-order-history', {
                parent: 'patient-abs',
                url: '/history-orders',
                ncyBreadcrumb: {
                    label: 'History Orders'
                },
                data: {
                    authorities: ['ROLE_SUPERVISOR', 'ROLE_NURSE', 'ROLE_DOCTOR'],
                    pageTitle: 'Orders'
                },
                params: {
                    orderType: 'ONE_TIME'
                },
                views: {
                    'content@patient-abs': {
                        templateUrl: 'app/entities/patient-order/history-order/patient-order-history.html',
                        controller: 'PatientOrderHistoryController',
                        controllerAs: 'vm'
                    },
                    'onetime@patient-order-history': {
                        templateUrl: 'app/entities/patient-order/history-order/patient-order-onetime.html',
                        controller: 'OneTimeHistoryOrderController',
                        controllerAs: 'vm'
                    },
                    'standing@patient-order-history': {
                        templateUrl: 'app/entities/patient-order/history-order/patient-order-standing.html',
                        controller: 'StandingHistoryOrderController',
                        controllerAs: 'vm'
                    },
                    'recurring@patient-order-history': {
                        templateUrl: 'app/entities/patient-order/history-order/patient-order-recurring.html',
                        controller: 'RecurringHistoryOrderController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {}
            })
            .state('patient-order-current.new', {
                url: '/new-order',
                ncyBreadcrumb: {
                    label: 'New',
                    parent: 'patient-order-current'
                },
                data: {
                    authorities: ['ROLE_SUPERVISOR', 'ROLE_NURSE', 'ROLE_DOCTOR'],
                    pageTitle: 'Orders'
                },
                params: {
                    orderType: 'ONE_TIME',
                    orderTpl: null,
                    patients: null
                },
                views: {
                    'content@patient-abs': {
                        templateUrl: 'app/entities/patient-order/patient-order-new.html',
                        controller: 'PatientOrderNewController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    patientOrder: ['$stateParams', 'entity', 'PatientOrder', function ($stateParams, entity, PatientOrder) {
                        var tpl = $stateParams.orderTpl;

                        if (tpl) {
                            var order = tpl.patientOrderJson;
                            if (order.patientOrderTests.length > 0) {
                                order.patient = entity;

                                return PatientOrder.schedule(order).$promise;
                            }

                            return tpl.patientOrderJson;
                        }

                        return {
                            signatureDate: null,
                            ordStatus: 'SCHEDULED',
                            orderType: $stateParams.orderType,
                            signed: false,
                            id: null,
                            patientOrderTests: []
                        };
                    }],
                    hasStanding: ['PatientOrder', '$stateParams', function (PatientOrder, $stateParams) {
                        return PatientOrder.hasStanding({id: $stateParams.id}).$promise;
                    }],
                    patients: ['$stateParams', function ($stateParams) {
                        return $stateParams.patients ? $stateParams.patients : null;
                    }],
                    patientList: ['Patient', '$stateParams', 'CoreService', function (Patient, $stateParams, CoreService) {
                        if ($stateParams.orderType == 'STANDING') {
                            return Patient.getWithOutStanding({id: CoreService.getCurrentClinic().id});
                        } else {
                            return Patient.getAllByClinic({id: CoreService.getCurrentClinic().id});
                        }
                    }],
                    orderFrequencies: ['OrderFrequency', '$stateParams', function (OrderFrequency, $stateParams) {
                        return OrderFrequency.byordertype({orderType: $stateParams.orderType})
                    }]
                }
            })
            .state('patient-order-update', {
                parent: 'patient-order-current',
                url: '/{oid}/update',
                ncyBreadcrumb: {
                    label: 'Order details',
                    parent: 'patient-order-current'
                },
                data: {
                    authorities: ['ROLE_SUPERVISOR', 'ROLE_NURSE', 'ROLE_DOCTOR'],
                    pageTitle: 'Order Details'
                },
                params: {
                    orderType: 'ONE_TIME'
                },
                views: {
                    'content@patient-abs': {
                        templateUrl: 'app/entities/patient-order/patient-order-update.html',
                        controller: 'PatientOrderUpdateController',
                        controllerAs: 'vm'
                    },
                    'order-tests@patient-order-update': {
                        templateUrl: 'app/entities/patient-order-test/patient-order-tests.html',
                        controller: 'PatientOrderTestController',
                        controllerAs: 'vm',
                        resolve: {
                            canCancel: function () {
                                return true;
                            }
                        }
                    }
                },
                resolve: {
                    patientOrder: ['$stateParams', 'PatientOrder', function ($stateParams, PatientOrder) {
                        return PatientOrder.get({id: $stateParams.oid}).$promise;
                    }]
                }
            })
            .state('patient-order-update.cancel', {
                url: '/cancel',
                data: {
                    authorities: ['ROLE_SUPERVISOR', 'ROLE_NURSE', 'ROLE_DOCTOR'],
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/patient-order/patient-order-cancel-dialog.html',
                        controller: 'PatientOrderCancelController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            patientOrder: ['PatientOrder', '$stateParams', function (PatientOrder, $stateParams) {
                                return PatientOrder.get({id: $stateParams.oid}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('patient-order-current', {"id": $stateParams.id}, {reload: 'patient-order-current'});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('patient-order-update.finish', {
                url: '/finish',
                data: {
                    authorities: ['ROLE_SUPERVISOR', 'ROLE_NURSE', 'ROLE_DOCTOR'],
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/patient-order/patient-order-finish-dialog.html',
                        controller: 'PatientOrderFinishController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            patientOrder: ['PatientOrder', '$stateParams', function (PatientOrder, $stateParams) {
                                return PatientOrder.get({id: $stateParams.oid}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('patient-order-current', {"id": $stateParams.id}, {reload: 'patient-order-current'});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('finish-new', {
                parent: 'patient-order-update',
                url: '/finish-new',
                data: {
                    authorities: ['ROLE_SUPERVISOR', 'ROLE_NURSE', 'ROLE_DOCTOR'],
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/patient-order/current-order/patient-order-standing-confirm.html',
                        controller: 'PatientOrderStandingFinishController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            patientOrder: ['PatientOrder', '$stateParams', function (PatientOrder, $stateParams) {
                                return PatientOrder.get({id: $stateParams.oid}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('patient-order-current.new', {
                            "id": $stateParams.id,
                            orderType: 'STANDING'
                        }, {reload: 'patient-order-current.new'});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('patient-order-view', {
                parent: 'patient-order-history',
                url: '/{oid}/view',
                ncyBreadcrumb: {
                    label: 'Order details',
                    parent: 'patient-order-history'
                },
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'Order Details'
                },
                params: {
                    orderType: 'ONE_TIME'
                },
                views: {
                    'content@patient-abs': {
                        templateUrl: 'app/entities/patient-order/patient-order-view.html',
                        controller: 'PatientOrderViewController',
                        controllerAs: 'vm'
                    },
                    'order-tests@patient-order-view': {
                        templateUrl: 'app/entities/patient-order-test/patient-order-tests.html',
                        controller: 'PatientOrderTestController',
                        controllerAs: 'vm',
                        resolve: {
                            canCancel: function () {
                                return false;
                            }
                        }
                    }
                },
                resolve: {
                    patientOrder: ['$stateParams', 'PatientOrder', function ($stateParams, PatientOrder) {
                        return PatientOrder.get({id: $stateParams.oid}).$promise;
                    }]
                }
            })
            .state('patient-order-unsigned', {
                parent: 'entity',
                url: '/patient-orders/unsigned',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_SUPERVISOR', 'ROLE_NURSE', 'ROLE_DOCTOR'],
                    pageTitle: 'Unsigned Results'
                },
                ncyBreadcrumb: {
                    label: 'Unsigned Results'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/patient-order/dashboard/patient-orders-unsigned.html',
                        controller: 'PatientOrdersUnsignedController',
                        controllerAs: 'vm'
                    }
                }
            })
    }
})();
