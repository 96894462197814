(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('PatientResultLatestController', PatientResultLatestController);

    PatientResultLatestController.$inject = ['clinic', '$compile', '$scope', 'Patient', 'filterRes', 'moment',
        '$uibModal', 'PopupService', 'DataTablesService', 'PatientResultSearch', '$q', 'DTColumnBuilder',
        'DTOptionsBuilder', 'GUIUtils', '$filter', 'PatientResultFile', '$rootScope',
        'CoreService', 'PatientResult', 'dialogs', 'Principal', 'ROLES', 'toastr'];

    function PatientResultLatestController(clinic, $compile, $scope, Patient, filterRes, moment,
                                           $uibModal, PopupService, DataTablesService, PatientResultSearch, $q, DTColumnBuilder,
                                           DTOptionsBuilder, GUIUtils, $filter, PatientResultFile, $rootScope,
                                           CoreService, PatientResult, $dialogs, Principal, ROLES, toastr) {
        var vm = this;

        vm.title = "Latest Results";
        vm.showFilter = false;
        var titleHtml = '<input type="checkbox" ng-model="vm.selectAll" ng-change="vm.toggleAll(vm.selected, vm.selectAll)">';
        vm.patientResults = [];
        vm.dtInstance = {};
        vm.clinic = clinic;
        vm.filterRes = filterRes;
        vm.selected = {};
        vm.selectAll = false;
        vm.shifts = Patient.get_shift({id: vm.clinic.id});
        vm.shift = null;
        vm.shiftImpl = -1;
        vm.currentMonth = false;
        vm.page = 1;
        vm.itemsPerPage = 10;

        vm.search = search;
        vm.toggleAll = DataTablesService.toggleAll;
        vm.toggleOne = DataTablesService.toggleOne;
        vm.selectShift = selectShift;
        vm.clearShift = clearShift;
        vm.printRangePDFs = printRangePDFs;
        vm.updateStatusButtonFilter = updateStatusButtonFilter;
        vm.updateCurrentMonthFilter = updateCurrentMonthFilter;
        vm.getFilter = getFilter;
        vm.details = details;
        vm.openCalendar = openCalendar;
        vm.clear = clear;
        vm.showFiltersPanel = showFiltersPanel;
        vm.sendToEmr = sendToEmr;
        vm.transition = transition;
        vm.dtInstanceCallback = dtInstanceCallback;

        angular.element('.filter-box').hide();

        function showFiltersPanel() {
            angular.element('.filter-box').toggleClass('active');

            if (angular.element('.filter-box').hasClass('active')) {
                angular.element('.toggle-filters').addClass('open');
                angular.element('.filter-box').slideDown();
            } else {
                angular.element('.toggle-filters').removeClass('open');
                angular.element('.filter-box').slideUp();
            }
        }

        vm.dtOptions = DTOptionsBuilder.fromFnPromise(function () {
            var defer = $q.defer();

            PatientResult.getAllByFilter(getFilter(), function (result, headers) {
                vm.totalItems = headers('X-Total-Count');

                vm.patientResults = result;
                defer.resolve(vm.patientResults);
            })

            vm.selected = {};
            vm.selectAll = false;

            return defer.promise;
        }).withOption("bPaginate", false)
            .withBootstrap()
            .withDOM('tp')
            .withOption('headerCallback', function (header) {
                if (!vm.headerCompiled) {
                    vm.headerCompiled = true;
                    $compile(angular.element(header).contents())($scope);
                }
            })
            .withOption('fnRowCallback',
                function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                    $compile(nRow)($scope);
                });

        vm.dtColumns = [
            DTColumnBuilder.newColumn(null).withTitle(titleHtml).withOption('width', '25px').notSortable().renderWith(function (data, type, full, meta) {
                vm.selected[full.id] = false;

                return '<input type="checkbox" ng-model="vm.selected[' + data.id + ']" ng-click="vm.toggleOne(vm.selected, vm.selectAll)">';
            }),
            DTColumnBuilder.newColumn('id').withTitle('ID'),
            DTColumnBuilder.newColumn('patientName').withTitle('Patient Name'),
            DTColumnBuilder.newColumn(null).withTitle('DOB').renderWith(function (data, type, full) {
                return moment(data.dob).format($rootScope.displayDateformat);
            }),
            DTColumnBuilder.newColumn(null).withTitle('Barcode').renderWith(function (data, type, full) {
                return data.accessionNumber ? data.accessionNumber : '';
            }),
            DTColumnBuilder.newColumn(null).withTitle('Collection Date').renderWith(function (data, type, full) {
                return data.collectionDate ? moment(data.collectionDate).format($rootScope.displayDateformat) : "";
            }),
            DTColumnBuilder.newColumn(null).withTitle('Result Date').renderWith(function (data, type, full) {
                return moment(data.createdDate).format($rootScope.displayDateformat);
            }),
            DTColumnBuilder.newColumn('').withTitle('').withOption('width', '175px').withTitle('Alert').notSortable()
                .renderWith(abnormalHtml),
            DTColumnBuilder.newColumn('status').withTitle('Status').renderWith(statusHtml),
            DTColumnBuilder.newColumn('').withTitle('Actions').withOption('class', 'col').withOption('width', '175px').notSortable()
                .renderWith(actionsHtml)
        ];

        function abnormalHtml(data, type, full, meta) {
            if (full.abnormalRange) {
                return GUIUtils.getStatusTemplate('ABNORMAL', 'danger');
            } else {
                return GUIUtils.getStatusTemplate('NORMAL', 'success');
            }
        }

        function statusHtml(data, type, full, meta) {
            if (full.status == 'PARTIAL') {
                return GUIUtils.getStatusTemplate(full.status, 'warning');
            } else {
                return GUIUtils.getStatusTemplate(full.status, 'primary');
            }
        }

        function actionsHtml(data, type, full, meta) {
            return '<a class="dt-btn warning" ng-click="vm.details(' + full.id + ')" uib-tooltip="View Details"><i class="fa fa-file-text" aria-hidden="true"></i></a>' +
                '<a class="dt-btn danger" ng-click="vm.printRangePDFs(' + full.id + ')" uib-tooltip="View PDF"><i class="fa fa-file-pdf-o" aria-hidden="true"></i></a>'
        }

        function search() {
            CoreService.setStorage('filterPatient', vm.filterRes);
            vm.dtInstance.reloadData();
        }

        function getFilter() {
            var filter = {
                clinicId: vm.clinic.id,
                patientName: vm.filterRes.patientName,
                searchQuery: vm.searchQuery,
                status: vm.filterRes.status,
                abnormalRange: vm.filterRes.abnormal,
                startDate: vm.filterRes.start ? moment(vm.filterRes.start).format("YYYY-MM-DD") : null,
                endDate: vm.filterRes.end ? moment(vm.filterRes.end).format("YYYY-MM-DD") : null,
                dob: vm.filterRes.dob ? moment(vm.filterRes.dob).format("YYYY-MM-DD") : null,
                accessionNumber: vm.filterRes.barcode,
                reviewedStatus: vm.filterRes.reviewedStatus,
                page: vm.filterRes.page - 1,
                size: vm.itemsPerPage,
                shift: vm.filterRes.shiftImpl
            };

            var must = [];
            must.push({
                bool: {
                    "should": [
                        {"term": {"clinicId": vm.clinic.id}},
                        {"term": {"clinic.id": vm.clinic.id}}
                    ]
                }
            });

            must.push({term: {"delStatus": false}});
            must.push({exists: {"field": "accessionNumber"}});
            must.push({term: {"patient.status": true}});

            if ((vm.filterRes.patientName != null && vm.filterRes.patientName.length > 0) ||
                (vm.searchQuery != null && vm.searchQuery.length > 0)) {

                var patientNameQuery = {
                    bool: {
                        should: []
                    }
                };

                if (vm.filterRes.patientName != null && vm.filterRes.patientName.length > 0) {
                    patientNameQuery.bool.should.push({match_phrase_prefix: {patientName: {query: vm.filterRes.patientName}}})
                }

                if (vm.searchQuery != null && vm.searchQuery.length > 0) {
                    patientNameQuery.bool.should.push({match_phrase_prefix: {patientName: {query: vm.searchQuery}}})
                }

                must.push(patientNameQuery);
            }

            if (vm.filterRes.status != null && vm.filterRes.status.length > 0) {
                must.push({match: {status: {query: vm.filterRes.status}}});
            }

            if (vm.filterRes.abnormal != null && vm.filterRes.abnormal.length > 0) {
                must.push({match: {abnormalRange: {query: vm.filterRes.abnormal}}});
            }

            if (vm.filterRes.start) {
                must.push({range: {collectionDate: {gte: moment(vm.filterRes.start).format("YYYY-MM-DD")}}});
            }

            if (vm.filterRes.end) {
                must.push({range: {collectionDate: {lte: moment(vm.filterRes.end).format("YYYY-MM-DD")}}});
            }

            if (vm.filterRes.dob) {
                must.push({match: {dob: moment(vm.filterRes.dob).format("YYYY-MM-DD")}});
            }

            if (vm.filterRes.barcode != null && vm.filterRes.barcode.length > 0) {
                must.push({match: {accessionNumber: {query: vm.filterRes.barcode}}});
            }

            if (vm.filterRes.status != null && vm.filterRes.status.length > 0) {
                must.push({match: {status: {query: vm.filterRes.status}}});
            }

            if (vm.filterRes.reviewedStatus != null && vm.filterRes.reviewedStatus.length > 0) {
                must.push({match: {reviewedStatus: vm.filterRes.reviewedStatus}});
            }

            // return must;

            return filter;
        }

        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.start = false;
        vm.datePickerOpenStatus.end = false;
        vm.datePickerOpenStatus.dob = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function clear() {
            vm.filterRes = CoreService.initPatientResultFilter();
            selectShift();
            CoreService.setStorage('filterPatient', vm.filterRes);
            vm.dtInstance.reloadData();
        }

        function selectShift() {
            if (vm.filterRes.shift == null) {
                vm.filterRes.shiftImpl = -1
            } else {
                vm.filterRes.shiftImpl = vm.filterRes.shift.id;
            }
        }

        function clearShift() {
            vm.shift = null;
            selectShift()
        }

        function printRangePDFs(id) {
            var ids;

            if (id) {
                ids = [id];
            } else {
                ids = DataTablesService.getSelectedIds(vm.selected);
            }

            if (ids.length > 0) {
                PatientResultFile.getAllInOneRangePdf({ids: ids});
            }
        }

        function updateStatusButtonFilter(status) {
            vm.filterRes.status = status;
            CoreService.setStorage('filterPatient', vm.filterRes);
            vm.dtInstance.reloadData();
        }

        function updateCurrentMonthFilter() {
            if (vm.currentMonth) {
                var nowdate = new Date();
                var monthStartDay = new Date(nowdate.getFullYear(), nowdate.getMonth(), 1);
                var monthEndDay = new Date(nowdate.getFullYear(), nowdate.getMonth() + 1, 0);

                vm.filterRes.start = monthStartDay;
                vm.filterRes.end = monthEndDay;
            } else {
                vm.filterRes = CoreService.initPatientResultFilter();
            }

            CoreService.setStorage('filterPatient', vm.filterRes);
            vm.dtInstance.reloadData();
        }

        function details(id) {
            var patientResult = vm.patientResults.find(function (el) {
                return el.id === id;
            });

            PopupService.showPatientResultDetPopup(patientResult);
        }

        function sendToEmr() {
            var ids = DataTablesService.getSelectedIds(vm.selected);

            if (ids.length > 0) {
                PatientResult.sendToEmr({ids: ids}, function (result) {
                    if (result.barcodes && result.barcodes.length > 0) {
                        $dialogs.error("Error", "The following patient results can't be send: " +
                            _.map(result.barcodes, function (barcode) {
                                    return barcode;
                                }
                            ).join(', ')
                        );
                    } else {
                        toastr.success('Patient results are in queue and will be send shortly');
                    }
                });
            }
        }

        vm.enableEmrIntegration = vm.clinic.emrIntegration != null && vm.clinic.emrIntegration.length > 0 && Principal.hasRole(ROLES.ROLE_ADMIN);

        function transition() {
            if (vm.dtInstance.reloadData) {
                CoreService.setStorage('filterPatient', vm.filterRes);

                vm.dtInstance.reloadData();
            }
        }

        function dtInstanceCallback(instance) {
            vm.dtInstance = instance;
        }
    }
})();
