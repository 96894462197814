(function () {
    'use strict';
    angular
        .module('dialysisApp')
        .factory('PatientResultFile', PatientResultFile);

    PatientResultFile.$inject = ['$resource', 'DataUtils'];

    function PatientResultFile($resource, DataUtils) {
        var resourceUrl = 'api/patient-result-files/:id';

        return $resource(resourceUrl, {}, {
            'getAllByPatientResult': {
                url: 'api/patient-result-files/by-patient-result/all/:id',
                method: 'GET',
                isArray: true
            },
            'getByPatientResult': {
                url: 'api/patient-result-files/by-patient-result/getpdf',
                method: 'POST',
                responseType: 'arraybuffer',
                transformResponse: function (data, headers) {
                    DataUtils.downloadAndOpenPDFFile(data, headers);
                }
            },
            'getPdf': {
                url: 'api/patient-result-files/get-pdf',
                method: 'POST',
                responseType: 'arraybuffer',
                transformResponse: function (data, headers) {
                    DataUtils.downloadAndOpenPDFFile(data, headers);
                }
            },
            'getOnePDFByPatientResults': {
                url: 'api/patient-result-files/get-all-in-one-pdf',
                method: 'POST',
                responseType: 'arraybuffer',
                transformResponse: function (data, headers) {
                    DataUtils.downloadAndOpenPDFFile(data, headers);
                }
            },
            'getAllInOneRangePdf': {
                url: 'api/patient-result-files/get-all-in-one-range-pdf',
                method: 'POST',
                responseType: 'arraybuffer',
                transformResponse: function (data, headers) {
                    DataUtils.downloadAndOpenPDFFile(data, headers);
                }
            }
        });
    }
})();
