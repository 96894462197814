(function () {
    'use strict';
    angular
        .module('dialysisApp')
        .factory('EnvResult', EnvResult);

    EnvResult.$inject = ['$resource', 'DateUtils', 'DataUtils'];

    function EnvResult($resource, DateUtils, DataUtils) {
        var resourceUrl = 'api/env-results/:id';

        function transformCountResponse(data) {
            if (data) {
                data = angular.fromJson(data);
                data = {value: data};
            }
            return data;
        }

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.dob = DateUtils.convertLocalDateFromServer(data.dob);
                        data.collectionDate = DateUtils.convertLocalDateFromServer(data.collectionDate);
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.dob = DateUtils.convertLocalDateToServer(copy.dob);
                    copy.collectionDate = DateUtils.convertLocalDateToServer(copy.collectionDate);
                    return angular.toJson(copy);
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.dob = DateUtils.convertLocalDateToServer(copy.dob);
                    copy.collectionDate = DateUtils.convertLocalDateToServer(copy.collectionDate);
                    return angular.toJson(copy);
                }
            },
            'by_clinic': {url: "api/env-results/by-clinic/:id", method: 'GET', isArray: true},
            'by_machine': {
                url: "api/env-results/by-machine/:id", method: 'GET', isArray: true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.createdDate = DateUtils.convertLocalDateFromServer(data.createdDate);
                    }
                    return data;
                }
            },
            'byBarcode': {
                url: "api/env-results/barcode/:barcode/",
                method: 'GET',
                // transformResponse: function (data) {
                //     if (data) {
                //         data = angular.fromJson(data);
                //         data.dob = DateUtils.convertLocalDateFromServer(data.dob);
                //         data.collectionData = DateUtils.convertLocalDateFromServer(data.collectionData);
                //         data.reviewedOn = DateUtils.convertLocalDateFromServer(data.reviewedOn);
                //     }
                //     return data;
                // }
            },
            'countAbnormalNotReviewedLast7Days': {url: 'api/env-results/count-abnormal-not-reviewed-last-7days/:id', method: 'GET', isArray: true},
            'markAsReviewed': {url: 'api/env-result/mark-as-reviewed', method: 'POST'},
            'updateDates': {method: 'POST', url: "api/env-results/update-dates"},
            'countAbnormalNotReviewed': {
                url: 'api/env-results/count-abnormal-not-reviewed/:id', method: 'GET',
                transformResponse: transformCountResponse
            }
        });
    }
})();
