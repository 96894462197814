(function() {
    'use strict';

    angular
        .module('dialysisApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('patient-picture-edit', {
            parent: 'patient-edit',
            url: '/picture',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', 'entity', function($stateParams, $state, $uibModal, entity) {
                $uibModal.open({
                    templateUrl: 'app/entities/patient-img/patient-img-dialog.html',
                    controller: 'PatientImgDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                        entity: entity
                    }
                }).result.then(function() {
                    $state.go('patient-edit', null, { reload: 'patient-edit'});
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('patient-picture-new', {
            parent: 'patient-new',
            url: '/picture-new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', 'entity', function($stateParams, $state, $uibModal, entity) {
                $uibModal.open({
                    templateUrl: 'app/entities/patient-img/patient-img-dialog.html',
                    controller: 'PatientImgDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                        entity: entity
                    }
                }).result.then(function() {
                    $state.go('patient-new', null, { reload: 'patient-new'});
                }, function() {
                    $state.go('^');
                });
            }]
        })
    }
})();
