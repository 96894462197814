(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('PatientOrdersSignDialogController', PatientOrdersSignDialogController);

    PatientOrdersSignDialogController.$inject = ['$uibModalInstance', 'orderIds', 'PatientOrder', 'toastr'];

    function PatientOrdersSignDialogController($uibModalInstance, orderIds, PatientOrder, toastr) {
        var vm = this;

        vm.signatureOpts = {
            cssClass: 'signature-canvas',
            color: '#00008B',
            'background-color': '#fff'
        };

        vm.signature = '';
        vm.signaturePin = null;

        vm.wizardTitle = 'Sign Order(s)';

        vm.patientOrderTests = [];
        vm.dtInstance = {};
        vm.orderIds = orderIds.values;

        vm.signOrders = signOrders;
        vm.showSignature = showSignature;
        vm.clear = clear;

        function signOrders() {
            vm.isSaving = true;
            PatientOrder.signOrders({
                orderIds: vm.orderIds,
                signature: vm.signature,
                pin: vm.signaturePin
            }, function (result) {
                if (result.length > 0) {
                    toastr.warning('Sign operation fail for some Patient Orders');
                } else {
                    toastr.success('Sign operation successful');
                }
                vm.isSaving = false;
                $uibModalInstance.close();
            }, function () {
                vm.isSaving = false;
            });
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function showSignature() {
            return vm.signaturePin == null || vm.signaturePin == '';
        }
    }
})();
