(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('Billing2Controller', Billing2Controller);

    Billing2Controller.$inject = ['OdsParamType', 'CoreService', 'Corporation', 'Clinic'];

    function Billing2Controller(OdsParamType, CoreService, Corporation, Clinic) {

        var vm = this;

        vm.currentClinic = CoreService.getCurrentClinic();

        vm.reportsGroup = {
            title: 'Billing',
            groups: [{
                id: 1,
                open: true,
                disabled: false,
                title: 'Clinic',
                icon: 'fa fa-dashboard',
                reports: [
                    {
                        id: 1,
                        title: 'Billing All',
                        url: 'api/report/billing/all',
                        base64: false,
                        params: [
                            {
                                title: 'Start Date',
                                name: 'start_date',
                                type: OdsParamType.DATE,
                                value: new Date(),
                                hidden: false
                            },
                            {
                                title: 'End Date',
                                name: 'end_date',
                                type: OdsParamType.DATE,
                                value: new Date(),
                                hidden: false
                            },
                            {
                                title: 'Price',
                                name: 'end_date',
                                type: OdsParamType.TEXT,
                                value: 60.0,
                                hidden: false
                            }
                        ]
                    },
                    {
                        id: 2,
                        title: 'Billing Total',
                        url: 'api/report/billing/total',
                        base64: false,
                        params: [
                            {
                                title: 'Start Date',
                                name: 'start_date',
                                type: OdsParamType.DATE,
                                value: new Date(),
                                hidden: false
                            },
                            {
                                title: 'End Date',
                                name: 'end_date',
                                type: OdsParamType.DATE,
                                value: new Date(),
                                hidden: false
                            }
                        ]
                    }
                ]
            }]
        };
    }
})();
