(function() {
    'use strict';
    angular
        .module('dialysisApp')
        .factory('Infection', Infection);

    Infection.$inject = ['$resource', 'DateUtils'];

    function Infection ($resource, DateUtils) {
        var resourceUrl =  'api/infections/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.suspectedDate = DateUtils.convertLocalDateFromServer(data.suspectedDate);
                        data.confirmedDate = DateUtils.convertLocalDateFromServer(data.confirmedDate);
                        data.hospitalizationDate = DateUtils.convertLocalDateFromServer(data.hospitalizationDate);
                        data.drInfectionDate = DateUtils.convertLocalDateFromServer(data.drInfectionDate);
                        data.aStartedDate = DateUtils.convertLocalDateFromServer(data.aStartedDate);
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.suspectedDate = DateUtils.convertLocalDateToServer(copy.suspectedDate);
                    copy.confirmedDate = DateUtils.convertLocalDateToServer(copy.confirmedDate);
                    copy.hospitalizationDate = DateUtils.convertLocalDateToServer(copy.hospitalizationDate);
                    copy.drInfectionDate = DateUtils.convertLocalDateToServer(copy.drInfectionDate);
                    copy.aStartedDate = DateUtils.convertLocalDateToServer(copy.aStartedDate);
                    return angular.toJson(copy);
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.suspectedDate = DateUtils.convertLocalDateToServer(copy.suspectedDate);
                    copy.confirmedDate = DateUtils.convertLocalDateToServer(copy.confirmedDate);
                    copy.hospitalizationDate = DateUtils.convertLocalDateToServer(copy.hospitalizationDate);
                    copy.drInfectionDate = DateUtils.convertLocalDateToServer(copy.drInfectionDate);
                    copy.aStartedDate = DateUtils.convertLocalDateToServer(copy.aStartedDate);
                    return angular.toJson(copy);
                }
            },
            'getAllByPatientId': { 'url': 'api/infections/patient/:id', method: 'GET', isArray: true},
        });
    }
})();
