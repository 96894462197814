(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('EnvTestNewDialogController', EnvTestNewDialogController);

    EnvTestNewDialogController.$inject = ['envTest' , 'order', 'CompendiumSearch', 'OrderFrequency', '$uibModalInstance', 'EnvOrder', 'CoreService'];

    function EnvTestNewDialogController(envTest, order, CompendiumSearch, OrderFrequency, $uibModalInstance, EnvOrder, CoreService) {
        var vm = this;

        vm.order = order;
        vm.envTest = envTest;
        vm.is_new = vm.envTest.schedules.length == 0;

        vm.form = {};
        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.endDate = false;
        vm.datePickerOpenStatus.staringDate = false;

        vm.orderfrequencies = OrderFrequency.query().$promise.then(function (data) {
          vm.orderfrequencies =  _.filter(data, { 'days': 0 });
        });

        vm.startOptions = {
            minDate: new Date(),
            startingDay: 1
        }

        vm.endOptions = {
            minDate: new Date(),
            startingDay: 1
        }

        vm.change = function () {
            if (vm.envTest.staringDate && vm.envTest.orderFrequency &&
                (vm.envTest.endDate || vm.envTest.orderFrequency.days == 0) &&
                vm.envTest.compendium) {

                vm.scheduleTest();
            }

            vm.endOptions.minDate = vm.envTest.staringDate;
        }

        vm.clinic = CoreService.getCurrentClinic();
        var days = vm.clinic.wDrawDays;

        vm.clear = function () {
            $uibModalInstance.dismiss('cancel');
        }

        vm.openCalendar = openCalendar;
        vm.orderfrequencies = [];

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        vm.compendiums = [];

        vm.getCompendium = getCompendium;

        function getCompendium(query) {
            if (query && query.length > 2) {
                CompendiumSearch.query({query: query}, function (result) {
                    vm.compendiums = result;
                });
            }
        }

        vm.scheduleTest = scheduleTest;

        function scheduleTest() {
            var orderCopy = {};
            angular.copy(vm.order, orderCopy);
            angular.copy([vm.envTest], orderCopy.envTests);

            EnvOrder.schedule(orderCopy, function (data) {
                vm.envTest = data.envTests[0];
            }, function () {
                alert('Error creating test');
            });
        }

        vm.accept = function () {
            $uibModalInstance.close({envTest: vm.envTest, is_new: vm.is_new});
        }
    }
})();
