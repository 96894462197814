(function() {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('AccessDialogController', AccessDialogController);

    AccessDialogController.$inject = ['$timeout', '$scope', '$state', '$uibModalInstance', 'access', 'Access'];

    function AccessDialogController ($timeout, $scope, $state, $uibModalInstance, access, Access) {
        var vm = this;

        vm.access = access;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.editing = $state.current.name != "access-view";

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.access.id !== null) {
                Access.update(vm.access, onSaveSuccess, onSaveError);
            } else {
                Access.save(vm.access, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('dialysisApp:accessUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.placementDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
