(function() {
    'use strict';

    angular
        .module('dialysisApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('patient-status', {
            parent: 'patient-abs',
            url: '/status',
            ncyBreadcrumb: {
                label: 'Statuses',
            },
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'Statuses'
            },
            views: {
                'content@patient-abs': {
                    templateUrl: 'app/entities/patient-status/patient-statuses.html',
                    controller: 'PatientStatusController',
                    controllerAs: 'vm'
                }
            }

        })
        .state('patient-status.view', {
            parent: 'patient-status',
            url: '/{psid}/view',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/patient-status/patient-status-dialog.html',
                    controller: 'PatientStatusDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        patientStatus: ['PatientStatus', function(PatientStatus) {
                            return PatientStatus.get({id : $stateParams.psid}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('patient-status', {id: $stateParams.id}, { reload: 'patient-status' });
                }, function() {
                    $state.go('patient-status', {id: $stateParams.id}, { reload: 'patient-status' });
                });
            }]
        })
        .state('patient-status.new', {
            parent: 'patient-status',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', 'entity', function($stateParams, $state, $uibModal, entity) {
                $uibModal.open({
                    templateUrl: 'app/entities/patient-status/patient-status-dialog.html',
                    controller: 'PatientStatusDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        patientStatus: ['CoreService', function (CoreService) {
                            return {
                                startDate: null,
                                endDate: null,
                                endStatus: null,
                                id: null,
                                clinic: CoreService.getCurrentClinic(),
                                patient: entity
                            };
                        }]
                    }
                }).result.then(function() {
                    $state.go('patient-status', {id: $stateParams.id}, { reload: 'patient-status' });
                }, function() {
                    $state.go('patient-status', {id: $stateParams.id}, { reload: 'patient-status' });
                });
            }]
        })
        .state('patient-status.delete', {
            parent: 'patient-status',
            url: '/{psid}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/patient-status/patient-status-delete-dialog.html',
                    controller: 'PatientStatusDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['PatientStatus', function(PatientStatus) {
                            return PatientStatus.get({id : $stateParams.psid}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('patient-status', null, { reload: 'patient-status' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
