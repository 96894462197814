(function() {
    'use strict';

    angular
        .module('dialysisApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('invoice-environmental', {
            parent:'invoice-detail',
            url: '/invoice-environmental',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'Invoice Environmental'
            },
            ncyBreadcrumb: {
                label: 'Invoice Environmental',
                parent: 'invoice'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/invoice-environmental/invoice-environmentals.html',
                    controller: 'InvoiceEnvironmentalController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
            }
        })
        .state('invoice-environmental-detail', {
            parent: 'entity',
            url: '/invoice-environmental/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'InvoiceEnvironmental'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/invoice-environmental/invoice-environmental-detail.html',
                    controller: 'InvoiceEnvironmentalDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: ['$stateParams', 'InvoiceEnvironmental', function($stateParams, InvoiceEnvironmental) {
                    return InvoiceEnvironmental.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'invoice-environmental',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('invoice-environmental-detail.edit', {
            parent: 'invoice-environmental-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/invoice-environmental/invoice-environmental-dialog.html',
                    controller: 'InvoiceEnvironmentalDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['InvoiceEnvironmental', function(InvoiceEnvironmental) {
                            return InvoiceEnvironmental.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('invoice-environmental.edit', {
            parent: 'invoice-environmental',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/invoice-environmental/invoice-environmental-dialog.html',
                    controller: 'InvoiceEnvironmentalDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['InvoiceEnvironmental', function(InvoiceEnvironmental) {
                            return InvoiceEnvironmental.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('invoice-environmental', null, { reload: 'invoice-environmental' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
    }
})();
