(function () {
    'use strict';
    angular
        .module('dialysisApp')
        .factory('Invoice', Invoice);

    Invoice.$inject = ['$resource', 'DateUtils', 'DataUtils'];

    function Invoice($resource, DateUtils, DataUtils) {
        var resourceUrl = 'api/invoices/:id';

        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.startDate = DateUtils.convertLocalDateFromServer(data.startDate);
                        data.endDate = DateUtils.convertLocalDateFromServer(data.endDate);
                    }
                    return data;
                }
            },
            'adjust': {
                method: 'POST',
                url: 'api/invoices/adjust',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.startDate = DateUtils.convertLocalDateToServer(copy.startDate);
                    copy.endDate = DateUtils.convertLocalDateToServer(copy.endDate);
                    return angular.toJson(copy);
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.startDate = DateUtils.convertLocalDateToServer(copy.startDate);
                    copy.endDate = DateUtils.convertLocalDateToServer(copy.endDate);
                    return angular.toJson(copy);
                }
            },
            'getPdf': {
                url: "api/invoices/get-pdf/:id",
                method: 'GET',
                responseType: 'arraybuffer',
                transformResponse: function (data, headers) {
                    DataUtils.downloadAndOpenPDFFile(data, headers);
                }
            }
        });
    }
})();
