(function () {
    'use strict';
    angular
        .module('dialysisApp')
        .factory('Patient', Patient);

    Patient.$inject = ['$resource', 'DateUtils', 'GUIUtils'];

    function Patient($resource, DateUtils, GUIUtils) {
        var resourceUrl = 'api/patients/:id';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.dob = DateUtils.convertLocalDateFromServer(data.dob);
                        data.fddDate = DateUtils.convertLocalDateFromServer(data.fddDate);
                        data.fDayDialysis = DateUtils.convertLocalDateFromServer(data.fDayDialysis);
                        data.clinicAdmission = DateUtils.convertLocalDateFromServer(data.clinicAdmission);

                        if (data.patientPictures && data.patientPictures.length > 0) {
                            data.patientPicture = data.patientPictures[0];
                        } else {
                            GUIUtils.resetUserPicture(data);
                        }

                        if (data.patientAccess) {
                            data.patientAccess.placementDate = DateUtils.convertLocalDateFromServer(data.patientAccess.placementDate);
                            data.patientAccess.endDate = DateUtils.convertLocalDateFromServer(data.patientAccess.endDate);
                        }

                        if (data.insurances) {
                            for (var i = 0; i < data.insurances.length; i++) {
                                if (data.insurances[i].dob) {
                                    data.insurances[i].dob = DateUtils.convertLocalDateFromServer(data.insurances[i].dob);
                                }
                            }
                        }
                    }

                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.dob = DateUtils.convertLocalDateToServer(copy.dob);
                    copy.fddDate = DateUtils.convertLocalDateToServer(copy.fddDate);
                    copy.fDayDialysis = DateUtils.convertLocalDateToServer(copy.fDayDialysis);
                    copy.clinicAdmission = DateUtils.convertLocalDateToServer(copy.clinicAdmission);

                    copy.patientPictures = [copy.patientPicture];

                    if (copy.patientAccess) {
                        copy.patientAccess.placementDate = DateUtils.convertLocalDateToServer(copy.patientAccess.placementDate);
                        copy.patientAccess.endDate = DateUtils.convertLocalDateToServer(copy.patientAccess.endDate);
                    }

                    for (var i = 0; i < copy.insurances.length; i++) {
                        if (copy.insurances[i].dob) {
                            copy.insurances[i].dob = DateUtils.convertLocalDateToServer(copy.insurances[i].dob);
                        }
                    }

                    return angular.toJson(copy);
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.dob = DateUtils.convertLocalDateToServer(copy.dob);
                    copy.fddDate = DateUtils.convertLocalDateToServer(copy.fddDate);
                    copy.fDayDialysis = DateUtils.convertLocalDateToServer(copy.fDayDialysis);
                    copy.clinicAdmission = DateUtils.convertLocalDateToServer(copy.clinicAdmission);

                    copy.patientPictures = [copy.patientPicture];

                    if (copy.patientAccess) {
                        copy.patientAccess.placementDate = DateUtils.convertLocalDateToServer(copy.patientAccess.placementDate);
                        copy.patientAccess.endDate = DateUtils.convertLocalDateToServer(copy.patientAccess.endDate);
                    }

                    for (var i = 0; i < copy.insurances.length; i++) {
                        if (copy.insurances[i].dob) {
                            copy.insurances[i].dob = DateUtils.convertLocalDateToServer(copy.insurances[i].dob);
                        }
                    }

                    return angular.toJson(copy);
                }
            },
            "getAllByClinic": {'url': 'api/patients/clinic/:id', method: 'GET', isArray: true},
            'import': {
                'url': 'api/patients/import',
                method: 'POST',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    return angular.toJson(copy);
                },
                transformResponse: function (data) {

                    var response = {
                        success: (data === 'true')
                    }
                    return response;
                }
            },
            'getWithOutStanding': {'url': 'api/patients/clinic/:id/with-out-standing', method: 'GET', isArray: true},
            'roster': {'url': 'api/patients/roster/:id/:status', method: 'GET', isArray: true},
            '_4filter': {url: 'api/patients/_4filter/by-clinic/:id', method: 'GET', isArray: true},
            'get_shift': {
                url: 'api/patients/shift/by-clinic/:id', method: 'GET', isArray: true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);

                        var shifts = [{id: -1, shift: 'Any'}];
                        for (var i = 0; i < data.length; i++) {
                            if (data[i] == null) {
                                shifts.push({id: 0, shift: 'No Shift'});
                            } else {
                                shifts.push({id: data[i], shift: data[i]});
                            }
                        }
                    }

                    return shifts;
                }
            },
            'change' : {
                url: 'api/patients/change', method: 'POST'
            },
            'deactivate': {url: 'api/patients/deactivate', method: 'POST'},
            'byBarcode': {url: 'api/patients/by-barcode/:barcode', method: 'GET', transformResponse: function (data) {
                if (data) {
                    data = angular.fromJson(data);
                    data.dob = DateUtils.convertLocalDateFromServer(data.dob);
                    data.fddDate = DateUtils.convertLocalDateFromServer(data.fddDate);
                    data.fDayDialysis = DateUtils.convertLocalDateFromServer(data.fDayDialysis);
                    data.clinicAdmission = DateUtils.convertLocalDateFromServer(data.clinicAdmission);

                    if (data.patientPictures && data.patientPictures.length > 0) {
                        data.patientPicture = data.patientPictures[0];
                    } else {
                        GUIUtils.resetUserPicture(data);
                    }

                    if (data.patientAccess) {
                        data.patientAccess.placementDate = DateUtils.convertLocalDateFromServer(data.patientAccess.placementDate);
                        data.patientAccess.endDate = DateUtils.convertLocalDateFromServer(data.patientAccess.endDate);
                    }

                    if (data.insurances) {
                        for (var i = 0; i < data.insurances.length; i++) {
                            if (data.insurances[i].dob) {
                                data.insurances[i].dob = DateUtils.convertLocalDateFromServer(data.insurances[i].dob);
                            }
                        }
                    }
                }

                return data;
            }},
            'withoutBarcode': {
            url: 'api/patients/by-patientId/:patient', method: 'GET', transformResponse: function (data) {
                if (data) {
                    data = angular.fromJson(data);
                    data.dob = DateUtils.convertLocalDateFromServer(data.dob);

                    if (data.patientPictures && data.patientPictures.length > 0) {
                        data.patientPicture = data.patientPictures[0];
                    } else {
                        GUIUtils.resetUserPicture(data);
                    }
                }

                return data;
            }
        },
            'byHealthParams': {url: 'api/patients/by-health', method: 'POST', isArray: true},
            'withOrderStatus': {url: 'api/patients/all/:id', method: 'GET', isArray: true},
            'allRoster': {'url': 'api/patients/roster/:id', method: 'GET', isArray: true}
        });
    }
})();
