(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('VaccinationController', VaccinationController);

    VaccinationController.$inject = ['$compile', '$scope', 'Vaccination', 'entity', '$q', 'DTColumnBuilder',
        'DTOptionsBuilder', '$filter', 'moment', 'GUIUtils', '$rootScope'];

    function VaccinationController($compile, $scope, Vaccination, entity, $q, DTColumnBuilder, DTOptionsBuilder,
                                   $filter, moment, GUIUtils, $rootScope) {
        var vm = this;

        vm.patient = entity;
        vm.vaccination = [];
        vm.search = search;
        vm.dtInstance = {};

        vm.dtOptions = DTOptionsBuilder.fromFnPromise(function () {
            var defer = $q.defer();
            if (!vm.searchQuery || vm.searchQuery == '') {
                Vaccination.getAllByPatientId({id : vm.patient.id}, function (result) {
                    vm.vaccination = result;
                    defer.resolve(result);
                });
            } else {
                defer.resolve($filter('filter')(vm.patients, vm.searchQuery, undefined));
            }
            return defer.promise;
        }).withPaginationType('full_numbers').withBootstrap().withDOM('tip').withOption('fnRowCallback',
            function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                $compile(nRow)($scope);
            });

        vm.dtColumns = [
            DTColumnBuilder.newColumn('vaccine.name').withTitle('Vaccination Type'),
            DTColumnBuilder.newColumn('completionDate').withTitle('Completion Date').renderWith(function (data, type, full) {
                return data ? moment(data).format($rootScope.displayDateformat) : "";
            }),
            DTColumnBuilder.newColumn(null).withTitle('Responded').renderWith(function (data, type, full) {
                return data.responded !== undefined ? GUIUtils.colorHtmlYesNo(data.responded) : '';
            }),
            DTColumnBuilder.newColumn(null).withTitle('Actions').withOption('width', '155px').notSortable()
                .renderWith(actionsHtml)
        ];

        function actionsHtml(data, type, full, meta){
            var stButtons = '';

            stButtons += '<a class="btn-sm btn-warning" ui-sref="vaccination.view({vid:' + data.id + '})">' +
                '   <i class="fa fa-edit"></i></a>&nbsp;';

            if (vm.patient.status) {
                stButtons += '<a class="btn-sm btn-danger" ui-sref="vaccination.delete({vid:' + data.id + '})">' +
                    '   <i class="fa fa-trash"></i></a>';
            }

            return stButtons;
        }

        function search() {
            vm.dtInstance.reloadData();
        }
    }
})();
