(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('EmployeeDialogController', EmployeeDialogController);

    EmployeeDialogController.$inject = ['$timeout', '$scope', 'Clinic', '$uibModalInstance',
        'entity', 'Employee', 'ROLES', 'Utils', 'CoreService',
        'EmployeeType', 'Principal', 'Corporation'];

    function EmployeeDialogController($timeout, $scope, Clinic, $uibModalInstance,
                                      entity, Employee, ROLES, Utils, CoreService,
                                      EmployeeType, Principal, Corporation) {

        var vm = this;

        vm.employee = entity;
        vm.clear = clear;
        vm.save = save;
        vm.clinics = [];
        vm.corporations = [];
        vm.changeCorporation = changeCorporation;

        Principal.hasAuthority(ROLES.ROLE_ADMIN).then(function(data){
            if (data == true) {
                vm.corporations = Corporation.query();
            } else {
                vm.corporations = [CoreService.getCurrentEmployee().corporation];
            }
        },function(btn){
        });

        if (vm.employee.corporation && vm.employee.corporation.id) {
            Clinic.byCorp({id: vm.employee.corporation.id}, function (results) {
                vm.clinics = results;
            });
        }

        function changeCorporation() {
            if (!vm.employee.corporation || !vm.employee.corporation.id) {
                vm.clinics = [];
                vm.employee.clinics = [];

                return;
            }

            Clinic.byCorp({id: vm.employee.corporation.id}, function (results) {
                vm.employee.clinics = [];
                vm.clinics = results;
            });
        };

        vm.employeeTypes = EmployeeType.query();
        vm.authorities = Utils.objectToArray(ROLES);
        vm.authorities.splice(vm.authorities.indexOf(ROLES.ROLE_ADMIN), 1);

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            vm.employee.user.firstName = vm.employee.name;
            vm.employee.user.lastName = vm.employee.lastName;
            var employee = {};
            angular.copy(vm.employee, employee);
            employee.user.authorities = [];
            for (var i = 0; i < vm.employee.user.authorities.length; i++) {
                var authority = {
                    name: vm.employee.user.authorities[i]
                }
                employee.user.authorities.push(authority);
            }
            if (vm.employee.id !== null) {
                Employee.update(employee, onSaveSuccess, onSaveError);
            } else {
                Employee.save(employee, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('dialysisApp:employeeUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

    }
})();
