(function() {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('CompendiumListDialogController', CompendiumListDialogController);

    CompendiumListDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'compendiums'];

    function CompendiumListDialogController ($timeout, $scope, $stateParams, $uibModalInstance, compendiums) {
        var vm = this;

        vm.compendiums = compendiums;
        vm.clear = clear;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();
