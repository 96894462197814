(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('PatientOrderTestIntervalDialogController', PatientOrderTestIntervalDialogController);

    PatientOrderTestIntervalDialogController.$inject = ['DateUtils', 'patientOrderTpl', '$uibModalInstance', 'clinic'];

    function PatientOrderTestIntervalDialogController(DateUtils, patientOrderTpl, $uibModalInstance, clinic) {
        var vm = this;

        vm.patientOrderTpl = patientOrderTpl;
        vm.staringDate = null;
        vm.endDate = null;
        vm.clinic = clinic;

        if (vm.patientOrderTpl.orderType == 'STANDING') {
            vm.endDate = DateUtils.convertLocalDateFromServer(vm.clinic.standingEnd);
        }

        var days = vm.clinic.drawDays;

        vm.form = {};
        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.endDate = false;
        vm.datePickerOpenStatus.staringDate = false;

        vm.startOptions = {
            minDate: new Date(),
            dateDisabled: disabled,
            startingDay: 1
        }

        vm.endOptions = {
            minDate: new Date(),
            startingDay: 1
        }

        function disabled(date) {
            if (vm.patientOrderTpl.orderType == "ONE_TIME") {
                return false;
            }

            var match = false;
            if (date.date.getDay() == 0) {
                //sunday js(0) -> java(6)
                match = date.mode === 'day' && days[6] == "0";
            } else {
                //others days
                match = date.mode === 'day' && days[date.date.getDay() - 1] == "0";
            }

            if (!match) {
                switch (vm.clinic.pDrawWeek) {
                    case 'FIRST_HALF_WEEK': {
                        if (date.date.getDate() < 8) {
                            match = false;
                        } else {
                            match = true;
                        }

                        break;
                    }
                    case 'FIRST_FULL_WEEK': {
                        var x = date.date.getDate() + 1 - (date.date.getDay() == 0 ? 7 : date.date.getDay());
                        match = x <= 0 || x >= 8;

                        break;
                    }
                    case 'SECOND_FULL_WEEK': {
                        var x = date.date.getDate() + 1 - (date.date.getDay() == 0 ? 7 : date.date.getDay()) - 7;
                        match = x <= 0 || x >= 8;

                        break;
                    }
                }
            }

            return match;
        }

        vm.clear = function () {
            $uibModalInstance.dismiss('cancel');
        }

        vm.openCalendar = openCalendar;
        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        vm.accept = function () {
            for (var i = 0; i < vm.patientOrderTpl.patientOrderJson.patientOrderTests.length; i++) {
                vm.patientOrderTpl.patientOrderJson.patientOrderTests[i].staringDate = vm.staringDate;
                vm.patientOrderTpl.patientOrderJson.patientOrderTests[i].endDate = vm.endDate ? vm.endDate : vm.staringDate;
            }

            $uibModalInstance.close({tpl: vm.patientOrderTpl});
        }

        vm.closeAlert = function(index) {
            vm.alerts.splice(index, 1);
        };
    }
})();
