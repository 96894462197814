(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('RptTemplateAdminController', RptTemplateAdminController);

    RptTemplateAdminController.$inject = ['$scope', '$state', 'RptTemplate', '$q', 'DTColumnBuilder',
        'DTOptionsBuilder', 'GUIUtils', '$filter', '$compile', 'RptTemplateSearch', 'CoreService',
        'ROLES', '$uibModal', 'Principal'];

    function RptTemplateAdminController($scope, $state, RptTemplate, $q, DTColumnBuilder,
                                        DTOptionsBuilder, GUIUtils, $filter, $compile, RptTemplateSearch, CoreService,
                                        ROLES, $uibModal, Principal) {
        var vm = this;
        vm.rptTemplate = [];
        vm.entityClassHumanized = 'Report Template';
        vm.title = 'Report Templates';
        vm.entityStateName = 'rpt-template-admin';
        vm.dtInstance = {};
        vm.clinic = CoreService.getCurrentClinic();

        vm.exportRptTemplate = exportRptTemplate;
        vm.search = search;
        vm.del = del;

        vm.dtOptions = DTOptionsBuilder.fromFnPromise(function () {
            var defer = $q.defer();
            if (!vm.searchQuery || vm.searchQuery == '') {
                RptTemplateSearch.query({
                    query: {
                        bool: {
                            must: getFilter()
                        }
                    }
                }, function (result) {
                    vm.rptTemplate = result;
                    defer.resolve(result);
                });
            } else {
                defer.resolve($filter('filter')(vm.rptTemplate, vm.searchQuery, undefined));
            }
            return defer.promise;
        }).withBootstrap()
            .withDOM('ltp')
            .withLanguage({
                "paginate": {
                    "first": '<i class="fa fa-angle-double-left" aria-hidden="true"></i>',
                    "previous": '<i class="fa fa-arrow-circle-left" aria-hidden="true"></i>',
                    "last": '<i class="fa fa-angle-double-right" aria-hidden="true"></i>',
                    "next": '<i class="fa fa-arrow-circle-right" aria-hidden="true"></i>'
                }
            })
            .withOption('lengthMenu', [[10, 15, 25, 50, -1], [10, 15, 25, 50, "All"]])
            .withOption('fnRowCallback', function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                $compile(nRow)($scope);
            });

        vm.dtColumns = [
            DTColumnBuilder.newColumn('name').withTitle('Name'),
            DTColumnBuilder.newColumn('description').withTitle('Description'),
            DTColumnBuilder.newColumn(null).withTitle('Type').renderWith(function (data) {
                return data.type ? data.type.replace("_", " ") : "";
            }),
            DTColumnBuilder.newColumn(null).withTitle('Category').renderWith(function (data) {
                return data.rptCategory ? data.rptCategory.name : "";
            }),
            DTColumnBuilder.newColumn(null).withTitle('Clinic').renderWith(function (data) {
                return data.rptCategory && data.rptCategory.clinic ? data.rptCategory.clinic.name : "";
            }),
            DTColumnBuilder.newColumn(null).withTitle('Actions').withOption('width', '155px').notSortable()
                .renderWith(actionsHtml)
        ];

        function actionsHtml(data, type, full, meta) {
            var html = "";

            if (Principal.hasRole(ROLES.ROLE_ADMIN)) {
                html += '<a class="btn-sm btn-success" ng-click="vm.exportRptTemplate(' + full.id + ')" uib-tooltip="Export">' +
                    '<span class="glyphicon glyphicon-export"></span></a>&nbsp;'
            }

            html += GUIUtils.getActionsTemplate(data, vm.entityStateName, ['update']) + '&nbsp;' +
                '<a class="btn-sm btn-danger" ng-click="vm.del(' + data.id + ')" uib-tooltip="Delete">' +
                '<span class="glyphicon glyphicon-trash"></span></a>';

            return  html;
        }

        function search() {
            vm.dtInstance.reloadData();
        }

        function getFilter() {
            var must = [];
            must.push({term: {"delStatus": false}});

            if (CoreService.currentHasEmployeeRole(ROLES.ROLE_ADMIN)) {
                must.push({
                    bool: {
                        "should": [
                            {not: {exists: {"field": "rptCategory.clinic"}}},
                            {term: {"rptCategory.clinic.id": vm.clinic.id}}
                        ]
                    }
                });

            } else {
                must.push({term: {"rptCategory.clinic.id": vm.clinic.id}});
            }

            return must;
        }

        function exportRptTemplate(id) {
            $uibModal.open({
                templateUrl: 'app/entities/rpt-template/rpt-template-export-dialog.html',
                controller: 'RptTemplateExportController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: ['RptTemplate', function (RptTemplate) {
                        return RptTemplate.get({id: id}).$promise;
                    }]
                },
                language: false
            });
        }

        function del(id) {
            $uibModal.open({
                templateUrl: 'app/entities/rpt-template/rpt-template-delete-dialog.html',
                controller: 'RptTemplateDeleteController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: ['RptTemplate', function (RptTemplate) {
                        return RptTemplate.get({id: id}).$promise;
                    }]
                },
                language: false
            }).result.then(function() {
               search();
            });
        }
    }
})();
