(function() {
    'use strict';
    angular
        .module('dialysisApp')
        .factory('EnvTest', EnvTest);

    EnvTest.$inject = ['$resource', 'DateUtils'];

    function EnvTest ($resource, DateUtils) {
        var resourceUrl =  'api/env-tests/:id';

        return $resource(resourceUrl, {}, {
            'order': { url: 'api/env-tests/by-order/:id', method: 'GET', isArray: true},
            'test_order_by_date': { url: 'api/env-tests/by-order/:id/:date', method: 'GET', isArray: true},
            'findAllWithoutBarcode': {
                url: 'api/env-tests/without-barcode', method: 'POST', isArray: true,
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.date = DateUtils.convertLocalDateToServer(copy.date);

                    return angular.toJson(copy);
                }
            },
            'with_all': { url: 'api/env-tests/with-all/:id', method: 'GET'},
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.staringDate = DateUtils.convertLocalDateFromServer(data.staringDate);
                        data.endDate = DateUtils.convertLocalDateFromServer(data.endDate);
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.staringDate = DateUtils.convertLocalDateToServer(copy.staringDate);
                    copy.endDate = DateUtils.convertLocalDateToServer(copy.endDate);
                    return angular.toJson(copy);
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.staringDate = DateUtils.convertLocalDateToServer(copy.staringDate);
                    copy.endDate = DateUtils.convertLocalDateToServer(copy.endDate);
                    return angular.toJson(copy);
                }
            },
            'findAllByBarcode': {url: 'api/env-tests/by-barcode/:barcode', method: 'GET', isArray: true},
            'findAllByBarcode2': {url: 'api/env-tests/by-barcode2/:barcode', method: 'GET', isArray: true},
        });
    }
})();
