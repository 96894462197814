(function() {
    'use strict';
    angular
        .module('dialysisApp')
        .factory('PatientResult', PatientResult);

    PatientResult.$inject = ['$resource', 'DateUtils', 'DataUtils'];

    function PatientResult ($resource, DateUtils, DataUtils) {
        var resourceUrl =  'api/patient-results/:id';

        function transformCountResponse(data) {
            if (data) {
                data = angular.fromJson(data);
                data = {value: data};
            }
            return data;
        }

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'byClinic': {url : "api/patient-results/by-clinic/:id/:shift/:days", method: 'GET', isArray: true},
            'byPatient': {url : "api/patient-results/by-patient/:id", method: 'GET', isArray: true,
                transformResponse:  function(data, headers) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.createdDate = DateUtils.convertLocalDateFromServer(data.createdDate);
                    }
                    return data;
                }},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.dob = DateUtils.convertLocalDateFromServer(data.dob);
                        data.collectionData = DateUtils.convertLocalDateFromServer(data.collectionData);
                        data.reviewedOn = DateUtils.convertLocalDateFromServer(data.reviewedOn);
                    }
                    return data;
                }
            },
            'byBarcode': {
                url : "api/patient-results/barcode/:barcode/",
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.dob = DateUtils.convertLocalDateFromServer(data.dob);
                        data.collectionData = DateUtils.convertLocalDateFromServer(data.collectionData);
                        data.reviewedOn = DateUtils.convertLocalDateFromServer(data.reviewedOn);
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.dob = DateUtils.convertLocalDateToServer(copy.dob);
                    copy.collectionData = DateUtils.convertLocalDateToServer(copy.collectionData);
                    copy.reviewedOn = DateUtils.convertLocalDateToServer(copy.reviewedOn);
                    return angular.toJson(copy);
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.dob = DateUtils.convertLocalDateToServer(copy.dob);
                    copy.collectionData = DateUtils.convertLocalDateToServer(copy.collectionData);
                    copy.reviewedOn = DateUtils.convertLocalDateToServer(copy.reviewedOn);
                    return angular.toJson(copy);
                }
            },
            'final': {url : "api/dfresults/:id", method: 'GET', isArray: true},
            'partial': {url : "api/dpresults/:id", method: 'GET', isArray: true},
            'unassigned': {url : "api/patient-results/unassigned/:account/:assigned", method: 'GET', isArray: true},
            'assign': {
                url: 'api/patient-results/assign', method: 'POST', isArray: true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'pending': {url: 'api/patient-results/pending/:id', method: 'GET', isArray: true},
            'deletePending': {url: 'api/patient-result/delete-pending', method: 'POST'},
            'deleteUnassigned': {url: 'api/patient-result/delete-unassigned', method: 'POST'},
            'test_order_by_barcode': {url: 'api/patient-order-tests/by-barcode/:barcode', method: 'GET', isArray: true},
            'critical': {url: 'api/patient-results/critical/:id/:acknowledge', method: 'GET', isArray: true},
            'get6M': {url: 'api/patient-result-dets/6M/:id/:detailId', method: 'GET', isArray: true},
            'countAbnormalNotReviewedLast7Days': {url: 'api/patient-results/count-abnormal-not-reviewed-last-7days/:id', method: 'GET', isArray: true},
            'markAsReviewed': {url: 'api/patient-result/mark-as-reviewed', method: 'POST'},
            'updateDates': {url: 'api/patient-results/update-dates', method: 'POST'},
            'acknowledge': {url: 'api/patient-result-dets/acknowledge', method: 'POST'},
            'nonperform': {url: 'api/patient-results/by-clinic/nonperform/:id', method: 'GET', isArray: true},
            'maintenanceRGroup': {url: 'api/patient-results/maintenance/rgroup', method: 'POST'},
            'maintenanceRange': {url: 'api/patient-results/maintenance/range', method: 'POST'},
            'countCriticals': {
                url: 'api/patient-results/count-criticals/:id', method: 'GET',
                transformResponse: transformCountResponse
            },
            'countPending': {
                url: 'api/patient-results/count-pending/:id', method: 'GET',
                transformResponse: transformCountResponse
            },
            'countUnassigned': {
                url: 'api/patient-results/count-unassigned/:account', method: 'GET',
                transformResponse: transformCountResponse
            },
            'countAbnormalNotReviewed': {
                url: 'api/patient-results/count-abnormal-not-reviewed/:id', method: 'GET',
                transformResponse: transformCountResponse
            },
            'sendToEmr': {
                url: 'api/patient-results/send-to-emr', method: 'POST', isArray: false
            },
            'getAllByFilter': {url: 'api/patient-results/filter', method: 'GET', isArray: true},
        });
    }
})();
