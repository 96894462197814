(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('home', {
                parent: 'app',
                url: '/',
                ncyBreadcrumb: {
                    label: 'Home',
                    parent: 'app'
                },
                data: {
                    pageTitle: 'Dialysis',
                    authorities: ['ROLE_USER']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/home/home.html',
                        controller: 'HomeController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    date: ['$stateParams', 'moment', function ($stateParams, moment) {
                        return $stateParams.date ? moment($stateParams.date).format("Y/MM/DD") : moment(new Date()).format("Y/MM/DD");
                    }],
                    hasPrinter: ['DYMO', function (DYMO) {
                        return DYMO.loadPrinters();
                    }]
                }
            })
    }
})();
