(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('PatientResultUnassignedDeleteDialog', PatientResultUnassignedDeleteDialog);

    PatientResultUnassignedDeleteDialog.$inject = ['$uibModalInstance', 'id', 'PatientResult', 'toastr'];

    function PatientResultUnassignedDeleteDialog($uibModalInstance, id, PatientResult, toastr) {
        var vm = this;

        vm.clear = clear;
        vm.deleteUnassignedResult = deleteUnassignedResult;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function deleteUnassignedResult() {
            PatientResult.deleteUnassigned({id: id.selected}, function () {
                $uibModalInstance.close(true);
                toastr.success('Delete operation successfully');
            }, function () {
                toastr.error('Delete operation fail');
            })
        }
    }
})();
