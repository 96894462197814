(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('DashboardDocController', DashboardDocController);

    DashboardDocController.$inject = ['$timeout', 'Dashboard', 'CoreService', 'EMPLOYEE_TYPE', 'dashboard', '$state'];

    function DashboardDocController($timeout, Dashboard, CoreService, EMPLOYEE_TYPE, dashboard, $state) {
        var vm = this;
        vm.dashboard = dashboard;
        vm.clinic = CoreService.getCurrentClinic();

        reload();

        vm.labels = ["Final Results", "Partial Results", "Results Not Reviewed"];
        vm.data = [0, 0, 0];
        vm.colors = ['#00A65A', '#F39C12', '#FB434A'];

        function reload() {
            if (vm.clinic != undefined) {
                Dashboard.get({id: vm.clinic.id}, function (result2) {
                    vm.dashboard = result2;
                    vm.employee = CoreService.getCurrentEmployee();

                    if (vm.employee.employeeType && vm.employee.employeeType.name == EMPLOYEE_TYPE.PHYSICIAN) {
                        vm.dashboard.unsignedOrders = vm.dashboard.unsignedOrdersByDoctor;
                    }

                    // vm.data = [vm.dashboard.finalResult, vm.dashboard.partialResult, vm.dashboard.resultNotReceived];

                    //build graph options and data
                    //for troubles with render angular tabs
                    $timeout(function () {
                        for (var i = 0; i < vm.dashboard.panels.length; i++) {
                            for (var j = 0; j < vm.dashboard.panels[i].patientResultHealths.length; j++) {
                                vm.dashboard.panels[i].patientResultHealths[j].data = getData(vm.dashboard.panels[i].patientResultHealths[j]);
                                vm.dashboard.panels[i].patientResultHealths[j].options = getOptions(vm.dashboard.panels[i].patientResultHealths[j]);
                                vm.dashboard.panels[i].patientResultHealths[j].colors = getColors(vm.dashboard.panels[i].patientResultHealths[j]);
                                vm.dashboard.panels[i].patientResultHealths[j].labels = getLabels(vm.dashboard.panels[i].patientResultHealths[j]);
                            }
                        }
                    }, 1);
                });
            }
        }

        function getData(patientResultHealth) {
            var data = [];

            patientResultHealth.patientResultHealthItems.forEach(function a(item, index) {
                data.push(item.count);
            });

            return data;
        }

        function getColors(patientResultHealth) {
            var color = [];

            patientResultHealth.patientResultHealthItems.forEach(function a(item, index) {
                color.push({
                    backgroundColor: item.color,
                    pointBackgroundColor: item.color
                });
            });

            return color;
        }

        function getLabels(patientResultHealth) {
            var labels = [];

            patientResultHealth.patientResultHealthItems.forEach(function a(item, index) {
                labels.push(item.range);
            });

            return labels;
        }

        function getOptions(patientResultHealth) {
            return vm.options = {
                responsive: true,
                patientResultHealth: angular.copy(patientResultHealth),
                legend: {display: true, position: 'right', fullWidth: false, labels:{'boxWidth': 10}},
                title: {display: true, text: patientResultHealth.testName},
                layout: {
                    padding: {
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: 0
                    }
                },
                pieceLabel: {
                    render: 'value',
                    showZero: true
                },
                onClick: function(e) {
                    var element = this.getElementAtEvent(e);
                    if (element.length) {
                        $state.go("patient-health", {
                            patientResultHealth: element[0]._chart.options.patientResultHealth,
                            patientResultHealthItem: element[0]._chart.options.patientResultHealth.patientResultHealthItems[element[0]._index]
                        });
                    }
                },
            };
        }
    }
})();
