(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('care-team-new', {
                parent: 'patient-detail',
                url: '/care-team-new',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', 'entity', function ($stateParams, $state, $uibModal, entity) {
                    $uibModal.open({
                        templateUrl: 'app/entities/care-team/care-team-dialog.html',
                        controller: 'CareTeamDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            careTeam: function () {
                                return {
                                    careTeamSpecialty: null,
                                    email: null,
                                    firstName: null,
                                    lastName: null,
                                    patient: entity,
                                    phone: null
                                };
                            }
                        }
                    }).result.then(function () {
                        $state.go('patient-detail', null, {reload: 'patient-detail'});
                    }, function () {
                        $state.go('patient-detail');
                    });
                }]
            })
            .state('care-team-view', {
                parent: 'patient-detail',
                url: '/{ctid}/ctview',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/care-team/care-team-dialog.html',
                        controller: 'CareTeamDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            careTeam: ['CareTeam', function (CareTeam) {
                                return CareTeam.get({id: $stateParams.ctid}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('patient-detail', null, {reload: 'patient-detail'});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('care-team-edit', {
                parent: 'patient-detail',
                url: '/{ctid}/ctedit',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/care-team/care-team-dialog.html',
                        controller: 'CareTeamDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            careTeam: ['CareTeam', function (CareTeam) {
                                return CareTeam.get({id: $stateParams.ctid}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('patient-detail', null, {reload: 'patient-detail'});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('care-team-delete', {
                parent: 'patient-detail',
                url: '/care-team/{ctid}/delete',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/care-team/care-team-delete-dialog.html',
                        controller: 'CareTeamDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['CareTeam', function (CareTeam) {
                                return CareTeam.get({id: $stateParams.ctid}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('patient-detail', {id: $stateParams.id}, {reload: 'patient-detail'});
                    }, function () {
                        $state.go('patient-detail', {id: $stateParams.id}, {reload: 'patient-detail'});
                    });
                }]
            });
    }
})();
