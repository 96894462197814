(function() {
    'use strict';

    angular
        .module('dialysisApp')
        .factory('EnvResultSearch', EnvResultSearch);

    EnvResultSearch.$inject = ['$resource'];

    function EnvResultSearch($resource) {
        var resourceUrl =  'api/_search/env-results/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
