(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('KineticSentController', KineticSentController);

    KineticSentController.$inject = ['KineticSearch', '$q', 'DTColumnBuilder', 'Clinic', '$scope', '$compile', '$uibModal',
        'DTOptionsBuilder', 'CoreService', '$filter', 'GUIUtils', 'filterData', 'DateUtils'];

    function KineticSentController(KineticSearch, $q, DTColumnBuilder, Clinic, $scope, $compile, $uibModal,
                                   DTOptionsBuilder, CoreService, $filter, GUIUtils, filterData, DateUtils) {
        var vm = this;
        vm.title = 'Kinetics';
        vm.entityClassHumanized = 'Kinetic';
        vm.entityStateName = 'kinetic';
        vm.editForm = {};
        vm.filterData = filterData;
        vm.clinics = Clinic.query();

        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.dob = false;
        vm.datePickerOpenStatus.sentStart = false;
        vm.datePickerOpenStatus.sentEnd = false;
        vm.datePickerOpenStatus.scheduleStart = false;
        vm.datePickerOpenStatus.scheduleEnd = false;

        vm.dtInstance = {};
        vm.clinic = CoreService.getCurrentClinic();
        vm.showFilter = false;

        vm.openCalendar = openCalendar;
        vm.filter = filter;
        vm.lessFilter = lessFilter;
        vm.search = search;
        vm.addKinetic = addKinetic;

        vm.popoverFilterEnable = popoverFilterEnable;

        vm.dtOptions = DTOptionsBuilder.fromFnPromise(function () {
            var defer = $q.defer();
            if (!vm.searchQuery || vm.searchQuery == '') {
                KineticSearch.query({
                    query: {
                        bool: {
                            must: filter()
                        }
                    }
                }, function (result) {
                    vm.kinetic = result;
                    defer.resolve(result);
                });
            } else {
                defer.resolve($filter('filter')(vm.kinetic, vm.searchQuery, undefined));
            }
            return defer.promise;
        }).withPaginationType('full_numbers')
            .withBootstrap()
            .withDOM('tip')
            .withOption('fnRowCallback',
                function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                    $compile(nRow)($scope);
                });

        vm.dtColumns = [
            DTColumnBuilder.newColumn(null).withTitle('ID').renderWith(function (data, type, full) {
                return data.id;
            }),
            DTColumnBuilder.newColumn(null).withTitle('Name').renderWith(function (data, type, full) {
                return data.patient ?
                    (data.patient.lastName + ', ' + data.patient.firstName + ' ' + (data.patient.middleInitial ? data.patient.middleInitial : ""))
                    : "";
            }),
            DTColumnBuilder.newColumn(null).withTitle('DOB').renderWith(function (data, type, full) {
                return data.patient ? moment(data.patient.dob).format("MM/DD/YYYY") : "";
            }),
            DTColumnBuilder.newColumn(null).withTitle('Barcode').renderWith(function (data, type, full) {
                return data.barcode;
            }),
            DTColumnBuilder.newColumn('preWeight').withTitle('Pre Weight'),
            DTColumnBuilder.newColumn('posWeight').withTitle('Pos Weight'),
            DTColumnBuilder.newColumn('dialysisLength').withTitle('Length'),
            DTColumnBuilder.newColumn(null).withTitle('Created Date').renderWith(function (data, type, full) {
                return data.createdDate ? moment(data.createdDate).format("MM/DD/YYYY") : "";
            }),
            DTColumnBuilder.newColumn(null).withTitle('Sent').renderWith(function (data, type, full) {
                return GUIUtils.colorHtmlYesNo(data.sent);
            }),
            DTColumnBuilder.newColumn(null).withTitle('Sent Date').renderWith(function (data, type, full) {
                return data.sent ? moment(data.sentDate).format("MM/DD/YYYY") : '';
            }),
            DTColumnBuilder.newColumn(null).withTitle('Schedule Date').renderWith(function (data, type, full) {
                return data.patientOrderItem ? moment(data.patientOrderItem.scheduleDate).format("MM/DD/YYYY") : '';
            }),
            DTColumnBuilder.newColumn(null).withTitle('Actions').withOption('width', '55px').notSortable().renderWith(actionsHtml)
        ];

        function actionsHtml(data, type, full, meta) {
            var stButtons = '';

            stButtons += '<a uib-tooltip="Recalculate" class="btn btn-default btn-sm details" ' +
                'ng-click="vm.addKinetic(' + data.id + ')">' +
                '   <i class="fa fa-external-link" aria-hidden="true"></i></>&nbsp;';

            return stButtons;
        }

        function addKinetic(id) {
            $uibModal.open({
                templateUrl: 'app/entities/patient-result-missing-data/patient-result-missing-data-dialog.html',
                controller: 'PatientResultMissingDataDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    entity: ['Kinetic', function (Kinetic) {
                        return Kinetic.get({id: id}).$promise;
                    }],
                }
            }).result.then(function (result) {
                vm.searchQuery = '';
                vm.dtInstance.reloadData();
            });
        }

        function search() {
            vm.dtInstance.reloadData();
        }

        function filter() {
            var must = [{term: {"delStatus": false}}];

            if (vm.filterData.scheduleStart != null) {
                must.push({range: {"patientOrderItem.scheduleDate": {gte: DateUtils.convertLocalDateToServer(vm.filterData.scheduleStart)}}});
            }

            if (vm.filterData.scheduleEnd != null) {
                var end = new Date(vm.filterData.scheduleEnd);
                end.setHours(23, 59, 59);
                must.push({range: {"patientOrderItem.scheduleDate": {lte: DateUtils.convertLocalDateToServer(vm.filterData.scheduleEnd)}}});
            }

            if (vm.filterData.sentStart != null) {
                must.push({range: {createdDate: {gte: vm.filterData.sentStart}}});
            }

            if (vm.filterData.sentEnd != null) {
                var end = new Date(vm.filterData.sentEnd);
                end.setHours(23, 59, 59);
                must.push({range: {createdDate: {lte: vm.filterData.sentEnd}}});
            }

            if (vm.filterData.firstName != null && vm.filterData.firstName.length > 0) {
                must.push({match: {"patient.firstName": {query: vm.filterData.firstName, fuzziness: 2}}});
            }

            if (vm.filterData.lastName && vm.filterData.lastName.length > 0) {
                must.push({match: {"patient.lastName": {query: vm.filterData.lastName, fuzziness: 2}}});
            }

            if (vm.filterData.dob) {
                must.push({match: {"patient.dob": {query: DateUtils.convertLocalDateToServer(vm.filterData.dob)}}});
            }

            if (vm.filterData.sent) {
                must.push({term: {sent: vm.filterData.sent}});
            }

            if (vm.filterData.skipped) {
                must.push({term: {skipped: vm.filterData.skipped}});
            }

            if (vm.filterData.barcode && vm.filterData.barcode.length > 0) {
                must.push({match: {"patientOrderItem.groupNumberId": vm.filterData.barcode}});
            }

            if (vm.filterData.clinic != null && vm.filterData.clinic.id !== undefined) {
                must.push({term: {"patient.clinic.id": vm.filterData.clinic.id}});
            }

            return must;
            // KineticSearch.query({
            //     query: {
            //         bool: {
            //             must: must
            //         }
            //     }
            // }, function (result) {
            //     vm.kinetic = result;
            //     vm.dtInstance.reloadData();
            // });
        }

        function popoverFilterEnable() {
            vm.showFilter = !vm.showFilter;
        }

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function lessFilter() {
            vm.filterData.scheduleStart = null;
            vm.filterData.scheduleEnd = null;
            vm.filterData.firstName = null;
            vm.filterData.lastName = null;
            vm.filterData.dob = null;
            vm.filterData.sent = null;
            vm.filterData.skipped = null;
            vm.filterData.barcode = null;

            search();
        }
    }
})();
