(function() {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('ClinicAdminCancelWizardDialog', ClinicAdminCancelWizardDialog);

    ClinicAdminCancelWizardDialog.$inject = ['$uibModalInstance'];

    function ClinicAdminCancelWizardDialog($uibModalInstance) {
        var vm = this;

        vm.title = "Cancel Wizard Confirmation";

        vm.clear = clear;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }
    }

})();
