(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .factory('DataUtils', DataUtils);

    DataUtils.$inject = ['$window', '$timeout', 'toastr'];

    function DataUtils($window, $timeout, toastr) {

        var service = {
            abbreviate: abbreviate,
            byteSize: byteSize,
            openFile: openFile,
            toBase64: toBase64,
            downloadFile: downloadFile,
            downloadAndOpenPDFFile: downloadAndOpenPDFFile
        };

        return service;

        function abbreviate(text) {
            if (!angular.isString(text)) {
                return '';
            }
            if (text.length < 30) {
                return text;
            }
            return text ? (text.substring(0, 15) + '...' + text.slice(-10)) : '';
        }

        function byteSize(base64String) {
            if (!angular.isString(base64String)) {
                return '';
            }

            function endsWith(suffix, str) {
                return str.indexOf(suffix, str.length - suffix.length) !== -1;
            }

            function paddingSize(base64String) {
                if (endsWith('==', base64String)) {
                    return 2;
                }
                if (endsWith('=', base64String)) {
                    return 1;
                }
                return 0;
            }

            function size(base64String) {
                return base64String.length / 4 * 3 - paddingSize(base64String);
            }

            function formatAsBytes(size) {
                return size.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + ' bytes';
            }

            return formatAsBytes(size(base64String));
        }

        function openFile(type, data, height, width) {
            if (height && width) {
                $window.open('data:' + type + ';base64,' + data, '_blank', 'height=' + height + ',width=' + width + '');
            } else {
                $window.open('data:' + type + ';base64,' + data, '_blank', 'height=300,width=400');
            }
        }

        function toBase64(file, cb) {
            var fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = function (e) {
                var base64Data = e.target.result.substr(e.target.result.indexOf('base64,') + 'base64,'.length);
                cb(base64Data);
            };
        }

        function downloadFile(data, headers) {
            var contentType = headers('Content-Type');
            var contentDisp = headers('Content-Disposition');

            if (!contentDisp) {
                toastr.error('Error downloading file');

                return;
            }

            var index = contentDisp.indexOf('filename="');

            var filename = "filename";

            if (index != -1) {
                var i = index + 10;
                while (contentDisp[i] != '"') {
                    i++;
                }

                filename = contentDisp.substring(index + 10, i);
            }

            var url = URL.createObjectURL(new Blob([data]));
            var a = document.createElement('a');
            a.href = url;
            a.download = filename;
            a.target = '_blank';
            a.click();
        }

        function downloadAndOpenPDFFile(data, headers) {
            var contentType = headers('Content-Type');
            var contentDisp = headers('Content-Disposition');
            var index = contentDisp.indexOf('filename="');

            var filename = "filename";

            if (index != -1) {
                var i = index + 10;

                while (contentDisp[i] != '"') {
                    i++;
                }

                filename = contentDisp.substring(index + 10, i);
            }

            var file = new Blob([data], {type: contentType});
            var fileURL = URL.createObjectURL(file);
            var w = window.open(fileURL);
            w.onload = function(){
                setTimeout(function(){
                    $(w.document).find('html').append('<head><title>' + filename + '</title></head>');
                }, 150);
            };
        }
    }
})();
