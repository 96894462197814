(function() {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('PatientResultDeleteController',PatientResultDeleteController);

    PatientResultDeleteController.$inject = ['dialogs', 'PatientResult', 'toastr'];

    function PatientResultDeleteController($dialogs, PatientResult, toastr) {
        var vm = this;

        vm.barcode = "";

        vm.deleteResult = deleteResult;

        function deleteResult() {
            var dlg = $dialogs.confirm('Please Confirm','Do you want to delete this patient result?', {size: 'md'});

            dlg.result.then(function(btn){
                PatientResult.byBarcode({barcode: vm.barcode}, function (result) {
                    PatientResult.delete({id: result.id}, function () {
                        vm.barcode = "";

                        toastr.success('Delete successfully!');
                    });
                });
            },function(btn) {
            });
        }
    }
})();
