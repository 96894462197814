(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('PatientOrderTemplateController', PatientOrderTemplateController);

    PatientOrderTemplateController.$inject = ['$state', 'ROLES', '$scope', '$compile', '$q', 'Principal', 'CoreService', 'PatientOrderTplSearch',
        'DTColumnBuilder', 'DTOptionsBuilder', 'PatientOrderTpl', 'GUIUtils', '$uibModal', '$filter', 'lodash', 'dialogs', 'Compendium'];

    function PatientOrderTemplateController($state, ROLES, $scope, $compile, $q, Principal, CoreService, PatientOrderTplSearch,
                                            DTColumnBuilder, DTOptionsBuilder, PatientOrderTpl, GUIUtils, $uibModal, $filter, _, $dialogs, Compendium) {

        var vm = this;

        vm.orders = [];
        vm.dtInstance = {};
        vm.patients = [];

        vm.search = search;
        vm.cloneTemplate = cloneTemplate;
        vm.selectPatients = selectPatients;
        vm.selectInterval = selectInterval;
        vm.checkTpl = checkTpl;

        vm.dtOptions = DTOptionsBuilder.fromFnPromise(function () {
            var defer = $q.defer();
            if (!vm.searchQuery || vm.searchQuery == '') {
                if (!Principal.hasRole(ROLES.ROLE_ADMIN)) {
                    PatientOrderTpl.byCorp({id: CoreService.getCurrentEmployee().corporation.id}, function (result) {
                        vm.orders = result;
                        defer.resolve(result);
                    });
                } else {
                    vm.orders = PatientOrderTpl.withOutCorp(function (result) {
                        vm.orders = result;
                        defer.resolve(vm.orders);
                    });
                }
            } else {
                defer.resolve($filter('filter')(vm.orders, vm.searchQuery, undefined));
            }
            return defer.promise;
        }).withBootstrap().withDOM('tip').withOption('fnRowCallback',
            function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                $compile(nRow)($scope);
            });

        vm.dtColumns = [
            DTColumnBuilder.newColumn('id').withTitle('ID'),
            DTColumnBuilder.newColumn('description').withTitle('Description'),
            DTColumnBuilder.newColumn(null).withTitle('Corporation').renderWith(function (data, type, full) {
                return data.corporation ? data.corporation.name : "";
            }),
            DTColumnBuilder.newColumn(null).withTitle('Order Type').renderWith(function (data, type, full) {
                return data.orderType;
            }),
            DTColumnBuilder.newColumn(null).withTitle('Created Date').renderWith(function (data, type, full) {
                return data.createdDate ? moment(data.createdDate).format("MM/DD/YYYY") : '';
            }),
            DTColumnBuilder.newColumn(null).withTitle('Actions').withOption('width', '200px').notSortable()
                .renderWith(actionsHtml)
        ];

        function actionsHtml(data, type, full, meta) {
            var stButtons = "";
            stButtons += '<a class="btn-sm btn-primary" ng-click="vm.checkTpl(' + data.id + ', 0)"> <i class="fa fa-clone"></i></a>&nbsp;';
            stButtons += Principal.hasRole(ROLES.ROLE_ADMIN) ? '' :
                '<a class="btn-sm btn-primary" ng-click="vm.checkTpl(' + data.id + ', 1)"> <i class="glyphicon glyphicon-paste"></i></a>&nbsp;';

            if (data.corporation || (!data.corporation && Principal.hasRole(ROLES.ROLE_ADMIN))) {
                return stButtons + GUIUtils.getActionsTemplate(data, $state.current.name, ['update', 'delete']);
            } else {
                return stButtons;
            }
        }

        function search() {
            vm.dtInstance.reloadData();
        }

        function selectPatients(id) {
            var _order = $.grep(vm.orders, function (order, i) {
                return order.id == id;
            })[0];

            if (_order.orderType == 'STANDING') {
                $uibModal.open({
                    templateUrl: 'app/entities/patient/patient-select-dialog.html',
                    controller: 'PatientStandingSelectDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        clinic: CoreService.getCurrentClinic(),
                        patients: {selected: vm.patients}
                    }
                }).result.then(function (result) {
                    vm.patients = result.patients;
                    vm.selectInterval(id);
                }, function () {
                });
            } else {
                $uibModal.open({
                    templateUrl: 'app/entities/patient/patient-select-dialog.html',
                    controller: 'PatientSelectDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        clinic: CoreService.getCurrentClinic(),
                        patients: {selected: vm.patients}
                    }
                }).result.then(function (result) {
                    vm.patients = result.patients;
                    vm.selectInterval(id);
                }, function () {
                });
            }
        }

        function selectInterval(id) {
            $uibModal.open({
                templateUrl: 'app/entities/window-picker/patient-order-test-interval-dialog.html',
                controller: 'PatientOrderTestIntervalDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    patientOrderTpl: ['PatientOrderTpl', function (PatientOrderTpl) {
                        return PatientOrderTpl.get({id: id}).$promise;
                    }],
                    clinic: ['CoreService', function (CoreService) {
                        return CoreService.getCurrentClinic()
                    }]
                }
            }).result.then(function (result) {
                var patient = vm.patients[0];

                $state.go("patient-order-current.new",
                    {id: patient.id, orderTpl: result.tpl, patients: {selected: vm.patients}}, {reload: true});
            }, function () {
            });
        }

        function cloneTemplate(id) {
            $state.go("patient-order-tpl.edit", {id: id, clone: true}, {reload: true});
        }

        function checkTpl(id, action) {
            PatientOrderTpl.get({id: id}, function (tpl) {
                var compendiums = _.map(tpl.patientOrderJson.patientOrderTests, 'compendium');

                Compendium.check({compendiums: compendiums}, function (result) {
                    if (result.compendiums.length > 0) {
                        var msg = _.map(result.compendiums, function (compendium) {
                            return "Test: " + compendium.code + ' - ' + compendium.description;
                        }).join('<br>');

                        $dialogs.error("Warning.", "This template need review some tests don't exist and need to be removed or changed from the template <br>" + msg);
                    } else {
                        switch (action) {
                            case 0: {
                                cloneTemplate(id);
                                break;
                            }
                            case 1: {
                                selectPatients(id);
                                break;
                            }
                        }
                    }
                });
            });
        }
    }
})();
