(function() {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('ClinicDetailController', ClinicDetailController);

    ClinicDetailController.$inject = ['$scope', '$sessionStorage', 'GUIUtils', '$state',
        'entity', 'Clinic', 'ReportGroup', 'CountryState'];

    function ClinicDetailController($scope, $sessionStorage, GUIUtils, $state,
                                    entity, Clinic, ReportGroup, CountryState) {
        var vm = this;
        vm.clinic = entity;
        vm.clinic.drawDays2 = GUIUtils.weekCodeStringToDays(vm.clinic.drawDays);
        vm.clinic.wDrawDays2 = GUIUtils.weekCodeStringToDays(vm.clinic.wDrawDays);
        vm.clinic.quarterlies2 = GUIUtils.monthsCodeStringToMonths(vm.clinic.quarterlies);

        vm.activeTab = $sessionStorage.activeClinicTab ? ($sessionStorage.activeClinicTab.id == entity.id ?
            $sessionStorage.activeClinicTab.activeTab : 0) : 0;

        vm.form = {};
        vm.editing = false;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;

        vm.days = GUIUtils.getDays();
        vm.months = GUIUtils.getMonths();

        vm.reportgroups = [];
        if (vm.clinic.reportGroup) {
            vm.reportgroups = [vm.clinic.reportGroup];
        }

        vm.countrystates = CountryState.query();

        function save () {
            vm.isSaving = true;

            vm.clinic.drawDays = GUIUtils.daysToWeekCodeString(vm.clinic.drawDays2);
            vm.clinic.wDrawDays = GUIUtils.daysToWeekCodeString(vm.clinic.wDrawDays2);
            vm.clinic.quarterlies = GUIUtils.monthsToMonthCodeString(vm.clinic.quarterlies2);

            Clinic.update(vm.clinic, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess (result) {
            $scope.$emit('dialysisApp:clinicUpdate', result);
            $state.reload("clinic");
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.changeEditingState = changeEditingState;

        function changeEditingState() {
            vm.editing = !vm.editing;
        }

        vm.cancelEditing = cancelEditing;

        function cancelEditing() {
            $state.reload("clinic");
        }

        vm.datePickerOpenStatus.startDate = false;
        vm.datePickerOpenStatus.contractExp = false;
        vm.datePickerOpenStatus.annualReview = false;
        vm.datePickerOpenStatus.standingEnd = false;
        vm.datePickerOpenStatus.startPickup = false;
        vm.datePickerOpenStatus.endPickup = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

        vm.keepActiveTab = keepActiveTab;

        function keepActiveTab($index) {
            $sessionStorage.activeClinicTab = {id: vm.clinic.id, activeTab: $index};
        }
    }
})();
