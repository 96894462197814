(function() {
    'use strict';

    angular
        .module('dialysisApp')
        .factory('EnvOrderSearch', EnvOrderSearch);

    EnvOrderSearch.$inject = ['$resource'];

    function EnvOrderSearch($resource) {
        var resourceUrl =  'api/_search/env-orders/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
