(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('problem-list-new', {
                parent: 'patient-detail',
                url: '/problem-list-new',
                data: {
                    authorities: ['ROLE_SUPERVISOR', 'ROLE_NURSE', 'ROLE_DOCTOR'],
                },
                onEnter: ['$stateParams', '$state', '$uibModal', 'entity', function ($stateParams, $state, $uibModal, entity) {
                    $uibModal.open({
                        templateUrl: 'app/entities/problem-list/problem-list-dialog.html',
                        controller: 'ProblemListDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            problem: function () {
                                return {
                                    renal: false,
                                    startDate: null,
                                    endDate: null,
                                    id: null,
                                    active: false,
                                    patient: entity
                                };
                            }
                        }
                    }).result.then(function () {
                        $state.go('patient-detail', null, {reload: 'patient-detail'});
                    }, function () {
                        $state.go('patient-detail');
                    });
                }]
            })
            .state('problem-list-view', {
                parent: 'patient-detail',
                url: '/{pid}/view',
                data: {
                    authorities: ['ROLE_SUPERVISOR', 'ROLE_NURSE', 'ROLE_DOCTOR'],
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/problem-list/problem-list-dialog.html',
                        controller: 'ProblemListDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            problem: ['ProblemList', function (ProblemList) {
                                return ProblemList.get({id: $stateParams.pid}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('patient-detail', null, {reload: 'patient-detail'});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('problem-list-delete', {
                parent: 'patient-detail',
                url: '/problem-list/{pid}/delete',
                data: {
                    authorities: ['ROLE_SUPERVISOR', 'ROLE_NURSE', 'ROLE_DOCTOR'],
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/problem-list/problem-list-delete-dialog.html',
                        controller: 'ProblemListDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['ProblemList', function (ProblemList) {
                                return ProblemList.get({id: $stateParams.pid}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('patient-detail', {id: $stateParams.id}, {reload: 'patient-detail'});
                    }, function () {
                        $state.go('patient-detail', {id: $stateParams.id}, {reload: 'patient-detail'});
                    });
                }]
            });
    }
})();
