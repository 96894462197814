(function () {
    'use strict';
    angular
        .module('dialysisApp')
        .factory('Employee', Employee);

    Employee.$inject = ['$resource', 'Utils'];

    function Employee($resource, Utils) {
        var resourceUrl = 'api/employees/:id';

        return $resource(resourceUrl, {}, {
            'query': {
                method: 'GET', isArray: true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        for (var i = 0; i < data.length; i++) {
                            data[i].user.authorities = Utils.objectRoleToArray(data[i].user.authorities);
                        }
                    }
                    return data;
                }
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.user.authorities = Utils.objectRoleToArray(data.user.authorities);
                    }
                    return data;
                }
            },
            'update': {method: 'PUT'},
            'employeeAccount': {url: 'api/employeeAccount', method: 'GET'},
            'employeeCorporations': {
                url: 'api/employeeCorporations', method: 'GET', isArray: true
            },
            'employeeCorpPhysicians': {url: 'api/employeeCorpPhysicians', method: 'GET', isArray: true}
        });
    }
})();
