(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('PatientResultCriticalDialogController', PatientResultCriticalDialogController);

    PatientResultCriticalDialogController.$inject = ['$uibModalInstance', 'result', 'PatientResultDet', 'PatientResult', 'lodash'];

    function PatientResultCriticalDialogController($uibModalInstance, result, PatientResultDet, PatientResult, _) {
        var vm = this;

        vm.criticalResult = result;
        vm.comments = PatientResultDet.getComments({id: result.id});
        vm.testResult =  result.result + ' (' + result.status + ')';
        vm.acknowledgeBy = result.name + ' ' + result.lastName;

        vm.clear = clear;
        vm.acknowledge = acknowledge;

        PatientResultDet.getEmployeeComments({id: result.id}, function (comments) {
            vm.prDetEmployeeComments = comments;
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function acknowledge() {
            PatientResult.acknowledge({ids: [result.id]}, function () {
                $uibModalInstance.dismiss('success')
            }, function () {
            })
        }
    }
})();
