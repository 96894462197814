(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('EnvOrderTestTplNewDialogController', EnvOrderTestTplNewDialogController);

    EnvOrderTestTplNewDialogController.$inject = ['CompendiumSearch', 'DiagnosisSearch', 'OrderFrequency', 'envOrder',
        'envOrderTest', '$uibModalInstance'];

    function EnvOrderTestTplNewDialogController(CompendiumSearch, DiagnosisSearch, OrderFrequency, envOrder,
                                                envOrderTest, $uibModalInstance) {
        var vm = this;

        vm.envOrder = envOrder;
        vm.envTest = envOrderTest;

        vm.form = {};

        vm.clear = function () {
            $uibModalInstance.dismiss('cancel');
        }

        vm.orderfrequencies = OrderFrequency.query().$promise.then(function (data) {
            vm.orderfrequencies = data;
        });

        vm.compendiums = [];

        vm.getCompendium = getCompendium;

        function getCompendium(query) {
            if (query && query.length > 2) {
                CompendiumSearch.query({query: query}, function (result) {
                    vm.compendiums = result;
                });
            }
        }

        vm.accept = function () {
            $uibModalInstance.close({envTest: vm.envTest});
        }
    }
})();
