(function() {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('RptCategoryController', RptCategoryController);

    RptCategoryController.$inject = ['$scope', '$state', 'RptCategory', 'RptCategorySearch', '$filter', 'ROLES', 'CoreService', 'DTOptionsBuilder',
        'DTColumnBuilder', '$q', 'GUIUtils', '$compile'];

    function RptCategoryController ($scope, $state, RptCategory, RptCategorySearch, $filter, ROLES, CoreService, DTOptionsBuilder,
                                    DTColumnBuilder, $q, GUIUtils, $compile) {
        var vm = this;

        vm.rptCategories = [];
        vm.dtInstance = {};
        vm.clinic = CoreService.getCurrentClinic();

        vm.search = search;

        vm.dtOptions = DTOptionsBuilder.fromFnPromise(function () {
            var defer = $q.defer();
            if (!vm.searchQuery || vm.searchQuery == '') {
                RptCategorySearch.query({
                    query: {
                        bool: {
                            must: getFilter()
                        }
                    }
                }, function (result) {
                    vm.rptCategories = result;
                    defer.resolve(result);
                });
            } else {
                defer.resolve($filter('filter')(vm.rptCategories, vm.searchQuery, undefined));
            }
            return defer.promise;
        }).withPaginationType('full_numbers')
            .withBootstrap()
            .withDOM('ltp')
            .withLanguage({
                "paginate": {
                    "first": '<i class="fa fa-angle-double-left" aria-hidden="true"></i>',
                    "previous": '<i class="fa fa-arrow-circle-left" aria-hidden="true"></i>',
                    "last": '<i class="fa fa-angle-double-right" aria-hidden="true"></i>',
                    "next": '<i class="fa fa-arrow-circle-right" aria-hidden="true"></i>'
                }
            })
            .withOption('aaSorting', [[0, 'desc'], [1, 'asc']])
            .withOption('headerCallback', function (header) {
                if (!vm.headerCompiled) {
                    vm.headerCompiled = true;
                    $compile(angular.element(header).contents())($scope);
                }
            })
            .withOption('lengthMenu', [[10, 15, 25, 50, -1], [10, 15, 25, 50, "All"]])
            .withOption('fnRowCallback',
                function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                    $compile(nRow)($scope);
                });

        vm.dtColumns = [
            DTColumnBuilder.newColumn('name').withTitle('Name'),
            DTColumnBuilder.newColumn(null).withTitle('Actions').withOption('width', '155px').notSortable()
                .renderWith(actionsHtml)
        ];

        function actionsHtml(data, type, full, meta) {
            return GUIUtils.getActionsTemplate(data, $state.current.name, ['update', 'delete']);
        }

        function search() {
            vm.dtInstance.reloadData();
        }

        function getFilter() {
            var must = [];

            if (CoreService.currentHasEmployeeRole(ROLES.ROLE_ADMIN)) {
                must.push({
                    bool: {
                        "should": [
                            {not: {exists: {"field": "clinic"}}},
                            {term: {"clinic.id": vm.clinic.id}}
                        ]
                    }
                });

            } else {
                must.push({term: {"clinic.id": vm.clinic.id}});
            }

            must.push({term: {"delStatus": false}});

            return must;
        }
    }
})();
