(function() {
    'use strict';

    angular
        .module('dialysisApp')
        .directive('bindValidity', bindValidity);

    function bindValidity() {
        var directive = {
            restrict: 'A',
            scope: false,
            require: ['?form', '?ngForm', 'bindValidity'],
            link: linkFunc,
            controller: ['$scope', '$attrs', '$parse', function($scope, $attrs, $parse) {
                var assign = $parse($attrs.bindValidity).assign;

                if (!angular.isFunction(assign)) {
                    throw new Error('the expression of bindValidity is not settable: ' + $attrs.bindValidity);
                }

                this.setFormController = function(formCtrl) {
                    if (!formCtrl) {
                        throw new Error('bindValidity requires one of <form> or ng-form');
                    }
                    $scope.$watch(
                        function () {
                            return formCtrl.$invalid;
                        },
                        function (newval) {
                            assign($scope, newval);
                        }
                    );
                };
            }],
        };

        return directive;

        function linkFunc(scope, element, attrs, ctrls) {
            var formCtrl, bindValidity;
            formCtrl = ctrls[0] || ctrls[1];
            bindValidity = ctrls[2];
            bindValidity.setFormController(formCtrl);
        }
    }
})();
