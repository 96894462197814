(function() {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('DiagnosisDialogController2', DiagnosisDialogController2);

    DiagnosisDialogController2.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Diagnosis','DTOptionsBuilder', 'DTColumnBuilder', '$q','$compile'];

    function DiagnosisDialogController2 ($timeout, $scope, $stateParams, $uibModalInstance, entity, Diagnosis, DTOptionsBuilder, DTColumnBuilder, $q, $compile) {
        var vm = this;

        vm.diagnoses = entity;
        vm.clear = clear;

        vm.dtOptions = DTOptionsBuilder.fromFnPromise(function () {
            var defer = $q.defer();
                Diagnosis.byPatientOrderTest({id: vm.diagnoses.orderTestId}, function (result) {
                    vm.Diagnosis = result;
                    defer.resolve(result);
                });


            return defer.promise;
        }).withPaginationType('full_numbers').withBootstrap().withDOM('tip')
            .withOption('paging', false).withOption('aaSorting', []).withOption('fnRowCallback',
                function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                    $compile(nRow)($scope);
                });
        vm.dtColumns = [
            DTColumnBuilder.newColumn(null).withTitle('ICD 10').renderWith(function (data, type, full) {
                return data.icd10Code;
            }),
            DTColumnBuilder.newColumn(null).withTitle('Test').renderWith(function (data, type, full) {
                return data.description;
            }),

        ];


        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();
