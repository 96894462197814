(function() {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('MedicationDetailController', MedicationDetailController);

    MedicationDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Medication', 'MedDosageUnit', 'MedRoute', 'MedFrequencyFrom', 'MedFrequencyTo', 'MedLocation', 'Patient'];

    function MedicationDetailController($scope, $rootScope, $stateParams, previousState, entity, Medication, MedDosageUnit, MedRoute, MedFrequencyFrom, MedFrequencyTo, MedLocation, Patient) {
        var vm = this;

        vm.medication = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('dialysisApp:medicationUpdate', function(event, result) {
            vm.medication = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
