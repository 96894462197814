(function() {
    'use strict';
    angular
        .module('dialysisApp')
        .factory('MachineBarcode', MachineBarcode);

    MachineBarcode.$inject = ['$resource'];

    function MachineBarcode ($resource) {
        var resourceUrl =  'api/machine-barcode/:barcode';

        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
        });
    }
})();
