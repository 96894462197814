(function () {
    'use strict';
    angular
        .module('dialysisApp')
        .factory('Calendar', Calendar);

    Calendar.$inject = ['$resource', 'DateUtils'];

    function Calendar($resource, DateUtils) {
        var resourceUrl = 'api/calendar/';

        return $resource(resourceUrl, {}, {
            'getCalendar': {
                method: 'POST', isArray: true, transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.startDate = DateUtils.convertLocalDateToServer(copy.startDate);
                    copy.endDate = DateUtils.convertLocalDateToServer(copy.endDate);

                    return angular.toJson(copy);
                },
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);

                        var events = [];

                        for (var i = 0; i < data.length; i++) {
                            if (data[i].startPickup && data[i].endPickup) {
                                data[i].startPickup = new Date(data[i].startPickup);
                                data[i].endPickup = new Date(data[i].endPickup);
                            }

                            var allDay = !(data[i].startPickup && data[i].endPickup);

                            for (var j = 0; j < data[i].days.length; j++) {
                                data[i].days[j].date = DateUtils.convertLocalDateFromServer(data[i].days[j].date);

                                if (!allDay) {
                                    data[i].startPickup.setDate(data[i].days[j].date.getDate());
                                    data[i].startPickup.setMonth(data[i].days[j].date.getMonth());
                                    data[i].startPickup.setYear(data[i].days[j].date.getFullYear());

                                    data[i].endPickup.setDate(data[i].days[j].date.getDate());
                                    data[i].endPickup.setMonth(data[i].days[j].date.getMonth());
                                    data[i].endPickup.setYear(data[i].days[j].date.getFullYear());
                                }

                                if (data[i].days[j].collectedPatient > 0) {
                                    events.push({
                                        id: '' + j + '-' + i,
                                        resourceId: '' + data[i].clinicId,
                                        title: data[i].clinic + ' P: ' + data[i].days[j].pendingPatient + ' / C: ' + data[i].days[j].collectedPatient,
                                        start: data[i].startPickup ? new Date(data[i].startPickup) : data[i].days[j].date,
                                        end: data[i].endPickup ? new Date(data[i].endPickup) : data[i].days[j].date,
                                        allDay: allDay,
                                        className: data[i].days[j].collectedPatient > 0 ? "bg-green" : "bg-red"
                                    });
                                }

                                if (data[i].days[j].collectedWater > 0) {
                                    events.push({
                                        id: '' + j + 'x-' + i,
                                        resourceId: '' + data[i].clinicId,
                                        title: data[i].clinic + ' P: ' + data[i].days[j].pendingWater + ' / C: ' + data[i].days[j].collectedWater,
                                        start: data[i].startPickup ? new Date(data[i].startPickup) : data[i].days[j].date,
                                        end: data[i].endPickup ? new Date(data[i].endPickup) : data[i].days[j].date,
                                        allDay: allDay,
                                        className: data[i].days[j].collectedWater > 0 ? "bg-blue" : "bg-aqua"
                                    });
                                }
                            }
                        }

                        return events;
                    }

                    return data;
                }
            }
        });
    }
})();
