(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('ClinicAdminController', ClinicAdminController);

    ClinicAdminController.$inject = ['$scope', '$state', '$compile', '$q', '$filter', 'DTColumnBuilder', 'DTOptionsBuilder', 'ClinicSearch', 'GUIUtils', '$uibModal'];

    function ClinicAdminController($scope, $state, $compile, $q, $filter, DTColumnBuilder, DTOptionsBuilder, ClinicSearch, GUIUtils, $uibModal) {
        var vm = this;

        vm.title = 'Clinic Admin';
        vm.entityClassHumanized = 'Clinic Admin';
        vm.entityStateName = 'clinic-admin';

        vm.search = search;
        vm.del = del;

        vm.clinics = [];
        vm.dtInstance = {};
        vm.searchQuery = "";

        vm.dtOptions = DTOptionsBuilder.fromFnPromise(function () {
            var defer = $q.defer();
            if (!vm.searchQuery || vm.searchQuery == '') {
                ClinicSearch.query({
                    query: {
                        bool: {
                            must: [
                                {term: {"delStatus": false}}
                            ]
                        }
                    }
                }, function (result) {
                    vm.clinics = result;
                    defer.resolve(result);
                });
            } else {
                defer.resolve($filter('filter')(vm.clinics, vm.searchQuery, undefined));
            }
            return defer.promise;
        }).withPaginationType('full_numbers')
            .withBootstrap()
            .withDOM('ltp')
            .withLanguage({
                "paginate": {
                    "first": '<i class="fa fa-angle-double-left" aria-hidden="true"></i>',
                    "previous": '<i class="fa fa-arrow-circle-left" aria-hidden="true"></i>',
                    "last": '<i class="fa fa-angle-double-right" aria-hidden="true"></i>',
                    "next": '<i class="fa fa-arrow-circle-right" aria-hidden="true"></i>'
                }
            })
            .withOption('aaSorting', [[0, 'desc'], [1, 'asc']])
            .withOption('headerCallback', function (header) {
                if (!vm.headerCompiled) {
                    vm.headerCompiled = true;
                    $compile(angular.element(header).contents())($scope);
                }
            })
            .withOption('lengthMenu', [[10, 15, 25, 50, -1], [10, 15, 25, 50, "All"]])
            .withOption('fnRowCallback',
                function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                    $compile(nRow)($scope);
                });

        vm.dtColumns = [
            DTColumnBuilder.newColumn('name').withTitle('Name'),
            DTColumnBuilder.newColumn('account').withTitle('Account ID'),
            DTColumnBuilder.newColumn(null).withTitle('Start Date').renderWith(function (data, type, full) {
                return data.startDate ? moment(data.startDate).format($scope.displayDateformat) : "";
            }),
            DTColumnBuilder.newColumn('phone').withTitle('Phone'),
            DTColumnBuilder.newColumn('fieldServiceRep').withTitle('Field Service Rep.'),
            DTColumnBuilder.newColumn('status').withTitle('Status').renderWith(function (data, type, full) {
                return full.status ? "Active" : "Inactive";
            }),
            DTColumnBuilder.newColumn('').withTitle('Actions').withOption('class', 'col').withOption('width', '175px').notSortable()
                .renderWith(actionsHtml)
        ];

        function actionsHtml(data, type, full, meta) {
            return toStateLink() + '<a class="dt-btn danger" uib-tooltip="Delete" ng-click="vm.del()"><i class="fa fa-trash" aria-hidden="true"></i></a>';
        }

        function toStateLink() {
            return '<a class="dt-btn warning" ui-sref="clinic-admin-view" uib-tooltip="Edit"><i class="fa fa-pencil" aria-hidden="true"></i></a>';
        }

        function search() {
            vm.dtInstance.reloadData();
        }

        function del() {
            $uibModal.open({
                templateUrl: 'app/entities/clinic/dashboard2/clinic-admin-delete-dialog.html',
                controller: 'ClinicAdminDeleteDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                windowTopClass: 'custom-dialog-styles',
                resolve: {

                }
            }).result.then(function () {

            }, function () {

            });
        }

    }
})();
