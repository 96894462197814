(function() {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('LabBundleBillingCodeDeleteController',LabBundleBillingCodeDeleteController);

    LabBundleBillingCodeDeleteController.$inject = ['$uibModalInstance', 'entity', 'LabBundleBillingCode'];

    function LabBundleBillingCodeDeleteController($uibModalInstance, entity, LabBundleBillingCode) {
        var vm = this;

        vm.labBundleBillingCode = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            LabBundleBillingCode.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
