(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('BundleController', BundleController);

    BundleController.$inject = ['$scope', '$state', 'Bundle', 'BundleSearch', 'CoreService', 'DTOptionsBuilder',
        'DTColumnBuilder', '$q', 'GUIUtils', '$filter'];

    function BundleController($scope, $state, Bundle, BundleSearch, CoreService, DTOptionsBuilder,
                              DTColumnBuilder, $q, GUIUtils, $filter) {
        var vm = this;

        vm.bundles = [];
        vm.clinic = CoreService.getCurrentClinic();
        vm.dtInstance = {};

        vm.search = search;

        vm.dtOptions = DTOptionsBuilder.fromFnPromise(function () {
            var defer = $q.defer();
            if (!vm.searchQuery || vm.searchQuery == '') {
                BundleSearch.query({
                    query: {
                        bool: {
                            must: [
                                {term: {"clinic.id": vm.clinic.id}},
                                {term: {"delStatus": false}}
                            ]
                        }
                    }
                }, function (result) {
                    vm.bundles = result;
                    defer.resolve(result);
                });
            } else {
                defer.resolve($filter('filter')(vm.bundles, vm.searchQuery, undefined));
            }
            return defer.promise;
        }).withPaginationType('full_numbers').withBootstrap().withDOM('tip');

        vm.dtColumns = [
            DTColumnBuilder.newColumn('id').withTitle('ID'),
            DTColumnBuilder.newColumn('compendium.code').withTitle('Compendium Code'),
            DTColumnBuilder.newColumn('compendium.description').withTitle('Description'),
            DTColumnBuilder.newColumn('billingFrequency.name').withTitle('Frequency'),
            DTColumnBuilder.newColumn('billingFrequency.days').withTitle('Days'),
            DTColumnBuilder.newColumn(null).withTitle('Modality').renderWith(function (data, type, full) {
                return data.dialysisModality ? data.dialysisModality.replace("_", " ") : "";
            }),
            DTColumnBuilder.newColumn(null).withTitle('Actions').withOption('width', '155px').notSortable()
                .renderWith(actionsHtml)
        ];

        function actionsHtml(data, type, full, meta) {
            return GUIUtils.getActionsTemplate(data, $state.current.name, ['update', 'delete']);
        }

        function search() {
            vm.dtInstance.reloadData();
        }
    }
})();
