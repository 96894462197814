(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('DashboardAdminEnvOrderScheduleController', DashboardAdminEnvOrderScheduleController);

    DashboardAdminEnvOrderScheduleController.$inject = ['CoreService', '$rootScope', 'hasPrinter', 'date', '$uibModal', '$compile', '$q', '$scope', 'GUIUtils', 'EnvOrder', 'DTOptionsBuilder', 'DTColumnBuilder', 'DateUtils', 'DYMO'];

    function DashboardAdminEnvOrderScheduleController(CoreService, $rootScope, hasPrinter, date, $uibModal, $compile, $q, $scope, GUIUtils, EnvOrder, DTOptionsBuilder, DTColumnBuilder, DateUtils, DYMO) {
        var vm = this;
        vm.clinic = CoreService.getCurrentClinic();

        vm.orders = [];
        vm.search = search;
        vm.dtInstance = {};
        vm.barCode = {};
        vm.oDate1 = new Date(date);
        vm.hasPrinter = hasPrinter;

        vm.selected = {};
        vm.selectAll = false;
        vm.toggleAll = toggleAll;
        vm.toggleOne = toggleOne;

        var titleHtml = '<input type="checkbox" ng-model="vm.selectAll" ng-change="vm.toggleAll()">';

        vm.dtOptions = DTOptionsBuilder.fromFnPromise(function () {
            var defer = $q.defer();

            if (vm.clinic) {
                EnvOrder.dashboardSchedule({clinicId: vm.clinic.id, date: vm.oDate1}, function (result) {
                    vm.orders = result;
                    defer.resolve(result);
                    vm.selected = {};
                    vm.selectAll = false;
                });
            } else {
                defer.resolve(vm.orders);
            }

            return defer.promise;
        }).withBootstrap().withDOM('tip')
            .withOption('createdRow', function(row, data, dataIndex) {
                $compile(angular.element(row).contents())($scope);
            })
            .withOption('aaSorting', [[1, 'asc']])
            .withOption('headerCallback', function(header) {
                if (!vm.headerCompiled) {
                    vm.headerCompiled = true;
                    $compile(angular.element(header).contents())($scope);
                }
            });

        vm.dtColumns = [
            DTColumnBuilder.newColumn(null).withTitle(titleHtml).notSortable().renderWith(function(data, type, full, meta) {
                vm.selected[full.orderId] = false;

                return '<input type="checkbox" ng-model="vm.selected[' + data.orderId + ']" ng-click="vm.toggleOne()">';
            }),
            DTColumnBuilder.newColumn(null).withTitle('Machine').renderWith(function (data, type, full) {
                return data.machine;
            }),
            DTColumnBuilder.newColumn(null).withTitle('Barcode').renderWith(function (data, type, full) {
                return data.barcode ? showBarcode(data) : '';
            }),
            DTColumnBuilder.newColumn(null).withTitle('Tests').withOption('width', '90px').renderWith(function (data, type, full, meta) {
                return showCompendiumHtml(data.orderId);
            }),
            DTColumnBuilder.newColumn(null).withTitle('Collected').renderWith(function (data, type, full) {
                return GUIUtils.colorHtmlYesNo(data.collected);
            }),
        ];

        vm.printLabels = function() {
            var orders = getSelectedIds();

            if (vm.hasPrinter) {
                var orders = getSelectedIds();
                if (orders.length > 0) {
                    EnvOrder.get_barcodes({
                        ids: orders,
                        date: DateUtils.convertLocalDateToServer(vm.oDate1)
                    }, function (result) {
                        DYMO.printEnvBarcodes(result);
                    });
                }
            }
        }

        function toggleAll () {
            for (var id in vm.selected) {
                if (vm.selected.hasOwnProperty(id)) {
                    vm.selected[id] = vm.selectAll;
                }
            }
        }

        function toggleOne () {
            for (var id in vm.selected) {
                if (vm.selected.hasOwnProperty(id)) {
                    if(!vm.selected[id]) {
                        vm.selectAll = false;
                        return;
                    }
                }
            }
            vm.selectAll = true;
        }

        function search() {
            vm.dtInstance.reloadData();
        }

        $scope.$on('dialysisApp:dashboardAdminCalendarUpdate', function(event, result) {
            vm.oDate1 = result;
            vm.selected = {};
            vm.selectAll = false;
            vm.search();
        });

        function showCompendiumHtml(row) {
            return '<a class="btn-sm btn-info" ng-click="vm.showCompendiums(' + row + ')">' +
                '   <i class="fa fa-list-ol"></i></a>';
        };

        vm.showCompendiums = function (index) {
            $uibModal.open({
                templateUrl: 'app/entities/compendium/compendium-list-dialog.html',
                controller: 'CompendiumListDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    compendiums: ['Compendium', function (Compendium) {
                        return Compendium.by_env_order({
                            id: index,
                            date: DateUtils.convertLocalDateToServer(vm.oDate1)
                        }).$promise;
                    }]
                }
            });
        };

        function getSelectedIds() {
            var orders = [];
            for (var id in vm.selected) {
                if (vm.selected[id]) {
                    orders.push(id);
                }
            }

            return orders;
        }

        function showBarcode(row) {
            return '<a ui-sref="' + "clinic-schedule-edetail-barcode({barcode:'" + row.barcode + "'})" + '">' + row.barcode + '</a>';
        }
    }
})();
