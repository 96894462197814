(function () {
    'use strict';
    angular
        .module('dialysisApp')
        .factory('Compendium', Compendium);

    Compendium.$inject = ['$resource'];

    function Compendium($resource) {
        var resourceUrl = 'api/compendiums/:id';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': {method: 'PUT'},
            'by_patient_order': {url: 'api/compendiums/by-patient-order/:id/:date', method: 'GET', isArray: true},
            'by_env_order': {url: 'api/compendiums/by-env-order/:id/:date', method: 'GET', isArray: true},
            'by_patient_without_barcode': {url: 'api/compendiums/by-patient-without-barcode/:patient/:date/:physician', method: 'GET', isArray: true},
            'by_patient_with_barcode': {url: 'api/compendiums/by-patient-with-barcode/:barcode', method: 'GET', isArray: true},
            'by_clinic_result_range_dif': {url: 'api/compendiums/by-clinic-result-range-dif/:id', method: 'GET', isArray: true},
            'check': {url: 'api/compendiums/check', method: 'POST', isArray: false},
            'byClinicSpecialPriceDif': {url: 'api/compendiums/by-clinic-special-price-dif/:id', method: 'GET', isArray: true},
            'byClinicBundleDif': {url: 'api/compendiums/by-clinic-bundle-dif/:id/:modality', method: 'GET', isArray: true},
            'byClinicCompositeDif': {url: 'api/compendiums/by-clinic-composite-dif/:id/:modality', method: 'GET', isArray: true},
            'byRptCompendiumDefaultMessageDif': {url: 'api/compendiums/by-rpt-compendium-default-message-dif/:id/:modality', method: 'GET', isArray: true}
        });
    }
})();
