// EnvResultUpdateDatesController
(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('EnvResultUpdateDatesController', EnvResultUpdateDatesController);

    EnvResultUpdateDatesController.$inject = ['EnvResult', 'toastr', '$state', 'dialogs'];

    function EnvResultUpdateDatesController(EnvResult, toastr, $state, $dialogs) {
        var vm = this;

        vm.barcode = '';
        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.receivedDate = false;
        vm.datePickerOpenStatus.collectionDate = false;
        vm.openCalendar = openCalendar;
        vm.saveDates=saveDates;
        vm.clear = clear;
        vm.clean = clean;

        function saveDates() {
            var dlg = $dialogs.confirm('Please Confirm','Do you want to update this result?', {size: 'md'});
            dlg.result.then(function(btn){
                vm.isSaving = true;
                EnvResult.updateDates({
                    barcode: vm.barcode,
                    startDate: vm.receivedDate,
                    endDate: vm.collectionDate
                }, function (result) {
                    toastr.success('Update dates operation successfully completed');
                    vm.isSaving = false;
                    vm.clean();
                }, function () {
                    vm.isSaving = false;
                });
            },function(btn){
            });
        }

        function clear() {
            $state.go("latest-env-result", {});
        }

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function clean(){
            vm.barcode = "";
            vm.receivedDate = "";
            vm.collectionDate = "";
        }
    }
})();

