(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('EnvOrderNewController', EnvOrderNewController);

    EnvOrderNewController.$inject = ['$scope', '$compile', '$state', 'machine', '$q', 'DTColumnBuilder' ,
        'DTOptionsBuilder', 'order', 'EnvOrder', '$uibModal'];

    function EnvOrderNewController($scope, $compile, $state, machine, $q, DTColumnBuilder,
                                   DTOptionsBuilder, order, EnvOrder, $uibModal) {
        var vm = this;

        vm.machine = machine;
        vm.order = order;
        vm.order.ordStatus = 'SCHEDULED';
        vm.order.machine = machine;

        vm.form = {};

        vm.save = save;

        vm.orderfrequencies = [];

        function save() {
            vm.isSaving = true;
            EnvOrder.save(vm.order, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess() {
            vm.isSaving = false;
            $state.go("env-order", {id: vm.machine.id});
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        vm.addEnvTest = function () {
            var test = {
                staringDate: null,
                id: null,
                schedules: []
            };

            vm.popupTest(test, -1);
        };

        vm.editPatientOrderTest = function (index) {
            vm.popupTest(vm.order.envTests[index], index);
        };

        vm.deletePatientOrderTest = function (index) {
            $uibModal.open({
                templateUrl: 'app/entities/patient-order-test/patient-order-test-delete-dialog.html',
                controller: 'PatientOrderTestDeleteNewController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    entity: {id: index}
                }
            }).result.then(function(result) {
                vm.order.envTests.splice(index, 1);

                vm.dtInstance.reloadData();
            }, function() {
            });
        }

        vm.popupTest = function(envTest, index) {
            $uibModal.open({
                templateUrl: 'app/entities/env-test/env-test-new-dialog.html',
                controller: 'EnvTestNewDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    order: vm.order,
                    envTest: envTest
                }
            }).result.then(function(result) {
                if (index < 0) {
                    vm.order.envTests.push(result.envTest);
                } else {
                    vm.order.envTests[index] = result.envTest;
                }

                vm.dtInstance.reloadData();
            }, function() {
            });
        }

        vm.dtInstance = {};

        vm.dtOptions = DTOptionsBuilder.fromFnPromise(function() {
            var defer = $q.defer();
            defer.resolve(vm.order.envTests);

            return defer.promise;
        }).withPaginationType('full_numbers').withBootstrap().withDOM('tip').withOption('paging', false).withOption('aaSorting', []).withOption('fnRowCallback',
            function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                $compile(nRow)($scope);
            });

        vm.dtColumns = [
            DTColumnBuilder.newColumn(null).withTitle('Test Code').renderWith(function (data, type, full) {
                return data.compendium.code;
            }),
            DTColumnBuilder.newColumn(null).withTitle('Description').renderWith(function (data, type, full) {
                return data.compendium.description;
            }),
            DTColumnBuilder.newColumn(null).withTitle('Start Date').renderWith(function (data, type, full) {
                return moment(data.staringDate).format("MM/DD/YYYY");
            }),
            DTColumnBuilder.newColumn(null).withTitle('End Date').renderWith(function (data, type, full) {
                return moment(data.endDate).format("MM/DD/YYYY");
            }),
            DTColumnBuilder.newColumn(null).withTitle('Actions').withOption('width', '155px').notSortable()
                .renderWith(actionsHtml)
        ];

        function actionsHtml(data, type, full, meta){
            var stButtons = '';

            stButtons += '<a class="btn-sm btn-warning" ng-click="vm.editPatientOrderTest(' + meta.row + ')">' +
                '   <i class="fa fa-edit"></i></a>&nbsp;';
            stButtons += '<a class="btn-sm btn-danger" ng-click="vm.deletePatientOrderTest(' + meta.row + ')">' +
                '   <i class="fa fa-trash"></i></a>';

            return stButtons;
        }
    }
})();
