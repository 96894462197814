(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('patient-order-update.patient-order-test-detail', {
                parent: 'patient-order-update',
                url: '/{tid}/detail',
                data: {
                    authorities: ['ROLE_SUPERVISOR', 'ROLE_NURSE', 'ROLE_DOCTOR'],
                    pageTitle: 'PatientOrderTest'
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/patient-order-test/patient-order-test-detail-dialog.html',
                        controller: 'PatientOrderTestDetailDialogController',
                        controllerAs: 'vm',
                        size: 'lg',
                        resolve: {
                            patientOrderTest: ['PatientOrderTest', function (PatientOrderTest) {
                                return PatientOrderTest.with_all({id: $stateParams.tid}).$promise;
                            }],
                            patientOrder: ['PatientOrder', function (PatientOrder) {
                                return PatientOrder.get({id: $stateParams.oid}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('patient-order-update', null, {reload: 'patient-order-update'});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('patient-order-view.patient-order-test-detail', {
                parent: 'patient-order-view',
                url: '/{tid}/detail',
                data: {
                    authorities: ['ROLE_SUPERVISOR', 'ROLE_NURSE', 'ROLE_DOCTOR'],
                    pageTitle: 'PatientOrderTest'
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/patient-order-test/patient-order-test-detail-dialog.html',
                        controller: 'PatientOrderTestDetailDialogController',
                        controllerAs: 'vm',
                        size: 'lg',
                        resolve: {
                            patientOrderTest: ['PatientOrderTest', function (PatientOrderTest) {
                                return PatientOrderTest.with_all({id: $stateParams.tid}).$promise;
                            }],
                            patientOrder: ['PatientOrder', function (PatientOrder) {
                                return PatientOrder.get({id: $stateParams.oid}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('patient-order-update', null, {reload: 'patient-order-update'});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
    }
})();
