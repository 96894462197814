(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('vaccination', {
                parent: 'patient-abs',
                url: '/vaccination',
                ncyBreadcrumb: {
                    label: 'Vaccinations',
                },
                data: {
                    authorities: ['ROLE_SUPERVISOR', 'ROLE_NURSE', 'ROLE_DOCTOR'],
                    pageTitle: 'Vaccinations'
                },
                views: {
                    'content@patient-abs': {
                        templateUrl: 'app/entities/vaccination/vaccinations.html',
                        controller: 'VaccinationController',
                        controllerAs: 'vm'
                    }
                }
            })
            .state('vaccination.view', {
                parent: 'vaccination',
                url: '/{vid}/view',
                data: {
                    authorities: ['ROLE_SUPERVISOR', 'ROLE_NURSE', 'ROLE_DOCTOR'],
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/vaccination/vaccination-dialog.html',
                        controller: 'VaccinationDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            vaccination: ['Vaccination', function (Vaccination) {
                                return Vaccination.get({id: $stateParams.vid}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('vaccination', {id: $stateParams.id}, {reload: 'vaccination'});
                    }, function () {
                        $state.go('vaccination', {id: $stateParams.id}, {reload: 'vaccination'});
                    });
                }]
            })
            .state('vaccination.new', {
                parent: 'vaccination',
                url: '/new',
                data: {
                    authorities: ['ROLE_SUPERVISOR', 'ROLE_NURSE', 'ROLE_DOCTOR']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', 'entity', function ($stateParams, $state, $uibModal, entity) {
                    $uibModal.open({
                        templateUrl: 'app/entities/vaccination/vaccination-dialog.html',
                        controller: 'VaccinationDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            vaccination: function () {
                                return {
                                    completionDate: null,
                                    responded: null,
                                    id: null,
                                    patient: entity
                                };
                            }
                        }
                    }).result.then(function () {
                        $state.go('vaccination', {id: $stateParams.id}, {reload: 'vaccination'});
                    }, function () {
                        $state.go('vaccination', {id: $stateParams.id}, {reload: 'vaccination'});
                    });
                }]
            })
            .state('vaccination.delete', {
                parent: 'vaccination',
                url: '/{vid}/delete',
                data: {
                    authorities: ['ROLE_SUPERVISOR', 'ROLE_NURSE', 'ROLE_DOCTOR']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/vaccination/vaccination-delete-dialog.html',
                        controller: 'VaccinationDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['Vaccination', function (Vaccination) {
                                return Vaccination.get({id: $stateParams.vid}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('vaccination', null, {reload: 'vaccination'});
                    }, function () {
                        $state.go('^');
                    });
                }]
            });
    }

})();
