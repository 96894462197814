(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('ClinicController', ClinicController);

    ClinicController.$inject = ['$scope', '$state', 'Clinic', 'CoreService', '$q', 'DTColumnBuilder', 'DTOptionsBuilder', 'ClinicSearch', '$filter', '$compile', 'corporation'];

    function ClinicController($scope, $state, Clinic, CoreService, $q, DTColumnBuilder, DTOptionsBuilder, ClinicSearch, $filter, $compile, corporation) {
        var vm = this;
        vm.title = 'Clinics';
        vm.entityClassHumanized = 'Clinic';
        vm.entityStateName = 'clinic';
        vm.entityStateName = 'clinic-new';

        vm.clinics = [];
        vm.search = search;
        vm.dtInstance = {};

        vm.dtOptions = DTOptionsBuilder.fromFnPromise(function () {
            var defer = $q.defer();
            if (!vm.searchQuery || vm.searchQuery == '') {
                if (corporation) {
                    ClinicSearch.query({
                        query: {
                            bool: {
                                must: [
                                    {term: {"corporation.id": corporation.id}},
                                    {term: {"delStatus": false}}
                                ]
                            }
                        }
                    }, function (result) {
                        vm.clinics = result;
                        defer.resolve(result);
                    });
                } else {
                    Clinic.query(function (result) {
                        vm.clinics = result;
                        defer.resolve(result);
                    });
                }
            } else {
                defer.resolve($filter('filter')(vm.clinics, vm.searchQuery, undefined));
            }
            return defer.promise;
        }).withPaginationType('full_numbers').withBootstrap().withDOM('tip')/*.withOption('scrollX', '100%')*/
            .withOption('fnRowCallback', function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                $compile(nRow)($scope);
            });

        vm.dtColumns = [
            DTColumnBuilder.newColumn('id').withTitle('Id'),
            DTColumnBuilder.newColumn('account').withTitle('Account'),
            DTColumnBuilder.newColumn('name').withTitle('Name'),
            DTColumnBuilder.newColumn('abbr').withTitle('Abbr'),
            DTColumnBuilder.newColumn('corporation.name').withTitle('Corporation'),
            DTColumnBuilder.newColumn(null).withTitle('Start Date'). renderWith(function (data, type, full) {
                return moment(data.startDate).format("MM/DD/YYYY");
            }),
            DTColumnBuilder.newColumn('phone').withTitle('Phone'),
            DTColumnBuilder.newColumn('fax').withTitle('Fax'),
            DTColumnBuilder.newColumn(null).withTitle('Actions').withOption('width', '155px').notSortable()
                .renderWith(actionsHtml)
        ];

        function actionsHtml(data, type, full, meta) {
            var stButtons = '<a class="btn-sm btn-primary" ui-sref="clinic({id:' + data.id + '})">' +
                '   <i class="glyphicon glyphicon-eye-open"></i></a>&nbsp;';
            stButtons += '<a class="btn-sm btn-warning" ng-click="vm.editClinic(' + data.id + ')">' +
                '   <i class="glyphicon glyphicon-pencil"></i></a>&nbsp;';

            return stButtons;
        }

        function search() {
            vm.dtInstance.reloadData();
        }

        vm.detailClinic = function (id) {
            var _clinic = $.grep(vm.clinics, function (clinic, i) {
                return clinic.id === id;
            })[0];
            CoreService.setCurrentClinic(_clinic);
            $state.go("clinic");
        };

        vm.editClinic = function (id) {
            var _clinic = $.grep(vm.clinics, function (clinic, i) {
                return clinic.id === id;
            })[0];
            CoreService.setCurrentClinic(_clinic);
            $state.go("clinic-edit");
        };
    }
})();
