(function () {
    'use strict';
    angular
        .module('dialysisApp')
        .factory('PdAdequacy', PdAdequacy);

    PdAdequacy.$inject = ['$resource', 'DateUtils'];

    function PdAdequacy($resource, DateUtils) {
        var resourceUrl = 'api/pd-adequacy/:id';

        return $resource(resourceUrl, {}, {
            // 'query': {method: 'GET', isArray: true},
            // 'get': {
            //     method: 'GET',
            //     transformResponse: function (data) {
            //         if (data) {
            //             data = angular.fromJson(data);
            //             data.beginDate = DateUtils.convertDateTimeFromServer(data.beginDate);
            //             data.endDate = DateUtils.convertDateTimeFromServer(data.endDate);
            //         }
            //         return data;
            //     }
            // },
            // 'update': {
            //     method: 'PUT',
            //     // transformRequest: function (data) {
            //     //     var copy = angular.copy(data);
            //     //     copy.beginDate = DateUtils.convertLocalDateToServer(copy.beginDate);
            //     //     copy.endDate = DateUtils.convertLocalDateToServer(copy.endDate);
            //     //
            //     //     return angular.toJson(copy);
            //     // }
            // },
            'save': {'url': 'api/pd-adequacy/save-all', method: 'POST'},
            'patients': {'url': 'api/pd-adequacy/patients/:id', method: 'GET', isArray: true},
            'missing': {url: 'api/pd-adequacy/missing-info', method: 'POST', isArray: true},
            'countMissing' : {url : 'api/pd-adequacy/count-missing/:id', method: 'GET',
                transformResponse: function (data) {
                    return {value: data};
                }
            }
        });
    }
})();
