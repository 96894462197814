(function() {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('MedLocationDeleteController',MedLocationDeleteController);

    MedLocationDeleteController.$inject = ['$uibModalInstance', 'entity', 'MedLocation'];

    function MedLocationDeleteController($uibModalInstance, entity, MedLocation) {
        var vm = this;

        vm.medLocation = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            MedLocation.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
