(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('latest-result', {
                parent: 'entity',
                url: '/latest-result',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_SUPERVISOR', 'ROLE_NURSE', 'ROLE_DOCTOR'],
                    pageTitle: 'Latest Results'
                },
                ncyBreadcrumb: {
                    label: 'Latest Results',
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/patient-result/patient-result-latest.html',
                        controller: 'PatientResultLatestController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    filter: null
                },
                resolve: {
                    clinic: ['CoreService', function (CoreService) {
                        return CoreService.getCurrentClinic();
                    }],
                    filterRes: ['$stateParams', 'CoreService', function ($stateParams, CoreService) {
                        if ($stateParams.filter) {
                            return CoreService.readPatientResultFilter($stateParams.filter)
                        } else {
                            var filterPatient = CoreService.getStorage('filterPatient');

                            if (filterPatient == undefined) {
                                return CoreService.initPatientResultFilter();
                            } else {
                                return CoreService.readPatientResultFromLocalStore(filterPatient);
                            }
                        }
                    }]
                }
            })
            .state('patient-result', {
                parent: 'patient-abs',
                url: '/platest-result',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_SUPERVISOR', 'ROLE_NURSE', 'ROLE_DOCTOR'],
                    pageTitle: 'Patient Results'
                },
                ncyBreadcrumb: {
                    label: 'Results',
                    parent: 'patient-detail'
                },
                views: {
                    'content@patient-abs': {
                        templateUrl: 'app/entities/patient-result/patient-result-by-patient.html',
                        controller: 'PatientResultByPatientController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    filterRes: function () {
                        return {
                            patientName: null,
                            start: null,
                            end: null,
                            abnormal: null,
                            status: null
                        }
                    }
                }
            })
            .state('dashboard-final-result', {
                parent: 'entity',
                url: '/dfresult',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_SUPERVISOR', 'ROLE_NURSE', 'ROLE_DOCTOR'],
                    pageTitle: 'Final Results'
                },
                ncyBreadcrumb: {
                    label: 'Final Results'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/patient-result/patient-result-final-partial-dashboard.html',
                        controller: 'DashboardResultController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    filterRes: function () {
                        return {
                            status: 'final',
                            title: 'Final Results'
                        }
                    }
                }
            })
            .state('dashboard-partial-result', {
                parent: 'entity',
                url: '/dpresult',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_SUPERVISOR', 'ROLE_NURSE', 'ROLE_DOCTOR'],
                    pageTitle: 'Partial Results'
                },
                ncyBreadcrumb: {
                    label: 'Partial Results'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/patient-result/patient-result-final-partial-dashboard.html',
                        controller: 'DashboardResultController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    filterRes: function () {
                        return {
                            status: 'partial',
                            title: 'Partial Results'
                        }
                    }
                }
            })
            .state('dashboard-critical-result', {
                parent: 'entity',
                url: '/dcresult',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_SUPERVISOR', 'ROLE_NURSE', 'ROLE_DOCTOR'],
                    pageTitle: 'Critical Results'
                },
                ncyBreadcrumb: {
                    label: 'Critical Results'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/patient-result/patient-result-final-partial-dashboard.html',
                        controller: 'DashboardResultController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    filterRes: function () {
                        return {
                            status: 'critical',
                            title: 'Critical Results'
                        }
                    }
                }
            })
            .state('dashboard2-critical-result', {
                parent: 'entity',
                url: '/critical-results',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_SUPERVISOR', 'ROLE_NURSE', 'ROLE_DOCTOR'],
                    pageTitle: 'Critical Results'
                },
                ncyBreadcrumb: {
                    label: 'Critical Results'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/patient-result/dashboard/patient-result-critical.html',
                        controller: 'PatientResultCriticalController',
                        controllerAs: 'vm'
                    }
                }
            })
            .state('dashboard2-critical-result.detail', {
                url: '/critical-result/detail',
                data: {
                    authorities: ['ROLE_SUPERVISOR', 'ROLE_NURSE', 'ROLE_DOCTOR'],
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/patient-result/patient-result-critical-dialog.html',
                        controller: 'PatientResultCriticalDetails',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            patientCriticalResult: ['PatientResult', '$stateParams', function (PatientResult, $stateParams) {
                                return PatientResult.get({id: $stateParams.oid}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('dashboard2-critical-result', {"id": $stateParams.id}, {reload: 'dashboard2-critical-result'});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('dashboard2-pending-result', {
                parent: 'entity',
                url: '/pending-results',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_SUPERVISOR', 'ROLE_NURSE', 'ROLE_DOCTOR'],
                    pageTitle: 'Pending Results'
                },
                ncyBreadcrumb: {
                    label: 'Pending Results'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/patient-result/dashboard/patient-result-pending.html',
                        controller: 'PatientResultPendingController',
                        controllerAs: 'vm'
                    }
                }
            })
            .state('dashboard2-unassigned-results', {
                parent: 'entity',
                url: '/unassigned-results',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_SUPERVISOR', 'ROLE_NURSE', 'ROLE_DOCTOR'],
                    pageTitle: 'Unassigned Results'
                },
                ncyBreadcrumb: {
                    label: 'Unassigned Results'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/patient-result/dashboard/patient-result-unassigned.html',
                        controller: 'PatientResultsUnassignedController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    filterRes: function () {
                        return {status: 'unassigned'}
                    }
                }

            })
            .state('dashboard-non-perform-result', {
                parent: 'entity',
                url: '/dnonperform',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_SUPERVISOR', 'ROLE_NURSE', 'ROLE_DOCTOR'],
                    pageTitle: 'Non Perform Results'
                },
                ncyBreadcrumb: {
                    label: 'Non Perform Results'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/patient-result/patient-result-final-partial-dashboard.html',
                        controller: 'DashboardResultController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    filterRes: function () {
                        return {
                            status: 'nonperform',
                            title: 'Non Perform Results'
                        }
                    }
                }
            })
            .state('unassigned-result', {
                parent: 'entity',
                url: '/unassigned',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_SUPERVISOR', 'ROLE_NURSE', 'ROLE_DOCTOR'],
                    pageTitle: 'Unassigned Results'
                },
                ncyBreadcrumb: {
                    label: 'Final Results'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/patient-result/patient-result-unassigned.html',
                        controller: 'UnassignedResultController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    filterRes: function () {
                        return {
                            status: 'PARTIAL'
                        }
                    },
                }
            })
            .state('trending', {
                parent: 'patient-abs',
                url: '/trending',
                data: {
                    authorities: ['ROLE_SUPERVISOR', 'ROLE_NURSE', 'ROLE_DOCTOR'],
                    pageTitle: 'Trending Results'
                },
                ncyBreadcrumb: {
                    label: 'Results',
                    parent: 'patient-detail'
                },
                views: {
                    'content@patient-abs': {
                        templateUrl: 'app/entities/patient-result/patient-result-trending.html',
                        controller: 'PatientResultTrendingController',
                        controllerAs: 'vm'
                    }
                }
            })
            .state('maintenance-rgroup', {
                parent: 'entity',
                url: '/maintenance-rgroup',
                data: {
                    authorities: ['ROLE_ADMIN'],
                    pageTitle: 'Maintenance Report Group'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/patient-result/maintenance-rgroup.html',
                        controller: 'MaintenanceRepGroupController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    filterRes: function () {
                        return {
                            start: null,
                            end: null,
                            clinics: []
                        }
                    },
                }
            })
            .state('maintenance-range', {
                parent: 'entity',
                url: '/maintenance-range',
                data: {
                    authorities: ['ROLE_ADMIN'],
                    pageTitle: 'Maintenance Range'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/patient-result/maintenance-range.html',
                        controller: 'MaintenanceRangeController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    filterRes: function () {
                        return {
                            start: null,
                            end: null,
                            clinics: []
                        }
                    },
                }
            })
            .state('dashboard-pending-result', {
                parent: 'entity',
                url: '/pending-result',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'Pending Result'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/patient-result/patient-result-pending.html',
                        controller: 'PendingResultController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    filterRes: function () {
                        return {
                            start: null,
                            end: null,
                            clinics: []
                        }
                    },
                }
            })
            .state('patient-result-update-dates', {
                parent: 'entity',
                url: '/patient-result-update-dates',
                data: {
                    authorities: ['ROLE_ADMIN'],
                    pageTitle: 'Update Patient Result Dates'
                },
                ncyBreadcrumb: {
                    label: 'Update Patient Result',
                    parent: 'latest-result'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/patient-result/patient-result-update-dates.html',
                        controller: 'PatientResultUpdateDatesController',
                        controllerAs: 'vm'
                    }
                }
            })
            .state('patient-result-delete', {
                parent: 'entity',
                url: '/patient-result-delete',
                data: {
                    authorities: ['ROLE_ADMIN'],
                    pageTitle: 'Delete patient result'
                },
                ncyBreadcrumb: {
                    label: 'Delete patient result',
                    parent: 'entity'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/patient-result/patient-result-delete.html',
                        controller: 'PatientResultDeleteController',
                        controllerAs: 'vm'
                    }
                }
            })

    }
})();
