(function() {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('PatientTypeDetailController', PatientTypeDetailController);

    PatientTypeDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'PatientType', 'Patient', 'Corporation'];

    function PatientTypeDetailController($scope, $rootScope, $stateParams, previousState, entity, PatientType, Patient, Corporation) {
        var vm = this;

        vm.patientType = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('dialysisApp:patientTypeUpdate', function(event, result) {
            vm.patientType = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
