(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('latest-result-det', {
                parent: 'latest-result',
                url: '/{id}/lrdetails',
                data: {
                    authorities: ['ROLE_SUPERVISOR', 'ROLE_NURSE', 'ROLE_DOCTOR', 'ROLE_ADMIN'],
                    pageTitle: 'Result Details'
                },
                ncyBreadcrumb: {
                    label: 'Result Details',
                    parent: 'latest-result'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/patient-result-det/patient-result-dets.html',
                        controller: 'PatientResultDetController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    patientResult: ['$stateParams', 'PatientResult', function ($stateParams, PatientResult) {
                        return PatientResult.get({id: $stateParams.id}).$promise;
                    }],
                    clinic: ['CoreService', function (CoreService) {
                        return CoreService.getCurrentClinic();
                    }]
                }
            })
            .state('patient-result-details', {
                parent: 'patient-result',
                url: '/{rid}/details',
                data: {
                    authorities: ['ROLE_SUPERVISOR', 'ROLE_NURSE', 'ROLE_DOCTOR', 'ROLE_ADMIN'],
                    pageTitle: 'Result Details'
                },
                ncyBreadcrumb: {
                    label: 'Result Details',
                    parent: 'patient-result'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/patient-result-det/patient-result-dets.html',
                        controller: 'PatientResultDetController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    patientResult: ['$stateParams', 'PatientResult', function ($stateParams, PatientResult) {
                        return PatientResult.get({id: $stateParams.rid}).$promise;
                    }],
                    clinic: ['CoreService', function (CoreService) {
                        return CoreService.getCurrentClinic();
                    }]
                }
            })
    }
})();
