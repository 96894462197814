(function () {
    'use strict';
    angular
        .module('dialysisApp')
        .factory('EnvOrderTpl', EnvOrderTpl);

    EnvOrderTpl.$inject = ['$resource', 'DateUtils'];

    function EnvOrderTpl($resource, DateUtils) {
        var resourceUrl = 'api/env-orders-tpl/:id';

        return $resource(resourceUrl, {}, {
            'query': {
                method: 'GET', isArray: true, transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);

                        for (var i = 0; i < data.length; i++){
                            data[i].envOrderJson = angular.fromJson(data[i].envOrderJson);
                        }
                    }

                    return data;
                }
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.envOrderJson = angular.fromJson(data.envOrderJson);
                    }

                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.envOrderJson = angular.toJson(copy.envOrderJson);

                    return angular.toJson(copy);
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.envOrderJson = angular.toJson(copy.envOrderJson);

                    return angular.toJson(copy);
                }
            }
        });
    }
})();
