(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('EnvTestController', EnvTestController);

    EnvTestController.$inject = ['order', '$compile', '$scope', 'EnvTest', '$q', 'DTColumnBuilder', 'DTOptionsBuilder', '$filter', '$uibModal'];

    function EnvTestController(order, $compile, $scope, EnvTest, $q, DTColumnBuilder, DTOptionsBuilder, $filter, $uibModal) {
        var vm = this;

        vm.order = order;
        vm.envTests = [];
        vm.dtInstance = {};

        vm.dtOptions = DTOptionsBuilder.fromFnPromise(function () {
            var defer = $q.defer();
            if (!vm.searchQuery || vm.searchQuery == '') {
                EnvTest.order({id: vm.order.id}, function (result) {
                    vm.envTests = result;
                    defer.resolve(result);
                });
            } else {
                defer.resolve($filter('filter')(vm.envTests, vm.searchQuery, undefined));
            }
            return defer.promise;
        }).withBootstrap().withDOM('tip').withOption('aaSorting', []).withOption('fnRowCallback',
            function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                $compile(nRow)($scope);
            });

        vm.dtColumns = [
            DTColumnBuilder.newColumn(null).withTitle('Test').renderWith(function (data, type, full) {
                return data.compendium.code;
            }),
            DTColumnBuilder.newColumn(null).withTitle('Description').renderWith(function (data, type, full) {
                return data.compendium.description;
            }),
            DTColumnBuilder.newColumn(null).withTitle('Start Date').renderWith(function (data, type, full) {
                return moment(data.staringDate).format("MM/DD/YYYY");
            }),
            DTColumnBuilder.newColumn(null).withTitle('End Date').renderWith(function (data, type, full) {
                return data.endDate ? moment(data.endDate).format("MM/DD/YYYY") : moment(data.staringDate).format("MM/DD/YYYY");
            }),
            DTColumnBuilder.newColumn(null).withTitle('Actions').withOption('width', '155px').notSortable()
                .renderWith(actionsHtml)
        ];

        function actionsHtml(data, type, full, meta){
            var stButtons = '';
            stButtons += '<a class="btn-sm btn-warning" ui-sref="env-order-detail.test-detail({tid:' + data.id + '})">' +
                '   <i class="fa fa-edit"></i></a>&nbsp;';

            return stButtons;
        }

        // function actionsHtml(data, type, full, meta){
        //     var stButtons = '';
        //
        //     stButtons += '<a class="btn-sm btn-warning" ng-click="vm.edit(' + meta.row + ')">' +
        //         '   <i class="fa fa-edit"></i></a>&nbsp;';
        //
        //     return stButtons;
        // }

        // vm.edit = function (index) {
        //     $uibModal.open({
        //         templateUrl: 'app/entities/env-test/env-test-dialog.html',
        //         controller: 'EnvTestDialogController',
        //         controllerAs: 'vm',
        //         backdrop: 'static',
        //         size: 'lg',
        //         resolve: {
        //             order: vm.order,
        //             envTest: vm.envTests[index],
        //             canCancel : vm.canCancel
        //         }
        //     }).result.then(function(result) {
        //
        //     }, function() {
        //     });
        // }
    }
})();
