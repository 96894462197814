(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('PatientResultsUnassignedController', PatientResultsUnassignedController);

    PatientResultsUnassignedController.$inject = ['$uibModal', '$compile', '$scope', 'moment', 'Patient', 'DateUtils',
        'CoreService', 'PatientResult', '$q', 'DTColumnBuilder', 'DTOptionsBuilder', 'GUIUtils', '$filter',
        '$rootScope', 'lodash', 'filterRes', 'toastr', 'PopupService', 'DataTablesService'];

    function PatientResultsUnassignedController($uibModal, $compile, $scope, moment, Patient, DateUtils, CoreService,
                                                PatientResult, $q, DTColumnBuilder, DTOptionsBuilder, GUIUtils,
                                                $filter, $rootScope, _, filterRes, toastr, PopupService, DataTablesService) {
        var vm = this;

        vm.patientResults = [];
        vm.dtInstance = {};
        vm.title = "Unassigned Results";
        vm.clinic = CoreService.getCurrentClinic();
        vm.patients = [];
        vm.patient = {};
        vm.selected = {};
        vm.selectAll = false;
        vm.filterRes = filterRes;
        vm.disableTitle = false;

        vm.search = search;
        vm.toggleAll = DataTablesService.toggleAll;
        vm.toggleOne = DataTablesService.toggleOne;
        vm.assign = assign;
        vm.updateStatusButtonFilter = updateStatusButtonFilter;
        vm.details = details;
        vm.modalDeleteUnassignedResult = modalDeleteUnassignedResult;

        var titleHtml = '<input type="checkbox" ng-model="vm.selectAll" ng-change="vm.toggleAll(vm.selected, vm.selectAll)" ng-disabled="vm.disableTitle">';

        Patient.getAllByClinic({id: vm.clinic.id}, function (result) {
            vm.patients = result;

            _.forEach(vm.patients, function (patient) {
                var optMiddleInitial = patient.middleInitial ? ', ' + patient.middleInitial : '';
                patient.fullName = patient.lastName + ', ' + patient.firstName + optMiddleInitial;
            })
        });

        vm.dtOptions = DTOptionsBuilder.fromFnPromise(function () {
            var defer = $q.defer();
            if (!vm.searchQuery || vm.searchQuery == '') {
                PatientResult.unassigned({
                    account: vm.clinic.account,
                    assigned: vm.filterRes.status
                }, function (result) {
                    vm.selected = {};
                    vm.selectAll = false;
                    vm.patient = {};
                    vm.patientResults = result;
                    vm.disableTitle = isTitleDisabled();
                    defer.resolve(result);
                });
            } else {
                defer.resolve($filter('filter')(vm.patientResults, vm.searchQuery, undefined));
            }

            return defer.promise;
        }).withPaginationType('full_numbers')
            .withBootstrap()
            .withOption('aaSorting', [])
            .withDOM('ltp')
            .withLanguage({
                "paginate": {
                    "first": '<i class="fa fa-angle-double-left" aria-hidden="true"></i>',
                    "previous": '<i class="fa fa-arrow-circle-left" aria-hidden="true"></i>',
                    "last": '<i class="fa fa-angle-double-right" aria-hidden="true"></i>',
                    "next": '<i class="fa fa-arrow-circle-right" aria-hidden="true"></i>'
                }
            })
            .withOption('fnRowCallback',
                function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                    $compile(nRow)($scope);
                })
            .withOption('headerCallback', function (header) {
                if (!vm.headerCompiled) {
                    vm.headerCompiled = true;
                    $compile(angular.element(header).contents())($scope);
                }
            });

        vm.dtColumns = [
            DTColumnBuilder.newColumn(null).withTitle(titleHtml).notSortable().renderWith(function (data, type, full, meta) {
                if (!full.assigned) {
                    vm.selected[full.id] = false;
                }
                return '<input type="checkbox" ng-model="vm.selected[' + data.id + ']" ng-click="vm.toggleOne(vm.selected, vm.selectAll)" ng-disabled="' + data.assigned + '">';
            }),
            DTColumnBuilder.newColumn(null).withTitle('Patient').renderWith(function (data, type, full) {
                return data.patientName;
            }),
            DTColumnBuilder.newColumn(null).withTitle('DOB').renderWith(function (data, type, full) {
                return data ? moment(data.dob).format($rootScope.displayDateformat) : "";
            }),
            DTColumnBuilder.newColumn(null).withTitle('Result Date').renderWith(function (data, type, full) {
                return data ? moment(data.collectionDate).format($rootScope.displayDateformat) : "";
            }),
            DTColumnBuilder.newColumn(null).withTitle('Status').renderWith(assignedStatusHtml),
            DTColumnBuilder.newColumn('').withTitle('Actions').withOption('width', '90px').notSortable()
                .withOption('class', 'col-btn').notSortable().renderWith(actionsHtml)
        ];

        function actionsHtml(data, type, full, meta) {
            var stButtons = '';

            stButtons += '<a class="btn btn-default btn-sm details" uib-tooltip="See Details"' +
                ' ng-click="vm.details(' + full.id + ')">' +
                '   <i class="fa fa-external-link"></i></a>&nbsp;';

            stButtons += '<a uib-tooltip="Delete" class="btn btn-default btn-sm delete" ' +
                ' ng-click="vm.modalDeleteUnassignedResult(' + "'" + full.id + "'" + ')" ' +
                ' ng-show="' + !full.assigned + '">' +
                '   <i class="fa fa-trash" aria-hidden="true"></i></a>&nbsp;';

            return stButtons;
        }

        function search() {
            vm.dtInstance.reloadData();
        }

        function assign() {
            var result = DataTablesService.getSelectedIds(vm.selected);

            if (result.length > 0) {
                vm.isSaving = true;
                PatientResult.assign({
                    ids: result, patientId: vm.patient.id
                }, function (result) {
                    if (result.length > 0) {
                        toastr.error('Assign operation fail');
                    } else {
                        toastr.success('Assign operation successfully');
                    }

                    vm.dtInstance.reloadData();
                    vm.isSaving = false;
                }, function () {
                    toastr.error('Assign operation fail');
                    vm.isSaving = false;
                });
            }
        }

        function assignedStatusHtml(data, type, full) {
            var assignedStatus = data.assigned ? {label: 'Assigned', template: 'success'}
                : {label: 'Unassigned', template: 'danger'};

            return GUIUtils.getStatusTemplate(assignedStatus.label, assignedStatus.template);
        }

        function updateStatusButtonFilter(status) {
            vm.filterRes.status = status;

            vm.dtInstance.reloadData();
        }

        function isTitleDisabled() {
            return !_.some(vm.patientResults, function (patient) {
                return _.isNull(patient.patientId);
            });
        }

        function details(id) {
            PopupService.showPatientResultDetPopup(id);
        }

        function modalDeleteUnassignedResult(id) {
            $uibModal.open({
                templateUrl: 'app/entities/patient-result/dashboard/patient-result-unassigned-delete-dialog.html',
                controller: 'PatientResultUnassignedDeleteDialog',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    id: {selected: id}
                }
            }).result.then(function () {
                vm.dtInstance.reloadData();
            }, function (result) {
            });
        }
    }
})();
