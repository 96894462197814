(function() {
    'use strict';

    angular
        .module('dialysisApp')
        .factory('ResultRangeSearch', ResultRangeSearch);

    ResultRangeSearch.$inject = ['$resource'];

    function ResultRangeSearch($resource) {
        var resourceUrl =  'api/_search/result-ranges/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
