(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('SidebarController', SidebarController);

    SidebarController.$inject = ['$rootScope', '$state', 'Principal', 'ProfileService',
        'CoreService', 'ROLES', 'Clinic', 'Dashboard', 'DYMO', 'employee', 'clinics'];

    function SidebarController($rootScope, $state, Principal, ProfileService,
                               CoreService, ROLES, Clinic, Dashboard, DYMO, employee, clinics) {
        $rootScope.background = '';
        DYMO.loadPrinters();
        DYMO.loadDefaultDYMOOrderTemplate();
        DYMO.loadDefaultDYMOEnvOrderTemplate();

        var vm = this;
        vm.patientAuthority = [ROLES.ROLE_DOCTOR, ROLES.ROLE_SUPERVISOR].join(',');

        vm.abnormal = 0;
        vm.normal = 0;
        vm.kinetics = 0;
        vm.pdAdequacy = 0;
        vm.pdAdequacyWOUrine = 0;

        vm.account = null;
        vm.employee = employee;

        vm.isNavbarCollapsed = true;
        vm.isControlbarCollapsed = true;
        vm.isAuthenticated = Principal.isAuthenticated;

        ProfileService.getProfileInfo().then(function (response) {
            vm.inProduction = response.inProduction;
            vm.swaggerEnabled = response.swaggerEnabled;
        });

        vm.toggleNavbar = toggleNavbar;
        vm.collapseNavbar = collapseNavbar;
        vm.changeClinic = changeClinic;
        vm.loadAllClinics = loadAllClinics;
        vm.deployClinics = deployClinics;

        vm.clinics = clinics;
        deployClinics(vm.clinics);

        vm.sideBarItems = [
            {
                id: 'home',
                open: 'menu-open',
                displayName: 'Dashboard',
                icon: 'fa fa-dashboard',
                state: '',
                style: 'display: block;',
                url: 'home',
                access: [ROLES.ROLE_USER].join(),
                items: [],
                alias: ["dashboard", "dashboard-doc", "dashboard-env", "dashboard-admin"]
            },
            {
                id: 'clinic',
                open: '',
                displayName: 'Clinic Information',
                icon: 'fa fa-medkit',
                state: '',
                style: 'display: block;',
                url: 'clinic',
                access: [ROLES.ROLE_USER].join(),
                items: []
            },
            {
                id: 'patient',
                open: '',
                displayName: 'Patients',
                icon: 'fa fa-users',
                state: '',
                style: 'display: none;',
                url: null,
                access: [ROLES.ROLE_SUPERVISOR, ROLES.ROLE_DOCTOR, ROLES.ROLE_NURSE].join(),
                items: [
                    {
                        state: '',
                        displayName: 'Roster',
                        url: 'patient',
                        icon: 'fa fa-dot-circle-o',
                        access: [ROLES.ROLE_SUPERVISOR, ROLES.ROLE_DOCTOR, ROLES.ROLE_NURSE].join()
                    }, {
                        state: '',
                        displayName: 'Demographics',
                        url: 'patient-detail-menu({state1: "patient-detail"})',
                        icon: 'fa fa-dot-circle-o',
                        alias: ["patient-detail"],
                        access: [ROLES.ROLE_SUPERVISOR, ROLES.ROLE_DOCTOR, ROLES.ROLE_NURSE].join()
                    }, {
                        state: '',
                        displayName: 'Hospitalization',
                        url: 'patient-detail-menu({state1: "hospitalization"})',
                        icon: 'fa fa-dot-circle-o',
                        alias: ["hospitalization"],
                        access: [ROLES.ROLE_SUPERVISOR, ROLES.ROLE_DOCTOR, ROLES.ROLE_NURSE].join()
                    }, {
                        state: '',
                        displayName: 'Infections',
                        url: 'patient-detail-menu({state1: "infection"})',
                        icon: 'fa fa-dot-circle-o',
                        alias: ["infection"],
                        access: [ROLES.ROLE_SUPERVISOR, ROLES.ROLE_DOCTOR, ROLES.ROLE_NURSE].join()
                    },
                    {
                        state: '',
                        displayName: 'Vaccination',
                        url: 'patient-detail-menu({state1: "vaccination"})',
                        icon: 'fa fa-dot-circle-o',
                        alias: ["vaccination"],
                        access: [ROLES.ROLE_SUPERVISOR, ROLES.ROLE_DOCTOR, ROLES.ROLE_NURSE].join()
                    },
                    {
                        state: '',
                        displayName: 'Inactive Patient',
                        url: 'patient-i',
                        icon: 'fa fa-dot-circle-o',
                        access: [ROLES.ROLE_SUPERVISOR, ROLES.ROLE_DOCTOR, ROLES.ROLE_NURSE].join()
                    }
                ]
            },
            {
                id: 'orders',
                open: '',
                displayName: 'Orders',
                icon: 'fa fa-calendar-check-o',
                state: '',
                style: 'display: block;',
                url: 'patient-detail-menu({state1: "patient-order-current"})',
                alias: ["patient-order-current"],
                access: [ROLES.ROLE_SUPERVISOR, ROLES.ROLE_DOCTOR, ROLES.ROLE_NURSE].join(),
                items: []
            },
            {
                id: 'kinetic',
                open: '',
                displayName: 'Kinetic',
                icon: 'fa fa-calculator',
                state: '',
                style: 'display: none;',
                url: 'kinetic',
                access: [ROLES.ROLE_SUPERVISOR, ROLES.ROLE_DOCTOR, ROLES.ROLE_NURSE].join(','),
                items: []
            },
            {
                id: 'pd_adequacy',
                open: '',
                displayName: 'Pd Adequacy',
                icon: 'fa fa-calculator',
                state: '',
                style: 'display: none;',
                url: 'pd-adequacy-patients',
                access: [ROLES.ROLE_SUPERVISOR, ROLES.ROLE_DOCTOR, ROLES.ROLE_NURSE].join(','),
                items: []
            },
            {
                id: 'results',
                open: '',
                displayName: 'Results',
                icon: 'fa fa-calendar-o',
                state: '',
                style: 'display: none;',
                alias: ["latest-result"],
                url: 'latest-result',
                access: [ROLES.ROLE_SUPERVISOR, ROLES.ROLE_DOCTOR, ROLES.ROLE_NURSE, ROLES.ROLE_ADMIN].join(),
                items: []
            },
            {
                id: 'environmental',
                open: '',
                displayName: 'Environmental',
                icon: 'fa fa-thermometer',
                state: '',
                style: 'display: none;',
                url: null,
                access: [ROLES.ROLE_SUPERVISOR, ROLES.ROLE_DOCTOR, ROLES.ROLE_NURSE, ROLES.ROLE_ENVIRONMENTAL].join(),
                items: [
                    {
                        state: '',
                        displayName: 'Sites',
                        url: 'machine',
                        icon: 'fa fa-dot-circle-o',
                        alias: ["machine"],
                        access: [ROLES.ROLE_SUPERVISOR, ROLES.ROLE_DOCTOR, ROLES.ROLE_NURSE, ROLES.ROLE_ENVIRONMENTAL].join()
                    },
                    {
                        // id: 'machine-detail',
                        id: 'env-order',
                        displayName: 'Orders',
                        state: '',
                        url: 'machine-detail-menu({statem: "env-order"})',
                        alias: ["env-order"],
                        access: [ROLES.ROLE_SUPERVISOR, ROLES.ROLE_DOCTOR, ROLES.ROLE_NURSE, ROLES.ROLE_ENVIRONMENTAL].join()
                    },
                    {
                        state: '',
                        displayName: 'Latest Results',
                        icon: 'fa fa-dot-circle-o',
                        url: 'machine-detail-menu({statem: "latest-env-result"})',
                        alias: ["latest-env-result", "machine-env-result"],
                        access: [ROLES.ROLE_SUPERVISOR, ROLES.ROLE_DOCTOR, ROLES.ROLE_NURSE, ROLES.ROLE_ENVIRONMENTAL].join()
                    }
                ]
            },
            {
                id: 'reports',
                open: '',
                displayName: 'Reports',
                icon: 'fa fa-bar-chart',
                state: '',
                style: 'display: none;',
                url: 'reports',
                access: [ROLES.ROLE_SUPERVISOR, ROLES.ROLE_DOCTOR, ROLES.ROLE_ENVIRONMENTAL, ROLES.ROLE_NURSE].join(),
                items: []
            },
            {
                id: 'rpt-template',
                open: '',
                displayName: 'Report 2.0',
                icon: 'fa fa-bar-chart',
                state: '',
                style: 'display: none;',
                url: 'rpt-template',
                access: [ROLES.ROLE_SUPERVISOR, ROLES.ROLE_DOCTOR, ROLES.ROLE_ENVIRONMENTAL, ROLES.ROLE_NURSE, ROLES.ROLE_ADMIN].join(),
                items: []
            },
            {
                id: 'report-template',
                state: '',
                open: '',
                displayName: 'Reports Templates',
                icon: 'fa fa-clone',
                style: 'display: none;',
                url: null,
                access: [ROLES.ROLE_SUPERVISOR, ROLES.ROLE_ADMIN].join(),
                items: [
                    {
                        state: 'rpt-template-admin',
                        displayName: 'Report 2.0',
                        url: 'rpt-template-admin',
                        icon: 'fa fa-circle-o',
                        access: [ROLES.ROLE_SUPERVISOR, ROLES.ROLE_ADMIN].join()
                    },
                    {
                        state: '',
                        displayName: 'Report Card Message',
                        url: 'rpt-compendium-message',
                        icon: 'fa fa-circle-o',
                        access: [ROLES.ROLE_SUPERVISOR, ROLES.ROLE_ADMIN].join()
                    },
                    {
                        state: '',
                        displayName: 'Default Report Card Message',
                        url: 'rpt-compendium-default-message',
                        icon: 'fa fa-circle-o',
                        access: [ROLES.ROLE_ADMIN].join()
                    },
                    {
                        state: '',
                        displayName: 'Report Language',
                        url: 'rpt-language',
                        icon: 'fa fa-dot-circle-o',
                        access: [ROLES.ROLE_ADMIN].join()
                    },
                    {
                        state: '',
                        displayName: 'Report Category',
                        url: 'rpt-category',
                        icon: 'fa fa-dot-circle-o',
                        access: [ROLES.ROLE_SUPERVISOR, ROLES.ROLE_ADMIN].join()
                    }
                ]
            },
            {
                id: 'template',
                state: '',
                open: '',
                displayName: 'Templates',
                icon: 'fa fa-clone',
                style: 'display: none;',
                url: null,
                access: [ROLES.ROLE_NURSE, ROLES.ROLE_SUPERVISOR, ROLES.ROLE_ADMIN, ROLES.ROLE_ENVIRONMENTAL].join(),
                items: [
                    {
                        state: '',
                        displayName: 'Patient Order',
                        url: 'patient-order-tpl',
                        icon: 'fa fa-dot-circle-o',
                        access: [ROLES.ROLE_NURSE, ROLES.ROLE_SUPERVISOR, ROLES.ROLE_ADMIN].join()
                    },
                    {
                        state: '',
                        displayName: 'Site Order',
                        url: 'env-order-tpl',
                        icon: 'fa fa-dot-circle-o',
                        access: [ROLES.ROLE_NURSE, ROLES.ROLE_SUPERVISOR, ROLES.ROLE_ADMIN, ROLES.ROLE_ENVIRONMENTAL].join()
                    },
                    {
                        id: 'report-config',
                        state: '',
                        open: '',
                        displayName: 'Patient Report',
                        style: 'display: none;',
                        icon: 'fa fa-circle-o',
                        url: 'report-design',
                        access: [ROLES.ROLE_SUPERVISOR].join(),
                    },
                    {
                        id: 'result-level-message',
                        state: 'result-level-message',
                        open: '',
                        displayName: 'Report Card',
                        style: 'display: none;',
                        icon: 'fa fa-circle-o',
                        url: 'result-level-message',
                        access: [ROLES.ROLE_SUPERVISOR].join(),
                    },
                    {
                        id: 'result-ranges',
                        state: '',
                        open: '',
                        displayName: 'Results Range',
                        style: 'display: none;',
                        icon: 'fa fa-circle-o',
                        url: 'result-range',
                        access: [ROLES.ROLE_SUPERVISOR].join()
                    }
                ]
            },
            {
                id: 'config',
                state: '',
                open: '',
                displayName: 'Configuration',
                icon: 'fa fa-cog',
                style: 'display: none;',
                url: null,
                access: [ROLES.ROLE_SUPERVISOR, ROLES.ROLE_ADMIN].join(),
                items: [
                    {
                        state: 'clinic-new',
                        displayName: 'New Clinic',
                        url: 'clinic-new',
                        icon: 'fa fa-dot-circle-o',
                        access: [ROLES.ROLE_ADMIN].join()
                    },
                    {
                        state: 'clinics',
                        displayName: 'All Clinics',
                        url: 'clinics',
                        icon: 'fa fa-dot-circle-o',
                        access: [ROLES.ROLE_ADMIN].join()
                    },
                    {
                        state: '',
                        displayName: 'Employees',
                        url: 'employee',
                        icon: 'fa fa-dot-circle-o',
                        access: [ROLES.ROLE_SUPERVISOR, ROLES.ROLE_ADMIN].join()
                    },
                    {
                        state: '',
                        displayName: 'Site Location',
                        url: 'site-location',
                        icon: 'fa fa-dot-circle-o',
                        access: [ROLES.ROLE_SUPERVISOR].join()
                    },
                    {
                        state: '',
                        displayName: 'Corporation',
                        url: 'corporation',
                        icon: 'fa fa-dot-circle-o',
                        access: [ROLES.ROLE_ADMIN].join()
                    },
                    {
                        id: 'patient-info-config',
                        state: '',
                        open: '',
                        displayName: 'Patient Info',
                        style: 'display: none;',
                        icon: 'fa fa-circle-o',
                        access: [ROLES.ROLE_SUPERVISOR].join(),
                        items: [
                            {
                                state: '',
                                displayName: 'Race',
                                url: 'race',
                                icon: 'fa fa-circle-o',
                                access: [ROLES.ROLE_SUPERVISOR].join()
                            },
                            {
                                state: '',
                                displayName: 'Language',
                                url: 'language',
                                icon: 'fa fa-circle-o',
                                access: [ROLES.ROLE_SUPERVISOR].join()
                            }
                        ]
                    },
                    {
                        id: 'patient-clinical-info-config',
                        state: '',
                        open: '',
                        displayName: 'Patient Clinical Info',
                        style: 'display: none;',
                        icon: 'fa fa-circle-o',
                        access: [ROLES.ROLE_SUPERVISOR].join(),
                        items: [
                            {
                                state: '',
                                displayName: 'Patient Type',
                                url: 'patient-type',
                                icon: 'fa fa-circle-o',
                                access: [ROLES.ROLE_SUPERVISOR].join()
                            },
                            {
                                state: '',
                                displayName: 'Modality',
                                url: 'modality',
                                icon: 'fa fa-circle-o',
                                access: [ROLES.ROLE_SUPERVISOR].join()
                            },
                            {
                                state: '',
                                displayName: 'Template',
                                url: 'patient-template',
                                icon: 'fa fa-circle-o',
                                access: [ROLES.ROLE_SUPERVISOR].join()
                            }
                        ]
                    },
                    {
                        id: 'patient-insurance-config',
                        state: '',
                        open: '',
                        displayName: 'Patient Insurance',
                        style: 'display: none;',
                        icon: 'fa fa-circle-o',
                        access: [ROLES.ROLE_SUPERVISOR, ROLES.ROLE_ADMIN].join(),
                        items: [
                            {
                                state: '',
                                displayName: 'Insurance Carrier',
                                url: 'insurance-carrier',
                                icon: 'fa fa-circle-o',
                                access: [ROLES.ROLE_ADMIN].join()
                            },
                            {
                                state: '',
                                displayName: 'Insurance Level',
                                url: 'insurance-level',
                                icon: 'fa fa-circle-o',
                                access: [ROLES.ROLE_SUPERVISOR].join()
                            },
                            {
                                state: '',
                                displayName: 'Insurance Relation',
                                url: 'insurance-relation',
                                icon: 'fa fa-circle-o',
                                access: [ROLES.ROLE_SUPERVISOR].join()
                            },
                            {
                                state: '',
                                displayName: 'Insurance Type',
                                url: 'insurance-type',
                                icon: 'fa fa-circle-o',
                                access: [ROLES.ROLE_SUPERVISOR].join()
                            }
                        ]
                    },
                    {
                        id: 'patient-care-team-config',
                        state: '',
                        open: '',
                        displayName: 'Patient Care Team',
                        style: 'display: none;',
                        icon: 'fa fa-circle-o',
                        access: [ROLES.ROLE_SUPERVISOR].join(),
                        items: [
                            {
                                state: '',
                                displayName: 'Care Team Specialty',
                                url: 'care-team-specialty',
                                icon: 'fa fa-circle-o',
                                access: [ROLES.ROLE_SUPERVISOR].join()
                            }
                        ]
                    },
                    {
                        id: 'hospitalization-discharge-to-config',
                        state: '',
                        open: '',
                        displayName: 'Patient Hospitalization',
                        style: 'display: none;',
                        icon: 'fa fa-circle-o',
                        access: [ROLES.ROLE_SUPERVISOR].join(),
                        items: [
                            {
                                state: '',
                                displayName: 'Hospitalization Discharge',
                                url: 'hospitalization-discharge-to',
                                icon: 'fa fa-circle-o',
                                access: [ROLES.ROLE_SUPERVISOR].join()
                            }
                        ]
                    },
                    {
                        id: 'patient-infection-config',
                        state: '',
                        open: '',
                        displayName: 'Patient Infection',
                        style: 'display: none;',
                        icon: 'fa fa-circle-o',
                        access: [ROLES.ROLE_SUPERVISOR].join(),
                        items: [
                            {
                                state: '',
                                displayName: 'Source',
                                url: 'source',
                                icon: 'fa fa-circle-o',
                                access: [ROLES.ROLE_SUPERVISOR].join()
                            },
                            {
                                state: '',
                                displayName: 'Primary Access',
                                url: 'infection-primary-access',
                                icon: 'fa fa-circle-o',
                                access: [ROLES.ROLE_SUPERVISOR].join()
                            },
                            {
                                state: '',
                                displayName: 'Type of Dialyser',
                                url: 'dialyser-type',
                                icon: 'fa fa-circle-o',
                                access: [ROLES.ROLE_SUPERVISOR].join()
                            },
                            {
                                state: '',
                                displayName: 'Symptoms',
                                url: 'symptom',
                                icon: 'fa fa-circle-o',
                                access: [ROLES.ROLE_SUPERVISOR].join()
                            }
                        ]
                    },
                    {
                        id: 'patient-medication',
                        state: '',
                        open: '',
                        displayName: 'Patient Medication',
                        style: 'display: none;',
                        icon: 'fa fa-circle-o',
                        access: [ROLES.ROLE_SUPERVISOR].join(),
                        items: [
                            {
                                state: '',
                                displayName: 'Dosage',
                                url: 'med-dosage-unit',
                                icon: 'fa fa-circle-o',
                                access: [ROLES.ROLE_SUPERVISOR].join()
                            },
                            {
                                state: '',
                                displayName: 'Route',
                                url: 'med-route',
                                icon: 'fa fa-circle-o',
                                access: [ROLES.ROLE_SUPERVISOR].join()
                            },
                            {
                                state: '',
                                displayName: 'Fecuency From',
                                url: 'med-frequency-from',
                                icon: 'fa fa-circle-o',
                                access: [ROLES.ROLE_SUPERVISOR].join()
                            },
                            {
                                state: '',
                                displayName: 'Fecuency To',
                                url: 'med-frequency-to',
                                icon: 'fa fa-circle-o',
                                access: [ROLES.ROLE_SUPERVISOR].join()
                            },
                            {
                                state: '',
                                displayName: 'Location',
                                url: 'med-location',
                                icon: 'fa fa-circle-o',
                                access: [ROLES.ROLE_SUPERVISOR].join()
                            }
                        ]
                    },
                    {
                        id: 'compendium',
                        state: '',
                        open: '',
                        displayName: 'Compendium',
                        style: 'display: none;',
                        icon: 'fa fa-circle-o',
                        url: 'compendium',
                        access: [ROLES.ROLE_ADMIN].join()
                    },
                    {
                        state: '',
                        displayName: 'Internal Codes',
                        url: 'rpt-metadata',
                        icon: 'fa fa-dot-circle-o',
                        access: [ROLES.ROLE_ADMIN].join()
                    },
                    {
                        id: 'compendium-report-group',
                        state: '',
                        open: '',
                        displayName: 'Compendium Report Group',
                        style: 'display: none;',
                        icon: 'fa fa-circle-o',
                        url: 'compendium-report-group',
                        access: [ROLES.ROLE_ADMIN].join()
                    },
                    {
                        id: 'tube',
                        state: '',
                        open: '',
                        displayName: 'Tubes',
                        style: 'display: none;',
                        icon: 'fa fa-circle-o',
                        url: 'tube',
                        access: [ROLES.ROLE_ADMIN].join()
                    },
                    {
                        id: 'xml-config',
                        state: '',
                        open: '',
                        displayName: 'Labels',
                        style: 'display: none;',
                        icon: 'fa fa-circle-o',
                        url: 'x-ml-config',
                        access: [ROLES.ROLE_ADMIN].join()
                    },
                    {
                        id: 'maintenance',
                        state: '',
                        open: '',
                        displayName: 'Maintenance',
                        style: 'display: none;',
                        icon: 'fa fa-circle-o',
                        url: 'x-ml-config',
                        access: [ROLES.ROLE_ADMIN].join(),
                        items: [
                            {
                                state: '',
                                displayName: 'Report Group',
                                url: 'maintenance-rgroup',
                                icon: 'fa fa-circle-o',
                                access: [ROLES.ROLE_ADMIN].join()
                            },
                            {
                                state: '',
                                displayName: 'Ranges',
                                url: 'maintenance-range',
                                icon: 'fa fa-circle-o',
                                access: [ROLES.ROLE_ADMIN].join()
                            }
                        ]
                    },
                    {
                        state: '',
                        displayName: 'Kinetic Skip',
                        url: 'kinetic-skip',
                        icon: 'fa fa-dot-circle-o',
                        access: [ROLES.ROLE_ADMIN].join()
                    },
                    {
                        id: 'vaccine',
                        state: '',
                        open: '',
                        displayName: 'Vaccines',
                        style: 'display: none;',
                        icon: 'fa fa-circle-o',
                        url: 'vaccine',
                        access: [ROLES.ROLE_ADMIN].join()
                    },
                    {
                        state: '',
                        displayName: 'Emr Integration Log',
                        url: 'pr-emr-integration-log',
                        icon: 'fa fa-dot-circle-o',
                        access: [ROLES.ROLE_SUPERVISOR, ROLES.ROLE_ADMIN].join()
                    },
                    {
                        state: '',
                        displayName: 'Lab Notifications',
                        url: 'lab-notification',
                        icon: 'fa fa-dot-circle-o',
                        access: [ROLES.ROLE_ADMIN].join()
                    },
                ]
            },
            {
                id: 'kinetic-sent',
                state: '',
                open: '',
                displayName: 'Kinetic Sent',
                icon: 'fa fa-cog',
                style: 'display: none;',
                url: 'kinetic-sent',
                access: [ROLES.ROLE_ADMIN].join(),
                items: []
            },
            {
                id: 'billing-menu',
                state: '',
                displayName: 'Billing',
                url: null,
                icon: 'fa fa-dollar',
                access: [ROLES.ROLE_ADMIN].join(),
                items: [
                    {
                        id: 'invoice',
                        state: 'invoice',
                        open: 'invoice',
                        displayName: 'Invoice',
                        style: 'display: none;',
                        icon: 'fa fa-circle-o',
                        access: [ROLES.ROLE_ADMIN].join(),
                        url: 'invoice'
                    },
                    {
                        id: 'billing',
                        state: 'billing',
                        open: 'billing',
                        displayName: 'Billing Documents',
                        style: 'display: none;',
                        icon: 'fa fa-circle-o',
                        access: [ROLES.ROLE_ADMIN].join(),
                        url: 'billing'
                    }, {
                        id: 'billing-bundle',
                        state: '',
                        open: '',
                        displayName: 'Billing Bundle',
                        style: 'display: none;',
                        icon: 'fa fa-circle-o',
                        access: [ROLES.ROLE_ADMIN].join(),
                        url: 'lab-bundle-billing-code'
                    }, {
                        id: 'compendium-special-price',
                        state: '',
                        open: '',
                        displayName: 'Compendium Special Prices',
                        style: 'display: none;',
                        icon: 'fa fa-circle-o',
                        access: [ROLES.ROLE_ADMIN].join(),
                        url: 'compendium-special-price'
                    }, {
                        id: 'billing-frequency',
                        state: '',
                        open: '',
                        displayName: 'Billing Frequency',
                        style: 'display: none;',
                        icon: 'fa fa-circle-o',
                        access: [ROLES.ROLE_ADMIN].join(),
                        url: 'billing-frequency'
                    }, {
                        id: 'bundle',
                        state: '',
                        open: '',
                        displayName: 'Bundle',
                        style: 'display: none;',
                        icon: 'fa fa-circle-o',
                        access: [ROLES.ROLE_ADMIN].join(),
                        url: 'bundle'
                    }, {
                        id: 'composite',
                        state: '',
                        open: '',
                        displayName: 'Composite',
                        style: 'display: none;',
                        icon: 'fa fa-circle-o',
                        access: [ROLES.ROLE_ADMIN].join(),
                        url: 'composite'
                    }
                ]
            },
            {
                id: 'adjust',
                state: '',
                displayName: 'Admin adjust',
                url: null,
                icon: 'fa fa-gear',
                access: [ROLES.ROLE_ADMIN].join(),
                items: [
                    {
                        id: 'patient-result-update-dates',
                        state: 'patient-result-update-dates',
                        open: 'patient-result-update-dates',
                        displayName: 'Patient Result Dates',
                        style: 'display: none;',
                        icon: 'fa fa-circle-o',
                        access: [ROLES.ROLE_ADMIN].join(),
                        url: 'patient-result-update-dates'
                    },
                    {
                        id: 'env-result-update-dates',
                        state: 'env-result-update-dates',
                        open: 'env-result-update-dates',
                        displayName: 'Site Result Dates',
                        style: 'display: none;',
                        icon: 'fa fa-circle-o',
                        access: [ROLES.ROLE_ADMIN].join(),
                        url: 'env-result-update-dates'
                    },
                    {
                        id: 'kinetic-skip',
                        state: 'kinetic-skip',
                        // open: 'env-result-update-dates',
                        displayName: 'Kinetic Skip',
                        style: 'display: none;',
                        icon: 'fa fa-circle-o',
                        access: [ROLES.ROLE_ADMIN].join(),
                        url: 'kinetic-skip'
                    },
                    {
                        id: 'patient-result-delete',
                        state: 'patient-result-delete',
                        displayName: 'Delete Patient Result',
                        style: 'display: none;',
                        icon: 'fa fa-circle-o',
                        access: [ROLES.ROLE_ADMIN].join(),
                        url: 'patient-result-delete'
                    },
                    {
                        id: 'env-result-delete',
                        state: 'env-result-delete',
                        displayName: 'Delete Env Result',
                        style: 'display: none;',
                        icon: 'fa fa-circle-o',
                        access: [ROLES.ROLE_ADMIN].join(),
                        url: 'env-result-delete'
                    }
                ]
            }
        ];

        $rootScope.$on('$stateChangeSuccess',
            function (event, toState, toParams, fromState, fromParams) {
                selectMenuItem($state.current.name);
            });

        selectMenuItem();

        function selectMenuItem() {
            var url = $state.current.name;
            var lessChild = null;
            var parent = null;
            var child = null;
            for (var i = 0; i < vm.sideBarItems.length; i++) {
                if (vm.sideBarItems[i].url == url || (vm.sideBarItems[i].alias != null && vm.sideBarItems[i].alias.indexOf(url) != -1)) {
                    vm.sideBarItems[i].state = 'active';
                    vm.sideBarItems[i].style = 'display: none;';
                } else {
                    vm.sideBarItems[i].state = '';
                    vm.sideBarItems[i].style = 'display: none;';
                    vm.sideBarItems[i].open = '';
                }

                for (var j = 0; j < vm.sideBarItems[i].items.length; j++) {
                    if (vm.sideBarItems[i].items[j].items == null) {
                        if (vm.sideBarItems[i].items[j].url == url || (vm.sideBarItems[i].items[j].alias != null && vm.sideBarItems[i].items[j].alias.indexOf(url) != -1)) {
                            parent = i;
                            child = j;
                        } else {
                            vm.sideBarItems[i].open = '';
                            vm.sideBarItems[i].style = 'display: none;';
                            vm.sideBarItems[i].state = '';
                            vm.sideBarItems[i].items[j].state = '';
                            vm.sideBarItems[i].items[j].icon = "fa fa-circle-o";
                            vm.sideBarItems[i].items[j].active = '';
                        }
                    } else {
                        for (var k = 0; k < vm.sideBarItems[i].items[j].items.length; k++) {
                            if (vm.sideBarItems[i].items[j].items[k].url == url || (vm.sideBarItems[i].items[j].items[k].alias != null && vm.sideBarItems[i].items[j].items[k].alias.indexOf(url) !== -1)) {
                                parent = i;
                                child = j;
                                lessChild = k;
                            } else {
                                vm.sideBarItems[i].open = '';
                                vm.sideBarItems[i].style = 'display: none;';
                                vm.sideBarItems[i].state = '';
                                vm.sideBarItems[i].items[j].open = '';
                                vm.sideBarItems[i].items[j].style = 'display: none;';
                                vm.sideBarItems[i].items[j].state = '';
                                vm.sideBarItems[i].items[j].items[k].state = '';
                                vm.sideBarItems[i].items[j].items[k].icon = "fa fa-circle-o";
                                vm.sideBarItems[i].items[j].items[k].active = '';
                            }
                        }
                    }
                }
            }

            if (parent != null && child != null) {
                if (lessChild == null) {
                    vm.sideBarItems[parent].open = 'menu-open';
                    vm.sideBarItems[parent].style = 'display: block;';
                    vm.sideBarItems[parent].state = 'active';

                    vm.sideBarItems[parent].items[child].state = 'active';
                    vm.sideBarItems[parent].items[child].icon = "fa fa-dot-circle-o";
                    vm.sideBarItems[parent].items[child].active = 'active';
                } else {
                    vm.sideBarItems[parent].open = 'menu-open';
                    vm.sideBarItems[parent].style = 'display: block;';
                    vm.sideBarItems[parent].state = 'active';

                    vm.sideBarItems[parent].items[child].open = 'menu-open';
                    vm.sideBarItems[parent].items[child].style = "display: block;";
                    vm.sideBarItems[parent].items[child].state = 'active';

                    vm.sideBarItems[parent].items[child].items[lessChild].state = 'active';
                    vm.sideBarItems[parent].items[child].items[lessChild].icon = "fa fa-dot-circle-o";
                    vm.sideBarItems[parent].items[child].items[lessChild].active = 'active';
                }
            }
        }

        function loadAllClinics() {
            Clinic.query(function (result) {
                deployClinics(result);
            });
        }

        sortSubMenus();

        $rootScope.$on('dialysisApp:clinicNew', loadAllClinics);

        function changeClinic() {
            CoreService.setCurrentPatient(null);
            CoreService.setCurrentClinic(vm.currentClinic);

            $state.go('home');
        }

        function toggleNavbar() {
            vm.isNavbarCollapsed = !vm.isNavbarCollapsed;
        }

        function collapseNavbar() {
            vm.isNavbarCollapsed = true;
        }

        function toggleControlbar() {
            vm.isControlbarCollapsed = !vm.isControlbarCollapsed;
        }

        function getEmployee() {
            vm.employee = CoreService.getCurrentEmployee();
        }

        function deployClinics(result) {
            vm.clinics = result;
            if (result.length > 0) {
                vm.currentClinic = CoreService.getCurrentClinic();

                if (!vm.currentClinic) {
                    vm.currentClinic = result[0];
                }

                CoreService.setCurrentClinic(vm.currentClinic);
            }
        }

        function sortSubMenus() {
            _.forEach(vm.sideBarItems, function (parentItem) {
                if (parentItem.items && parentItem.items.length > 0) {
                    parentItem.items = _.sortBy(parentItem.items, 'displayName');
                }
            });
        }
    }
})();
