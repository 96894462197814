(function() {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('Dashboard2Controller', Dashboard2Controller);

    Dashboard2Controller.$inject = ['$scope',  'CoreService', 'activeTab'];

    function Dashboard2Controller ($scope,  CoreService, activeTab) {
        var vm = this;

        vm.activeTab = activeTab;
        vm.keepActiveTab = keepActiveTab;

        function keepActiveTab(index) {
            CoreService.setStorage('activeScheduleTab', index);
            vm.activeTab = index;
        }
    }
})();
