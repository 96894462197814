(function() {
    'use strict';

    angular
        .module('dialysisApp')
        .factory('PatientTemplateSearch', PatientTemplateSearch);

    PatientTemplateSearch.$inject = ['$resource'];

    function PatientTemplateSearch($resource) {
        var resourceUrl =  'api/_search/patient-templates/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
