(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('SampleController', SampleController);

    SampleController.$inject = ['$scope', '$state', '$q', 'DTColumnBuilder',
        'DTOptionsBuilder', '$filter', '$compile', 'GUIUtils', '$uibModal'];

    function SampleController($scope, $state, $q, DTColumnBuilder,
                              DTOptionsBuilder, $filter, $compile, GUIUtils, $uibModal) {
        var vm = this;

        vm.title = "Sample Title";
        vm.dummy = [];
        vm.dtInstance = {};
        vm.selected = {};
        vm.searchQuery = "";
        var titleHtml = '<input type="checkbox" ng-model="vm.selectAll" ng-change="vm.toggleAll()">';

        vm.toggleAll = toggleAll;
        vm.toggleOne = toggleOne;
        vm.search = search;
        vm.getSelectedIds = getSelectedIds;
        vm.modal = modal;

        vm.dtOptions = DTOptionsBuilder.fromFnPromise(function () {
            var defer = $q.defer();
            if (!vm.searchQuery || vm.searchQuery == '') {
                vm.dummy = dummy();
                defer.resolve(vm.dummy);
            } else {
                defer.resolve($filter('filter')(vm.dummy, vm.searchQuery, undefined));
            }
            return defer.promise;
        }).withPaginationType('full_numbers')
            .withBootstrap()
            .withDOM('ltp')
            .withLanguage({
                "paginate": {
                    "first": '<i class="fa fa-angle-double-left" aria-hidden="true"></i>',
                    "previous": '<i class="fa fa-arrow-circle-left" aria-hidden="true"></i>',
                    "last": '<i class="fa fa-angle-double-right" aria-hidden="true"></i>',
                    "next": '<i class="fa fa-arrow-circle-right" aria-hidden="true"></i>'
                }
            })
            .withOption('aaSorting', [[0, 'desc'], [1, 'asc']])
            .withOption('headerCallback', function (header) {
                if (!vm.headerCompiled) {
                    vm.headerCompiled = true;
                    $compile(angular.element(header).contents())($scope);
                }
            })
            .withOption('lengthMenu', [[10, 15, 25, 50, -1], [10, 15, 25, 50, "All"]])
            .withOption('fnRowCallback',
                function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                    $compile(nRow)($scope);
                });

        vm.dtColumns = [
            DTColumnBuilder.newColumn(null).withTitle(titleHtml).withOption('width', '25px').notSortable().renderWith(function (data, type, full, meta) {
                vm.selected[full.id] = false;

                return '<input type="checkbox" ng-model="vm.selected[' + data.id + ']" ng-click="vm.toggleOne()">';
            }),
            DTColumnBuilder.newColumn('id').withTitle('ID'),
            DTColumnBuilder.newColumn('name').withTitle('Name'),
            DTColumnBuilder.newColumn(null).withTitle('Date').renderWith(function (data, type, full) {
                return data.date ? moment(data.date).format($scope.displayDateformat) : "";
            }),
            DTColumnBuilder.newColumn(null).withTitle('Link to State').renderWith(function (data, type, full) {
                return data.link ? toStateLink(data) : '';
            }),
            DTColumnBuilder.newColumn('').withTitle('Colors').withOption('class', 'col').withOption('width', '175px').notSortable()
                .renderWith(yesNoHtml),
            DTColumnBuilder.newColumn('').withTitle('Actions').withOption('class', 'col').withOption('width', '175px').notSortable()
                .renderWith(actionsHtml)
        ];

        function yesNoHtml(data, type, full, meta) {
            if (full.status == true) {
                return GUIUtils.getStatusTemplate('TRUE', 'danger');
            } else if (full.status == false){
                return GUIUtils.getStatusTemplate('FALSE', 'success');
            } else {
                return GUIUtils.getStatusTemplate('UNDEFINED', 'warning');
            }
        }

        function actionsHtml(data, type, full, meta) {
            return '<a class="dt-btn warning" ng-click="vm.modal(' + full.id + ')" uib-tooltip="View Details"><i class="fa fa-file-text" aria-hidden="true"></i></a>';
        }

        function toStateLink(row) {
            // return '<a ui-sref="' + "dashboard({barcode:'" + row.barcode + "'})" + '">' + row.barcode + '</a>';
            return '<a ui-sref="home">go to dashboard</a>';
        }

        function dummy() {
            return [
                {id: 1, name: 'Animal.com', status: true, date: new Date(), link: 'dashboard'},
                {id: 2, name: 'LaSombra.com', status: false, date: new Date(), link: null},
                {id: 3, name: 'PepeElGlobero.com', status: null, date: null, link: 'dashboard'},
                {id: 4, name: 'LordOfTheRing.com', status: true, date: new Date(), link: 'dashboard'}
            ]
        }

        function modal() {
            $uibModal.open({
                templateUrl: 'app/entities/sample/sample-dialog.html',
                controller: 'SampleDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                windowTopClass: 'custom-dialog-styles',
                resolve: {

                }
            }).result.then(function () {
                //apply
            }, function (result) {
                //cancel
            });
        }

        function search() {
            vm.dtInstance.reloadData();
        }

        function toggleAll() {
            for (var id in vm.selected) {
                if (vm.selected.hasOwnProperty(id)) {
                    vm.selected[id] = vm.selectAll;
                }
            }
        }

        function toggleOne() {
            for (var id in vm.selected) {
                if (vm.selected.hasOwnProperty(id)) {
                    if (!vm.selected[id]) {
                        vm.selectAll = false;
                        return;
                    }
                }
            }
            vm.selectAll = true;
        }

        function getSelectedIds() {
            var ids = [];
            for (var id in vm.selected) {
                if (vm.selected[id]) {
                    ids.push(id);
                }
            }

            return ids;
        }
    }
})();
