(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('BillingFrequencyController', BillingFrequencyController);

    BillingFrequencyController.$inject = ['$scope', '$state', 'BillingFrequency', 'BillingFrequencySearch',
        'DTOptionsBuilder', 'DTColumnBuilder', '$q', 'GUIUtils'];

    function BillingFrequencyController($scope, $state, BillingFrequency, BillingFrequencySearch,
                                        DTOptionsBuilder, DTColumnBuilder, $q, GUIUtils) {
        var vm = this;

        vm.billingFrequencies = [];
        vm.dtInstance = {};

        vm.search = search;

        vm.dtOptions = DTOptionsBuilder.fromFnPromise(function () {
            var defer = $q.defer();
            if (!vm.searchQuery || vm.searchQuery == '') {
                BillingFrequencySearch.query({
                    query: {
                        bool: {
                            must: [
                                {term: {"delStatus": false}}
                            ]
                        }
                    }
                }, function (result) {
                    vm.billingFrequencies = result;
                    defer.resolve(result);
                });
            } else {
                defer.resolve($filter('filter')(vm.billingFrequencies, vm.searchQuery, undefined));
            }
            return defer.promise;
        }).withPaginationType('full_numbers').withBootstrap().withDOM('tip');

        vm.dtColumns = [
            DTColumnBuilder.newColumn('id').withTitle('ID'),
            DTColumnBuilder.newColumn('name').withTitle('Frequency'),
            DTColumnBuilder.newColumn('days').withTitle('Interval in days'),
            DTColumnBuilder.newColumn(null).withTitle('Actions').withOption('width', '155px').notSortable()
                .renderWith(actionsHtml)
        ];

        function actionsHtml(data, type, full, meta) {
            return GUIUtils.getActionsTemplate(data, $state.current.name, ['update', 'delete']);
        }


        function search() {
            vm.dtInstance.reloadData();
        }
    }
})();
