(function () {
    'use strict';
    angular
        .module('dialysisApp')
        .factory('HotFix', HotFix);

    HotFix.$inject = ['$resource', 'DateUtils'];

    function HotFix($resource, DateUtils) {
        var resourceUrl = 'api/hotfix';

        return $resource(resourceUrl, {}, {
            'patientResultStatus': {'url': 'api/hotfix/patient-results/update', method: 'POST'},

            //mobile
            'clinicDetails': {'url': 'mobile/clinics', method: 'GET', isArray: true},
            'patients': {'url': 'mobile/patients/by-clinic/:id', method: 'GET', isArray: true},
            'pdetail': {'url': 'mobile/patients/detail/:id', method: 'GET'},
            'criticals': {'url': 'mobile/patient-result-dets/by-patient/criticals/:id', method: 'GET', isArray: true},
            '_6M': {'url': 'mobile/patient-result-dets/6M/:id/:detailId', method: 'GET', isArray: true},
            'comments': {'url': 'mobile/patient-result-dets/comments/:id', method: 'GET', isArray: true},
            'acknowledge': {'url': 'mobile/patient-result-dets/acknowledge/:id', method: 'POST'}
        });
    }
})();
