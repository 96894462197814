(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('patient-order-tpl', {
                parent: 'entity',
                url: '/patient-order-tpl',
                ncyBreadcrumb: {
                    label: 'Patient Order Templates'
                },
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_SUPERVISOR', 'ROLE_NURSE', 'ROLE_DOCTOR'],
                    pageTitle: 'Order Templates'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/patient-order-tpl/patient-order-tpl.html',
                        controller: 'PatientOrderTemplateController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {}
            })
            .state('patient-order-tpl.new', {
                url: '/new',
                ncyBreadcrumb: {
                    label: 'New Order Template'
                },
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_SUPERVISOR', 'ROLE_NURSE', 'ROLE_DOCTOR'],
                    pageTitle: 'New Patient Order Template'
                },
                params: {
                    orderType: 'ONE_TIME',
                    clone: false
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/patient-order-tpl/patient-order-tpl-new.html',
                        controller: 'PatientOrderNewTemplateController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    patientOrderTpl: ['$stateParams', function ($stateParams) {
                        return {
                            patientOrderJson: {
                                orderType: $stateParams.orderType,
                                id: null,
                                patientOrderTests: [],
                                ordStatus: 'SCHEDULED'
                            }
                        };
                    }],
                }
            })
            .state('patient-order-tpl.edit', {
                parent: 'patient-order-tpl',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_SUPERVISOR', 'ROLE_NURSE', 'ROLE_DOCTOR'],
                },
                params: {
                    orderType: 'ONE_TIME',
                    clone: false
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/patient-order-tpl/patient-order-tpl-new.html',
                        controller: 'PatientOrderNewTemplateController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    patientOrderTpl: ['$stateParams', 'PatientOrderTpl', function ($stateParams, PatientOrderTpl) {
                        return PatientOrderTpl.get({id: $stateParams.id}).$promise;
                    }]
                }
            })
            .state('patient-order-tpl.delete', {
                parent: 'patient-order-tpl',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_SUPERVISOR', 'ROLE_NURSE', 'ROLE_DOCTOR'],
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/patient-order-tpl/patient-order-tpl-delete-dialog.html',
                        controller: 'PatientOrderTplDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['PatientOrderTpl', function (PatientOrderTpl) {
                                return PatientOrderTpl.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('patient-order-tpl', null, {reload: 'patient-order-tpl'});
                    }, function () {
                        $state.go('^');
                    });
                }]
            });
    }
})();
