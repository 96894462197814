(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('VaccinationDialogController', VaccinationDialogController);

    VaccinationDialogController.$inject = ['$timeout', '$scope', 'vaccination', '$uibModalInstance', 'Vaccination', 'Vaccine', '$state'
    ];

    function VaccinationDialogController($timeout, $scope, vaccination, $uibModalInstance, Vaccination, Vaccine, $state) {
        var vm = this;
        vm.vaccination = vaccination;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.vaccines = Vaccine.query();
        vm.editing = $state.current.name != "vaccination.view";

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            if (vm.vaccination.id !== null) {
                Vaccination.update(vm.vaccination, onSaveSuccess, onSaveError);
            } else {
                Vaccination.save(vm.vaccination, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('dialysisApp:vaccinationUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.completionDate = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
