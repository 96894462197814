(function() {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('PatientResultMissingDataDialogController', PatientResultMissingDataDialogController);

    PatientResultMissingDataDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'Clinic',
        'entity', 'PatientResultMissingData', 'Kinetic'];

    function PatientResultMissingDataDialogController ($timeout, $scope, $stateParams, $uibModalInstance, Clinic,
                                                       entity, PatientResultMissingData, Kinetic) {
        var vm = this;

        vm.kinetic = entity;
        vm.kinetic.kg = true;
        vm.kinetic.hhmm = false;

        vm.clear = clear;
        vm.save = save;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            if (!vm.kinetic.kg) {
                vm.kinetic.preWeight = vm.kinetic.preWeight * 0.453592;
                vm.kinetic.posWeight = vm.kinetic.posWeight * 0.453592;
            }

            if (vm.kinetic.hhmm) {
                vm.kinetic.dialysisLength = moment(vm.kinetic.dialysisLengthHhmm, 'HH:mm').get('hour') * 60;
                vm.kinetic.dialysisLength += moment(vm.kinetic.dialysisLengthHhmm, 'HH:mm').get('minutes');
            }

            vm.isSaving = true;
            Kinetic.save({kinetics: [vm.kinetic]}, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess (result) {
            $scope.$emit('dialysisApp:patientResultMissingDataUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }
    }
})();
