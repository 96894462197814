(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('DashboardAdminAllOrdersScheduleController', DashboardAdminAllOrdersScheduleController);

    DashboardAdminAllOrdersScheduleController.$inject = ['$rootScope', 'date', '$scope', 'hasPrinter', 'EnvOrder',
        'PatientOrder', 'DateUtils', 'CoreService'];

    function DashboardAdminAllOrdersScheduleController($rootScope, date, $scope, hasPrinter, EnvOrder,
                                                  PatientOrder, DateUtils, CoreService) {
        var vm = this;
        vm.clinic = CoreService.getCurrentClinic();

        vm.hasPrinter = hasPrinter;
        vm.patientOrders = 0;
        vm.envOrders = 0;
        vm.dashboard = {
            printedBarcodes: 0,
            unPrintedBarcodes: 0,
            printedWaterLabel: 0,
            unPrintedWaterLabel: 0
        };

        vm.activeTab = 0;

        vm.rmConfig1 = {
            mondayStart: false,
            initState: "month", /* decade || year || month */
            maxState: "decade",
            minState: "month",
            decadeSize: 12,
            monthSize: 42, /* "auto" || fixed nr. (35 or 42) */
            min: new Date("2016-01-01"),
            max: null,
            format: "yyyy-MM-dd",
            markDates: markPatientDates,
            subscribe: 'dialysisApp:dashboardAdminCalendarUpdate1',
            broadcast: 'dialysisApp:dashboardAdminCalendarUpdate2',
            redraw: 'dialysisApp:dashboardAdminRedrawPatientCalendar'
        };

        vm.rmConfig2 = {
            mondayStart: false,
            initState: "month", /* decade || year || month */
            maxState: "decade",
            minState: "month",
            decadeSize: 12,
            monthSize: 42, /* "auto" || fixed nr. (35 or 42) */
            min: new Date("2016-01-01"),
            max: null,
            format: "yyyy-MM-dd",
            markDates: markEnvDates,
            subscribe: 'dialysisApp:dashboardAdminCalendarUpdate2',
            broadcast: 'dialysisApp:dashboardAdminCalendarUpdate1',
            redraw: 'dialysisApp:dashboardAdminRedrawEnvCalendar'
        };

        vm.oDate1 = new Date(date);
        vm.oDate2 = new Date(date);

        //load active tab from storage
        vm.activeTab = CoreService.getStorage('activeScheduleTab');

        vm.select1 = function (oDate) {
            CoreService.setStorage('activeScheduleDate', oDate);
            $rootScope.$broadcast('dialysisApp:dashboardAdminCalendarUpdate2', oDate);
            $rootScope.$broadcast('dialysisApp:dashboardAdminCalendarUpdate', oDate);
        }

        vm.select2 = function (oDate) {
            CoreService.setStorage('activeScheduleDate', oDate);
            $rootScope.$broadcast('dialysisApp:dashboardAdminCalendarUpdate1', oDate);
            $rootScope.$broadcast('dialysisApp:dashboardAdminCalendarUpdate', oDate);
        }

        $scope.$on('dialysisApp:dashboardAdminCalendarUpdate', function (event, result) {
            if (vm.clinic) {
                PatientOrder.not_collected({
                    id: vm.clinic.id,
                    date: DateUtils.convertLocalDateToServer(result)
                }, function (result) {
                    vm.patientOrders = result.value;
                });
            }
        });

        $scope.$on('dialysisApp:dashboardAdminCalendarUpdate', function (event, result) {
            if (vm.clinic) {
                EnvOrder.not_collected({
                    id: vm.clinic.id,
                    date: DateUtils.convertLocalDateToServer(result)
                }, function (result) {
                    vm.envOrders = result.value;
                });
            }
        });

        function markPatientDates(oDate) {
            return vm.clinic ? PatientOrder.markDates({clinicId: vm.clinic.id, date: oDate}) : [];
        }

        function markEnvDates(oDate) {
            return vm.clinic ? EnvOrder.markDates({clinicId: vm.clinic.id, date: oDate}) : [];
        }

        vm.keepActiveTab = keepActiveTab;

        function keepActiveTab(index) {
            //save current tab
            CoreService.setStorage('activeScheduleTab', index);
            vm.activeTab = index;
        }
    }
})();
