(function() {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('LabNotificationController', LabNotificationController);

    LabNotificationController.$inject = ['$scope', '$state', 'LabNotification', '$q', 'moment', '$rootScope',
        'DTColumnBuilder' , 'DTOptionsBuilder', 'GUIUtils', '$filter', '$compile'];

    function LabNotificationController ($scope, $state, LabNotification, $q, moment, $rootScope,
                                        DTColumnBuilder , DTOptionsBuilder, GUIUtils, $filter, $compile) {
        var vm = this;
        vm.labNotification = [];
        vm.search = search;
        vm.loadAll = loadAll;
        vm.entityClassHumanized = 'Lab Notification';
        vm.title = 'Lab Notifications';
        vm.entityStateName = 'lab-notification';

        vm.loadAll = loadAll();

        vm.dtInstance = {};

        vm.dtOptions = DTOptionsBuilder.fromFnPromise(function() {
            var defer = $q.defer();
            if (!vm.searchQuery || vm.searchQuery == '') {
                LabNotification.query( function (result) {
                    vm.labNotification = result;
                    defer.resolve(result);
                });
            }else {
                defer.resolve($filter('filter')(vm.labNotification, vm.searchQuery, undefined));
            }
            return defer.promise;
        }).withPaginationType('full_numbers').withBootstrap().withDOM('tip')
            .withOption('fnRowCallback', function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                $compile(nRow)($scope);
            });

        vm.dtColumns = [
            DTColumnBuilder.newColumn('name').withTitle('Name'),
            DTColumnBuilder.newColumn(null).withTitle('Description').renderWith(function (data, type, full) {
                return data.description && data.description.length > 30 ? data.description.substr(1, 30) + "..." : data.description;
            }),
            DTColumnBuilder.newColumn(null).withTitle('Start Date').renderWith(function (data, type, full) {
                return data.startDate ? moment(data.startDate).format($rootScope.displayDateformat) : "";
            }),
            DTColumnBuilder.newColumn(null).withTitle('End Date').renderWith(function (data, type, full) {
                return data.endDate ? moment(data.endDate).format($rootScope.displayDateformat) : "";
            }),
            DTColumnBuilder.newColumn(null).withTitle('Type').renderWith(function (data) {
                return data.type ? data.type.replace("_", " ") : "";
            }),
            DTColumnBuilder.newColumn(null).withTitle('Active').renderWith(function (data, type, full) {
                return GUIUtils.colorHtmlYesNo(data.active);
            }),
            DTColumnBuilder.newColumn(null).withTitle('Top Priority').renderWith(function (data, type, full) {
                return GUIUtils.colorHtmlYesNo(data.topPriority);
            }),
            DTColumnBuilder.newColumn(null).withTitle('Actions').withOption('width', '155px').notSortable()
                .renderWith(actionsHtml)
        ];

        function actionsHtml(data, type, full, meta){
            return GUIUtils.getActionsTemplate(data, $state.current.name, ['update', 'delete']);
        }


        function loadAll() {
            LabNotification.query(function(result) {
                vm.labNotifications = result;
                vm.searchQuery = null;
            });
        }

        function search () {
            vm.dtInstance.reloadData();
        }
    }
})();

