(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('PatientResultDetController', PatientResultDetController);

    PatientResultDetController.$inject = ['patientResult', '$scope', 'PatientResultDetSearch', '$compile', 'Patient', 'lodash', 'PatientResult',
        '$q', 'DTColumnBuilder', 'DTOptionsBuilder', 'GUIUtils', '$filter', 'PatientResultFile', '$uibModalInstance'];

    function PatientResultDetController(patientResult, $scope, PatientResultDetSearch, $compile, Patient, _, PatientResult,
                                        $q, DTColumnBuilder, DTOptionsBuilder, GUIUtils, $filter, PatientResultFile, $uibModalInstance) {
        var vm = this;

        vm.patientResult = patientResult;
        vm.dtInstance = {};

        vm.pdf = {};

        if (vm.patientResult.patientId) {
            vm.patient = Patient.get({id: vm.patientResult.patientId});
        } else {
            vm.patient = null;
        }

        //TODO get the physician
        vm.physician = vm.patient ? vm.patient.employee : null;
        vm.patientResultDets = [];
        vm.groups = [];
        vm.groupsAll = {name: 'All', value: true};
        vm.levels = {
            all: true,
            abnormals: true,
            criticals: true,
            L: true,
            LL: true,
            N: true,
            H: true,
            HH: true
        };

        vm.search = search;
        vm.clear = clear;
        vm.toggleFilters = toggleFilters;

        vm.dtColumns = [
            DTColumnBuilder.newColumn('testCode').withOption('width', '190px').withTitle('Test Code'),
            DTColumnBuilder.newColumn('testName').withOption('width', '350px').withTitle('Test'),
            DTColumnBuilder.newColumn('rGroup').withOption('width', '250px').withTitle('Group').notVisible(),
            DTColumnBuilder.newColumn(null).withTitle('Result').renderWith(function (data, type, full) {
                if (full.result) {
                    if (full.activeRange) {
                        return decorateResult(full.rangeStatus, full.result);
                    } else {
                        return decorateResult(full.status, full.result);
                    }
                }

                return "";
            }),
            DTColumnBuilder.newColumn(null).withTitle('Ranges').renderWith(function (data, type, full) {
                if (full.activeRange) {
                    return full.range ? full.range : full.cutOff;
                } else {
                    return full.cutOff;
                }
            }),
            DTColumnBuilder.newColumn('units').withTitle('Units')
        ];

        if (vm.patientResult.reviewedStatus == false && vm.patientResult.patientId) {
            PatientResult.markAsReviewed({id: vm.patientResult.id});
        }

        vm.dtOptions = DTOptionsBuilder.fromFnPromise(function () {
            var defer = $q.defer();

            if (vm.patientResultDets.length == 0) {
                PatientResultDetSearch.query({
                    query: {
                        bool: {
                            must: getFilter()
                        }
                    },
                }, function (result) {
                    vm.patientResultDets = result;

                    var groupsIndex = {};

                    vm.patientResultDets.forEach(function (detail) {
                        if (detail.rGroup && groupsIndex[detail.rGroup] == undefined) {
                            groupsIndex[detail.rGroup] = detail.rGroup;
                            vm.groups.push({name: detail.rGroup, value: true});
                        }
                    });

                    defer.resolve(vm.patientResultDets);
                });
            } else {
                //groupsNames actives
                var groupsNames = _.map(_.filter(vm.groups, {'value': true}), function (group) {
                    return group.name;
                });

                //status actives
                var levelsIn = [];
                //status inactives
                var levelsOut = [];

                _.forOwn(vm.levels, function (value, key) {
                    if (['L', 'LL', 'N', 'H', 'HH'].indexOf(key) != -1) {
                        if (value) {
                            levelsIn.push(key);
                        } else {
                            levelsOut.push(key)
                        }
                    }
                });

                var resultsDets = _.filter(vm.patientResultDets, function (det) {
                    var status = det.activeRange ? det.rangeStatus : det.status;

                    return groupsNames.indexOf(det.rGroup) != -1 &&
                        //check if appear a value different of L, LL, N, H, HH like A
                        (levelsIn.indexOf(status) != -1 || levelsOut.indexOf(status) == -1);
                });

                defer.resolve($filter('filter')(resultsDets, vm.searchQuery, undefined));
            }

            return defer.promise;
        }).withPaginationType('full_numbers')
            .withBootstrap()
            .withDOM('ltp')
            .withLanguage({
                "paginate": {
                    "first": '<i class="fa fa-angle-double-left" aria-hidden="true"></i>',
                    "previous": '<i class="fa fa-arrow-circle-left" aria-hidden="true"></i>',
                    "last": '<i class="fa fa-angle-double-right" aria-hidden="true"></i>',
                    "next": '<i class="fa fa-arrow-circle-right" aria-hidden="true"></i>'
                }
            })
            .withOption('aaSorting', [[2, 'asc'], [0, 'asc']])
            .withOption('fnDrawCallback', function (oSettings) {
                var api = this.api();
                var rows = api.rows({page: 'current'}).nodes();
                var last = null;
                api.column(2, {page: 'current'}).data().each(function (group, i) {
                    if (last !== group) {
                        $(rows).eq(i).before(
                            '<tr class="group"><td colspan="6"><strong>' + group + '</strong></td></tr>'
                        );
                        last = group;
                    }
                });
            })
            .withOption('lengthMenu', [[10, 15, 25, 50, -1], [10, 15, 25, 50, "All"]])
            .withOption('fnRowCallback',
                function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                    $compile(nRow)($scope);
                });

        function decorateResult(status, result) {
            switch (status) {
                case 'N': {
                    return result;
                }
                case 'L':
                case 'H': {
                    return GUIUtils.getStatusTemplate(result + ' ' + status, 'primary');
                }
                case 'LL':
                case 'HH': {
                    return GUIUtils.getStatusTemplate(result + ' ' + status, 'danger');
                }
                default: {
                    return '';
                }
            }
        }

        function search() {
            vm.dtInstance.reloadData();
        }

        vm.getPDF = function () {
            PatientResultFile.getPdf(vm.pdf.id);
        };

        function getFilter() {
            var must = [];
            must.push({term: {"delStatus": false}});
            must.push({term: {"patientResultId": vm.patientResult.id}});

            return must;
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function toggleFilters() {
            angular.element('.filters-wrapper').slideToggle()
        }

        vm.groupsCheck = groupsCheck;
        vm.groupCheckAll = groupCheckAll;
        vm.levelAll = levelAll;
        vm.abnormals = abnormals;
        vm.criticals = criticals;
        vm.normal = normal;
        vm.notNormal = notNormal;

        function levelAll() {
            for (var key in vm.levels) {
                vm.levels[key] = vm.levels.all;
            }

            search();
        }

        function abnormals() {
            if (vm.levels.abnormals) {
                for (var key in vm.levels) {
                    switch (key) {
                        case 'abnormals':
                        case 'L':
                        case 'H':
                            vm.levels[key] = true;
                            break;
                        default:
                            vm.levels[key] = false;
                    }
                }
            } else {
                for (var key in vm.levels) {
                    switch (key) {
                        case 'all':
                        case 'criticals':
                        case 'L':
                        case 'H':
                            vm.levels[key] = false;
                            break;
                    }
                }
            }

            search();
        }

        function criticals() {
            if (vm.levels.criticals) {
                for (var key in vm.levels) {
                    switch (key) {
                        case 'criticals':
                        case 'LL':
                        case 'HH':
                            vm.levels[key] = true;
                            break;
                        default:
                            vm.levels[key] = false;
                    }
                }
            } else {
                for (var key in vm.levels) {
                    switch (key) {
                        case 'all':
                        case 'abnormals':
                        case 'LL':
                        case 'HH':
                            vm.levels[key] = false;
                            break;
                    }
                }
            }

            search();
        }

        function normal() {
            if (vm.levels.N) {
                for (var key in vm.levels) {
                    switch (key) {
                        case 'abnormals':
                        case 'criticals':
                            vm.levels[key] = false;
                            break;
                        case 'all':
                            vm.levels[key] = vm.levels.L && vm.levels.LL && vm.levels.H && vm.levels.HH && vm.levels.N;
                            break;
                    }
                }
            } else {
                for (var key in vm.levels) {
                    switch (key) {
                        case 'abnormals':
                            vm.levels[key] = vm.levels.L && vm.levels.LL && vm.levels.H && vm.levels.HH;
                            break;
                        case 'criticals':
                            vm.levels[key] = !vm.levels.L && vm.levels.LL && !vm.levels.H && vm.levels.HH;
                            break;
                        case 'all':
                            vm.levels[key] = false;
                            break;
                    }
                }
            }

            search();
        }

        function notNormal() {
            vm.levels['abnormals'] = vm.levels.L && vm.levels.LL && vm.levels.H && vm.levels.HH && !vm.levels.N;
            vm.levels['criticals'] = !vm.levels.L && vm.levels.LL && !vm.levels.h && vm.levels.hh && !vm.levels.N;
            vm.levels['all'] = vm.levels.L && vm.levels.LL && vm.levels.H && vm.levels.HH && vm.levels.N;

            search();
        }

        function groupsCheck() {
            var all = true;

            vm.groups.forEach(function (group) {
                all = all && group.value;
            });

            vm.groupsAll.value = all;

            search();
        }

        function groupCheckAll() {
            vm.groups.forEach(function (group) {
                group.value = vm.groupsAll.value;
            });

            search();
        }
    }
})();
