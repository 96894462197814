(function() {
    'use strict';

    angular
        .module('dialysisApp')
        .factory('RptLanguageSearch', RptLanguageSearch);

    RptLanguageSearch.$inject = ['$resource'];

    function RptLanguageSearch($resource) {
        var resourceUrl =  'api/_search/rpt-languages/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
