(function() {
    'use strict';
    angular
        .module('dialysisApp')
        .factory('EnvResultFile', EnvResultFile);

    EnvResultFile.$inject = ['$resource', 'DataUtils'];

    function EnvResultFile ($resource, DataUtils) {
        var resourceUrl =  'api/env-result-files/:id';

        return $resource(resourceUrl, {}, {
            'getAllByEnvResult': {
                url: 'api/env-result-files/by-env-result/all/:id',
                method: 'GET',
                isArray: true
            },
            'getPdf': {
                url: 'api/env-result-files/get-pdf',
                method: 'POST',
                responseType: 'arraybuffer',
                transformResponse: function (data, headers) {
                    DataUtils.downloadAndOpenPDFFile(data, headers);
                }
            },
            'getByEnvResult': {
                url: 'api/env-result-files/by-machine-result/getpdf',
                method: 'POST',
                responseType: 'arraybuffer',
                transformResponse: function (data, headers) {
                    DataUtils.downloadAndOpenPDFFile(data, headers);
                }
            },
            'getAllInOnePdf': {
                url: 'api/env-result-files/get-all-in-one-pdf',
                method: 'POST',
                responseType: 'arraybuffer',
                transformResponse: function (data, headers) {
                    DataUtils.downloadAndOpenPDFFile(data, headers);
                }
            }
        });
    }
})();
