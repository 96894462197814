(function() {
    'use strict';

    angular
        .module('dialysisApp')
        .factory('InfectionSearch', InfectionSearch);

    InfectionSearch.$inject = ['$resource'];

    function InfectionSearch($resource) {
        var resourceUrl =  'api/_search/infections/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
