(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('kinetic', {
                parent: 'entity',
                url: '/kinetic',
                data: {
                    authorities: ['ROLE_SUPERVISOR', 'ROLE_NURSE', 'ROLE_DOCTOR'],
                    pageTitle: 'Kinetics'
                },
                ncyBreadcrumb: {
                    label: 'Kinetic',
                    parent: 'clinic'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/kinetic/kinetics.html',
                        controller: 'KineticController',
                        controllerAs: 'vm'
                    }
                }
            })
            .state('kinetic-info', {
                parent: 'kinetic',
                url: '/kinetic-info',
                data: {
                    authorities: ['ROLE_SUPERVISOR', 'ROLE_NURSE', 'ROLE_DOCTOR'],
                    pageTitle: 'Kinetic Info'
                },
                ncyBreadcrumb: {
                    label: 'Kinetic Info',
                },
                params: {
                    patients: null,
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/kinetic/kinetic-info.html',
                        controller: 'KineticInfoController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    patients: ['$stateParams', function ($stateParams) {
                        return $stateParams.patients;
                    }]
                }
            })
            .state('kinetic-sent', {
                parent: 'entity',
                url: '/kinetic-sent',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'Kinetics Sent Monthly'
                },
                ncyBreadcrumb: {
                    label: 'Kinetic Sent Monthly',
                    parent: 'clinic'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/kinetic/kinetic-sent.html',
                        controller: 'KineticSentController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    filterData: [function () {
                        var start = new Date();
                        start.setDate(1);
                        start.setHours(0, 0, 0);

                        var end = new Date(start.getFullYear(), start.getMonth() + 1, 0);

                        return {
                            scheduleStart: null,
                            scheduleEnd: null,
                            sentStart: start,
                            sentEnd: end,
                            firstName: null,
                            lastName: null,
                            dob: null,
                            sent: null,
                            skipped: null,
                            barcode: null,
                            clinic: {},
                        }
                    }],
                }
            })
            .state('kinetic-skip', {
                parent: 'entity',
                url: '/kinetic-skip',
                data: {
                    authorities: ['ROLE_ADMIN'],
                    pageTitle: 'Skip Kinetics'
                },
                ncyBreadcrumb: {
                    label: 'Kinetic Sent Monthly',
                    parent: 'clinic'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/kinetic/kinetic-info-skip.html',
                        controller: 'KineticInfoSkipController',
                        controllerAs: 'vm'
                    }
                }
            })
    }
})();
