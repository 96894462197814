(function() {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('RptCompendiumMessageDetailController', RptCompendiumMessageDetailController);

    RptCompendiumMessageDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'RptCompendiumMessage', 'RptLanguage', 'Compendium', 'Clinic'];

    function RptCompendiumMessageDetailController($scope, $rootScope, $stateParams, previousState, entity, RptCompendiumMessage, RptLanguage, Compendium, Clinic) {
        var vm = this;

        vm.rptCompendiumMessage = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('dialysisApp:rptCompendiumMessageUpdate', function(event, result) {
            vm.rptCompendiumMessage = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
