(function() {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('SiteLocationDetailController', SiteLocationDetailController);

    SiteLocationDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'SiteLocation', 'Machine', 'Clinic'];

    function SiteLocationDetailController($scope, $rootScope, $stateParams, previousState, entity, SiteLocation, Machine, Clinic) {
        var vm = this;

        vm.siteLocation = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('dialysisApp:siteLocationUpdate', function(event, result) {
            vm.siteLocation = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
