(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('RptCompendiumMessageController', RptCompendiumMessageController);

    RptCompendiumMessageController.$inject = ['$scope', '$state', 'RptCompendiumMessage', 'RptCompendiumMessageSearch',
        'CoreService', 'DTOptionsBuilder', 'DTColumnBuilder', '$compile', '$q', 'GUIUtils', '$filter'];

    function RptCompendiumMessageController($scope, $state, RptCompendiumMessage, RptCompendiumMessageSearch,
                                            CoreService, DTOptionsBuilder, DTColumnBuilder, $compile, $q, GUIUtils, $filter) {
        var vm = this;

        vm.rptCompendiumMessages = [];
        vm.clinic = CoreService.getCurrentClinic();
        vm.dtInstance = {};
        vm.title = "Compendium Messages";
        vm.entityClassHumanized = "Compendium Messages";
        vm.filterRes = {};
        vm.selected = {};
        vm.selectAll = false;

        vm.search = search;
        vm.getFilter = getFilter;
        vm.clear = clear;
        vm.showFiltersPanel = showFiltersPanel;

        angular.element('.filter-box').hide();

        function showFiltersPanel() {
            angular.element('.filter-box').toggleClass('active');

            if (angular.element('.filter-box').hasClass('active')) {
                angular.element('.toggle-filters').addClass('open');
                angular.element('.filter-box').slideDown();
            } else {
                angular.element('.toggle-filters').removeClass('open');
                angular.element('.filter-box').slideUp();
            }
        }

        vm.dtOptions = DTOptionsBuilder.fromFnPromise(function () {
            var defer = $q.defer();

            if (!vm.searchQuery || vm.searchQuery == '') {
                RptCompendiumMessageSearch.query(
                    {
                        query: {
                            bool: {
                                must: getFilter()
                            }
                        }
                    }, function (result) {
                        vm.rptCompendiumMessages = result;
                        defer.resolve(result);
                    });
            } else {
                defer.resolve($filter('filter')(vm.rptCompendiumMessages, vm.searchQuery, undefined));
            }

            vm.selected = {};
            vm.selectAll = false;

            return defer.promise;

        }).withBootstrap()
            .withDOM('ltp')
            .withLanguage({
                "paginate": {
                    "first": '<i class="fa fa-angle-double-left" aria-hidden="true"></i>',
                    "previous": '<i class="fa fa-arrow-circle-left" aria-hidden="true"></i>',
                    "last": '<i class="fa fa-angle-double-right" aria-hidden="true"></i>',
                    "next": '<i class="fa fa-arrow-circle-right" aria-hidden="true"></i>'
                }
            })
            .withOption('lengthMenu', [[10, 15, 25, 50, -1], [10, 15, 25, 50, "All"]])
            .withOption('fnRowCallback', function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                $compile(nRow)($scope);
            });

        vm.dtColumns = [
            DTColumnBuilder.newColumn(null).withTitle('Language').renderWith(function (data) {
                return data.rptLanguage ? data.rptLanguage.name : "";
            }),
            DTColumnBuilder.newColumn(null).withTitle('Compendium Code').renderWith(function (data) {
                return data.compendium ? data.compendium.code : "";
            }),
            DTColumnBuilder.newColumn(null).withTitle('Compendium Description').renderWith(function (data) {
                return data.compendium ? data.compendium.description : "";
            }),
            DTColumnBuilder.newColumn(null).withTitle('Low Message').renderWith(function (data) {
                return data.lowMessage && data.lowMessage.length > 30 ? data.lowMessage.substr(1, 30) + "..." : data.lowMessage;
            }),
            DTColumnBuilder.newColumn(null).withTitle('Normal Message').renderWith(function (data) {
                return data.normalMessage && data.normalMessage.length > 30 ? data.normalMessage.substr(1, 30) + "..." : data.normalMessage;
            }),
            DTColumnBuilder.newColumn(null).withTitle('High Message').renderWith(function (data) {
                return data.highMessage && data.highMessage.length > 30 ? data.highMessage.substr(1, 30) + "..." : data.highMessage;
            }),
            DTColumnBuilder.newColumn(null).withTitle('Actions').withOption('class', 'col').withOption('width', '175px').notSortable()
                .renderWith(actionsHtml)
        ];

        function getFilter() {
            var must = [];

            must.push({
                bool: {
                    "should": [
                        {"term": {"clinic.id": vm.clinic.id}},
                    ]
                }
            });

            must.push({term: {"delStatus": false}});

            if (vm.filterRes.name != null && vm.filterRes.name.length > 0) {
                must.push({match: {"rptLanguage.name": {query: vm.filterRes.name + "%"}}});

            }

            if (vm.filterRes.description != null && vm.filterRes.description.length > 0) {
                must.push({wildcard: {"compendium.description": "*" + vm.filterRes.description + "*"}});
            }

            return must;
        }


        function search() {
            vm.dtInstance.reloadData();
        }

        function clear() {
            vm.filterRes ={};
            vm.dtInstance.reloadData();
        }

        function actionsHtml(data, type, full, meta) {
            return GUIUtils.getActionsTemplate(data, $state.current.name, ['all']);
        }
    }
})();
