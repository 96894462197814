(function() {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('RptLanguageController', RptLanguageController);

    RptLanguageController.$inject = ['$scope', '$state', 'RptLanguage', 'RptLanguageSearch', 'DTOptionsBuilder',
    'DTColumnBuilder', '$q', '$compile', 'GUIUtils'];

    function RptLanguageController ($scope, $state, RptLanguage, RptLanguageSearch, DTOptionsBuilder,
                                    DTColumnBuilder, $q, $compile, GUIUtils) {
        var vm = this;

        vm.rptLanguages = [];
        vm.search = search;

        vm.dtInstance = {};
        vm.title = "Languages";
        vm.entityClassHumanized = "Languages";

        vm.dtOptions = DTOptionsBuilder.fromFnPromise(function () {
            var defer = $q.defer();
            if (!vm.searchQuery || vm.searchQuery == '') {
                RptLanguageSearch.query({
                    query: {
                        bool: {
                            must: [
                                {term: {"delStatus": false}}
                            ]
                        }
                    }
                }, function (result) {
                    vm.rptLanguages = result;
                    defer.resolve(result);
                });
            } else {
                defer.resolve($filter('filter')(vm.rptLanguages, vm.searchQuery, undefined));
            }
            return defer.promise;
        }).withBootstrap()
            .withDOM('ltp')
            .withLanguage({
                "paginate": {
                    "first": '<i class="fa fa-angle-double-left" aria-hidden="true"></i>',
                    "previous": '<i class="fa fa-arrow-circle-left" aria-hidden="true"></i>',
                    "last": '<i class="fa fa-angle-double-right" aria-hidden="true"></i>',
                    "next": '<i class="fa fa-arrow-circle-right" aria-hidden="true"></i>'
                }
            })
            .withOption('lengthMenu', [[10, 15, 25, 50, -1], [10, 15, 25, 50, "All"]])
            .withOption('fnRowCallback', function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                $compile(nRow)($scope);
            });

        vm.dtColumns = [
            DTColumnBuilder.newColumn(null).withTitle('Category').renderWith(function (data) {
                return data.name;
            }),
            DTColumnBuilder.newColumn(null).withTitle('Actions').withOption('class', 'col').withOption('width', '175px').notSortable()
                .renderWith(actionsHtml)
        ];

        function search() {
            vm.dtInstance.reloadData();
        }

        function actionsHtml(data, type, full, meta){
            return GUIUtils.getActionsTemplate(data, $state.current.name, ['all']);
        }
    }
})();
