(function() {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('HomeController', HomeController);

    HomeController.$inject = ['CoreService', 'ROLES', '$state'];

    function HomeController (CoreService, ROLES, $state) {
        var vm = this;

        CoreService.initActiveScheduleTabTab();

        if (CoreService.currentHasEmployeeRole(ROLES.ROLE_ENVIRONMENTAL)) {
            $state.go("dashboard-env");
        } else if (CoreService.currentHasEmployeeRole(ROLES.ROLE_ADMIN)) {
            $state.go("dashboard-admin");
        } else if (CoreService.currentHasEmployeeRole(ROLES.ROLE_LAB_OVERVIEW)) {
            $state.go("calendar");
        } else {
            var clinic = CoreService.getCurrentClinic();
            $state.go("dashboard2");

        }
    }
})();
