(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('PatientResultPendingController', PatientResultPendingController);

    PatientResultPendingController.$inject = ['CoreService', 'PatientResult', '$scope', '$compile', '$q', 'PatientBarcode',
        'DTColumnBuilder', 'DTOptionsBuilder', 'moment', '$filter', '$rootScope', '$uibModal', 'lodash', 'PopupService', 'DataTablesService'];

    function PatientResultPendingController(CoreService, PatientResult, $scope, $compile, $q, PatientBarcode,
                                            DTColumnBuilder, DTOptionsBuilder, moment, $filter, $rootScope, $uibModal, _, PopupService, DataTablesService) {
        var vm = this;
        vm.patientResults = [];

        vm.dtInstance = {};
        vm.title = "Pending Results";
        vm.clinic = CoreService.getCurrentClinic();
        vm.selected = {};
        vm.selectAll = false;

        vm.search = search;
        vm.toggleAll = DataTablesService.toggleAll;
        vm.toggleOne = DataTablesService.toggleOne;
        vm.modalPendingResults = modalPendingResults;
        vm.modalDeletePendingResult = modalDeletePendingResult;

        var titleHtml = '<input type="checkbox" ng-model="vm.selectAll" ng-change="vm.toggleAll(vm.selected, vm.selectAll)">';

        vm.dtOptions = DTOptionsBuilder.fromFnPromise(function () {
            var defer = $q.defer();
            if (!vm.searchQuery || vm.searchQuery == '') {
                PatientResult.pending({id: vm.clinic.id}, function (result) {
                    vm.selected = {};
                    vm.selectAll = false;

                    vm.patientResults = result;
                    defer.resolve(result);
                });
            } else {
                defer.resolve($filter('filter')(vm.patientResults, vm.searchQuery, undefined));
            }
            return defer.promise;
        })
            .withPaginationType('full_numbers')
            .withBootstrap()
            .withOption('aaSorting', [])
            .withDOM('ltp')
            .withLanguage({
                "paginate": {
                    "first": '<i class="fa fa-angle-double-left" aria-hidden="true"></i>',
                    "previous": '<i class="fa fa-arrow-circle-left" aria-hidden="true"></i>',
                    "last": '<i class="fa fa-angle-double-right" aria-hidden="true"></i>',
                    "next": '<i class="fa fa-arrow-circle-right" aria-hidden="true"></i>'
                }
            })
            .withOption('headerCallback', function (header) {
                if (!vm.headerCompiled) {
                    vm.headerCompiled = true;
                    $compile(angular.element(header).contents())($scope);
                }
            })
            .withOption('fnRowCallback',
                function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                    $compile(nRow)($scope);
                });

        vm.dtColumns = [
            DTColumnBuilder.newColumn(null).withTitle(titleHtml).notSortable().renderWith(function (data, type, full, meta) {
                vm.selected[full.groupNumberId] = false;

                return '<input type="checkbox" ng-model="vm.selected[' + "'" + full.groupNumberId + "'" + ']" ng-click="vm.toggleOne(vm.selected, vm.selectAll)">';
            }),
            DTColumnBuilder.newColumn(null).withTitle('Patient').renderWith(function (data, type, full) {
                return data.firstName + ' ' + data.middleInitial + ' ' + data.lastName;
            }),
            DTColumnBuilder.newColumn(null).withTitle('DOB').renderWith(function (data, type, full) {
                return data ? moment(data.dob).format($rootScope.displayDateformat) : "";
            }),
            DTColumnBuilder.newColumn('groupNumberId').withTitle('Specimen ID'),
            DTColumnBuilder.newColumn(null).withTitle('Collection Date').renderWith(function (data, type, full) {
                return data ? moment(data.collectedDate).format($rootScope.displayDateformat) : "";
            }),
            DTColumnBuilder.newColumn(null).withTitle('Actions').withOption('width', '90px').withOption('class', 'col-btn').notSortable()
                .renderWith(actionsHtml)
        ];

        function actionsHtml(data, type, full, meta) {
            var stButtons = '';

            stButtons += '<a uib-tooltip="See Details" class="btn btn-default btn-sm details" ng-click="vm.modalPendingResults(' + "'" + full.groupNumberId + "'" + ')">' +
                '   <i class="fa fa-external-link" aria-hidden="true"></i></a>&nbsp;';

            stButtons += '<a uib-tooltip="Cancel Requisition" class="btn btn-default btn-sm delete" ng-click="vm.modalDeletePendingResult(' + "'" + full.groupNumberId + "'" + ')">' +
                '   <i class="fa fa-trash" aria-hidden="true"></i></a>&nbsp;';

            return stButtons;
        }

        function search() {
            vm.dtInstance.reloadData();
        }

        function modalPendingResults(groupNumberId) {
            PopupService.showPatientBarcodePopup(groupNumberId);
        }

        function modalDeletePendingResult(barcodes) {
            if (barcodes) {
                barcodes = [barcodes];
            } else {
                barcodes = DataTablesService.getSelectedIds(vm.selected);
            }

            $uibModal.open({
                templateUrl: 'app/entities/patient-result/dashboard/patient-result-pending-delete-dialog.html',
                controller: 'PatientResultPendingDeleteDialog',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    barcodes: {selected: barcodes}
                }
            }).result.then(function () {
                vm.dtInstance.reloadData();
            }, function (result) {
            });
        }
    }
})();
