(function() {
    'use strict';
    angular
        .module('dialysisApp')
        .factory('Machine', Machine);

    Machine.$inject = ['$resource'];

    function Machine ($resource) {
        var resourceUrl =  'api/machines/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'getAllByClinic': { 'url': 'api/machines/clinic/:id', method: 'GET', isArray: true},
            'get_all_active': { 'url': 'api/machines/clinic/active/:id', method: 'GET', isArray: true},
            'byBarcode': {url: 'api/machines/by-barcode/:barcode', method: 'GET'}
        });
    }
})();
