(function() {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('PatientPictureDetailController', PatientPictureDetailController);

    PatientPictureDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'PatientPicture'];

    function PatientPictureDetailController($scope, $rootScope, $stateParams, previousState, entity, PatientPicture) {
        var vm = this;

        vm.patientPicture = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('dialysisApp:patientPictureUpdate', function(event, result) {
            vm.patientPicture = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
