(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('PatientResultCriticalController', PatientResultCriticalController);

    PatientResultCriticalController.$inject = ['CoreService', 'PatientResult', '$scope', '$compile', '$q',
        'DTColumnBuilder', 'DTOptionsBuilder', 'moment', '$filter', '$rootScope', '$uibModal', 'lodash', 'GUIUtils', 'DataTablesService'];

    function PatientResultCriticalController(CoreService, PatientResult, $scope, $compile, $q,
                                             DTColumnBuilder, DTOptionsBuilder, moment, $filter, $rootScope, $uibModal, _, GUIUtils, DataTablesService) {
        var vm = this;

        vm.patientResults = [];

        vm.dtInstance = {};
        vm.title = "Critical Results";
        vm.clinic = CoreService.getCurrentClinic();
        vm.selected = {};
        vm.selectAll = false;
        vm.acknowledgeFilter = 'Unacknowledged';
        vm.disableTitle = false;

        vm.search = search;
        vm.toggleAll = DataTablesService.toggleAll;
        vm.toggleOne = DataTablesService.toggleOne;
        vm.modalCriticalResultDetails = modalCriticalResultDetails;
        vm.modalCriticalResultChart = modalCriticalResultChart;
        vm.modalCriticalResultCreateDetEmployeeComment = modalCriticalResultCreateDetEmployeeComment;
        vm.acknowledge = acknowledge;
        vm.updateAcknowledgeButtonFilter = updateAcknowledgeButtonFilter;

        var titleHtml = '<input type="checkbox" ng-model="vm.selectAll" ng-change="vm.toggleAll(vm.selected, vm.selectAll)" ng-disabled="vm.disableTitle">';

        vm.dtOptions = DTOptionsBuilder.fromFnPromise(function () {
            var defer = $q.defer();
            if (!vm.searchQuery || vm.searchQuery == '') {
                PatientResult.critical({
                    id: vm.clinic.id,
                    acknowledge: vm.acknowledgeFilter
                }, function (result) {
                    vm.selected = {};
                    vm.selectAll = false;
                    vm.patientResults = result;
                    vm.disableTitle = !enableTitle();
                    defer.resolve(result);
                });
            } else {
                defer.resolve($filter('filter')(vm.patientResults, vm.searchQuery, undefined));
            }
            return defer.promise;
        })
            .withPaginationType('full_numbers')
            .withBootstrap()
            .withOption('aaSorting', [])
            .withDOM('ltp')
            .withLanguage({
                "paginate": {
                    "first": '<i class="fa fa-angle-double-left" aria-hidden="true"></i>',
                    "previous": '<i class="fa fa-arrow-circle-left" aria-hidden="true"></i>',
                    "last": '<i class="fa fa-angle-double-right" aria-hidden="true"></i>',
                    "next": '<i class="fa fa-arrow-circle-right" aria-hidden="true"></i>'
                }
            })
            .withOption('fnRowCallback',
                function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                    $compile(nRow)($scope);
                })
            .withOption('headerCallback', function (header) {
                if (!vm.headerCompiled) {
                    vm.headerCompiled = true;
                    $compile(angular.element(header).contents())($scope);
                }
            });

        vm.dtColumns = [
            DTColumnBuilder.newColumn(null).withTitle(titleHtml).notSortable().renderWith(function (data, type, full, meta) {
                if (!data.isAcknowledge) vm.selected[full.id] = false;

                return '<input type="checkbox" ng-model="vm.selected[' + data.id + ']" ng-click="vm.toggleOne(vm.selected, vm.selectAll)" ng-disabled=' + data.isAcknowledge + '>';
            }),
            DTColumnBuilder.newColumn('patientName').withTitle('Patient'),
            DTColumnBuilder.newColumn(null).withTitle('DOB').renderWith(function (data, type, full) {
                return data ? moment(data.dob).format($rootScope.displayDateformat) : "";
            }),
            DTColumnBuilder.newColumn('barcode').withTitle('Specimen ID'),
            DTColumnBuilder.newColumn(null).withTitle('Collection Date').renderWith(function (data, type, full) {
                return data ? moment(data.collectionDate).format($rootScope.displayDateformat) : "";
            }),
            DTColumnBuilder.newColumn('testName').withTitle('Test Name'),
            DTColumnBuilder.newColumn(null).withTitle('Result').renderWith(function (data, type, full) {
                return GUIUtils.getStatusTemplate(data.result + ' ' + data.status, 'danger');
            }),
            DTColumnBuilder.newColumn(null).withTitle('Status').renderWith(statusHtml),
            DTColumnBuilder.newColumn(null).withTitle('Actions').withOption('class', 'col-btn').withOption('width', '90px').notSortable()
                .renderWith(actionsHtml)
        ];

        function statusHtml(data, type, full, meta) {
            if (!full.isAcknowledge) {
                return GUIUtils.getStatusTemplate("Unacknowledged", 'danger');
            } else {
                return GUIUtils.getStatusTemplate("Acknowledge", 'success');
            }
        }

        function actionsHtml(data, type, full, meta) {
            var stButtons = '';

            stButtons += '<a uib-tooltip="See Graph" class="btn btn-default btn-sm see-chart" ng-click="vm.modalCriticalResultChart(' + full.id + ')">' +
                '   <i class="fa fa-bar-chart" aria-hidden="true"></i></a>&nbsp;';

            stButtons += '<a uib-tooltip="See Details" class="btn btn-default btn-sm details" ng-click="vm.modalCriticalResultDetails(' + full.id + ')">' +
                '   <i class="fa fa-external-link" aria-hidden="true"></i></a>&nbsp;';

            stButtons += '<a uib-tooltip="Add Comment" class="btn btn-default btn-sm" ng-click="vm.modalCriticalResultCreateDetEmployeeComment(' + full.id + ')">' +
                '   <i class="fa fa-plus" aria-hidden="true"></i></a>&nbsp;';

            return stButtons;
        }

        function search() {
            vm.dtInstance.reloadData();
        }

        function acknowledge() {
            var criticalResults = DataTablesService.getSelectedIds(vm.selected);

            if (criticalResults.length > 0) {
                vm.isSaving = true;
                PatientResult.acknowledge({ids: criticalResults}, function () {
                    vm.dtInstance.reloadData();
                    vm.isSaving = false;
                });
            }
        }

        function updateAcknowledgeButtonFilter(acknowledge) {
            vm.acknowledgeFilter = acknowledge;

            vm.dtInstance.reloadData();
        }

        function modalCriticalResultDetails(id) {
            var criticalResult = _.filter(vm.patientResults, {'id': id});

            $uibModal.open({
                templateUrl: 'app/entities/patient-result/dashboard/patient-result-critical-dialog.html',
                controller: 'PatientResultCriticalDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    result: criticalResult[0]
                }
            }).result.then(function () {
                vm.dtInstance.reloadData();
            }, function (result) {
                vm.dtInstance.reloadData();
            });
        }

        function modalCriticalResultChart(id) {
            var criticalResult = _.filter(vm.patientResults, {'id': id});

            $uibModal.open({
                templateUrl: 'app/entities/patient-result/dashboard/patient-result-critical-chart-dialog.html',
                controller: 'PatientResultCriticalChartDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    result: criticalResult[0]
                }
            }).result.then(function () {
                vm.dtInstance.reloadData();
            }, function (result) {
            });
        }

        function modalCriticalResultCreateDetEmployeeComment(id) {
            $uibModal.open({
                templateUrl: 'app/entities/pr-det-employee-comment/pr-det-employee-comment-dialog.html',
                controller: 'PRDetEmployeeCommentDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                windowTopClass: 'custom-dialog-styles',
                resolve: {
                    prDetEmployeeComment: {
                        comment: null,
                        patientResultDetId: id,
                        employeeId: null,
                        id: null
                    }
                }
            }).result.then(function () {
            }, function (result) {
            });
        }

        function enableTitle() {
            return _.some(vm.patientResults, function (result) {
                return result.isAcknowledge == false;
            });
        }
    }
})();
