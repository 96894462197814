(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('PatientOrderTestTplNewDialogController', PatientOrderTestTplNewDialogController);

    PatientOrderTestTplNewDialogController.$inject = ['CompendiumSearch', 'DiagnosisSearch', 'OrderFrequency', 'patientOrder',
        'patientOrderTest', '$uibModalInstance'];

    function PatientOrderTestTplNewDialogController(CompendiumSearch, DiagnosisSearch, OrderFrequency, patientOrder,
                                                    patientOrderTest, $uibModalInstance) {
        var vm = this;

        vm.patientOrder = patientOrder;
        vm.patientOrderTest = patientOrderTest;

        vm.form = {};
        vm.formProblem = {};

        vm.clear = function () {
            $uibModalInstance.dismiss('cancel');
        }

        vm.orderfrequencies = [];

        vm.compendiums = [];

        vm.getCompendium = getCompendium;

        function getCompendium(query) {
            if (query && query.length > 2) {
                CompendiumSearch.query({query: query}, function (result) {
                    vm.compendiums = result;
                });
            }
        }

        vm.diagnoses = [];

        vm.getDiagnoses = getDiagnoses;

        function getDiagnoses(query) {
            if (query && query.length > 2) {
                DiagnosisSearch.query({query: query}, function (result) {
                    vm.diagnoses = result;
                });
            }
        }

        changeOrderType();
        vm.changeOrderType = changeOrderType;

        function changeOrderType() {
            vm.orderfrequencies = OrderFrequency.byordertype({orderType: vm.patientOrder.orderType}).$promise.then(function (data) {
                vm.orderfrequencies = data;
            });
        }

        vm.accept = function () {
            $uibModalInstance.close({patientOrderTest: vm.patientOrderTest});
        }

        vm.addProblemDiagnosis = function (index) {
            var i = vm.patientOrderTest.diagnoses.indexOf(vm.problems[index].diagnosis);

            if (vm.problemsChk[index].in) {
                if (i == -1) {
                    vm.patientOrderTest.diagnoses.push(vm.problems[index].diagnosis);
                }
            } else {
                if (i > -1) {
                    vm.patientOrderTest.diagnoses.splice(i, 1);
                }
            }

            vm.change();
        }
    }
})();
