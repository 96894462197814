(function () {
    'use strict';
    // DO NOT EDIT THIS FILE, EDIT THE GULP TASK NGCONSTANT SETTINGS INSTEAD WHICH GENERATES THIS FILE
    angular
        .module('dialysisApp')
        .constant('VERSION', "0.0.1-SNAPSHOT")
        .constant('DEBUG_INFO_ENABLED', false)
        .constant('ROLES', {
	"ROLE_ADMIN": "ROLE_ADMIN",
	"ROLE_SUPERVISOR": "ROLE_SUPERVISOR",
	"ROLE_USER": "ROLE_USER",
	"ROLE_DOCTOR": "ROLE_DOCTOR",
	"ROLE_ENVIRONMENTAL": "ROLE_ENVIRONMENTAL",
	"ROLE_NURSE": "ROLE_NURSE",
	"ROLE_LAB_OVERVIEW": "ROLE_LAB_OVERVIEW"
})
        .constant('EMPLOYEE_TYPE', {
	"PHYSICIAN": "PHYSICIAN",
	"NURSER": "NURSER",
	"OTHER": "OTHER"
})
        .constant('APP', {
	"APP_NAME": "Dialysis",
	"APP_SUFFIX_NAME": "",
	"APP_SHORT_NAME": "D"
})
        .constant('IDLE', {
	"idle": 600,
	"timeout": 30
})
;
})();
