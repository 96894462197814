(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('MachineBarcodeDetailController', MachineBarcodeDetailController);

    MachineBarcodeDetailController.$inject = ['moment', 'machine', 'EnvTest', 'barcode', 'envResult',
        '$compile', '$scope', '$q', '$uibModal', 'DTOptionsBuilder', 'DTColumnBuilder'
    ];

    function MachineBarcodeDetailController(moment, machine, EnvTest, barcode, envResult,
                                              $compile, $scope, $q, $uibModal, DTOptionsBuilder, DTColumnBuilder) {
        var vm = this;

        vm.machine = machine;
        vm.barcode = barcode;
        vm.envResult = envResult;

        vm.dtOptions = DTOptionsBuilder.fromFnPromise(function() {
            var defer = $q.defer();
            EnvTest.findAllByBarcode({barcode: vm.barcode.id}, function(result) {
                defer.resolve(result);
            });

            return defer.promise;
        }).withPaginationType('full_numbers').withBootstrap().withDOM('tip')
            .withOption('paging', false).withOption('aaSorting', []).withOption('fnRowCallback',
                function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                    $compile(nRow)($scope);
                });

        vm.dtColumns = [
            DTColumnBuilder.newColumn(null).withTitle('Test').renderWith(function (data, type, full) {
                return data.compendium.code;
            }),
            DTColumnBuilder.newColumn(null).withTitle('Description').renderWith(function (data, type, full) {
                return data.compendium.description;
            }),
            DTColumnBuilder.newColumn(null).withTitle('Frequency').renderWith(function (data, type, full) {
                return data.orderFrequency.name;
            }),
            DTColumnBuilder.newColumn(null).withTitle('Start Date').renderWith(function (data, type, full) {
                return moment(data.staringDate).format("MM/DD/YYYY");
            }),
            DTColumnBuilder.newColumn(null).withTitle('End Date').renderWith(function (data, type, full) {
                return data.endDate? moment(data.endDate).format("MM/DD/YYYY") : moment(data.staringDate).format("MM/DD/YYYY");
            })
        ];

        function showICD10Html(row) {
            return '&nbsp;&nbsp;<a class="btn-sm btn-info" ng-click="vm.showICD10(' + row + ')">' +
                '   <i class="fa fa-list-ol"></i></a>';
        }

        vm.showICD10 = function (index) {
            $uibModal.open({
                templateUrl: 'app/entities/diagnosis/diagnosis-dialog.html',
                controller: 'DiagnosisDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    entity: vm.patientOrderTests[index]
                }
            });
        }
    }
})();
