(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('KineticInfoSkipController', KineticInfoSkipController);

    KineticInfoSkipController.$inject = ['Kinetic', 'dialogs'];

    function KineticInfoSkipController(Kinetic, $dialogs) {
        var vm = this;

        vm.barcode = "";

        vm.skip = function() {
            var dlg = $dialogs.confirm('Please Confirm','Do you want to skip this kinetics?', {size: 'md'});

            dlg.result.then(function(btn){
                Kinetic.skip({barcode: vm.barcode}, function () {
                    vm.barcode = "";
                    alert("Success!")
                });
            },function(btn){
            });
        }
    }
})();
