(function() {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('RptMetadataDeleteController',RptMetadataDeleteController);

    RptMetadataDeleteController.$inject = ['$uibModalInstance', 'entity', 'RptMetadata'];

    function RptMetadataDeleteController($uibModalInstance, entity, RptMetadata) {
        var vm = this;

        vm.rptMetadata = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            RptMetadata.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
