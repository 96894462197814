(function() {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('ClinicAdminViewController', ClinicAdminViewController);

    ClinicAdminViewController.$inject = ['$uibModal'];

    function ClinicAdminViewController($uibModal) {
        var vm = this;

        vm.title = 'Clinic Admin View';
        vm.entityClassHumanized = 'Clinic Admin View';
        vm.entityStateName = 'clinic-admin-view';
        vm.del = del;
        vm.cancelWizard = cancelWizard;

        vm.secondBilling = false;
        vm.companyBilling = false;

        vm.toggleSecondBilling = toggleSecondBilling;
        vm.toggleCompanyBilling = toggleCompanyBilling;

        function toggleSecondBilling() {
            if (vm.secondBilling) {
                // Clear all associated models ...
            }
            vm.secondBilling = !vm.secondBilling;
        }

        function toggleCompanyBilling() {
            if (vm.companyBilling) {
                // Clear all associated models ...
            }
            vm.companyBilling = !vm.companyBilling;
        }

        function del() {
            $uibModal.open({
                templateUrl: 'app/entities/clinic/dashboard2/clinic-admin-delete-dialog.html',
                controller: 'ClinicAdminDeleteDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                windowTopClass: 'custom-dialog-styles',
                resolve: {

                }
            }).result.then(function () {

            }, function () {

            });
        }

        function cancelWizard() {
            $uibModal.open({
                templateUrl: 'app/entities/clinic/dashboard2/clinic-admin-cancel-wizard-dialog.html',
                controller: 'ClinicAdminCancelWizardDialog',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                windowTopClass: 'custom-dialog-styles',
                resolve: {

                }
            }).result.then(function () {
                //apply
            }, function (result) {
                //cancel
            });
        }
    }
})();
