(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('PatientOrderCurrentController', PatientOrderCurrentController);

    PatientOrderCurrentController.$inject = ['$state'];

    function PatientOrderCurrentController($state) {
        var vm = this;

        vm.activeTab = $state.params.orderType == 'ONE_TIME' ? 0 : $state.params.orderType == 'STANDING' ? 2 : 1;
    }
})();
