(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .factory('CoreService', CoreService);

    CoreService.$inject = ['$sessionStorage', 'Base64', '$rootScope'];

    function CoreService($sessionStorage, Base64, $rootScope) {

        var service = {
            setCurrentClinic: setCurrentClinic,
            getCurrentClinic: getCurrentClinic,
            setCurrentEmployee: setCurrentEmployee,
            getCurrentEmployee: getCurrentEmployee,
            setCurrentPatient: setCurrentPatient,
            getCurrentPatient: getCurrentPatient,
            getAvatar: getAvatar,
            clearSession: clearSession,
            currentHasEmployeeRole: currentHasEmployeeRole,
            setCurrentMachine: setCurrentMachine,
            getCurrentMachine: getCurrentMachine,
            removeCurrentMachine: removeCurrentMachine,
            setStorage: setStorage,
            getStorage: getStorage,
            initActiveScheduleTabTab: initActiveScheduleTabTab,
            toggleAll: toggleAll,
            toggleOne: toggleOne,
            getSelectedIds: getSelectedIds,
            readPatientResultFilter: readPatientResultFilter,
            initPatientResultFilter: initPatientResultFilter,
            readPatientResultFromLocalStore: readPatientResultFromLocalStore,
            initEnvResultFilter: initEnvResultFilter,
            readEnvResultFilter: readEnvResultFilter,
            readEnvResultFromLocalStore: readEnvResultFromLocalStore,
            getActiveScheduleDateEnv: getActiveScheduleDateEnv,
            getActiveScheduleDate: getActiveScheduleDate,
            readEmrIntegrationLogFilter: readEmrIntegrationLogFilter,
            initEmrIntegrationLogFilter: initEmrIntegrationLogFilter,
            readEmrIntegrationLogFromLocalStore: readEmrIntegrationLogFromLocalStore
        };

        return service;

        /**
         * Set a clinic to the session storage
         * @param clinic
         */
        function setCurrentClinic(clinic) {
            if (clinic) {
                $sessionStorage.selectedClinic = Base64.encode(JSON.stringify(clinic));
                $rootScope.$broadcast('dialysisApp:setCurrentClinic', clinic);
            }
        }

        /**
         * Get current clinic from the session storage
         */
        function getCurrentClinic() {
            if ($sessionStorage.selectedClinic) {
                return JSON.parse(Base64.decode($sessionStorage.selectedClinic));
            }

            return null;
        }

        /**
         * Set a employee to the session storage
         * @param employee
         */
        function setCurrentEmployee(currentEmployee) {
            if (currentEmployee) {
                $sessionStorage.currentEmployee = Base64.encode(JSON.stringify(currentEmployee));
            }
        }

        /**
         * Get current employee from the session storage
         */
        function getCurrentEmployee() {
            if ($sessionStorage.currentEmployee) {
                return JSON.parse(Base64.decode($sessionStorage.currentEmployee));
            }

            return null;
        }

        /**
         * Get random avatar by gender
         */
        function getAvatar(gender) {

            var maleAvatar = [
                'content/adminLTE/img/avatar.png',
                'content/adminLTE/img/avatar4.png',
                'content/adminLTE/img/avatar5.png'
            ];

            var femaleAvatar = [
                'content/adminLTE/img/avatar2.png',
                'content/adminLTE/img/avatar3.png'
            ];

            var ramdom = 0;
            if (gender == 'MALE') {
                ramdom = Math.floor((Math.random() * 3));
                return maleAvatar[ramdom];
            }
            else {
                ramdom = Math.floor((Math.random() * 2));
                return femaleAvatar[ramdom];
            }
        }

        /**
         * Clear the session storage
         */
        function clearSession() {
            $sessionStorage.$reset();
        }

        function setCurrentPatient(patient) {
            if (patient) {
                $sessionStorage.patient = Base64.encode(JSON.stringify(patient));
            } else {
                $sessionStorage.patient = null;
            }
        }

        function getCurrentPatient() {
            if ($sessionStorage.patient) {
                return JSON.parse(Base64.decode($sessionStorage.patient));
            }

            return null;
        }

        function setCurrentMachine(machine) {
            if (machine) {
                $sessionStorage.machine = Base64.encode(JSON.stringify(machine));
            } else {
                $sessionStorage.machine = null;
            }

        }

        function getCurrentMachine() {
            if ($sessionStorage.machine) {
                return JSON.parse(Base64.decode($sessionStorage.machine));
            }

            return null;
        }

        function removeCurrentMachine() {
            $sessionStorage.machine = null;
        }

        function currentHasEmployeeRole(role) {
            var employee = getCurrentEmployee();

            if (employee == null) {
                return false;
            }

            return $.grep(getCurrentEmployee().user.authorities, function (authority, i) {
                return authority.name == role;
            }).length > 0;
        }

        function setStorage(attr, value) {
            $sessionStorage[attr] = value;
        }

        function getStorage(attr) {
            return $sessionStorage[attr];
        }

        function initActiveScheduleTabTab() {
            if ($sessionStorage["activeScheduleTab"] === undefined) {
                $sessionStorage["activeScheduleTab"] = 0;
            }

            if ($sessionStorage["activeScheduleDate"] === undefined) {
                $sessionStorage["activeScheduleDate"] = new Date();
            }
        }

        function getActiveScheduleDateEnv() {
            if ($sessionStorage["activeScheduleDateEnv"] === undefined) {
                return $sessionStorage["activeScheduleDateEnv"] = new Date();
            }

            return $sessionStorage["activeScheduleDateEnv"];
        }

        function getActiveScheduleDate() {
            if ($sessionStorage["activeScheduleDate"] === undefined) {
                return $sessionStorage["activeScheduleDate"] = new Date();
            }

            return $sessionStorage["activeScheduleDate"];
        }

        function toggleAll(selected, selectAll) {
            for (var id in selected) {
                if (selected.hasOwnProperty(id)) {
                    selected[id] = selectAll;
                }
            }
        }

        function toggleOne(selected, selectAll) {
            for (var id in selected) {
                if (selected.hasOwnProperty(id)) {
                    if (!selected[id]) {
                        selectAll = false;
                        return;
                    }
                }
            }
            selectAll = true;
        }

        function getSelectedIds(selected) {
            var ids = [];

            for (var id in selected) {
                if (selected[id]) {
                    ids.push(id);
                }
            }

            return ids;
        }

        function readPatientResultFilter(paramsfilter) {
            var filter = {
                reviewedStatus: paramsfilter.reviewedStatus,
                patientName: paramsfilter.patientName,
                start: paramsfilter.start,
                end: paramsfilter.end,
                abnormal: paramsfilter.abnormal,
                status: paramsfilter.status,
                dob: paramsfilter.dob,
                barcode: paramsfilter.barcode,
                shift: paramsfilter.shift,
                shiftImpl: paramsfilter.shiftImpl == null ? -1 : paramsfilter.shiftImpl,
                page: paramsfilter.page == null ? 1 : paramsfilter.page
            }

            return filter;
        }

        function readPatientResultFromLocalStore(filterLS) {
            if (filterLS == undefined) {
                filterLS = CoreService.getStorage('filterPatient');
            }

            var filter = {
                reviewedStatus: filterLS.reviewedStatus,
                patientName: filterLS.patientName,
                start: isNaN(Date.parse(filterLS.start)) ? filterLS.start : Date.parse(filterLS.start),
                end: isNaN(Date.parse(filterLS.end)) ? filterLS.end : Date.parse(filterLS.end),
                abnormal: filterLS.abnormal,
                status: filterLS.status,
                dob: isNaN(Date.parse(filterLS.dob)) ? filterLS.dob : Date.parse(filterLS.dob),
                barcode: filterLS.barcode,
                shift: filterLS.shift,
                shiftImpl: filterLS.shiftImpl,
                page: filterLS.page ? filterLS.page : 1
            }

            return filter;
        }

        function initPatientResultFilter() {
            var endDate = new Date();
            var startDate = new Date();
            startDate.setDate(endDate.getDate() - 30);

            return {
                reviewedStatus: null,
                patientName: null,
                start: startDate,
                end: endDate,
                abnormal: null,
                status: null,
                dob: null,
                barcode: null,
                shift: null,
                shiftImpl: -1,
                page: 1
            }
        }

        function initEnvResultFilter() {
            var endDate = new Date();
            var startDate = new Date();
            startDate.setDate(endDate.getDate() - 30);

            return {
                name: null,
                start: startDate,
                end: endDate,
                abnormal: null,
                status: null,
                barcode: null,
                reviewedStatus: null
            };
        }

        function readEnvResultFilter(paramsfilter) {
            var filter = {
                name: paramsfilter.name,
                start: paramsfilter.startDate,
                end: paramsfilter.endDate,
                abnormal: paramsfilter.abnormal,
                status: paramsfilter.status,
                barcode: paramsfilter.barcode,
                reviewedStatus: paramsfilter.reviewedStatus
            };

            return filter;
        }

        function readEnvResultFromLocalStore(filterLS) {
            if (filterLS == undefined) {
                filterLS = CoreService.getStorage('filterEnvironmental');
            }

            var filter = {
                name: filterLS.name,
                start: isNaN(Date.parse(filterLS.start)) ? filterLS.start : Date.parse(filterLS.start),
                end: isNaN(Date.parse(filterLS.end)) ? filterLS.end : Date.parse(filterLS.end),
                abnormal: filterLS.abnormal,
                status: filterLS.status,
                barcode: filterLS.barcode,
                reviewedStatus: filterLS.reviewedStatus
            }

            return filter;
        }


        function readEmrIntegrationLogFilter(paramsfilter) {
            var filter = {
                start: paramsfilter.start,
                end: paramsfilter.end,
            }

            return filter;
        }

        function initEmrIntegrationLogFilter() {
            var endDate = new Date();
            var startDate = new Date();
            startDate.setDate(endDate.getDate() - 30);

            return {
                start: startDate,
                end: endDate,

            }
        }

        function readEmrIntegrationLogFromLocalStore(filterLS) {
            if (filterLS == undefined) {
                filterLS = CoreService.getStorage('filterEmrIntegrationLog');
            }

            var filter = {
                start: isNaN(Date.parse(filterLS.start)) ? filterLS.start : Date.parse(filterLS.start),
                end: isNaN(Date.parse(filterLS.end)) ? filterLS.end : Date.parse(filterLS.end),
            }

            return filter;
        }
    }
})();
