(function() {
    'use strict';

    angular
        .module('dialysisApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('bundle', {
            parent: 'entity',
            url: '/bundle',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'Bundles'
            },
            ncyBreadcrumb: {
                label: 'Bundle',
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/bundle/bundles.html',
                    controller: 'BundleController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
            }
        })
        .state('bundle-detail', {
            parent: 'entity',
            url: '/bundle/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'Bundle'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/bundle/bundle-detail.html',
                    controller: 'BundleDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: ['$stateParams', 'Bundle', function($stateParams, Bundle) {
                    return Bundle.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'bundle',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('bundle-detail.edit', {
            parent: 'bundle-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/bundle/bundle-dialog.html',
                    controller: 'BundleDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Bundle', function(Bundle) {
                            return Bundle.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('bundle.new', {
            parent: 'bundle',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/bundle/bundle-dialog.html',
                    controller: 'BundleDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                dialysisModality: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('bundle', null, { reload: 'bundle' });
                }, function() {
                    $state.go('bundle');
                });
            }]
        })
        .state('bundle.edit', {
            parent: 'bundle',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/bundle/bundle-dialog.html',
                    controller: 'BundleDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Bundle', function(Bundle) {
                            return Bundle.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('bundle', null, { reload: 'bundle' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('bundle.delete', {
            parent: 'bundle',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/bundle/bundle-delete-dialog.html',
                    controller: 'BundleDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Bundle', function(Bundle) {
                            return Bundle.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('bundle', null, { reload: 'bundle' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
