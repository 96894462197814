(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('pr-emr-integration-log', {
                parent: 'entity',
                url: '/pr-emr-integration-log',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_SUPERVISOR'],
                    pageTitle: 'Emr Integration Log'
                },
                ncyBreadcrumb: {
                    label: 'Emr Integration Log',
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/pr-emr-integration-log/pr-emr-integration-log.html',
                        controller: 'PrEmrIntegrationLogController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    filter: null
                },
                resolve: {
                    clinic: ['CoreService', function (CoreService) {
                        return CoreService.getCurrentClinic();
                    }],
                    filterRes: ['$stateParams', 'CoreService', function ($stateParams, CoreService) {
                        if ($stateParams.filter) {
                            return CoreService.readEmrIntegrationLogFilter($stateParams.filter)
                        } else {
                            var filterEmrIntegrationLog = CoreService.getStorage('filterEmrIntegrationLog');

                            if (filterEmrIntegrationLog == undefined) {
                                return CoreService.initEmrIntegrationLogFilter();
                            } else {
                                return CoreService.readEmrIntegrationLogFromLocalStore(filterEmrIntegrationLog);
                            }
                        }
                    }]
                }
            })
    }
})();
