(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('PatientResultTrendingController', PatientResultTrendingController);

    PatientResultTrendingController.$inject = ['entity', 'PatientResultDet', 'DateUtils', 'OdsReportsService'];

    function PatientResultTrendingController(entity, PatientResultDet, DateUtils, OdsReportsService) {
        var vm = this;

        vm.patient = entity;
        vm.compendiums = [];
        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.endDate = false;
        vm.datePickerOpenStatus.staringDate = false;
        vm.trending = {
            staringDate: new Date('2016-01-01'),
            endDate: new Date(),
            compendium: null
        };
        vm.trendings = [];
        vm.trendings2 = [];
        vm.id = 0;

        vm.options = {
            chart: {
                type: 'lineChart',
                height: 550,
                margin: {
                    top: 100,
                    right: 40,
                    bottom: 110,
                    left: 100
                },
                x: function (d) {
                    return d ? d[0] : null;
                },
                y: function (d) {
                    return d ? d[1] : null;
                },
                // average: function (d) {
                //     return d.mean / 100;
                // },

                color: d3.scale.category10().range(),
                duration: 300,
                useInteractiveGuideline: true,
                // clipVoronoi: false,

                xAxis: {
                    axisLabel: 'Time Line',
                    tickFormat: function (d) {
                        return d3.time.format('%m/%d/%y')(new Date(d))
                    },
                    showMaxMin: false,
                    staggerLabels: true
                },

                yAxis: {
                    axisLabel: 'Test Result Value',
                    // tickFormat: function (d) {
                    //     return d3.format(',.1%')(d);
                    // },
                    axisLabelDistance: 20,
                    // showMaxMin: false,
                }
            }
        };

        vm.data = [];

        vm.openCalendar = openCalendar;
        vm.getCompendium = getCompendium;
        vm.getPDF = getPDF;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        vm.getCompendium();

        function getCompendium() {
            if (vm.trending.staringDate && vm.trending.endDate) {
                vm.compendiums = PatientResultDet.compendium(
                    {
                        id: vm.patient.id,
                        start: DateUtils.convertLocalDateToServer(vm.trending.staringDate),
                        end: DateUtils.convertLocalDateToServer(vm.trending.endDate)
                    }, function (result) {
                        vm.trending.compendium = null;
                        vm.compendiums = result;
                    });
            }
        }

        vm.getResults = function () {
            PatientResultDet.trending({
                id: vm.patient.id,
                start: DateUtils.convertLocalDateToServer(vm.trending.staringDate),
                end: DateUtils.convertLocalDateToServer(vm.trending.endDate),
                testcode: vm.trending.compendium.code
            }, function (results) {
                vm.trendings.push({
                    id: vm.id,
                    start: DateUtils.convertLocalDateToServer(vm.trending.staringDate),
                    end: DateUtils.convertLocalDateToServer(vm.trending.endDate),
                    testcode: vm.trending.compendium.code,
                    description: vm.trending.compendium.description
                });

                vm.trendings2.push(vm.trendings[vm.trendings.length - 1]);

                var resultsDetData = {
                    id: vm.id,
                    key: vm.trending.compendium.code + " " + vm.trending.compendium.description + "(" + results.length + ")",
                    values: [],
                    mean: 250
                };

                for (var i = 0; i < results.length; i++) {
                    resultsDetData.values.push([new Date(results[i].createdDate).getTime(), parseInt(results[i].result)]);
                }

                vm.data.push(resultsDetData);

                vm.id++;
            });
        };

        vm.alert = function () {
            for (var i = 0; i < vm.data.length; i++) {
                var noExist = $.grep(vm.trendings2, function (trending) {
                    return trending.id == vm.data[i].id;
                });

                if (noExist.length == 0) {
                    vm.data.splice(i, 1);
                    break;
                }
            }

            angular.copy(vm.trendings2, vm.trendings);
        }

        function getPDF() {
            PatientResultDet.trendingRepo({patientId: vm.patient.id, kineticParams: vm.trendings2}, function (data) {
                OdsReportsService.forceDownloadAndOpenPDFObject(data);
            });
        }
    }
})();
