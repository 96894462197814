/**
 * Created by maykel on 2/2/2017.
 */
(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('PatientOrderStandingFinishController', PatientOrderStandingFinishController);

    PatientOrderStandingFinishController.$inject = ['$uibModalInstance', 'patientOrder', 'PatientOrder'];

    function PatientOrderStandingFinishController($uibModalInstance, id, PatientOrder) {
        var vm = this;

        vm.patientOrder = id;
        vm.clear = clear;
        vm.confirmFinish = confirmFinish;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmFinish(id) {
            PatientOrder.finish({id: id},
                function () {
                    $uibModalInstance.close(true);
                }
            );
        }
    }
})();
