(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('MaintenanceRangeController', MaintenanceRangeController);

    MaintenanceRangeController.$inject = ['filterRes', 'Clinic', 'PatientResult', 'dialogs'];

    function MaintenanceRangeController(filterRes, Clinic, PatientResult, $dialogs) {
        var vm = this;

        vm.filterRes = filterRes;

        vm.clinics = Clinic.query();

        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.start = false;
        vm.datePickerOpenStatus.end = false;

        vm.openCalendar = openCalendar;
        vm.save = save;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function save() {
            var dlg = $dialogs.confirm('Please Confirm','Reset all result ranges?', {size: 'md'});
            dlg.result.then(function(btn){
                PatientResult.maintenanceRange(vm.filterRes, onSaveSuccess, onSaveError);

                function onSaveSuccess(result) {
                    vm.isSaving = false;
                }

                function onSaveError() {
                    vm.isSaving = false;
                }
            });
        }
    }
})();
