(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('PatientOrderCancelController', PatientOrderCancelController);

    PatientOrderCancelController.$inject = ['$uibModalInstance', 'patientOrder', 'PatientOrder'];

    function PatientOrderCancelController($uibModalInstance, patientOrder, PatientOrder) {
        var vm = this;

        vm.patientOrder = patientOrder;
        vm.clear = clear;
        vm.confirmCancel = confirmCancel;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmCancel(id) {
            PatientOrder.cancel({id: id},
                function () {
                    $uibModalInstance.close(true);
                }
            );
        }
    }
})();
