(function() {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('CorporationDetailController', CorporationDetailController);

    CorporationDetailController.$inject = ['$scope', '$rootScope', 'previousState', 'corporation'];

    function CorporationDetailController($scope, $rootScope, previousState, corporation) {
        var vm = this;

        vm.corporation = corporation;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('dialysisApp:corporationUpdate', function(event, result) {
            vm.corporation = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
