(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('patient', {
                parent: 'entity',
                url: '/patient',
                data: {
                    authorities: ['ROLE_SUPERVISOR', 'ROLE_NURSE', 'ROLE_DOCTOR'],
                    pageTitle: 'Patients'
                },
                ncyBreadcrumb: {
                    label: 'Patients',
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/patient/patients.html',
                        controller: 'PatientController',
                        controllerAs: 'vm'
                    }
                    // 'select-filter@patient': {
                    //     templateUrl: 'app/entities/patient/patient-select-filter.html',
                    //     controller: 'PatientSelectFilter',
                    //     controllerAs: 'vm'
                    // }
                },
                resolve: {
                    patient: [function () {
                        return {
                            firstName: null,
                            lastName: null,
                            dob: null,
                            gender: null,
                            shift: null,
                            diabetic: null,
                            treatmentDays: null,
                            status: null,
                            physician: {},
                            modality: {}
                        }
                    }],
                    clinic: ['CoreService', function (CoreService) {
                        return CoreService.getCurrentClinic();
                    }]
                }
            })
            .state('patient-i', {
                parent: 'entity',
                url: '/patient-i',
                data: {
                    authorities: ['ROLE_SUPERVISOR', 'ROLE_NURSE', 'ROLE_DOCTOR'],
                    pageTitle: 'Patients'
                },
                ncyBreadcrumb: {
                    label: 'Inactive Patients',
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/patient/patients-i.html',
                        controller: 'PatientIController',
                        controllerAs: 'vm'
                    },
                    'select-filter@patient': {
                        templateUrl: 'app/entities/patient/patient-select-filter.html',
                        controller: 'PatientSelectFilter',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    patient: [function () {
                        return {
                            firstName: null,
                            lastName: null,
                            dob: null,
                            gender: null,
                            shift: null,
                            diabetic: null,
                            treatmentDays: null,
                            status: null,
                            physician: {},
                            modality: {}
                        }
                    }],
                    clinic: ['CoreService', function (CoreService) {
                        return CoreService.getCurrentClinic();
                    }]
                }
            })
            .state('patient-abs', {
                parent: 'entity',
                url: '/patient/{id}',
                abstract: true,
                data: {
                    authorities: ['ROLE_SUPERVISOR', 'ROLE_NURSE', 'ROLE_DOCTOR'],
                    pageTitle: 'Patients'
                },
                ncyBreadcrumb: {
                    label: 'Demographics',
                    parent: 'patient-detail'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/patient/tpl/patient-tpl.html',
                        controller: 'PatientAbstractController',
                        controllerAs: 'vm'
                    },
                    'select-filter@patient-abs': {
                        templateUrl: 'app/entities/patient/patient-select-filter.html',
                        controller: 'PatientSelectFilter',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    entity: ['$stateParams', 'Patient', function ($stateParams, Patient) {
                        return Patient.get({id: $stateParams.id}).$promise;
                    }]
                }
            })
            .state('patient-detail', {
                parent: 'patient-abs',
                url: '/detail',
                data: {
                    authorities: ['ROLE_SUPERVISOR', 'ROLE_NURSE', 'ROLE_DOCTOR'],
                    pageTitle: 'Patient'
                },
                ncyBreadcrumb: {
                    label: 'Demographics',
                    parent: 'patient'
                },
                views: {
                    'content@patient-abs': {
                        templateUrl: 'app/entities/patient/patient-detail.html',
                        controller: 'PatientDetailController',
                        controllerAs: 'vm'
                    },
                    'problem-list@patient-detail': {
                        templateUrl: 'app/entities/problem-list/problem-list.html',
                        controller: 'ProblemListController',
                        controllerAs: 'vm'
                    },
                    'care-team@patient-detail': {
                        templateUrl: 'app/entities/care-team/care-teams.html',
                        controller: 'CareTeamController',
                        controllerAs: 'vm'
                    },
                    'patient-medications@patient-detail': {
                        templateUrl: 'app/entities/medication/medications.html',
                        controller: 'MedicationController',
                        controllerAs: 'vm'
                    },
                    'patient-access@patient-detail': {
                        templateUrl: 'app/entities/access/accesses.html',
                        controller: 'AccessController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {}
            })
            .state('patient-detail-menu', {
                parent: 'entity',
                url: '/patient-menu/{state1}',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_SUPERVISOR', 'ROLE_NURSE', 'ROLE_DOCTOR'],
                    pageTitle: 'Patient'
                },
                views: {
                    'content@': {
                        controller: 'PatientMenuController',
                        controllerAs: 'vm'
                    }
                }
            })
            .state('patient-new', {
                parent: 'entity',
                url: '/patient-new',
                data: {
                    authorities: ['ROLE_SUPERVISOR', 'ROLE_NURSE', 'ROLE_DOCTOR'],
                    pageTitle: 'New Patient'
                },
                ncyBreadcrumb: {
                    label: 'New Patient',
                    parent: 'patient'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/patient/patient-new.html',
                        controller: 'PatientNewController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    entity: ['GUIUtils', 'CoreService', function (GUIUtils, CoreService) {
                        return {
                            firstName: null,
                            lastName: null,
                            middleInitial: null,
                            dob: null,
                            ssn: null,
                            gender: null,
                            shift: null,
                            diabetic: false,
                            mrn: null,
                            fDayDialysis: null,
                            clinicAdmission: null,
                            treatmentDays: '0000000',
                            drawDays: '0000000',
                            kidneyTransplant: false,
                            status: true,
                            id: null,
                            externalMrn: null,
                            externalBillingId: null,
                            addresses: [],
                            insurances: [],
                            patientPicture: {
                                picture: GUIUtils.getDefaultUserPicture(),
                                pictureContentType: GUIUtils.getDefaultUserPictureContentType(),
                            },
                            clinic: CoreService.getCurrentClinic()
                        };
                    }]
                }
            })
            .state('patient-edit', {
                parent: 'patient-abs',
                url: '/edit',
                data: {
                    authorities: ['ROLE_SUPERVISOR', 'ROLE_NURSE', 'ROLE_DOCTOR'],
                    pageTitle: 'Patient Edit'
                },
                ncyBreadcrumb: {
                    label: 'Edit',
                    parent: 'patient-abs'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/patient/patient-new.html',
                        controller: 'PatientNewController',
                        controllerAs: 'vm'
                    }
                }
            })
            .state('patient-change', {
                parent: 'entity',
                url: '/patient-change',
                data: {
                    authorities: ['ROLE_SUPERVISOR', 'ROLE_NURSE', 'ROLE_DOCTOR'],
                    pageTitle: 'Patient Change Clinic'
                },
                ncyBreadcrumb: {
                    label: 'Patient Change Clinic',
                },
                params: {
                    id: null,
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/patient/patient-change.html',
                        controller: 'PatientChangeController',
                        controllerAs: 'vm'
                    },
                    'select-filter@patient-change': {
                        templateUrl: 'app/entities/patient/patient-select-filter.html',
                        controller: 'PatientSelectFilter',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    patient: [function () {
                        return {
                            firstName: null,
                            lastName: null,
                            dob: null,
                            gender: null,
                            shift: null,
                            diabetic: null,
                            treatmentDays: null,
                            status: null,
                            physician: {},
                            modality: {}
                        }
                    }],
                    clinic: ['CoreService', function (CoreService) {
                        return CoreService.getCurrentClinic();
                    }],
                    id: ['$stateParams', function ($stateParams) {
                        return $stateParams.id;
                    }]
                }
            })
            .state('patient-health', {
                parent: 'dashboard-doc',
                url: '/patient-health',
                data: {
                    authorities: ['ROLE_SUPERVISOR', 'ROLE_NURSE', 'ROLE_DOCTOR'],
                    pageTitle: 'Patient Change Clinic'
                },
                ncyBreadcrumb: {
                    label: 'Patient Change Clinic',
                },
                params: {
                    id: null,
                    patientResultHealth: null,
                    patientResultHealthItem: null
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    if ($stateParams.patientResultHealth && $stateParams.patientResultHealth) {
                        $uibModal.open({
                            templateUrl: 'app/entities/patient/patient-health-dialog.html',
                            controller: 'PatientHealthDialogController',
                            controllerAs: 'vm',
                            size: 'md',
                            resolve: {
                                clinic: ['CoreService', function (CoreService) {
                                    return CoreService.getCurrentClinic();
                                }],
                                patientResultHealth: $stateParams.patientResultHealth,
                                patientResultHealthItem: $stateParams.patientResultHealthItem
                            }
                        }).result.then(function () {
                            $state.go('^');
                        }, function () {
                            $state.go('^');
                        });
                    } else {
                        $state.go('dashboard-doc');
                    }
                }]
            })
    }
})();
