(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('order-wizard', {
                parent: 'entity',
                url: '/order-wizard',
                ncyBreadcrumb: {
                    label: 'Orders'
                },
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'Orders'
                },
                params: {

                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/patient-order-wizard/patient-order-wizard.html',
                        controller: 'PatientOrderWizardController',
                        controllerAs: 'vm'
                    }
                }
            })
    }
})();
