(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('EmployeeController', EmployeeController);

    EmployeeController.$inject = ['$compile', '$scope', '$state', 'Employee', 'EmployeeSearch', '$q', 'DTColumnBuilder',
        'DTOptionsBuilder', 'GUIUtils', '$filter'];

    function EmployeeController($compile, $scope, $state, Employee, EmployeeSearch, $q, DTColumnBuilder,
                                DTOptionsBuilder, GUIUtils, $filter) {
        var vm = this;

        vm.employees = [];
        vm.search = search;
        vm.loadAll = loadAll;
        vm.dtInstance = {};

        vm.dtOptions = DTOptionsBuilder.fromFnPromise(function () {
            var defer = $q.defer();
            if (!vm.searchQuery || vm.searchQuery == '') {
                Employee.query(function (result) {
                    vm.employees = result;
                    defer.resolve(result);
                });
            } else {
                defer.resolve($filter('filter')(vm.employees, vm.searchQuery, undefined));
            }
            return defer.promise;
        }).withPaginationType('full_numbers').withBootstrap().withDOM('tip').withOption('scrollX', '100%')
            .withOption('fnRowCallback', function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                $compile(nRow)($scope);
            });

        vm.dtColumns = [
            DTColumnBuilder.newColumn('title').withTitle('Title').withOption('width', '20px'),
            DTColumnBuilder.newColumn(null).withTitle('Full Name').renderWith(function (data, type, full) {
                return data.name + ' ' + data.lastName;
            }),
            DTColumnBuilder.newColumn('gender').withTitle('Gender').withOption('width', '20px'),
            DTColumnBuilder.newColumn(null).withTitle('Email').renderWith(function (data, type, full) {
                return GUIUtils.mailto(data.user.email, []);
            }),
            DTColumnBuilder.newColumn('employeeType.name').withTitle('Type'),
            DTColumnBuilder.newColumn(null).withTitle('Corporation').renderWith(function (data, type, full) {
                return data.corporation ? data.corporation.name : '';
            }),
            DTColumnBuilder.newColumn('user.login').withTitle('User'),
            DTColumnBuilder.newColumn(null).withTitle('Roles').renderWith(function (data, type, full) {
                var template = '';
                for (var i = 0; i < data.user.authorities.length; i++) {
                    template += GUIUtils.getStatusTemplate(data.user.authorities[i], 'primary') + '&nbsp';
                }
                return template;
            }),
            DTColumnBuilder.newColumn(null).withTitle('Active').renderWith(function (data, type, full) {
                if (data.user.activated) {
                    return GUIUtils.getStatusTemplate("Yes", 'success');
                } else {
                    return GUIUtils.getStatusTemplate("No", 'danger');
                }
            }),
            DTColumnBuilder.newColumn(null).withTitle('Actions').withOption('width', '100px').notSortable()
                .renderWith(actionsHtml)
        ];

        function loadAll() {
            Employee.query(function (result) {
                vm.employees = result;
                vm.searchQuery = null;
            });
        }

        function actionsHtml(data, type, full, meta) {
            return GUIUtils.getActionsTemplate(data, $state.current.name, ['update', 'delete']);
        }

        function search() {
            vm.dtInstance.reloadData();
        }
    }
})();
