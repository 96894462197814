(function() {
    'use strict';

    angular
        .module('dialysisApp')
        .factory('MedFrequencyFromSearch', MedFrequencyFromSearch);

    MedFrequencyFromSearch.$inject = ['$resource'];

    function MedFrequencyFromSearch($resource) {
        var resourceUrl =  'api/_search/med-frequency-froms/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
