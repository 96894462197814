(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('CareTeamController', CareTeamController);

    CareTeamController.$inject = ['$filter', '$scope', '$q', 'entity', '$compile', 'CareTeam', 'DTColumnBuilder', 'DTOptionsBuilder'];

    function CareTeamController($filter, $scope, $q, entity, $compile, CareTeam, DTColumnBuilder, DTOptionsBuilder) {
        var vm = this;

        vm.form = {};
        vm.patient = entity;

        vm.careTeams = [];
        vm.careTeamsDtInstance = {};

        vm.careTeamsDtOptions = DTOptionsBuilder.fromFnPromise(function () {
            var defer = $q.defer();

            CareTeam.getAllByPatientId({id: vm.patient.id}, function (result) {
                vm.careTeams = result;
                defer.resolve(result);
            });
            return defer.promise;
        }).withPaginationType('full_numbers').withBootstrap().withDOM('tip').withOption('fnRowCallback',
            function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                $compile(nRow)($scope);
            });

        vm.careTeamsDtColumns = [
            DTColumnBuilder.newColumn(null).withTitle('Type/Specialty').renderWith(function (data, type, full) {
                return data.careTeamSpecialty ? data.careTeamSpecialty.name : "";
            }),
            DTColumnBuilder.newColumn(null).withTitle('Name').renderWith(function (data, type, full) {
                return data.firstName + ' ' + data.lastName;
            }),
            DTColumnBuilder.newColumn('phone').withTitle('Phone').renderWith(function (data, type, full) {
                return $filter('phone')(data);
            }),
            DTColumnBuilder.newColumn('email').withTitle('Email'),
            DTColumnBuilder.newColumn(null).withTitle('Actions').withOption('width', '155px').notSortable()
                .renderWith(actionsHtml)
        ];

        function actionsHtml(data, type, full, meta){
            var stButtons = '';

            stButtons += '<a class="btn-sm btn-info" ui-sref="care-team-view({ctid:' + data.id + '})">' +
                '   <i class="fa fa-eye"></i></a>&nbsp;';

            if (vm.patient.status) {
                stButtons += '<a class="btn-sm btn-danger" ui-sref="care-team-delete({ctid:' + data.id + '})">' +
                    '   <i class="fa fa-trash"></i></a>';
            }

            return stButtons;
        }
    }
})();
