(function() {
    'use strict';

    angular
        .module('dialysisApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('patient-template', {
            parent: 'entity',
            url: '/patient-template',
            data: {
                authorities: ['ROLE_SUPERVISOR'],
                pageTitle: 'PatientTemplates'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/patient-template/patient-templates.html',
                    controller: 'PatientTemplateController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
            }
        })
        .state('patient-template-detail', {
            parent: 'entity',
            url: '/patient-template/{id}',
            data: {
                authorities: ['ROLE_SUPERVISOR'],
                pageTitle: 'PatientTemplate'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/patient-template/patient-template-detail.html',
                    controller: 'PatientTemplateDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: ['$stateParams', 'PatientTemplate', function($stateParams, PatientTemplate) {
                    return PatientTemplate.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'patient-template',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('patient-template-detail.edit', {
            parent: 'patient-template-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_SUPERVISOR'],
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/patient-template/patient-template-dialog.html',
                    controller: 'PatientTemplateDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['PatientTemplate', function(PatientTemplate) {
                            return PatientTemplate.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('patient-template.new', {
            parent: 'patient-template',
            url: '/new',
            data: {
                authorities: ['ROLE_SUPERVISOR'],
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/patient-template/patient-template-dialog.html',
                    controller: 'PatientTemplateDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                name: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('patient-template', null, { reload: 'patient-template' });
                }, function() {
                    $state.go('patient-template');
                });
            }]
        })
        .state('patient-template.edit', {
            parent: 'patient-template',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_SUPERVISOR'],
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/patient-template/patient-template-dialog.html',
                    controller: 'PatientTemplateDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['PatientTemplate', function(PatientTemplate) {
                            return PatientTemplate.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('patient-template', null, { reload: 'patient-template' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('patient-template.delete', {
            parent: 'patient-template',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_SUPERVISOR']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/patient-template/patient-template-delete-dialog.html',
                    controller: 'PatientTemplateDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['PatientTemplate', function(PatientTemplate) {
                            return PatientTemplate.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('patient-template', null, { reload: 'patient-template' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }
})();
