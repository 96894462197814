'use strict';

angular
    .module('ods-lib-old', [
        'ui.bootstrap',
        'ui.select',
        'webcam',
        // 'pdf',
        'ui.bootstrap.datetimepicker',
        'datatables',
        'datatables.factory',
        'datatables.bootstrap',
        'datatables.colreorder',
        'datatables.columnfilter',
        'angularMoment',
        'ui.toggle'
        // ,
        // 'dndLists',
        // 'ngTable'
    ])
    .value('version', '1.0');

