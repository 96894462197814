(function() {
    'use strict';
    angular
        .module('dialysisApp')
        .factory('PatientBarcode', PatientBarcode);

    PatientBarcode.$inject = ['$resource'];

    function PatientBarcode ($resource) {
        var resourceUrl =  'api/patient-barcode/:barcode';

        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
        });
    }
})();
