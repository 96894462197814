(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('CompendiumController', CompendiumController);

    CompendiumController.$inject = ['$scope', '$state', 'Compendium', 'CompendiumSearch', '$q',
        'DTColumnBuilder', 'DTOptionsBuilder', 'GUIUtils', '$filter'];

    function CompendiumController($scope, $state, Compendium, CompendiumSearch, $q,
                                  DTColumnBuilder, DTOptionsBuilder, GUIUtils, $filter) {
        var vm = this;

        vm.compendiums = [];
        vm.search = search;
        vm.dtInstance = {};

        vm.dtOptions = DTOptionsBuilder.fromFnPromise(function () {
            var defer = $q.defer();
            if (!vm.searchQuery || vm.searchQuery == '') {
                Compendium.query(function (result) {
                    vm.compendiums = result;
                    defer.resolve(result);
                });
            } else {
                defer.resolve($filter('filter')(vm.compendiums, vm.searchQuery, undefined));
            }
            return defer.promise;
        }).withPaginationType('full_numbers').withBootstrap().withDOM('tip');

        vm.dtColumns = [
            DTColumnBuilder.newColumn('id').withTitle('ID'),
            DTColumnBuilder.newColumn('code').withTitle('Code'),
            DTColumnBuilder.newColumn('intCode').withTitle('Internal Code'),
            DTColumnBuilder.newColumn('description').withTitle('Description'),
            DTColumnBuilder.newColumn('abbr').withTitle('Abbr'),
            DTColumnBuilder.newColumn('wGroup').withTitle('WGroup'),
            DTColumnBuilder.newColumn('specimen').withTitle('Specimen'),
            DTColumnBuilder.newColumn('units').withTitle('Units'),
            DTColumnBuilder.newColumn(null).withTitle('Rep Group').renderWith(function (data, type, full) {
                return data.compendiumReportGroup ? data.compendiumReportGroup.name : "";
            }),
            DTColumnBuilder.newColumn(null).withTitle('Actions').withOption('width', '155px').notSortable()

                .renderWith(actionsHtml)
        ];

        function actionsHtml(data, type, full, meta) {
            return GUIUtils.getActionsTemplate(data, $state.current.name, ['update', 'delete']);
        }

        function search() {
            vm.dtInstance.reloadData();
        }
    }
})();
