(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('ClinicNewController', ClinicNewController);

    ClinicNewController.$inject = ['$scope', '$sessionStorage', 'GUIUtils', 'CoreService', '$state',
        'entity', 'Corporation',
        'Clinic', 'ReportGroup', 'CountryState'];

    function ClinicNewController($scope, $sessionStorage, GUIUtils, CoreService, $state,
                                 entity, Corporation,
                                 Clinic, ReportGroup, CountryState) {
        var vm = this;
        vm.editing = true;
        vm.clinic = entity;
        vm.clinic.drawDays2 = GUIUtils.weekCodeStringToDays(vm.clinic.drawDays);
        vm.clinic.wDrawDays2 = GUIUtils.weekCodeStringToDays(vm.clinic.wDrawDays);
        vm.clinic.quarterlies2 = GUIUtils.monthsCodeStringToMonths(vm.clinic.quarterlies);
        vm.corporations = Corporation.query();

        vm.form = {};
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;

        vm.days = GUIUtils.getDays();
        vm.months = GUIUtils.getMonths();
        // vm.reportgroups = ReportGroup.query();
        vm.countrystates = CountryState.query();
        vm.changeInterfaceR = changeInterfaceR;
        vm.changeInterfaceG = changeInterfaceG;

        function save() {
            vm.isSaving = true;

            vm.clinic.wDrawDays = GUIUtils.daysToWeekCodeString(vm.clinic.wDrawDays2);
            vm.clinic.drawDays = GUIUtils.daysToWeekCodeString(vm.clinic.drawDays2);
            vm.clinic.quarterlies = GUIUtils.monthsToMonthCodeString(vm.clinic.quarterlies2);

            if (vm.clinic.id !== null) {
                Clinic.update(vm.clinic, onSaveSuccess, onSaveError);
            } else {
                Clinic.save(vm.clinic, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('dialysisApp:clinicNew', result);
            CoreService.setCurrentClinic(result);
            $state.go("clinic", null, {reload: true});
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        vm.cancelEditing = cancelEditing;

        function cancelEditing() {
            $state.reload("clinic");
        }

        vm.datePickerOpenStatus.startDate = false;
        vm.datePickerOpenStatus.contractExp = false;
        vm.datePickerOpenStatus.annualReview = false;
        vm.datePickerOpenStatus.standingEnd = false;
        vm.datePickerOpenStatus.startPickup = false;
        vm.datePickerOpenStatus.endPickup = false;
        vm.timepickerOptions = {
            readonlyInput: false,
            showMeridian: false
        }

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        vm.keepActiveTab = keepActiveTab;

        function keepActiveTab($index) {
            $sessionStorage.activeClinicTab = {id: vm.clinic.id, activeTab: $index};
        }

        function changeInterfaceR() {
            if (vm.clinic.renesanIntegration) {
                vm.clinic.gaiaIntegration = false;
            }
        }

        function changeInterfaceG() {
            if (vm.clinic.gaiaIntegration) {
                vm.clinic.renesanIntegration = false;
            }
        }
    }
})();
