(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('PatientOrderNewTemplateController', PatientOrderNewTemplateController);

    PatientOrderNewTemplateController.$inject = ['$scope', '$compile', '$state', 'patientOrderTpl', '$q', 'DTColumnBuilder', 'DTOptionsBuilder', 'GUIUtils', 'ROLES',
        'CoreService', 'OrderFrequency', 'PatientOrderTpl', 'Via', 'Employee', '$uibModal', '$stateParams', 'Principal', 'Corporation', 'Compendium', 'AlertService'];

    function PatientOrderNewTemplateController($scope, $compile, $state, patientOrderTpl, $q, DTColumnBuilder, DTOptionsBuilder, GUIUtils, ROLES,
                                               CoreService, OrderFrequency, PatientOrderTpl, Via, Employee, $uibModal, $stateParams, Principal, Corporation, Compendium, AlertService) {

        var vm = this;

        vm.patientOrderTpl = patientOrderTpl;
        vm.patientOrder = patientOrderTpl.patientOrderJson;

        vm.form = {};
        vm.save = save;
        vm.vias = Via.query();
        vm.employees = [];

        if (Principal.hasRole(ROLES.ROLE_ADMIN)) {
            vm.corporations = Corporation.query();
        } else {
            vm.employees = Employee.employeeCorpPhysicians();
            vm.corporations = [CoreService.getCurrentEmployee().corporation];
            vm.corporations = [CoreService.getCurrentEmployee().corporation];
            vm.patientOrderTpl.corporation = CoreService.getCurrentEmployee().corporation;
            vm.patientOrder.corporation = CoreService.getCurrentEmployee().corporation;
        }

        if ($stateParams.clone) {
            vm.patientOrderTpl.id = null;
        }

        vm.orderfrequencies = [];
        vm.compendiumErrors = [];

        function save() {
            vm.isSaving = true;

            vm.patientOrderTpl.patientOrderJson = vm.patientOrder;
            vm.patientOrderTpl.via = vm.patientOrder.via;
            vm.patientOrderTpl.orderType = vm.patientOrder.orderType;
            vm.patientOrderTpl.signedBy = vm.patientOrder.signedBy;
            vm.patientOrderTpl.corporation = vm.patientOrder.corporation;

            vm.isSaving = true;
            if (vm.patientOrderTpl.id !== null) {
                PatientOrderTpl.update(vm.patientOrderTpl, onSaveSuccess, onSaveError);
            } else {
                PatientOrderTpl.save(vm.patientOrderTpl, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess() {
            vm.isSaving = false;
            $state.go("patient-order-tpl");
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        changeOrderType();
        vm.changeOrderType = changeOrderType;

        function changeOrderType() {
            vm.orderfrequencies = OrderFrequency.byordertype({orderType: vm.patientOrder.orderType}).$promise.then(function (data) {
                vm.orderfrequencies = data;
            });
        }

        vm.addPatientOrderTest = function () {
            var test = {
                renal: false,
                id: null,
                orderFrequency: vm.orderfrequencies[0],
                diagnoses: []
            };

            vm.popupTest(test, -1);
        };

        vm.editPatientOrderTest = function (index) {
            vm.popupTest(vm.patientOrder.patientOrderTests[index], index);
        };

        vm.deletePatientOrderTest = function (index) {
            $uibModal.open({
                templateUrl: 'app/entities/patient-order-test/patient-order-test-delete-dialog.html',
                controller: 'PatientOrderTestDeleteNewController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    entity: {id: index}
                }
            }).result.then(function () {
                vm.patientOrder.patientOrderTests.splice(index, 1);

                vm.dtInstance.reloadData();
            }, function () {
            });
        };

        vm.showICD10 = function (index) {
            $uibModal.open({
                templateUrl: 'app/entities/diagnosis/diagnosis-dialog.html',
                controller: 'DiagnosisDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    entity: vm.patientOrder.patientOrderTests[index]
                }
            });
        };

        vm.popupTest = function (patientOrderTest, index) {
            $uibModal.open({
                templateUrl: 'app/entities/patient-order-tpl/patient-order-test-tpl-new-dialog.html',
                controller: 'PatientOrderTestTplNewDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    patientOrder: vm.patientOrder,
                    patientOrderTest: patientOrderTest,
                }
            }).result.then(function (result) {
                if (index < 0) {
                    vm.patientOrder.patientOrderTests.push(result.patientOrderTest);
                } else {
                    vm.patientOrder.patientOrderTests[index] = result.patientOrderTest;
                }
                vm.dtInstance.reloadData();
            }, function () {
            });
        };

        vm.dtInstance = {};

        vm.dtOptions = DTOptionsBuilder.fromFnPromise(function () {
            var defer = $q.defer();

            vm.compendiumErrors = [];
            var compendiums = _.map(vm.patientOrder.patientOrderTests, 'compendium');

            Compendium.check({compendiums: compendiums}, function (result) {
                if (result.compendiums.length > 0) {
                    _.forEach(result.compendiums, function (compendium) {
                        return vm.compendiumErrors.push(compendium.code);
                    });
                }

                defer.resolve(vm.patientOrder.patientOrderTests);
            });

            return defer.promise;
        }).withPaginationType('full_numbers').withBootstrap().withDOM('tip').withOption('paging', false).withOption('aaSorting', []).withOption('fnRowCallback',
            function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                $compile(nRow)($scope);
            });

        vm.dtColumns = [
            DTColumnBuilder.newColumn(null).withTitle('Test').renderWith(function (data, type, full) {
                return (vm.compendiumErrors.indexOf(data.compendium.code) == -1 ? data.compendium.code : '<span class="label label-danger">' + data.compendium.code + '</span>');
            }),
            DTColumnBuilder.newColumn(null).withTitle('Description').renderWith(function (data, type, full) {
                return data.compendium.description;
            }),
            DTColumnBuilder.newColumn(null).withTitle('Frequency').renderWith(function (data, type, full) {
                return data.orderFrequency.name;
            }),
            DTColumnBuilder.newColumn(null).withTitle('Diagnosis (ICD10)').renderWith(function (data, type, full, meta) {
                return data.diagnoses != null && data.diagnoses.length == 1 ? data.diagnoses[0].icd10Code : data.diagnoses[0].icd10Code + showICD10Html(meta.row);
            }),
            DTColumnBuilder.newColumn(null).withTitle('Renal').renderWith(function (data, type, full) {
                return GUIUtils.colorHtmlYesNo(data.renal);
            }),
            DTColumnBuilder.newColumn(null).withTitle('Actions').withOption('width', '155px').notSortable()
                .renderWith(actionsHtml)
        ];

        function actionsHtml(data, type, full, meta) {
            var stButtons = '';

            stButtons += '<a class="btn-sm btn-warning" ng-click="vm.editPatientOrderTest(' + meta.row + ')">' +
                '   <i class="fa fa-edit"></i></a>&nbsp;';
            stButtons += '<a class="btn-sm btn-danger" ng-click="vm.deletePatientOrderTest(' + meta.row + ')">' +
                '   <i class="fa fa-trash"></i></a>';

            return stButtons;
        };

        function showICD10Html(row) {
            return '&nbsp;&nbsp;<a class="btn-sm btn-info" ng-click="vm.showICD10(' + row + ')">' +
                '   <i class="fa fa-list-ol"></i></a>';
        };
    }
})();
