(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('PrEmrIntegrationLogController', PrEmrIntegrationLogController);

    PrEmrIntegrationLogController.$inject = ['clinic', '$compile', '$scope', 'Patient', 'filterRes', 'moment',
        '$uibModal', 'PopupService', 'DataTablesService', 'PrEmrIntegrationLogSearch', '$q', 'DTColumnBuilder',
        'DTOptionsBuilder', 'GUIUtils', '$filter', 'PatientResultFile', '$rootScope',
        'CoreService', 'DateUtils'];

    function PrEmrIntegrationLogController(clinic, $compile, $scope, Patient, filterRes, moment,
                                           $uibModal, PopupService, DataTablesService, PrEmrIntegrationLogSearch, $q, DTColumnBuilder,
                                           DTOptionsBuilder, GUIUtils, $filter, PatientResultFile, $rootScope,
                                           CoreService, DateUtils) {
        var vm = this;

        vm.title = "Emr Integration Log";
        vm.showFilter = false;
        var titleHtml = '<input type="checkbox" ng-model="vm.selectAll" ng-change="vm.toggleAll(vm.selected, vm.selectAll)">';
        vm.prEmrIntegrationLogResults = [];
        vm.dtInstance = {};
        vm.clinic = clinic;
        vm.filterRes = filterRes;
        vm.selected = {};
        vm.selectAll = false;
        vm.currentMonth = false;
        vm.search = search;
        vm.toggleAll = DataTablesService.toggleAll;
        vm.toggleOne = DataTablesService.toggleOne;
        vm.getFilter = getFilter;
        vm.openCalendar = openCalendar;
        vm.clear = clear;
        vm.showFiltersPanel = showFiltersPanel;

        angular.element('.filter-box').hide();

        function showFiltersPanel() {
            angular.element('.filter-box').toggleClass('active');

            if (angular.element('.filter-box').hasClass('active')) {
                angular.element('.toggle-filters').addClass('open');
                angular.element('.filter-box').slideDown();
            } else {
                angular.element('.toggle-filters').removeClass('open');
                angular.element('.filter-box').slideUp();
            }
        }

        vm.dtOptions = DTOptionsBuilder.fromFnPromise(function () {
            var defer = $q.defer();

            if (!vm.searchQuery || vm.searchQuery == '') {
                PrEmrIntegrationLogSearch.query(
                    {
                        query: {
                            bool: {
                                must: getFilter()
                            }
                        }
                    }, function (result) {
                        vm.prEmrIntegrationLogResults = result;
                        defer.resolve(vm.prEmrIntegrationLogResults);
                    });
            } else {
                defer.resolve($filter('filter')(vm.prEmrIntegrationLogResults, vm.searchQuery, undefined));
            }

            vm.selected = {};
            vm.selectAll = false;

            return defer.promise;
        }).withPaginationType('full_numbers')
            .withBootstrap()
            .withDOM('ltp')
            .withLanguage({
                "paginate": {
                    "first": '<i class="fa fa-angle-double-left" aria-hidden="true"></i>',
                    "previous": '<i class="fa fa-arrow-circle-left" aria-hidden="true"></i>',
                    "last": '<i class="fa fa-angle-double-right" aria-hidden="true"></i>',
                    "next": '<i class="fa fa-arrow-circle-right" aria-hidden="true"></i>'
                }
            })
            .withOption('aaSorting', [[5, 'desc']])
            .withOption('lengthMenu', [[10, 15, 25, 50, -1], [10, 15, 25, 50, "All"]])
            .withOption('headerCallback', function (header) {
                if (!vm.headerCompiled) {
                    vm.headerCompiled = true;
                    $compile(angular.element(header).contents())($scope);
                }
            })
            .withOption('fnRowCallback',
                function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                    $compile(nRow)($scope);
                });

        vm.dtColumns = [
            DTColumnBuilder.newColumn(null).withTitle(titleHtml).withOption('width', '25px').notSortable().renderWith(function (data, type, full, meta) {
                vm.selected[full.id] = false;

                return '<input type="checkbox" ng-model="vm.selected[' + data.id + ']" ng-click="vm.toggleOne(vm.selected, vm.selectAll)">';
            }),
            DTColumnBuilder.newColumn(null).withTitle('Accession Number').renderWith(function (data, type, full) {
                return data.patientResult ? data.patientResult.accessionNumber : "";
            }),
            DTColumnBuilder.newColumn(null).withTitle('Collection Date').renderWith(function (data, type, full) {
                return data.patientResult ? moment(data.patientResult.collectionDate).format($rootScope.displayDateformat) : "";
            }),
            DTColumnBuilder.newColumn('status').withTitle('Status'),

            DTColumnBuilder.newColumn(null).withTitle('Sent Date').renderWith(function (data, type, full) {
                return moment(data.createdDate).format($rootScope.displayAmDateTimeFormat);
            }),
            DTColumnBuilder.newColumn(null).withTitle('Sent Date').notVisible().renderWith(function (data, type, full) {
                return moment(data.createdDate).format("YYYYMMDDHHmmss");
            }),
        ];

        function search() {
            CoreService.setStorage('filterEmrIntegrationLog', vm.filterRes);
            vm.dtInstance.reloadData();
        }

        function getFilter() {
            var must = [];

            must.push({
                bool: {
                    "should": [
                        {"term": {"patientResult.clinicId": vm.clinic.id}}
                    ]
                }
            });

            if (vm.filterRes.start) {
                must.push({range: {createdDate: {gte: moment(vm.filterRes.start).format("YYYY-MM-DD")}}});
            }

            if (vm.filterRes.end) {
                var end = new Date(vm.filterRes.end);
                end.setHours(23, 59, 59);
                must.push({range: {createdDate: {lte: moment(end).format("YYYY-MM-DDTHH:mm:ss")}}});
            }

            if (vm.filterRes.barcode != null && vm.filterRes.barcode.length > 0) {
                must.push({match: {'patientResult.accessionNumber': {query: vm.filterRes.barcode}}});
            }

            if (vm.filterRes.status != null && vm.filterRes.status.length > 0) {
                must.push({match: {status: {query: vm.filterRes.status}}});
            }

            return must;
        }

        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.start = false;
        vm.datePickerOpenStatus.end = false;
        vm.datePickerOpenStatus.dob = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function clear() {
            vm.filterRes = CoreService.initEmrIntegrationLogFilter();
            CoreService.setStorage('filterEmrIntegrationLog', vm.filterRes);
            vm.dtInstance.reloadData();
        }
    }
})();
