(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('InvoicePatientController', InvoicePatientController);

    InvoicePatientController.$inject = ['$scope', '$state', 'InvoicePatient', 'InvoicePatientSearch', 'DTOptionsBuilder',
        '$q', 'DTColumnBuilder', 'invoice', 'GUIUtils', '$uibModal', '$compile'];

    function InvoicePatientController($scope, $state, InvoicePatient, InvoicePatientSearch, DTOptionsBuilder,
                                      $q, DTColumnBuilder, invoice, GUIUtils, $uibModal, $compile) {
        var vm = this;

        vm.invoicePatients = [];
        vm.invoice = invoice;
        vm.dtInstance = {};

        vm.edit = edit;
        vm.search = search;

        vm.dtOptions = DTOptionsBuilder.fromFnPromise(function () {
            var defer = $q.defer();

            if (!vm.searchQuery || vm.searchQuery == '') {
                InvoicePatientSearch.query({
                    query: {
                        bool: {
                            must: [
                                {
                                    bool: {
                                        "should": [
                                            {"term": {"invoiceId": vm.invoice.id}},
                                            {"term": {"invoice.id": vm.invoice.id}}
                                        ]
                                    }
                                }
                            ]
                        }
                    }
                }, function (result) {
                    vm.invoicePatients = result;
                    defer.resolve(result);
                });
            } else {
                defer.resolve($filter('filter')(vm.invoicePatients, vm.searchQuery, undefined));
            }
            return defer.promise;
        }).withPaginationType('full_numbers')
            .withBootstrap()
            .withDOM('tip')
            .withLanguage({
                "paginate": {
                    "first": '<i class="fa fa-angle-double-left" aria-hidden="true"></i>',
                    "previous": '<i class="fa fa-arrow-circle-left" aria-hidden="true"></i>',
                    "last": '<i class="fa fa-angle-double-right" aria-hidden="true"></i>',
                    "next": '<i class="fa fa-arrow-circle-right" aria-hidden="true"></i>'
                }
            })
            .withOption('fnRowCallback',
                function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                    $compile(nRow)($scope);
                })
            .withOption('headerCallback', function (header) {
                if (!vm.headerCompiled) {
                    vm.headerCompiled = true;
                    $compile(angular.element(header).contents())($scope);
                }
            });

        vm.dtColumns = [
            DTColumnBuilder.newColumn('id').withTitle('ID'),
            DTColumnBuilder.newColumn('accessionNumber').withTitle('Accession #'),
            DTColumnBuilder.newColumn('patientName').withTitle('Patient Name'),
            DTColumnBuilder.newColumn(null).withTitle('DOS').renderWith(function (data, type, full) {
                return data.date ? moment(data.date).format("MM/DD/YYYY") : '';
            }),
            DTColumnBuilder.newColumn('description').withTitle('Service Description'),
            DTColumnBuilder.newColumn(null).withTitle('Price').renderWith(function (data, type, full) {
                return data.price ? '$' + data.price : '';
            }),
            DTColumnBuilder.newColumn(null).withTitle('Adjustment').renderWith(function (data, type, full) {
                return data.adjustment ? '$' + data.adjustment : '';
            }),
            DTColumnBuilder.newColumn('adjustmentComment').withTitle('Adjustment Comment'),
            DTColumnBuilder.newColumn(null).withTitle('Actions').withOption('width', '55px').withOption('class', 'col-btn').notSortable().renderWith(actionsHtml)
        ];

        function actionsHtml(data, type, full, meta) {
            var stButtons = '';

            stButtons += '<a uib-tooltip="See Details" class="btn btn-default btn-sm details" ng-click="vm.edit(' + data.id + ')">' +
                '   <i class="fa fa-external-link" aria-hidden="true"></i></a>&nbsp;';

            return stButtons;
        }

        function search() {
            vm.dtInstance.reloadData();
        }

        function edit(id) {
            $uibModal.open({
                templateUrl: 'app/entities/invoice-patient/invoice-patient-dialog.html',
                controller: 'InvoicePatientDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: ['InvoicePatient', function (InvoicePatient) {
                        return InvoicePatient.get({id: id}).$promise;
                    }]
                }
            }).result.then(function() {
                vm.search();
            });
        }
    }
})();
