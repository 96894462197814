(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('CriticalResultsTopBoxController', CriticalResultsTopBoxController);

    CriticalResultsTopBoxController.$inject = ['clinic', '$scope', '$state', '$q', 'DTColumnBuilder', 'PatientResult', '$uibModal','openModalCritical','CoreService'];

    function CriticalResultsTopBoxController(clinic, $scope, $state, $q, DTColumnBuilder, PatientResult, $uibModal, openModalCritical, CoreService) {
        var vm = this;
        vm.openModalCritical = openModalCritical;
        vm.criticals = 0;
        PatientResult.countCriticals({id: clinic.id}, function (result) {
            vm.criticals = result;

            if (result.value > 0 && vm.openModalCritical == undefined ) {
                modalCriticalResults(vm.criticals);
                CoreService.setStorage('openModalCritical', true);
            }
        });

        vm.modalCriticalResults = modalCriticalResults;

        function modalCriticalResults(criticals) {
            $uibModal.open({
                templateUrl: 'app/entities/patient-result/dashboard/patient-result-critical-alert-dialog.html',
                controller: 'PatientResultCriticalAlertDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    criticals: criticals
                }
            });
        }
    }
})();
