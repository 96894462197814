(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('PatientTemplateController', PatientTemplateController);

    PatientTemplateController.$inject = ['$scope', '$state', 'PatientTemplate', 'PatientTemplateSearch', '$q', 'DTColumnBuilder', 'DTOptionsBuilder', 'GUIUtils', '$filter'];

    function PatientTemplateController($scope, $state, PatientTemplate, PatientTemplateSearch, $q, DTColumnBuilder, DTOptionsBuilder, GUIUtils, $filter) {
        var vm = this;

        vm.patientTemplates = [];
        vm.search = search;
        vm.loadAll = loadAll;
        vm.dtInstance = {};

        vm.dtOptions = DTOptionsBuilder.fromFnPromise(function () {
            var defer = $q.defer();
            if (!vm.searchQuery || vm.searchQuery == '') {
                PatientTemplate.query(function (result) {
                    vm.patientTemplates = result;
                    defer.resolve(result);
                });
            } else {
                defer.resolve($filter('filter')(vm.patientTemplates, vm.searchQuery, undefined));
            }
            return defer.promise;
        }).withPaginationType('full_numbers').withBootstrap().withDOM('tip');

        vm.dtColumns = [
            DTColumnBuilder.newColumn('name').withTitle('Name'),
            DTColumnBuilder.newColumn(null).withTitle('Actions').withOption('width', '155px').notSortable()
                .renderWith(actionsHtml)
        ];

        function loadAll() {
            PatientTemplate.query(function (result) {
                vm.patientTemplates = result;
                vm.searchQuery = null;
            });
        }

        function actionsHtml(data, type, full, meta) {
            return GUIUtils.getActionsTemplate(data, $state.current.name, ['all']);
        }

        function search() {
            vm.dtInstance.reloadData();
        }
    }
})();
