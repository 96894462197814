(function() {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('RptMetadataDetailController', RptMetadataDetailController);

    RptMetadataDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'RptMetadata'];

    function RptMetadataDetailController($scope, $rootScope, $stateParams, previousState, entity, RptMetadata) {
        var vm = this;

        vm.rptMetadata = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('dialysisApp:rptMetadataUpdate', function(event, result) {
            vm.rptMetadata = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
