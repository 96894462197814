(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('clinics', {
                parent: 'entity',
                url: '/clinics',
                data: {
                    authorities: ['ROLE_ADMIN'],
                    pageTitle: 'Clinics'
                },
                params: {
                    corporation: null
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/clinic/clinics.html',
                        controller: 'ClinicController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    corporation: ['$stateParams', function ($stateParams) {
                        return $stateParams.corporation;
                    }]
                }
            })
            .state('clinic', {
                parent: 'entity',
                url: '/clinic',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'Clinic'
                },
                ncyBreadcrumb: {
                    label: 'Clinic',
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/clinic/clinic-detail.html',
                        controller: 'ClinicDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    entity: ['$stateParams', 'Clinic', 'CoreService', function ($stateParams, Clinic, CoreService) {
                        return Clinic.get({id: CoreService.getCurrentClinic().id}).$promise;
                    }],
                }
            })
            .state('clinic-new', {
                parent: 'entity',
                url: '/clinic-new',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                ncyBreadcrumb: {
                    label: 'New Clinic',
                    parent: 'clinic'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/clinic/clinic-new.html',
                        controller: 'ClinicNewController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    entity: function () {
                        return {
                            name: null,
                            startDate: null,
                            phone: null,
                            fax: null,
                            email: null,
                            address1: null,
                            address2: null,
                            city: null,
                            zipCode: null,
                            billingAddress1: null,
                            billingAddress2: null,
                            billingCity: null,
                            billingZipCode: null,
                            crownWebId: null,
                            website: null,
                            billingContact: null,
                            billingPhone: null,
                            billingEmail: null,
                            medicareId: null,
                            npi: null,
                            contractExp: null,
                            annuals: null,
                            ssn: null,
                            annualReview: null,
                            semiAnnuals: null,
                            drawDays: "0000000",
                            wDrawDays: "0000000",
                            quarterlies: "000000000000",
                            fdod: null,
                            status: false,
                            gaiaIntegration: false,
                            renesanIntegration: false,
                            id: null,
                            emrIntegration: null,
                        };
                    }
                }
            })
            .state('clinic-edit', {
                parent: 'clinic',
                url: '/edit',
                data: {
                    authorities: ['ROLE_SUPERVISOR', 'ROLE_ADMIN'],
                    pageTitle: 'Clinic'
                },
                ncyBreadcrumb: {
                    label: 'Clinic'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/clinic/clinic-new.html',
                        controller: 'ClinicNewController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    entity: ['$stateParams', 'Clinic', 'CoreService', function ($stateParams, Clinic, CoreService) {
                        return Clinic.get({id: CoreService.getCurrentClinic().id}).$promise;
                    }]
                }
            })
            .state('clinic-admin', {
                parent: 'entity',
                url: '/clinic-admin',
                data: {
                    authorities: ['ROLE_ADMIN'],
                    pageTitle: 'Clinic Admin'
                },
                ncyBreadcrumb: {
                    label: 'Clinic Admin'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/clinic/dashboard2/clinic-admin.html',
                        controller: 'ClinicAdminController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {

                }
            })
            .state('clinic-admin-view', {
                parent: 'clinic-admin',
                url: '/clinic-admin-view',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'Clinic Admin View'
                },
                ncyBreadcrumb: {
                    label: 'Clinic Admin View'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/clinic/dashboard2/clinic-admin-view.html',
                        controller: 'ClinicAdminViewController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {

                }

            })
    }
})();
