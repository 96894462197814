(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('PatientResultPendingDeleteDialog', PatientResultPendingDeleteDialog);

    PatientResultPendingDeleteDialog.$inject = ['$uibModalInstance', 'barcodes', 'PatientResult', 'toastr'];

    function PatientResultPendingDeleteDialog($uibModalInstance, barcodes, PatientResult, toastr) {
        var vm = this;

        vm.clear = clear;
        vm.deletePendingResult = deletePendingResult;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function deletePendingResult() {
            vm.isSaving = true;
            PatientResult.deletePending({barcodes: barcodes.selected}, function () {
                $uibModalInstance.close(true);
                toastr.success('Delete operation successfully');
                vm.isSaving = false;
            }, function () {
                toastr.error('Delete operation fail');
                vm.isSaving = false;
            })
        }
    }
})();
