(function() {
    'use strict';
    angular
        .module('dialysisApp')
        .factory('Dashboard', Dashboard);

    Dashboard.$inject = ['$resource'];

    function Dashboard ($resource) {
        var resourceUrl =  'api/dashboard/:id';

        return $resource(resourceUrl, {}, {
            'get': {method: 'GET'},
            'environmental': {url: 'api/dashboard/environmental/:id', method: 'GET'},
            'admin': {method: 'GET', url: 'api/dashboard/admin'},
            'labels': {method: 'GET', url: 'api/dashboard/labels/:id'},
        });
    }
})();
