(function() {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('PatientMenuController',PatientMenuController);

    PatientMenuController.$inject = ['$rootScope', '$stateParams', '$state', '$uibModal', 'CoreService'];

    function PatientMenuController($rootScope, $stateParams, $state, $uibModal, CoreService) {
        var patient = CoreService.getCurrentPatient();

        if (patient) {
            switch ($stateParams.state1) {
                case 'patient-detail' :  $state.go("patient-detail", {"id": patient.id}); break;
                case 'hospitalization' :  $state.go("hospitalization", {"id": patient.id}); break;
                case 'infection' :  $state.go("infection", {"id": patient.id}); break;
                case 'vaccination' :  $state.go("vaccination", {"id": patient.id}); break;
                case 'latest-result' : $state.go("patient-result", {"id": patient.id}); break;
                case 'trending' : $state.go("trending", {"id": patient.id}); break;
                case 'patient-order-current' : $state.go("patient-order-current", {"id": patient.id}); break;
                case 'kinetic' : $state.go("kinetic", {"id": patient.id}); break;
                case 'patient-reports' : $state.go("patient-reports", {"id": patient.id}); break;
                case 'patient-report-card' : $state.go("patient-report-card", {"id": patient.id}); break;
                case 'latest-result-all': $state.go("latest-result"); break;
                default: $state.go("patient-detail", {"id": patient.id}); break;
            }
        } else {
            switch ($stateParams.state1) {
                case 'latest-result' : $state.go("latest-result"); break;
                case 'latest-result-all' : $state.go("latest-result"); break;
                default : {
                    $state.go("patient");
                    // $uibModal.open({
                    //     templateUrl: 'app/entities/patient/patient-menu-dialog.html',
                    //     controller: ['$uibModalInstance', function ($uibModalInstance) {
                    //         var vm = this;
                    //
                    //         vm.dashboard = dashboard;
                    //
                    //         function dashboard () {
                    //             $uibModalInstance.dismiss('cancel');
                    //             $state.go("home");
                    //         }
                    //
                    //         vm.roster = roster;
                    //
                    //         function roster () {
                    //             $uibModalInstance.dismiss('cancel');
                    //             $state.go("patient");
                    //         }
                    //     }],
                    //     controllerAs: 'vm',
                    //     backdrop: 'static',
                    //     size: 'sm'
                    // });
                }
            }
        }
    }
})();
