(function() {
    'use strict';

    angular
        .module('dialysisApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('dialyser-type', {
            parent: 'entity',
            url: '/dialyser-type',
            data: {
                authorities: ['ROLE_SUPERVISOR'],
                pageTitle: 'Types of Dialyser'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/dialyser-type/dialyser-types.html',
                    controller: 'DialyserTypeController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
            }
        })
        .state('dialyser-type-detail', {
            parent: 'entity',
            url: '/dialyser-type/{id}',
            data: {
                authorities: ['ROLE_SUPERVISOR'],
                pageTitle: 'DialyserType'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/dialyser-type/dialyser-type-detail.html',
                    controller: 'DialyserTypeDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: ['$stateParams', 'DialyserType', function($stateParams, DialyserType) {
                    return DialyserType.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'dialyser-type',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('dialyser-type-detail.edit', {
            parent: 'dialyser-type-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_SUPERVISOR']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/dialyser-type/dialyser-type-dialog.html',
                    controller: 'DialyserTypeDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['DialyserType', function(DialyserType) {
                            return DialyserType.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('dialyser-type.new', {
            parent: 'dialyser-type',
            url: '/new',
            data: {
                authorities: ['ROLE_SUPERVISOR']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/dialyser-type/dialyser-type-dialog.html',
                    controller: 'DialyserTypeDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                name: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('dialyser-type', null, { reload: 'dialyser-type' });
                }, function() {
                    $state.go('dialyser-type');
                });
            }]
        })
        .state('dialyser-type.edit', {
            parent: 'dialyser-type',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_SUPERVISOR']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/dialyser-type/dialyser-type-dialog.html',
                    controller: 'DialyserTypeDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['DialyserType', function(DialyserType) {
                            return DialyserType.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('dialyser-type', null, { reload: 'dialyser-type' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('dialyser-type.delete', {
            parent: 'dialyser-type',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_SUPERVISOR']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/dialyser-type/dialyser-type-delete-dialog.html',
                    controller: 'DialyserTypeDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['DialyserType', function(DialyserType) {
                            return DialyserType.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('dialyser-type', null, { reload: 'dialyser-type' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
