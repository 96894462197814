(function() {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('CompendiumDetailController', CompendiumDetailController);

    CompendiumDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Compendium', 'PatientOrderTest'];

    function CompendiumDetailController($scope, $rootScope, $stateParams, previousState, entity, Compendium, PatientOrderTest) {
        var vm = this;

        vm.compendium = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('dialysisApp:compendiumUpdate', function(event, result) {
            vm.compendium = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
