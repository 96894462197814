(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('PatientBarcodeDetail2Controller', PatientBarcodeDetail2Controller);

    PatientBarcodeDetail2Controller.$inject = ['barcode', 'moment', '$compile', '$scope', 'GUIUtils',
        '$q', '$uibModal', 'PatientOrderTest', 'DTOptionsBuilder', 'DTColumnBuilder',
        'patient', '$uibModalInstance', 'patientOrder', 'lodash', 'Diagnosis', 'dialogs', 'PatientOrderItem'];

    function PatientBarcodeDetail2Controller(barcode, moment, $compile, $scope, GUIUtils,
                                             $q, $uibModal, PatientOrderTest, DTOptionsBuilder, DTColumnBuilder,
                                             patient, $uibModalInstance, patientOrder, _, Diagnosis, $dialogs, PatientOrderItem) {
        var vm = this;
        vm.barcode = barcode;
        vm.patient = patient;
        vm.patientOrder = patientOrder;
        vm.diagnosis = [];

        vm.clear = clear;
        vm.showCancelTestDialog = showCancelTestDialog;
        vm.loadAll = loadAll;
        vm.showDescription = showDescription;

        loadAll();

        function loadAll() {
            if (vm.barcode) {
                PatientOrderTest.findAllByBarcode2({barcode: vm.barcode.id}, function (result) {
                    vm.patientOrderTests = result;
                });
            } else {
                PatientOrderTest.findAllWithoutBarcode({
                    patientId: vm.patient.id,
                    date: vm.patientOrder.scheduleDate,
                    physicianId: vm.patientOrder.physicianId
                }, function (result) {
                    vm.patientOrderTests = result;
                });
            }
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function showDescription($event, testId) {
            Diagnosis.byPatientOrderTest({id: testId}, function (result) {
                vm.diagnosis[testId] = result;
                var t = angular.element($event.currentTarget);
                t.parent().parent().toggleClass('hideDescription');
            });
        };

        function showCancelTestDialog(item) {
            var dlg = $dialogs.confirm('Please Confirm', 'Are you sure you want to Cancel Test?', {size: 'md'});
            dlg.result.then(function (btn) {
                PatientOrderItem.cancel({id: item}, function () {
                    loadAll();
                });
            }, function () {
                item.canceled = false;
            });
        }
    }
})();
