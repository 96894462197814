(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('RptTemplateController', RptTemplateController);

    RptTemplateController.$inject = ['$scope', '$state', 'RptTemplate', 'RptTemplateSearch', '$q',
        'DTColumnBuilder', 'DTOptionsBuilder', 'GUIUtils', 'CoreService', '$filter', '$compile', '$uibModal'];

    function RptTemplateController($scope, $state, RptTemplate, RptTemplateSearch, $q,
                                   DTColumnBuilder, DTOptionsBuilder, GUIUtils, CoreService, $filter, $compile, $uibModal) {
        var vm = this;

        vm.rptTemplate = [];
        vm.search = search;
        vm.report = report;

        vm.clinic = CoreService.getCurrentClinic();

        vm.title = 'Report 2.0';

        vm.dtInstance = {};

        vm.dtOptions = DTOptionsBuilder.fromFnPromise(function () {
            var defer = $q.defer();
            if (!vm.searchQuery || vm.searchQuery == '') {
                RptTemplateSearch.query({
                    query: {
                        bool: {
                            must: [
                                {term: {"rptCategory.clinic.id": vm.clinic.id}},
                                {term: {"active": true}},
                                {term: {"delStatus": false}}
                            ]
                        }
                    }
                }, function (result) {
                    vm.rptTemplate = result;
                    defer.resolve(result);
                });
            } else {
                defer.resolve($filter('filter')(vm.rptTemplate, vm.searchQuery, undefined));
            }
            return defer.promise;
        }).withBootstrap()
            .withDOM('ltp')
            .withLanguage({
                "paginate": {
                    "first": '<i class="fa fa-angle-double-left" aria-hidden="true"></i>',
                    "previous": '<i class="fa fa-arrow-circle-left" aria-hidden="true"></i>',
                    "last": '<i class="fa fa-angle-double-right" aria-hidden="true"></i>',
                    "next": '<i class="fa fa-arrow-circle-right" aria-hidden="true"></i>'
                }
            })
            .withOption('lengthMenu', [[10, 15, 25, 50, -1], [10, 15, 25, 50, "All"]])
            .withOption('fnRowCallback', function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                $compile(nRow)($scope);
            });

        vm.dtColumns = [
            DTColumnBuilder.newColumn('name').withTitle('Name'),
            DTColumnBuilder.newColumn('description').withTitle('Description'),
            DTColumnBuilder.newColumn(null).withTitle('Type').renderWith(function (data) {
                return data.type ? data.type.replace("_", " ") : "";
            }),
            DTColumnBuilder.newColumn(null).withTitle('Category').renderWith(function (data) {
                return data.rptCategory ? data.rptCategory.name : "";
            }),
            DTColumnBuilder.newColumn('').withTitle('Actions').withOption('class', 'col').withOption('width', '175px').notSortable()
                .renderWith(actionsHtml)
        ];

        function search() {
            vm.dtInstance.reloadData();
        }

        function actionsHtml(data, type, full, meta) {
            return '<a class="dt-btn warning" ng-click="vm.report(' + full.id + ", '" + full.type + "'" + ')" uib-tooltip="Report"><i class="fa fa-file-text" aria-hidden="true"></i></a>';
        }

        function report(id, type) {
            var viewHtml = "";
            var controller = 'RptTemplateDialogController';

            switch (type) {
                case 'GRID' : {
                    viewHtml = 'app/entities/rpt-template/wizard/report/rpt-template-grid-report-dialog.html';
                    break;
                }
                case 'CUMULATIVE' : {
                    viewHtml = 'app/entities/rpt-template/wizard/report/rpt-template-cumulative-report-dialog.html';
                    break;
                }
                case 'REPORT_CARD' : {
                    viewHtml = 'app/entities/rpt-template/wizard/report/rpt-template-report-card-report-dialog.html';
                    break;
                }
                case 'AVERAGE' : {
                    viewHtml = 'app/entities/rpt-template/wizard/report/rpt-template-average-report-dialog.html';
                    break;
                }
                case 'GRAPH' : {
                    viewHtml = 'app/entities/rpt-template/wizard/report/rpt-template-graph-report-dialog.html';
                    break;
                }
                // case 'ADVANCED' : {
                //     viewHtml = 'app/entities/rpt-template/wizard/report/rpt-template-advanced-report-dialog.html';
                // }
                case 'VACCINATION' : {
                    viewHtml = 'app/entities/rpt-template/wizard/report/rpt-template-vaccination-report-dialog.html';
                    break;
                }
                case 'ENV_GRID' : {
                    viewHtml = 'app/entities/rpt-template/wizard/report/rpt-template-env-grid-report-dialog.html';
                    controller = 'RptTemplateEnvDialogController';

                    break;
                }
                case 'ENV_REFERENCE_RANGE' : {
                    viewHtml = 'app/entities/rpt-template/wizard/report/rpt-template-env-reference-range-report-dialog.html';
                    controller = 'RptTemplateEnvDialogController';

                    break;
                }
                case 'ENV_AVERAGE' : {
                    viewHtml = 'app/entities/rpt-template/wizard/report/rpt-template-env-average-report-dialog.html';
                    controller = 'RptTemplateEnvDialogController';

                    break;
                }

                default: return;
            }

            $uibModal.open({
                templateUrl: viewHtml,
                controller: controller,
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: ['RptTemplate', function (RptTemplate) {
                        return RptTemplate.get({id: id}).$promise;
                    }]
                },
                language: false
            });
        }
    }
})();
