(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('PatientStandingSelectDialogController', PatientStandingSelectDialogController);

    PatientStandingSelectDialogController.$inject = ['$uibModalInstance', '$scope', 'clinic', 'Patient', 'dialogs',
        '$q', 'DTColumnBuilder', 'DTOptionsBuilder', '$filter', '$compile', 'patients', 'GUIUtils'];

    function PatientStandingSelectDialogController($uibModalInstance, $scope, clinic, Patient, $dialogs,
                                                   $q, DTColumnBuilder, DTOptionsBuilder, $filter, $compile, patients, GUIUtils) {
        var vm = this;
        vm.clinic = clinic;
        vm.patients = patients.selected;

        vm.clear = function () {
            $uibModalInstance.dismiss('cancel');
        };

        vm.accept = function () {
            var dlg = $dialogs.confirm('Please Confirm',
                'Some selected patients may have active standing orders and will be finalized?', {size: 'md'});
            dlg.result.then(function (btn) {
                var patients = [];
                for (var id in vm.selected) {
                    if (vm.selected[id]) {
                        var _patient = $.grep(vm.patientsList, function (patient, i) {
                            return patient.id == id;
                        })[0];

                        patients.push(_patient);
                    }
                }

                if (patients.length > 0) {
                    $uibModalInstance.close({patients: patients});
                }
            }, function () {
                $uibModalInstance.dismiss('cancel');
            });
        };

        vm.patientsList = [];
        vm.search = search;
        vm.dtInstance = {};

        vm.selected = {};
        vm.selectAll = false;
        vm.toggleAll = toggleAll;
        vm.toggleOne = toggleOne;
        var titleHtml = '<input type="checkbox" ng-model="vm.selectAll" ng-change="vm.toggleAll()">';

        vm.dtOptions = DTOptionsBuilder.fromFnPromise(function () {
            var defer = $q.defer();
            if (vm.isFilter) {
                defer.resolve(vm.patientsList);
                vm.isFilter = false;
            } else if (!vm.searchQuery || vm.searchQuery == '') {
                // vm.patientList = Patient.getWithOutStanding({id: vm.clinic.id}, function (result) {
                vm.patientList = Patient.withOrderStatus({id: vm.clinic.id}, function (result) {
                    vm.patientsList = result;
                    defer.resolve(result);
                });
            } else {
                defer.resolve($filter('filter')(vm.patientsList, vm.searchQuery, undefined));
            }
            return defer.promise;
        }).withPaginationType('full_numbers').withBootstrap().withOption('aaSorting', [[0, 'asc']]).withDOM('tip')
            .withOption('createdRow', function (row, data, dataIndex) {
                $compile(angular.element(row).contents())($scope);
            })
            .withOption('headerCallback', function (header) {
                if (!vm.headerCompiled) {
                    vm.headerCompiled = true;
                    $compile(angular.element(header).contents())($scope);
                }
            });

        vm.dtColumns = [
            DTColumnBuilder.newColumn(null).withTitle(titleHtml).notSortable().renderWith(function (data, type, full, meta) {
                var _patient = $.grep(vm.patients, function (patient, i) {
                    return patient.id == data.id;
                })[0];

                vm.selected[full.id] = _patient != null;

                return '<input type="checkbox" ng-model="vm.selected[' + data.id + ']" ng-click="vm.toggleOne()">';
            }),
            DTColumnBuilder.newColumn(null).withTitle('Name').renderWith(function (data, type, full) {
                return data.lastName + ', ' + data.firstName + ' ' + (data.middleInitial ? data.middleInitial : "");
            }),
            DTColumnBuilder.newColumn(null).withTitle('DOB').renderWith(function (data, type, full) {
                return moment(data.dob).format("MM/DD/YYYY");
            }),
            DTColumnBuilder.newColumn('gender').withTitle('Gender'),
            DTColumnBuilder.newColumn(null).withTitle('Has Standing').renderWith(function (data, type, full) {
                return GUIUtils.colorHtmlYesNo(data.hasStanding == 'true');
            }),
            DTColumnBuilder.newColumn(null).withTitle('Diabetic').renderWith(function (data, type, full) {
                return GUIUtils.colorHtmlYesNo(data.diabetic);
            }),
            DTColumnBuilder.newColumn(null).withTitle('Physician').renderWith(function (data, type, full) {
                return data.employee ? 'Dr. ' + data.employee.name + ' ' + data.employee.lastName: '';
            })
        ];

        function search() {
            vm.dtInstance.reloadData();
        };

        function toggleAll() {
            for (var id in vm.selected) {
                if (vm.selected.hasOwnProperty(id)) {
                    vm.selected[id] = vm.selectAll;
                }
            }
        };

        function toggleOne() {
            for (var id in vm.selected) {
                if (vm.selected.hasOwnProperty(id)) {
                    if (!vm.selected[id]) {
                        vm.selectAll = false;
                        return;
                    }
                }
            }

            vm.selectAll = true;
        };
    }
})();
