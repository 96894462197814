(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('latest-env-result-det', {
                parent: 'latest-env-result',
                url: '/{id}/edetails',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_SUPERVISOR', 'ROLE_NURSE', 'ROLE_ENVIRONMENTAL', 'ROLE_DOCTOR'],
                    pageTitle: 'Environmental Result Details'
                },
                ncyBreadcrumb: {
                    label: 'Result Details',
                    parent: 'latest-env-result'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/env-result-det/env-result-dets.html',
                        controller: 'EnvResultDetController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    envResult: ['$stateParams', 'EnvResult', function ($stateParams, EnvResult) {
                        return EnvResult.get({id: $stateParams.id}).$promise;
                    }]
                }
            })
    }
})();
