(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('CalendarController', CalendarController);

    CalendarController.$inject = ['Calendar', 'uiCalendarConfig', '$scope', '$compile', 'Clinic', '$interval'];

    function CalendarController(Calendar, uiCalendarConfig, $scope, $compile, Clinic, $interval) {
        var vm = this;
        var date = new Date();
        var d = date.getDate();
        var m = date.getMonth();
        var y = date.getFullYear();

        // vm.calendars = Calendar.getCalendar({date: new Date()});/

        vm.eventRender = function( event, element, view ) {
            element.attr({'tooltip': event.title,
                'tooltip-append-to-body': true});
            $compile(element)($scope);
        };

        vm.clinics = Clinic.query();

        vm.calendarConfig = {
            calendar: {
                height: "100%",
                editable: false,
                header: {
                    left: 'month,agendaWeek,basicDay',
                    center: 'title',
                    right: 'prev,next today'
                }
                // eventClick: $scope.alertEventOnClick,
                // eventDrop: $scope.alertOnDrop,
                // eventResize: $scope.alertOnResize
                // start: new Date(y, m, 1),
                // end: new Date(y, m, 31),
                // eventRender: vm.eventRender,
                // viewRender: function(view, element) {
                //     $log.debug("View Changed: ", view.visStart, view.visEnd, view.start, view.end);
                // }
            }
        };

        var myInterval = $interval(function(){
            reload();
        }, 10000);

        function reload() {
            // vm.calendarConfig.calendar.fullCalendar('rerenderEvents');
            uiCalendarConfig.calendars.myCalendar.fullCalendar('refetchEvents');
        }

        vm.eventsF = function (start, end, timezone, callback) {
            Calendar.getCalendar({startDate: start, endDate: end}).$promise.then(function (data) {
                callback(data);
            });
            // var events = [{title: 'Feed Me ' + m,start: s + (50000),end: s + (100000),allDay: false, className: ['customFeed']}];
        };

        vm.eventSources = [vm.eventsF];
    }
})();
