(function() {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('PatientOrderTestDetailDialogController', PatientOrderTestDetailDialogController);

    PatientOrderTestDetailDialogController.$inject = ['patientOrderTest', '$uibModalInstance', 'PatientOrderItem', 'patientOrder', 'dialogs'];

    function PatientOrderTestDetailDialogController (patientOrderTest, $uibModalInstance, PatientOrderItem, patientOrder, $dialogs) {
        var vm = this;

        vm.patientOrderTest = patientOrderTest;
        vm.patientOrder = patientOrder;

        vm.items = PatientOrderItem.by_test({id: patientOrderTest.id});

        vm.form = {};

        vm.clear = function () {
            $uibModalInstance.dismiss('cancel');
        }

        vm.accept = function () {
            $uibModalInstance.close();
        }

        vm.cancelOrderTestItem = function (item) {
            if (!item.canceled) {
                var dlg = $dialogs.confirm('Please Confirm', 'Are you sure you want to Cancel Test?', {size: 'md'});
                dlg.result.then(function (btn) {
                    PatientOrderItem.cancel({id: item.id}, function () {
                    }, function () {
                        $state.reload("patient-order-update", {oid: vm.patientOrder.id});
                    });
                }, function () {
                    item.canceled = false;
                });
            }
        }
    }
})();
