(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('UnassignedResultController', UnassignedResultController);

    UnassignedResultController.$inject = ['$compile', '$scope', 'filterRes', 'moment', 'Patient', 'DateUtils',
        'CoreService', 'PatientResult', '$q', 'DTColumnBuilder', 'DTOptionsBuilder', 'GUIUtils', '$filter'];

    function UnassignedResultController($compile, $scope, filterRes, moment, Patient, DateUtils,
                                        CoreService, PatientResult, $q, DTColumnBuilder, DTOptionsBuilder, GUIUtils, $filter) {

        var vm = this;

        vm.title = "Unassigned Result";
        vm.patientResults = [];
        vm.search = search;
        vm.dtInstance = {};
        vm.filterRes = filterRes;
        vm.clinic = CoreService.getCurrentClinic();
        vm.patients = [];
        vm.patient = {};

        vm.selected = {};
        vm.selectAll = false;
        vm.toggleAll = toggleAll;
        vm.toggleOne = toggleOne;

        var titleHtml = '<input type="checkbox" ng-model="vm.selectAll" ng-change="vm.toggleAll()">';

        Patient._4filter({
            query: {
                bool: {
                    must: [
                        {term: {"clinicId": vm.clinic.id}},
                        {term: {"delStatus": false}}
                    ]
                }
            }
        }, function (result) {
            vm.patients = result;

            for (var i = 0; i < vm.patients.length; i++) {
                vm.patients[i].name = vm.patients[i].firstName + ' ' + vm.patients[i].lastName;
            }
        });

        vm.dtOptions = DTOptionsBuilder.fromFnPromise(function () {
            var defer = $q.defer();
            if (vm.isFilter) {
                defer.resolve(vm.patientResults);
                vm.isFilter = false;
            } else if (!vm.searchQuery || vm.searchQuery == '') {
                PatientResult.unassigned({account: vm.clinic.account}, function (result) {
                    vm.selected = {};
                    vm.selectAll = false;
                    vm.toggleAll = toggleAll;
                    vm.toggleOne = toggleOne;
                    vm.patient = {};
                    vm.patientResults = result;
                    defer.resolve(result);
                });
            } else {
                defer.resolve($filter('filter')(vm.patientResults, vm.searchQuery, undefined));
            }

            return defer.promise;
        }).withPaginationType('full_numbers').withBootstrap().withOption('aaSorting', []).withDOM('tip').withOption('fnRowCallback',
            function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                $compile(nRow)($scope);
            }).withOption('createdRow', function (row, data, dataIndex) {
            $compile(angular.element(row).contents())($scope);
        }).withOption('headerCallback', function (header) {
            if (!vm.headerCompiled) {
                vm.headerCompiled = true;
                $compile(angular.element(header).contents())($scope);
            }
        });

        vm.dtColumns = [
            DTColumnBuilder.newColumn(null).withTitle(titleHtml).notSortable().renderWith(function (data, type, full, meta) {
                vm.selected[full.id] = false;

                return '<input type="checkbox" ng-model="vm.selected[' + data.id + ']" ng-click="vm.toggleOne()">';
            }),
            DTColumnBuilder.newColumn(null).withTitle('Patient').renderWith(function (data, type, full) {
                return data.patientName;
            }),
            DTColumnBuilder.newColumn(null).withTitle('DOB').renderWith(function (data, type, full) {
                return moment(data.dob).format("MM/DD/YYYY");
            }),
            DTColumnBuilder.newColumn('status').withTitle('Status'),
            DTColumnBuilder.newColumn(null).withTitle('Result Date').renderWith(function (data, type, full) {
                return moment(data.createdDate).format("MM/DD/YYYY");
            }),
            DTColumnBuilder.newColumn('').withTitle('').withOption('width', '155px').notSortable()
                .renderWith(abnormalHtml),
        ];

        function abnormalHtml(data, type, full, meta) {
            if (full.abnormal) {
                return GUIUtils.getStatusTemplate('ABNORMAL', 'danger');
            } else {
                return GUIUtils.getStatusTemplate('NORMAL', 'success');
            }
        }

        function search() {
            vm.dtInstance.reloadData();
        }

        function toggleAll() {
            for (var id in vm.selected) {
                if (vm.selected.hasOwnProperty(id)) {
                    vm.selected[id] = vm.selectAll;
                }
            }
        }

        function toggleOne() {
            for (var id in vm.selected) {
                if (vm.selected.hasOwnProperty(id)) {
                    if (!vm.selected[id]) {
                        vm.selectAll = false;
                        return;
                    }
                }
            }
            vm.selectAll = true;
        }

        vm.assign = function () {
            if (!vm.patient) {
                return;
            }

            var result = [];
            for (var id in vm.selected) {
                if (vm.selected[id]) {
                    result.push(id);
                }
            }

            if (result.length > 0) {
                vm.isSaving = true;
                PatientResult.assign({
                    ids: result,
                    date: DateUtils.convertLocalDateToServer(vm.oDate1),
                    patientId: vm.patient.id
                }, function () {
                    vm.dtInstance.reloadData();
                    vm.isSaving = false;
                }, function () {
                })
            }
        }

        vm.filterPatients = filterPatients;
        function filterPatients(query) {
            if (query && query.length > 1) {
                vm.currentClinic = CoreService.getCurrentClinic();
                Patient._4filter({
                    query: {
                        bool: {
                            should: [
                                {match: {firstName: {query: query, fuzziness: 1}}},
                                {match: {lastName: {query: query, fuzziness: 1}}}
                            ],
                            must: [
                                {term: {"clinic.id": vm.currentClinic.id}},
                                {term: {"delStatus": false}}
                            ]
                        }
                    }
                }, function (result) {
                    vm.patients = result;
                });
            }
        }
    }
})();
