(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('HotFixController', HotFixController);

    HotFixController.$inject = ['HotFix', 'dialogs', 'CoreService'];

    function HotFixController(HotFix, $dialogs, CoreService) {
        var vm = this;

        vm.patientResultStatus = patientResultStatus;

        function patientResultStatus() {
            var dlg = $dialogs.confirm('Please Confirm', 'This hot fix run a patient result status update based in the range values', {size: 'md'});
            dlg.result.then(function (btn) {
                HotFix.patientResultStatus();
            });
        }

        // vm.clinics = HotFix.clinicDetails();
        //
        // HotFix.patients({id: 17302}, function (result) {
        //     vm.patients = result;
        //     vm.count = 0;
        //
        //     for (var i = 0; i < vm.patients.length; i++) {
        //         vm.count += vm.patients[i].criticals;
        //     }
        // });
        //
        // vm.pdetail = HotFix.pdetail({id: 60082});
        // vm.criticals = HotFix.criticals({id: 60109});
        // vm.comments = HotFix.comments({id: 277840});
        // vm._6m = HotFix._6M({id: 60149, detailId: 289050});
        // vm.acknowledge = HotFix.acknowledge({id: 268668});
        // vm.acknowledge = HotFix.acknowledge({id: 268669});
        // vm.acknowledge = HotFix.acknowledge({id: 273094});
    }
})();
