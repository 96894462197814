(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('BillingController', BillingController);

    BillingController.$inject = ['OdsParamType', 'CoreService', 'Corporation', 'Clinic'];

    function BillingController(OdsParamType, CoreService, Corporation, Clinic) {

        var vm = this;

        vm.currentClinic = CoreService.getCurrentClinic();
        vm.clinics = Clinic.query();

        vm.reportsGroup = {
            title: 'Billing',
            groups: [{
                id: 1,
                open: true,
                disabled: false,
                title: 'Clinic',
                icon: 'fa fa-dashboard',
                reports: [
                    {
                        id: 1,
                        title: 'Billing bundle renal',
                        url: 'api/report/invoice/patient-results-in-bundle',
                        base64: false,
                        params: [
                            {
                                title: 'Clinic',
                                name: 'id',
                                type: OdsParamType.LIST,
                                hidden: false,
                                list: vm.clinics
                            },
                            {
                                title: 'Start Date',
                                name: 'start_date',
                                type: OdsParamType.DATE,
                                value: new Date(),
                                hidden: false
                            },
                            {
                                title: 'End Date',
                                name: 'end_date',
                                type: OdsParamType.DATE,
                                value: new Date(),
                                hidden: false
                            },
                            {
                                title: 'Price',
                                name: 'price',
                                type: OdsParamType.TEXT,
                                value: 60.0,
                                hidden: false
                            }
                        ]
                    },
                    {
                        id: 2,
                        title: 'Billing not renal',
                        url: 'api/report/invoice/patient-results-not-in-bundle',
                        base64: false,
                        params: [
                            {
                                title: 'Clinic',
                                name: 'id',
                                type: OdsParamType.LIST,
                                hidden: false,
                                list: vm.clinics
                            },
                            {
                                title: 'Start Date',
                                name: 'start_date',
                                type: OdsParamType.DATE,
                                value: new Date(),
                                hidden: false
                            },
                            {
                                title: 'End Date',
                                name: 'end_date',
                                type: OdsParamType.DATE,
                                value: new Date(),
                                hidden: false
                            }
                        ]
                    },
                    {
                        id: 3,
                        title: 'Billing a Clinic CSV',
                        url: 'api/report/invoice/clinic-csv',
                        base64: false,
                        pdf: false,
                        params: [
                            {
                                title: 'Clinic',
                                name: 'id',
                                type: OdsParamType.LIST,
                                hidden: false,
                                list: vm.clinics
                            },
                            {
                                title: 'Start Date',
                                name: 'start_date',
                                type: OdsParamType.DATE,
                                value: new Date(),
                                hidden: false
                            },
                            {
                                title: 'End Date',
                                name: 'end_date',
                                type: OdsParamType.DATE,
                                value: new Date(),
                                hidden: false
                            }
                        ]
                    },
                    {
                        id: 4,
                        title: 'Billing Water',
                        url: 'api/report/invoice/env-results',
                        base64: false,
                        params: [
                            {
                                title: 'Clinic',
                                name: 'id',
                                type: OdsParamType.LIST,
                                hidden: false,
                                list: vm.clinics
                            },
                            {
                                title: 'Start Date',
                                name: 'start_date',
                                type: OdsParamType.DATE,
                                value: new Date(),
                                hidden: false
                            },
                            {
                                title: 'End Date',
                                name: 'end_date',
                                type: OdsParamType.DATE,
                                value: new Date(),
                                hidden: false
                            }
                        ]
                    },
                    {
                        id: 5,
                        title: 'Billing Medicare HL7',
                        url: 'api/report/invoice/medicare-hl7',
                        base64: false,
                        pdf: false,
                        params: [
                            {
                                title: 'Corporation',
                                name: 'corporationId',
                                type: OdsParamType.LIST,
                                hidden: false,
                                list: [{
                                    id: 6,
                                    name: 'Santa Clarita'
                                }],
                                value: null
                            },
                            {
                                title: 'Clinics',
                                name: 'clinics',
                                placeholder: 'Select clinics...',
                                type: OdsParamType.MULTI_SELECT,
                                valueField: 'id',
                                titleField: 'name',
                                required: false,
                                value: [{
                                    id: 21,
                                    name: 'Laurel Canyon Dialysis'
                                }, {
                                    id: 23,
                                    name: 'Laurel Canyon PD'
                                }, {
                                    id: 13,
                                    name: 'Santa Clarita Dialysis'
                                }, {
                                    id: 22,
                                    name: 'Santa Clarita PD'
                                }],
                                list: [{
                                    id: 21,
                                    name: 'Laurel Canyon Dialysis'
                                }, {
                                    id: 23,
                                    name: 'Laurel Canyon PD'
                                }, {
                                    id: 13,
                                    name: 'Santa Clarita Dialysis'
                                }, {
                                    id: 22,
                                    name: 'Santa Clarita PD'
                                }]//Clinic.byCorp({id: 6})
                            },
                            {
                                title: 'Start Date',
                                name: 'start_date',
                                type: OdsParamType.DATE,
                                value: new Date(),
                                hidden: false
                            },
                            {
                                title: 'End Date',
                                name: 'end_date',
                                type: OdsParamType.DATE,
                                value: new Date(),
                                hidden: false
                            }
                        ]
                    }
                ]
            },
                {
                    id: 1,
                    open: true,
                    disabled: false,
                    title: 'Corporation',
                    icon: 'fa fa-dashboard',
                    reports: [
                        {
                            id: 5,
                            title: 'Patient Bill',
                            url: 'api/report/patient-results/billing/corp-patient',
                            base64: false,
                            params: [
                                {
                                    title: 'Corporation',
                                    name: 'id',
                                    type: OdsParamType.LIST,
                                    hidden: false,
                                    list: Corporation.query()
                                },
                                {
                                    title: 'Start Date',
                                    name: 'start_date',
                                    type: OdsParamType.DATE,
                                    value: new Date(),
                                    hidden: false
                                },
                                {
                                    title: 'End Date',
                                    name: 'end_date',
                                    type: OdsParamType.DATE,
                                    value: new Date(),
                                    hidden: false
                                },
                                {
                                    title: 'Price',
                                    name: 'price',
                                    type: OdsParamType.TEXT,
                                    value: 60.0,
                                    hidden: false
                                }
                            ]
                        },
                        {
                            id: 6,
                            title: 'Environmental Bill',
                            url: 'api/report/env-results/billing/corp-env',
                            base64: false,
                            params: [
                                {
                                    title: 'Corporation',
                                    name: 'id',
                                    type: OdsParamType.LIST,
                                    hidden: false,
                                    list: Corporation.query()
                                },
                                {
                                    title: 'Start Date',
                                    name: 'start_date',
                                    type: OdsParamType.DATE,
                                    value: new Date(),
                                    hidden: false
                                },
                                {
                                    title: 'End Date',
                                    name: 'end_date',
                                    type: OdsParamType.DATE,
                                    value: new Date(),
                                    hidden: false
                                }
                            ]
                        },
                        {
                            id: 7,
                            title: 'Test without price',
                            url: 'api/report/patient-results/billing/test-no-price',
                            base64: false,
                            params: [
                                {
                                    title: 'Corporation',
                                    name: 'id',
                                    type: OdsParamType.LIST,
                                    hidden: false,
                                    list: Corporation.query()
                                },
                                {
                                    title: 'Start Date',
                                    name: 'start_date',
                                    type: OdsParamType.DATE,
                                    value: new Date(),
                                    hidden: false
                                },
                                {
                                    title: 'End Date',
                                    name: 'end_date',
                                    type: OdsParamType.DATE,
                                    value: new Date(),
                                    hidden: false
                                }
                            ]
                        },
                        {
                            id: 8,
                            title: 'Insurance CSV(no in bundle no renal)',
                            url: 'api/invoice/csv-no-bundle-no-renal',
                            base64: false,
                            binary: true,
                            params: [
                                {
                                    title: 'Clinic',
                                    name: 'ids',
                                    type: OdsParamType.MULTI_SELECT,
                                    hidden: false,
                                    list: vm.clinics,
                                    value: []
                                },
                                {
                                    title: 'Start Date',
                                    name: 'start_date',
                                    type: OdsParamType.DATE,
                                    value: new Date(2018,3,1),
                                    hidden: false
                                },
                                {
                                    title: 'End Date',
                                    name: 'end_date',
                                    type: OdsParamType.DATE,
                                    value: new Date(2018,3,30),
                                    hidden: false
                                }
                            ]
                        },
                    ]
                }]
        };
    }
})();
