(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('EnvOrderController', EnvOrderController);

    EnvOrderController.$inject = ['$compile', 'moment', 'machine', '$scope', '$state', 'EnvOrder', 'EnvOrderSearch', '$q', 'DTColumnBuilder', 'DTOptionsBuilder', 'GUIUtils', '$filter'];

    function EnvOrderController($compile, moment, machine, $scope, $state, EnvOrder, EnvOrderSearch, $q, DTColumnBuilder, DTOptionsBuilder, GUIUtils, $filter) {
        var vm = this;

        vm.machine = machine;
        vm.envOrders = [];
        vm.search = search;
        vm.dtInstance = {};

        vm.dtOptions = DTOptionsBuilder.fromFnPromise(function () {
            var defer = $q.defer();
            if (!vm.searchQuery || vm.searchQuery == '') {
                EnvOrder.byMachine({id: vm.machine.id}, function (result) {
                    vm.envOrders = result;
                    defer.resolve(result);
                });
            } else {
                defer.resolve($filter('filter')(vm.envOrders, vm.searchQuery, undefined));
            }
            return defer.promise;
        }).withBootstrap().withDOM('tip').withOption('aaSorting', []).withOption('fnRowCallback',
            function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                $compile(nRow)($scope);
            });

        vm.dtColumns = [
            DTColumnBuilder.newColumn('id').withTitle('Order Number'),
            DTColumnBuilder.newColumn('startDate').withTitle('Start Date').renderWith(function (data, type, full) {
                return data ? moment(data).format("MM/DD/YYYY") : "";
            }),
            DTColumnBuilder.newColumn(null).withTitle('End Date').renderWith(function (data, type, full) {
                return data.endDate ? moment(data.endDate).format("MM/DD/YYYY") : '';
            }),
            DTColumnBuilder.newColumn(null).withTitle('Actions').withOption('width', '155px').notSortable()
                .renderWith(actionsHtml)
        ];

        function actionsHtml(data, type, full, meta){
            var stButtons = '';

            stButtons += '<a class="btn-sm btn-warning" ui-sref="env-order-detail({oid:' + data.id + '})">' +
                '   <i class="fa fa-edit"></i></a>&nbsp;';

            return stButtons;
        }

        function search() {
            vm.dtInstance.reloadData();
        }

    }
})();
