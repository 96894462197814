(function () {
    'use strict';
    angular
        .module('dialysisApp')
        .factory('PatientOrderTpl', PatientOrderTpl);

    PatientOrderTpl.$inject = ['$resource'];

    function PatientOrderTpl($resource) {
        var resourceUrl = 'api/patient-orders-tpl/:id';

        return $resource(resourceUrl, {}, {
            'query': {
                method: 'GET', isArray: true, transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);

                        for (var i = 0; i < data.length; i++){
                            data[i].patientOrderJson = angular.fromJson(data[i].patientOrderJson);
                        }
                    }

                    return data;
                }
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.patientOrderJson = angular.fromJson(data.patientOrderJson);
                    }

                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.patientOrderJson = angular.toJson(copy.patientOrderJson);

                    return angular.toJson(copy);
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.patientOrderJson = angular.toJson(copy.patientOrderJson);

                    return angular.toJson(copy);
                }
            },
            'byCorp': {url: "api/patient-orders-tpl/by-corporation/:id", method: 'GET', isArray: true},
            'withOutCorp': {url: "api/patient-orders-tpl/with-out-corporation", method: 'GET', isArray: true}
        });
    }
})();
