(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('UnassignedResultsTopBoxController', UnassignedResultsTopBoxController);

    UnassignedResultsTopBoxController.$inject = ['clinic', '$scope', '$state', '$q', 'DTColumnBuilder', 'PatientResult'];

    function UnassignedResultsTopBoxController(clinic, $scope, $state, $q, DTColumnBuilder, PatientResult) {
        var vm = this;
        vm.unassigned = 0;

        vm.unassigned = PatientResult.countUnassigned({account: clinic.account});
    }
})();
