(function() {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('MedDosageUnitDetailController', MedDosageUnitDetailController);

    MedDosageUnitDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'MedDosageUnit', 'Medication', 'Corporation'];

    function MedDosageUnitDetailController($scope, $rootScope, $stateParams, previousState, entity, MedDosageUnit, Medication, Corporation) {
        var vm = this;

        vm.medDosageUnit = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('dialysisApp:medDosageUnitUpdate', function(event, result) {
            vm.medDosageUnit = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
