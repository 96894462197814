(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('MachineController', MachineController);

    MachineController.$inject = ['$scope', 'clinic', 'Machine', '$q', 'DTColumnBuilder', 'DTOptionsBuilder', 'GUIUtils', '$filter', '$compile'];

    function MachineController($scope, clinic, Machine, $q, DTColumnBuilder, DTOptionsBuilder, GUIUtils, $filter, $compile) {
        var vm = this;
        vm.title = 'Sites';
        vm.entityClassHumanized = 'Sites';
        vm.entityClass = 'machine';

        vm.machines = [];
        vm.search = search;
        vm.dtInstance = {};

        vm.dtOptions = DTOptionsBuilder.fromFnPromise(function () {
            var defer = $q.defer();
            if (vm.isFilter) {
                defer.resolve(vm.machines);
                vm.isFilter = false;
            } else if (!vm.searchQuery || vm.searchQuery == '') {
                Machine.getAllByClinic({id : clinic.id}, function (result) {
                    vm.machines = result;
                    defer.resolve(result);
                });
            } else {
                defer.resolve($filter('filter')(vm.machines, vm.searchQuery, undefined));
            }
            return defer.promise;
        }).withPaginationType('full_numbers').withBootstrap().withDOM('tip')/*.withOption('scrollX', '100%')*/
            .withOption('fnRowCallback', function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                $compile(nRow)($scope);
            });

        vm.dtColumns = [
            DTColumnBuilder.newColumn('name').withTitle('Name'),
            DTColumnBuilder.newColumn(null).withTitle('Location').renderWith(function (data, type, full) {
                return data.siteLocation ? data.siteLocation.name : "";
            }),
            DTColumnBuilder.newColumn(null).withTitle('Active').renderWith(function (data, type, full) {
                return data.mStatus ? GUIUtils.getStatusTemplate('Yes', 'success') : GUIUtils.getStatusTemplate('No', 'danger');
            }),
            DTColumnBuilder.newColumn('mType').withTitle('Type'),
            DTColumnBuilder.newColumn(null).withTitle("").withOption('width', '180px').notSortable().renderWith(actionsHtml)
        ];

        function actionsHtml(data, type, full, meta) {
            return '<div>' +
                '<div class="btn-group" uib-dropdown>' +
                '<button type="button" class="btn btn-default" ui-sref="machine.edit({id:' + data.id + '})">Details</button>' +
                '<button type="button" class="btn btn-default dropdown-toggle" uib-dropdown-toggle ng-disabled="disabled" data-toggle="dropdown" aria-expanded="false">' +
                '<span class="caret"></span>' +
                '<span class="sr-only">Toggle Dropdown</span>' +
                '</button>' +
                '<ul class="dropdown-menu" uib-dropdown-menu role="menu">' +
                '<li><a ui-sref="env-order({id:' + data.id + '})">Orders</a></li>' +
                '<li><a ui-sref="machine-env-result({id:' + data.id + '})">Results</a></li>' +
                '</ul>' +
                '</div></div>';
        };

        function search() {
            vm.dtInstance.reloadData();
        }
    }
})();
