(function() {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('MedFrequencyFromDialogController', MedFrequencyFromDialogController);

    MedFrequencyFromDialogController.$inject = ['$timeout', '$scope', '$uibModalInstance', 'entity', 'MedFrequencyFrom', 'Medication'];

    function MedFrequencyFromDialogController ($timeout, $scope, $uibModalInstance, entity, MedFrequencyFrom, Medication) {
        var vm = this;

        vm.medFrequencyFrom = entity;
        vm.clear = clear;
        vm.save = save;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.medFrequencyFrom.id !== null) {
                MedFrequencyFrom.update(vm.medFrequencyFrom, onSaveSuccess, onSaveError);
            } else {
                MedFrequencyFrom.save(vm.medFrequencyFrom, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('dialysisApp:medFrequencyFromUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
