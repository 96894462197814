(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('PendingResultController', PendingResultController);

    PendingResultController.$inject = ['CoreService', '$uibModal', '$compile', '$q', '$scope', 'GUIUtils',
        'PatientOrder', 'DTOptionsBuilder', 'DTColumnBuilder', 'DateUtils'];

    function PendingResultController(CoreService, $uibModal, $compile, $q, $scope, GUIUtils,
                                     PatientOrder, DTOptionsBuilder, DTColumnBuilder, DateUtils) {
        var vm = this;
        vm.clinic = CoreService.getCurrentClinic();

        vm.orders = [];
        vm.search = search;
        vm.dtInstance = {};

        vm.dtOptions = DTOptionsBuilder.fromFnPromise(function () {
            var defer = $q.defer();

            if (vm.clinic) {
                PatientOrder.pending({clinicId: vm.clinic.id, shift: -1}, function (result) {
                    vm.orders = result;
                    defer.resolve(vm.orders);
                });
            } else {
                defer.resolve(vm.orders);
            }

            return defer.promise;
        }).withBootstrap().withDOM('tip')
            .withOption('createdRow', function (row, data, dataIndex) {
                $compile(angular.element(row).contents())($scope);
            })
            .withOption('aaSorting', [[1, 'asc']])
            .withOption('headerCallback', function (header) {
                if (!vm.headerCompiled) {
                    vm.headerCompiled = true;
                    $compile(angular.element(header).contents())($scope);
                }
            });

        vm.dtColumns = [
            DTColumnBuilder.newColumn(null).withTitle('Patient Name').renderWith(function (data, type, full) {
                return data.lastName + ', ' + data.firstName + ' ' + data.middleName;
            }),
            DTColumnBuilder.newColumn(null).withTitle('D.O.B').withOption('width', '110px').renderWith(function (data, type, full) {
                return moment(data.dob).format("MM/DD/YYYY");
            }),
            DTColumnBuilder.newColumn(null).withTitle('Collected Date').withOption('width', '110px').renderWith(function (data, type, full) {
                return moment(data.scheduleDate).format("MM/DD/YYYY");
            }),
            DTColumnBuilder.newColumn(null).withTitle('Barcode').renderWith(function (data, type, full) {
                return data.barcode ? showBarcode(data) : '';
            }),
            DTColumnBuilder.newColumn(null).withTitle('Tests').withOption('width', '90px').renderWith(function (data, type, full, meta) {
                return showCompendiumHtml(data.barcode);
            }),
            DTColumnBuilder.newColumn(null).withTitle('Collected').withOption('width', '80px').renderWith(function (data, type, full) {
                return GUIUtils.colorHtmlYesNo(data.collected);
            })
        ];

        function search() {
            vm.dtInstance.reloadData();
        }

        $scope.$on('dialysisApp:dashboardCalendarUpdate', function (event, result) {
            vm.oDate1 = result;
            vm.selected = {};
            vm.selectAll = false;
            vm.search();
        });

        function showBarcode(row) {
            return '<a ui-sref="' + "clinic-schedule-pdetail-barcode({barcode:'" + row.barcode + "'})" + '">' + row.barcode + '</a>';
        }

        function showCompendiumHtml(barcode) {
            return '<a class="btn-sm btn-info" ng-click="vm.showCompendiums(' + "'" + barcode + "'" + ')">' +
                '   <i class="fa fa-list-ol"></i></a>';
        };

        vm.showCompendiums = function (barcode) {
            $uibModal.open({
                templateUrl: 'app/entities/compendium/compendium-list-dialog.html',
                controller: 'CompendiumListDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    compendiums: ['Compendium', function (Compendium) {
                        return Compendium.by_patient_with_barcode({barcode: barcode}).$promise;
                    }]
                }
            });
        };
    }
})();
