(function() {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('EnvResultDetailController', EnvResultDetailController);

    EnvResultDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'EnvResult', 'EnvOrder', 'EnvResultDet'];

    function EnvResultDetailController($scope, $rootScope, $stateParams, previousState, entity, EnvResult, EnvOrder, EnvResultDet) {
        var vm = this;

        vm.envResult = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('dialysisApp:envResultUpdate', function(event, result) {
            vm.envResult = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
