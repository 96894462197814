(function () {
    'use strict';
    angular
        .module('dialysisApp')
        .factory('RptMetadata', RptMetadata);

    RptMetadata.$inject = ['$resource'];

    function RptMetadata($resource) {
        var resourceUrl = 'api/rpt-metadata/:id';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': {method: 'PUT'},
            'queryDiff': {url: 'api/rpt-metadata-diff', method: 'GET', isArray: true},
            'getByIntCode': {
                url: 'api/rpt-metadata-by-intcode/:intCode',
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            }
        });
    }
})();
