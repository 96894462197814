(function() {
    'use strict';
    angular
        .module('dialysisApp')
        .factory('PatientResultDet', PatientResultDet);

    PatientResultDet.$inject = ['$resource', 'DateUtils'];

    function PatientResultDet ($resource, DateUtils) {
        var resourceUrl =  'api/patient-result-dets/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'compendium': {method: 'GET', isArray: true, url: 'api/patient-result-dets/compendium/:id/:start/:end'},
            'trending': {method: 'GET', isArray: true, url: 'api/patient-result-dets/by-patient/:id/:start/:end/:testcode'},
            'trendingRepo': {url: 'api/report/trending', method: 'POST', isArray: false},
            'getComments': {url: 'api/patient-result-dets/comments/:id', method: 'GET', isArray: true},
            'getEmployeeComments': {url: 'api/patient-result-dets/emp-comments/:id', method: 'GET', isArray: true}
        });
    }
})();
