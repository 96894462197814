(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('ResultRangeController', ResultRangeController);

    ResultRangeController.$inject = ['$scope', '$state', 'ResultRange', '$q', 'DTColumnBuilder', 'clinic',
        'DTOptionsBuilder', 'GUIUtils', '$filter', '$compile', 'dialogs'];

    function ResultRangeController($scope, $state, ResultRange, $q, DTColumnBuilder, clinic,
                                   DTOptionsBuilder, GUIUtils, $filter, $compile, $dialogs) {
        var vm = this;
        vm.title = 'Result Ranges';
        vm.entityClassHumanized = 'Result Range';
        vm.entityStateName = 'result-range';

        vm.resultRanges = [];
        vm.search = search;
        vm.loadAll = loadAll;
        vm.init = init;
        vm.dtInstance = {};

        vm.clinic = clinic;

        vm.dtOptions = DTOptionsBuilder.fromFnPromise(function () {
            var defer = $q.defer();
            if (!vm.searchQuery || vm.searchQuery == '') {
                ResultRange.getAllByClinic(vm.clinic.id, function (result) {
                    vm.resultRanges = result;
                    defer.resolve(result);
                });
            } else {
                defer.resolve($filter('filter')(vm.resultRanges, vm.searchQuery, undefined));
            }
            return defer.promise;
        }).withPaginationType('full_numbers').withBootstrap().withDOM('tip')/*.withOption('scrollX', '100%')*/
            .withOption('fnRowCallback', function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                $compile(nRow)($scope);
            });

        vm.dtColumns = [
            DTColumnBuilder.newColumn('low').withTitle('Low'),
            DTColumnBuilder.newColumn('high').withTitle('High'),
            DTColumnBuilder.newColumn('criticalLow').withTitle('CriticalLow'),
            DTColumnBuilder.newColumn('criticalHigh').withTitle('CriticalHigh'),
            DTColumnBuilder.newColumn('description').withTitle('Description'),
            DTColumnBuilder.newColumn(null).withTitle('Compendium').renderWith(function (data, type, full) {
                return data.compendium == undefined ? '' : data.compendium.code + ' - ' + data.compendium.description;
            }),
            DTColumnBuilder.newColumn(null).withTitle('Actions').withOption('width', '155px').notSortable()
                .renderWith(actionsHtml)
        ];

        function loadAll() {
            ResultRange.query(function (result) {
                vm.resultRanges = result;
                vm.searchQuery = null;
            });
        }

        function actionsHtml(data, type, full, meta) {
            return GUIUtils.getActionsTemplate(data, $state.current.name, ['all']);
        }

        function search() {
            vm.dtInstance.reloadData();
        }

        function init() {
            var dlg = $dialogs.confirm('Please Confirm','Reset all result ranges? All current result ranges will be removed', {size: 'md'});
            dlg.result.then(function(btn){
                ResultRange.init({id: vm.clinic.id}, function () {
                    vm.dtInstance.reloadData();
                });
            });
        }
    }
})();
