(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('RptTemplateDialogController', RptTemplateDialogController);

    RptTemplateDialogController.$inject = ['$timeout', '$scope', '$uibModalInstance', 'entity', 'RptTemplate', '$q',
        'RptCategorySearch', 'CoreService', 'ROLES', 'Compendium', 'lodash', 'RptLanguage',
        'DTColumnBuilder', 'DTOptionsBuilder', '$compile', 'Patient', 'moment', '$filter',
        '$rootScope', 'DataTablesService', 'GUIUtils', 'VaccineSearch', 'Employee'];

    function RptTemplateDialogController($timeout, $scope, $uibModalInstance, entity, RptTemplate, $q,
                                         RptCategorySearch, CoreService, ROLES, Compendium, _, RptLanguage,
                                         DTColumnBuilder, DTOptionsBuilder, $compile, Patient, moment, $filter,
                                         $rootScope, DataTablesService, GUIUtils, VaccineSearch, Employee) {
        var vm = this;

        vm.rptTemplate = entity;
        vm.clinic = CoreService.getCurrentClinic();
        vm.rptCategories = RptCategorySearch.query({
            query: {
                bool: {
                    must: getFilter()
                }
            }
        });
        vm.compendiums = Compendium.query();

        if (vm.rptTemplate.type == 'REPORT_CARD') {
            vm.rptLanguages = RptLanguage.query();
        }

        if (vm.rptTemplate.type == 'VACCINATION') {
            vm.vaccines = VaccineSearch.query({
                query: {
                    bool: {
                        must: [{term: {"delStatus": false}}]
                    }
                }
            });
        }

        vm.rptCompendiumRange = {compendiums: [], below: null, above: null};
        vm.form = {};

        vm.patients = [];
        vm.searchQuery = "";
        vm.dtInstance = {};
        var titleHtml = '<input type="checkbox" ng-model="vm.selectAll" ng-change="vm.toggleAll(vm.selected, vm.selectAll)">';
        vm.toggleAll = DataTablesService.toggleAll;
        vm.toggleOne = DataTablesService.toggleOne;
        vm.selected = {};
        vm.modalities = ['HEMO_DIALYSIS', 'PERITONEAL_DIALYSIS'];
        vm.physicians = Employee.employeeCorpPhysicians();

        vm.clear = clear;
        vm.save = save;
        vm.getFilter = getFilter;
        vm.addRange = addRange;
        vm.deleteRange = deleteRange;
        vm.cloneRange = cloneRange;
        vm.report = report;
        vm.openCalendar = openCalendar;
        vm.search = search;

        vm.dtOptions = DTOptionsBuilder
            .fromFnPromise(function () {
                var defer = $q.defer();
                if (!vm.searchQuery || vm.searchQuery == '') {
                    Patient.allRoster({id: vm.clinic.id}, function (result) {
                        vm.patients = result;
                        defer.resolve(result);
                    });
                } else {
                    defer.resolve($filter('filter')(vm.patients, vm.searchQuery, undefined));
                }
                return defer.promise;
            })
            .withPaginationType('full_numbers')
            .withBootstrap()
            .withDOM('ltp')
            .withLanguage({
                "paginate": {
                    "first": '<i class="fa fa-angle-double-left" aria-hidden="true"></i>',
                    "previous": '<i class="fa fa-arrow-circle-left" aria-hidden="true"></i>',
                    "last": '<i class="fa fa-angle-double-right" aria-hidden="true"></i>',
                    "next": '<i class="fa fa-arrow-circle-right" aria-hidden="true"></i>'
                }
            })
            .withOption('aaSorting', [[1, 'asc']])
            .withOption('headerCallback', function (header) {
                if (!vm.headerCompiled) {
                    vm.headerCompiled = true;
                    $compile(angular.element(header).contents())($scope);
                }
            })
            .withOption('lengthMenu', [[10, 15, 25, 50, -1], [10, 15, 25, 50, "All"]])
            .withOption('fnRowCallback',
                function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                    $compile(nRow)($scope);
                }
            );

        vm.dtColumns = [
            DTColumnBuilder.newColumn(null).withTitle(titleHtml).withOption('width', '25px').notSortable().renderWith(function (data, type, full, meta) {
                vm.selected[full.id] = false;

                return '<input type="checkbox" ng-model="vm.selected[' + data.id + ']" ng-click="vm.toggleOne(vm.selected, vm.selectAll)">';
            }),
            DTColumnBuilder.newColumn(null).withTitle('Name').withOption('width', '180px').renderWith(function (data, type, full) {
                return data.lastName + ', ' + data.firstName + ' ' + (data.middleInitial ? data.middleInitial : "");
            }),
            DTColumnBuilder.newColumn(null).withTitle('DOB').renderWith(function (data, type, full) {
                return moment(data.dob).format($rootScope.displayDateformat);
            }),
            DTColumnBuilder.newColumn('gender').withTitle('Gender'),
            DTColumnBuilder.newColumn(null).withTitle('Diabetic').renderWith(function (data, type, full) {
                return GUIUtils.colorHtmlYesNo(data.diabetic);
            })
        ];

        function search() {
            vm.dtInstance.reloadData();
        }

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            if (vm.rptTemplate.id !== null) {
                RptTemplate.update(vm.rptTemplate, onSaveSuccess, onSaveError);
            } else {
                RptTemplate.save(vm.rptTemplate, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('dialysisApp:rptTemplateUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function getFilter() {
            var must = [];
            must.push({term: {"delStatus": false}});

            if (CoreService.currentHasEmployeeRole(ROLES.ROLE_ADMIN)) {
                must.push({
                    bool: {
                        "should": [
                            {not: {exists: {"field": "clinic"}}},
                            {term: {"clinic.id": vm.clinic.id}}
                        ]
                    }
                });

            } else {
                must.push({term: {"clinic.id": vm.clinic.id}});
            }

            return must;
        }

        function addRange() {
            _.forEach(vm.rptCompendiumRange.compendiums, function (compendium) {
                vm.rptTemplate.rptCompendiumRanges.push({
                    compendium: compendium,
                    belowEq: vm.rptCompendiumRange.belowEq,
                    aboveEq: vm.rptCompendiumRange.aboveEq,
                    index: vm.rptTemplate.rptCompendiumRanges.length
                });
            });

            vm.rptCompendiumRange.compendiums = [];
        }

        function deleteRange(index) {
            vm.rptTemplate.rptCompendiumRanges.splice(index, 1);

            reindex();
        }

        function cloneRange(index) {
            vm.rptTemplate.rptCompendiumRanges.splice(index, 0, angular.copy(vm.rptTemplate.rptCompendiumRanges[index]));

            reindex();
        }

        function reindex() {
            _.forEach(vm.rptTemplate.rptCompendiumRanges, function (range, index) {
                range.index = index;
            })
        }

        function report() {
            var ids = DataTablesService.getSelectedIds(vm.selected).map(Number);
            vm.rptTemplate.patients = ids;

            RptTemplate.getReportPdf(vm.rptTemplate);
        }

        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.startDate = false;
        vm.datePickerOpenStatus.endDate = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
