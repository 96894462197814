(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('PatientOrderUpdateController', PatientOrderUpdateController);

    PatientOrderUpdateController.$inject = ['$state', 'patientOrder', 'entity', '$stateParams',
        'CoreService', 'PatientOrder', 'Via', 'Employee'];

    function PatientOrderUpdateController($state, patientOrder, entity, $stateParams,
                                        CoreService, PatientOrder,  Via, Employee) {
        var vm = this;

        vm.patientOrder = patientOrder;
        vm.patient = entity;
        vm.canSign = !vm.patientOrder.signed && CoreService.getCurrentEmployee().id == vm.patientOrder.signedBy.id;
        vm.canFinish = false;
        vm.canCancel = true;

        if (vm.patientOrder.ordStatus == 'SCHEDULED') {
            // for (var i = 0; i < patientOrder.patientOrderTests.length && vm.canCancel; i++) {
            //     for (var j = 0; j < patientOrder.patientOrderTests[i].patientOrderItems.length && vm.canCancel; j++) {
            //         vm.canCancel = !patientOrder.patientOrderTests[i].patientOrderItems[j].collected
            //             || patientOrder.patientOrderTests[i].patientOrderItems[j].canceled;
            //     }
            // }

            // vm.canFinish = !vm.canCancel;
            vm.canCancel = true;
        }

        vm.form = {};
        vm.save = save;
        vm.vias = Via.query();
        vm.employees = Employee.employeeCorpPhysicians();

        function save() {
            vm.isSaving = true;
            PatientOrder.update(vm.patientOrder, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess(result) {
            vm.isSaving = false;
            $state.go("patient-order-current", {orderType: result.orderType});
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        vm.signIn = signIn;

        function signIn() {
            if (vm.canSign) {
                vm.patientOrder.signed = true;
            }

            PatientOrder.update(vm.patientOrder, onSaveSuccess, onSaveError);
        }
    }
})();
