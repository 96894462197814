(function() {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('RptCategoryDialogController', RptCategoryDialogController);

    RptCategoryDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity',
        'RptCategory', 'CoreService', 'ROLES'];

    function RptCategoryDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity,
                                          RptCategory, CoreService, ROLES) {
        var vm = this;

        vm.rptCategory = entity;
        vm.clear = clear;
        vm.save = save;

        vm.clinics = CoreService.currentHasEmployeeRole(ROLES.ROLE_ADMIN) ? [CoreService.getCurrentClinic()] : [];

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.rptCategory.id !== null) {
                RptCategory.update(vm.rptCategory, onSaveSuccess, onSaveError);
            } else {
                RptCategory.save(vm.rptCategory, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('dialysisApp:rptCategoryUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

    }
})();
