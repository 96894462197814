(function() {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('CompositeDeleteController',CompositeDeleteController);

    CompositeDeleteController.$inject = ['$uibModalInstance', 'entity', 'Composite'];

    function CompositeDeleteController($uibModalInstance, entity, Composite) {
        var vm = this;

        vm.composite = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            Composite.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
