(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('PendingResultsTopBoxController', PendingResultsTopBoxController);

    PendingResultsTopBoxController.$inject = ['clinic', '$scope', '$state', '$q', 'DTColumnBuilder', 'PatientResult',
        'DTOptionsBuilder', '$filter', '$compile', 'GUIUtils', '$uibModal'];

    function PendingResultsTopBoxController(clinic, $scope, $state, $q, DTColumnBuilder, PatientResult,
                              DTOptionsBuilder, $filter, $compile, GUIUtils, $uibModal) {
        var vm = this;
        vm.pending = 0;

        vm.pending = PatientResult.countPending({id: clinic.id});
    }
})();
