(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('KineticInfoController', KineticInfoController);

    KineticInfoController.$inject = ['Kinetic', 'CoreService', 'patients', '$state', 'dialogs', 'lodash'];

    function KineticInfoController(Kinetic, CoreService, patients, $state, $dialogs, _) {
        var vm = this;
        vm.clinic = CoreService.getCurrentClinic();
        vm.missing = [];
        vm.editForm = {};
        vm.date = new Date();

        if (!patients || patients.length == 0) {
            $state.go("kinetic", null, {reload: true});
        } else {
            vm.patients = patients;
            var ids = [];

            for (var i = 0; i < vm.patients.length; i++) {
                ids.push(vm.patients[i].id);
            }

            Kinetic.missing({ids: ids}, function (result) {
                vm.missing = result;

                _.forEach(vm.missing, function (k) {
                    vm.datePickerOpenStatus.begin.push(false);
                    vm.datePickerOpenStatus.end.push(false);

                    k.hhmm = vm.clinic.kineticTimeFormat == 'HHMM';
                });
            });
        }

        vm.openCalendar = openCalendar;
        function openCalendar(field, index) {
            vm.datePickerOpenStatus[field][index] = true;
        }

        vm.datePickerOpenStatus = {
            begin: [],
            end: []
        };

        vm.save = function () {
            var kinetics = [];

            for (var i = 0; i < vm.missing.length; i++) {
                var kinetic = {};

                angular.copy(vm.missing[i], kinetic);

                if (!kinetic.kg) {
                    kinetic.preWeight = kinetic.preWeight * 0.453592;
                    kinetic.posWeight = kinetic.posWeight * 0.453592;
                }

                if (kinetic.hhmm) {
                    kinetic.dialysisLength = moment(kinetic.dialysisLengthHhmm, 'HH:mm').get('hour') * 60;
                    kinetic.dialysisLength += moment(kinetic.dialysisLengthHhmm, 'HH:mm').get('minutes');
                }

                //TODO This code must be fixed, this is only a tmp solution
                if(kinetic.dialysisLength !== null && kinetic.preWeight !== null && kinetic.posWeight !== null) {
                    kinetics.push(kinetic);
                }
            }

            if (kinetics.length > 0) {
                Kinetic.save({kinetics: kinetics}, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess() {
            vm.isSaving = false;
            $state.go("kinetic", null, {reload: true});
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        vm.remove = function (index) {
            vm.missing.splice(index, 1);
        }

        vm.discard = function(index) {
            var dlg = $dialogs.confirm('Please Confirm','Do you want to skip this kinetics?', {size: 'md'});
            dlg.result.then(function(btn){
                var kinetic = vm.missing.splice(index, 1);
                kinetic[0].sent = true;
                kinetic[0].preWeight = 0;
                kinetic[0].posWeight = 0;
                kinetic[0].beginDate = new Date();
                kinetic[0].endDate = new Date();
                kinetic[0].dialysisLength = 0;

                Kinetic.save({kinetics: kinetic}, function () {
                    vm.isSaving = false;
                    if (vm.missing.length == 0) {
                        $state.go("kinetic", null, {reload: true});
                    }
                }, onSaveError);
            },function(btn){
            });
        }
    }
})();
