(function () {
   'use strict';
    angular
       .module('dialysisApp')
       .controller('PatientOrderWizardDialogController', PatientOrderWizardDialogController);

    PatientOrderWizardDialogController.$inject = ['$uibModalInstance', '$scope', '$state', '$q', 'DTColumnBuilder',
        'DTOptionsBuilder', '$filter', '$compile', 'GUIUtils'];

    function PatientOrderWizardDialogController($uibModalInstance, $scope, $state, $q, DTColumnBuilder,
                                                DTOptionsBuilder, $filter, $compile, GUIUtils) {
        var vm = this;

        vm.clear = clear;

        vm.onFinishWizard = onFinishWizard;
        vm.onCancelWizard = onCancelWizard;
        vm.renderRow = false;

        vm.dummy = [];
        vm.searchQuery = "";
        vm.dtInstance = {};
        var titleHtml = '<input type="checkbox" ng-model="vm.selectAll" ng-change="vm.toggleAll()">';
        vm.search = search;
        vm.toggleAll = toggleAll;
        vm.toggleOne = toggleOne;
        vm.getSelectedIds = getSelectedIds;
        vm.selected = {};

        vm.dtOptions = DTOptionsBuilder.fromFnPromise(function () {
            var defer = $q.defer();
            if (!vm.searchQuery || vm.searchQuery == '') {
                vm.dummy = dummy();
                defer.resolve(vm.dummy);
            } else {
                defer.resolve($filter('filter')(vm.dummy, vm.searchQuery, undefined));
            }
            return defer.promise;
        })
        .withPaginationType('full_numbers')
        .withBootstrap()
        .withDOM('ltp')
        .withLanguage({
            "paginate": {
                "first": '<i class="fa fa-angle-double-left" aria-hidden="true"></i>',
                "previous": '<i class="fa fa-arrow-circle-left" aria-hidden="true"></i>',
                "last": '<i class="fa fa-angle-double-right" aria-hidden="true"></i>',
                "next": '<i class="fa fa-arrow-circle-right" aria-hidden="true"></i>'
            }
        })
        .withOption('aaSorting', [[0, 'desc'], [1, 'asc']])
        .withOption('headerCallback', function (header) {
            if (!vm.headerCompiled) {
                vm.headerCompiled = true;
                $compile(angular.element(header).contents())($scope);
            }
        })
        .withOption('lengthMenu', [[10, 15, 25, 50, -1], [10, 15, 25, 50, "All"]])
        .withOption('fnRowCallback',
            function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                $compile(nRow)($scope);
            }
        );

        vm.dtColumns = [
            DTColumnBuilder.newColumn(null).withTitle(titleHtml).withOption('width', '25px').notSortable().renderWith(function (data, type, full, meta) {
                vm.selected[full.id] = false;

                return '<input type="checkbox" ng-model="vm.selected[' + data.id + ']" ng-click="vm.toggleOne()">';
            }),
            DTColumnBuilder.newColumn('id').withTitle('ID'),
            DTColumnBuilder.newColumn('name').withTitle('Name'),
            DTColumnBuilder.newColumn(null).withTitle('DOB').renderWith(function (data, type, full) {
                return data.date ? moment(data.date).format($scope.displayDateformat) : "";
            }),
            DTColumnBuilder.newColumn('gender').withTitle('Gender'),
            DTColumnBuilder.newColumn('diabetic').withTitle('Diabetic')
        ];

        function dummy() {
            return [
                {id: 1, name: 'Epifanio Goldenberg', date: new Date(), gender: 'Male', diabetic: 'YES'},
                {id: 2, name: 'Gruttenger Aran', date: new Date(), gender: 'Female', diabetic: 'YES'},
                {id: 3, name: 'Magnus Northenfield', date: new Date(), gender: 'Male', diabetic: 'YES'},
                {id: 4, name: 'Mathias Perez', date: new Date(), gender: 'Male', diabetic: 'NO'},
                {id: 5, name: 'Sergay Fucker', date: new Date(), gender: 'Male', diabetic: 'YES'},
                {id: 6, name: 'Masseratti Aronian', date: new Date(), gender: 'Female', diabetic: 'NO'},
                {id: 7, name: 'Pericoldio Energizer', date: new Date(), gender: 'Female', diabetic: 'YES'},
                {id: 8, name: 'Dopperganger Estroncio', date: new Date(), gender: 'Other', diabetic: 'NO'},
                {id: 9, name: 'Astenerte Macedonio', date: new Date(), gender: 'Female', diabetic: 'YES'},
                {id: 10, name: 'Costrance Gasteroide', date: new Date(), gender: 'Male', diabetic: 'NO'}
            ]
        }

        function search() {
            vm.dtInstance.reloadData();
        }

        function toggleAll() {
            for (var id in vm.selected) {
                if (vm.selected.hasOwnProperty(id)) {
                    vm.selected[id] = vm.selectAll;
                }
            }
        }

        function toggleOne() {
            for (var id in vm.selected) {
                if (vm.selected.hasOwnProperty(id)) {
                    if (!vm.selected[id]) {
                        vm.selectAll = false;
                        return;
                    }
                }
            }
            vm.selectAll = true;
        }

        function getSelectedIds() {
            var ids = [];
            for (var id in vm.selected) {
                if (vm.selected[id]) {
                    ids.push(id);
                }
            }

            return ids;
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        $scope.enableDetails = function enableDetails($event) {
            var t = angular.element($event.currentTarget);
            t.parent().parent().toggleClass('hideDetails');
        };

        $scope.showDescription = function showDescription($event) {
            var t = angular.element($event.currentTarget);
            t.parent().parent().toggleClass('hideDescription');
        };

        function onFinishWizard() {

        }

        function onCancelWizard() {

        }
    }
})();
