(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('EnvOrderTemplateController', EnvOrderTemplateController);

    EnvOrderTemplateController.$inject = ['$state', 'ROLES', '$scope', '$compile', '$q', 'Principal', 'CoreService', 'EnvOrderTplSearch',
        'DTColumnBuilder', 'DTOptionsBuilder', 'EnvOrderTpl', 'GUIUtils', '$uibModal', '$filter', 'Compendium', 'dialogs'];

    function EnvOrderTemplateController($state, ROLES, $scope, $compile, $q, Principal, CoreService, EnvOrderTplSearch,
                                            DTColumnBuilder, DTOptionsBuilder, EnvOrderTpl, GUIUtils, $uibModal, $filter, Compendium, $dialogs) {

        var vm = this;

        vm.orders = [];
        vm.search = search;
        vm.dtInstance = {};
        vm.machines = [];

        vm.selectMachines = selectMachines;
        vm.selectInterval = selectInterval;
        vm.cloneTemplate = cloneTemplate;
        vm.checkTpl = checkTpl;

        vm.dtOptions = DTOptionsBuilder.fromFnPromise(function () {
            var defer = $q.defer();
            if (!vm.searchQuery || vm.searchQuery == '') {
                if (!Principal.hasRole(ROLES.ROLE_ADMIN)) {
                    EnvOrderTplSearch.query({
                        query: {
                            bool: {
                                must: [
                                    {term: {"delStatus": false}},
                                    {not: {exists: {"field": "corporation"}}}
                                ]
                            }
                        }
                    }, function (result) {
                        vm.orders = result;

                        EnvOrderTplSearch.query({
                            query: {
                                bool: {
                                    must: [
                                        {term: {"delStatus": false}},
                                        {match: {"corporation.id": CoreService.getCurrentEmployee().corporation.id}}
                                    ]
                                }
                            }
                        }, function (result) {
                            vm.orders = result.concat(vm.orders);
                            defer.resolve(vm.orders);
                        });
                    });
                } else {
                    EnvOrderTplSearch.query({
                        query: {
                            bool: {
                                must: [
                                    {term: {"delStatus": false}},
                                    {not: {exists: {"field": "corporation"}}}
                                ]
                            }
                        }
                    }, function (result) {
                        vm.orders = result;
                        defer.resolve(result);
                    });
                }
            } else {
                defer.resolve($filter('filter')(vm.orders, vm.searchQuery, undefined));
            }
            return defer.promise;
        }).withBootstrap().withDOM('tip').withOption('aaSorting', [[1, 'asc']]).withOption('fnRowCallback',
            function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                $compile(nRow)($scope);
            });

        vm.dtColumns = [
            DTColumnBuilder.newColumn('id').withTitle('ID'),
            DTColumnBuilder.newColumn('description').withTitle('Description'),
            DTColumnBuilder.newColumn(null).withTitle('Corporation').renderWith(function (data, type, full) {
                return data.corporation ? data.corporation.name : "";
            }),
            DTColumnBuilder.newColumn(null).withTitle('Created Date').renderWith(function (data, type, full) {
                return moment(data.createdDate).format("MM/DD/YYYY");
            }),
            DTColumnBuilder.newColumn(null).withTitle('Actions').withOption('width', '200px').notSortable()
                .renderWith(actionsHtml)
        ];

        function actionsHtml(data, type, full, meta) {
            var stButtons = "";
            stButtons += '<a class="btn-sm btn-primary" ng-click="vm.checkTpl(' + data.id + ', 0)"> <i class="fa fa-clone"></i></a>&nbsp;';
            stButtons += Principal.hasRole(ROLES.ROLE_ADMIN) ? '' :
                '<a class="btn-sm btn-primary" ng-click="vm.checkTpl(' + data.id + ', 1)"> <i class="glyphicon glyphicon-paste"></i></a>&nbsp;';

            // return stButtons + GUIUtils.getActionsTemplate(data, $state.current.name, ['update', 'delete']);

            if (data.corporation || (!data.corporation && Principal.hasRole(ROLES.ROLE_ADMIN))) {
                return stButtons + GUIUtils.getActionsTemplate(data, $state.current.name, ['update', 'delete']);
            } else {
                return stButtons;
            }
        }

        function search() {
            vm.dtInstance.reloadData();
        }

        function selectMachines(id) {
            $uibModal.open({
                templateUrl: 'app/entities/machine/machine-select-dialog.html',
                controller: 'MachineSelectDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    clinic: CoreService.getCurrentClinic(),
                    machines: {selected: vm.machines}
                }
            }).result.then(function (result) {
                vm.machines = result.machines;
                vm.selectInterval(id);
            }, function () {
            });
        }

        function selectInterval (id) {
            $uibModal.open({
                templateUrl: 'app/entities/window-picker/env-test-interval-dialog.html',
                controller: 'EnvTestIntervalDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    envOrderTpl: ['EnvOrderTpl', function (EnvOrderTpl) {
                        return EnvOrderTpl.get({id: id}).$promise;
                    }],
                    clinic: CoreService.getCurrentClinic()
                }
            }).result.then(function (result) {
                $state.go("env-order-new-all", {orderTpl: result.tpl, machines: vm.machines, allMachines: vm.machinesOrigin}, {reload: true});
            }, function () {
            });
        }

        function cloneTemplate(id) {
            $state.go("env-order-tpl.edit", {id: id, clone: true}, {reload: true});
        }

        function checkTpl(id, action) {
            EnvOrderTpl.get({id: id}, function (tpl) {
                var compendiums = _.map(tpl.envOrderJson.envTests, 'compendium');

                Compendium.check({compendiums: compendiums}, function (result) {
                    if (result.compendiums.length > 0) {
                        var msg = _.map(result.compendiums, function (compendium) {
                            return "Test: " + compendium.code + (compendium.description ? ' - ' + compendium.description : '');
                        }).join('<br>');

                        $dialogs.error("Warning.", "This template need review some tests don't exist and need to be removed or changed from the template <br>" + msg);
                    } else {
                        switch (action) {
                            case 0: {
                                cloneTemplate(id);
                                break;
                            }
                            case 1: {
                                selectMachines(id);
                                break;
                            }
                        }
                    }
                });
            });
        }
    }
})();
