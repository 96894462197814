(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('MachineSelectDialogController', MachineSelectDialogController);

    MachineSelectDialogController.$inject = ['$uibModalInstance', '$scope', 'clinic', 'Machine', '$q', 'DTColumnBuilder', 'DTOptionsBuilder', 'GUIUtils', '$filter', '$compile', 'machines'];

    function MachineSelectDialogController($uibModalInstance, $scope, clinic, Machine, $q, DTColumnBuilder, DTOptionsBuilder, GUIUtils, $filter, $compile, machines) {
        var vm = this;
        vm.machines = machines.selected;

        vm.clear = function () {
            $uibModalInstance.dismiss('cancel');
        }

        vm.accept = function () {
            var machines = [];
            for (var id in vm.selected) {
                if (vm.selected[id]) {
                    var _machine = $.grep(vm.machinesList, function (machine, i) {
                        return machine.id == id;
                    })[0];

                    machines.push(_machine);
                }
            }

            if (machines.length > 0) {
                $uibModalInstance.close({machines: machines});
            }
        }

        vm.machinesList = [];
        vm.search = search;
        vm.dtInstance = {};

        vm.selected = {};
        vm.selectAll = false;
        vm.toggleAll = toggleAll;
        vm.toggleOne = toggleOne;
        var titleHtml = '<input type="checkbox" ng-model="vm.selectAll" ng-change="vm.toggleAll()">';

        vm.dtOptions = DTOptionsBuilder.fromFnPromise(function () {
            var defer = $q.defer();
            if (vm.isFilter) {
                defer.resolve(vm.machinesList);
                vm.isFilter = false;
            } else if (!vm.searchQuery || vm.searchQuery == '') {
                // MachineSearch.query({
                //     query: {
                //         bool: {
                //             must: [
                //                 {term: {"clinic.id": clinic.id}},
                //                 {term: {"delStatus": false}},
                //                 {term: {"mStatus": true}}
                //             ]
                //         }
                //     }
                // }, function (result) {
                //     vm.machinesList = result;
                //     defer.resolve(result);
                // });

                Machine.get_all_active({"id": clinic.id
                }, function (result) {
                    vm.machinesList = result;
                    defer.resolve(result);
                });

            } else {
                defer.resolve($filter('filter')(vm.machinesList, vm.searchQuery, undefined));
            }
            return defer.promise;
        }).withPaginationType('full_numbers').withBootstrap().withDOM('tip')
            .withOption('createdRow', function (row, data, dataIndex) {
                $compile(angular.element(row).contents())($scope);
            })
            .withOption('headerCallback', function (header) {
                if (!vm.headerCompiled) {
                    vm.headerCompiled = true;
                    $compile(angular.element(header).contents())($scope);
                }
            }).withOption('aaSorting', [[1, 'ASC']]);

        vm.dtColumns = [
            DTColumnBuilder.newColumn(null).withTitle(titleHtml).notSortable().renderWith(function (data, type, full, meta) {
                var _machine = $.grep(vm.machines, function (machine, i) {
                    return machine.id == data.id;
                })[0];

                vm.selected[full.id] = _machine != null;

                return '<input type="checkbox" ng-model="vm.selected[' + data.id + ']" ng-click="vm.toggleOne()">';
            }),
            DTColumnBuilder.newColumn('name').withTitle('Name'),
            DTColumnBuilder.newColumn('location').withTitle('Location'),
            DTColumnBuilder.newColumn(null).withTitle('Active').renderWith(function (data, type, full) {
                return data.mStatus ? GUIUtils.getStatusTemplate('Yes', 'success') : GUIUtils.getStatusTemplate('No', 'danger');
            }),
            DTColumnBuilder.newColumn('mType').withTitle('Type'),
        ];

        function search() {
            vm.dtInstance.reloadData();
        }

        function toggleAll() {
            for (var id in vm.selected) {
                if (vm.selected.hasOwnProperty(id)) {
                    vm.selected[id] = vm.selectAll;
                }
            }
        }

        function toggleOne() {
            for (var id in vm.selected) {
                if (vm.selected.hasOwnProperty(id)) {
                    if (!vm.selected[id]) {
                        vm.selectAll = false;
                        return;
                    }
                }
            }

            vm.selectAll = true;
        }
    }
})();
