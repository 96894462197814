(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('EnvOrderNewTemplateController', EnvOrderNewTemplateController);

    EnvOrderNewTemplateController.$inject = ['$scope', '$compile', '$state', 'envOrderTpl', '$q', 'DTColumnBuilder', 'DTOptionsBuilder', '$stateParams', 'ROLES',
        'CoreService', 'EnvOrderTpl', '$uibModal', 'Principal', 'Corporation', 'Compendium', 'lodash'];

    function EnvOrderNewTemplateController($scope, $compile, $state, envOrderTpl, $q, DTColumnBuilder, DTOptionsBuilder, $stateParams, ROLES,
                                               CoreService, EnvOrderTpl, $uibModal, Principal, Corporation, Compendium, _) {

        var vm = this;

        vm.envOrderTpl = envOrderTpl;
        vm.envOrder = envOrderTpl.envOrderJson;
        vm.compendiumErrors = [];

        vm.form = {};
        vm.save = save;

        if (Principal.hasRole(ROLES.ROLE_ADMIN)) {
            vm.corporations = Corporation.query();
        } else {
            vm.corporations = [CoreService.getCurrentEmployee().corporation];
            vm.envOrderTpl.corporation = CoreService.getCurrentEmployee().corporation;
            vm.envOrder.corporation = CoreService.getCurrentEmployee().corporation;
        }

        if ($stateParams.clone) {
            vm.envOrderTpl.id = null;
        }

        function save() {
            vm.isSaving = true;

            vm.envOrderTpl.envOrderJson = vm.envOrder;
            vm.envOrderTpl.corporation = vm.envOrder.corporation;

            vm.isSaving = true;
            if (vm.envOrderTpl.id !== null) {
                EnvOrderTpl.update(vm.envOrderTpl, onSaveSuccess, onSaveError);
            } else {
                EnvOrderTpl.save(vm.envOrderTpl, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess() {
            vm.isSaving = false;
            $state.go("env-order-tpl");
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        vm.addEnvOrderTest = function () {
            var test = {
                id: null
            };

            vm.popupTest(test, -1);
        };

        vm.editEnvOrderTest = function (index) {
            vm.popupTest(vm.envOrder.envTests[index], index);
        };

        vm.deleteEnvOrderTest = function (index) {
            $uibModal.open({
                templateUrl: 'app/entities/env-test/env-test-delete-dialog.html',
                controller: 'EnvTestDeleteNewController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    entity: {id: index}
                }
            }).result.then(function () {
                vm.envOrder.envTests.splice(index, 1);

                vm.dtInstance.reloadData();
            }, function () {
            });
        };

        vm.popupTest = function (envOrderTest, index) {
            $uibModal.open({
                templateUrl: 'app/entities/env-order-tpl/env-order-test-tpl-new-dialog.html',
                controller: 'EnvOrderTestTplNewDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    envOrder: vm.envOrder,
                    envOrderTest: envOrderTest,
                }
            }).result.then(function (result) {
                if (index < 0) {
                    vm.envOrder.envTests.push(result.envTest);
                } else {
                    vm.envOrder.envTests[index] = result.envTest;
                }
                vm.dtInstance.reloadData();
            }, function () {
            });
        };

        vm.dtInstance = {};

        vm.dtOptions = DTOptionsBuilder.fromFnPromise(function () {
            var defer = $q.defer();

            vm.compendiumErrors = [];
            var compendiums = _.map(vm.envOrder.envTests, 'compendium');

            Compendium.check({compendiums: compendiums}, function (result) {
                if (result.compendiums.length > 0) {
                    _.forEach(result.compendiums, function (compendium) {
                        return vm.compendiumErrors.push(compendium.code);
                    });
                }

                defer.resolve(vm.envOrder.envTests);
            });

            return defer.promise;
        }).withPaginationType('full_numbers').withBootstrap().withDOM('tip').withOption('paging', false).withOption('aaSorting', []).withOption('fnRowCallback',
            function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                $compile(nRow)($scope);
            });

        vm.dtColumns = [
            DTColumnBuilder.newColumn(null).withTitle('Test').renderWith(function (data, type, full) {
                return (vm.compendiumErrors.indexOf(data.compendium.code) == -1 ? data.compendium.code : '<span class="label label-danger">' + data.compendium.code + '</span>');
            }),
            DTColumnBuilder.newColumn(null).withTitle('Description').renderWith(function (data, type, full) {
                return data.compendium.description;
            }),
            DTColumnBuilder.newColumn(null).withTitle('Description').renderWith(function (data, type, full) {
                return data.orderFrequency.name;
            }),
            DTColumnBuilder.newColumn(null).withTitle('Actions').withOption('width', '155px').notSortable()
                .renderWith(actionsHtml)
        ];

        function actionsHtml(data, type, full, meta) {
            var stButtons = '';

            stButtons += '<a class="btn-sm btn-warning" ng-click="vm.editEnvOrderTest(' + meta.row + ')">' +
                '   <i class="fa fa-edit"></i></a>&nbsp;';
            stButtons += '<a class="btn-sm btn-danger" ng-click="vm.deleteEnvOrderTest(' + meta.row + ')">' +
                '   <i class="fa fa-trash"></i></a>';

            return stButtons;
        };
    }
})();
