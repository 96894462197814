(function() {
    'use strict';

    angular
        .module('dialysisApp')
        .factory('MedRouteSearch', MedRouteSearch);

    MedRouteSearch.$inject = ['$resource'];

    function MedRouteSearch($resource) {
        var resourceUrl =  'api/_search/med-routes/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
