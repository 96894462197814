  (function () {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('PatientOrderNewController', PatientOrderNewController);

    PatientOrderNewController.$inject = ['ROLES', '$scope', '$compile', '$state', 'ProblemList', 'patientOrder', 'entity', '$q', 'DTColumnBuilder', 'DTOptionsBuilder', 'GUIUtils',
        'CoreService', 'EMPLOYEE_TYPE', 'hasStanding', 'patients', 'patientList', 'orderFrequencies',
        'OrderFrequency', 'PatientOrder', 'Via', 'Employee', '$uibModal', 'Patient', '$stateParams'];

    function PatientOrderNewController(ROLES, $scope, $compile, $state, ProblemList, patientOrder, entity, $q, DTColumnBuilder, DTOptionsBuilder, GUIUtils,
                                       CoreService, EMPLOYEE_TYPE, hasStanding, patients, patientList, orderFrequencies,
                                       OrderFrequency, PatientOrder, Via, Employee, $uibModal, Patient, $stateParams) {

        var vm = this;

        vm.patient = entity;
        vm.clinic = CoreService.getCurrentClinic();
        vm.patientOrder = patientOrder;
        vm.patientOrder.patient = vm.patient;
        vm.patientOrder.employee = CoreService.getCurrentEmployee();
        vm.access = ROLES.ROLE_SUPERVISOR;

        vm.patients = patients ? patients.selected : [entity];

        vm.patientList = patientList;
        vm.orderfrequencies = orderFrequencies;

        if (vm.patientOrder.employee.employeeType.name == EMPLOYEE_TYPE.PHYSICIAN && CoreService.currentHasEmployeeRole(ROLES.ROLE_DOCTOR)) {
            vm.patientOrder.signedBy = vm.patientOrder.employee;
            vm.patientOrder.signed = true;
        }

        vm.problems = ProblemList.getAllActiveByPatientId({id: vm.patient.id});
        vm.hasStanding = hasStanding.value;

        vm.form = {};
        vm.save = save;
        vm.vias = Via.query();
        vm.employees = Employee.employeeCorpPhysicians();

        function save() {
            vm.isSaving = true;
            vm.patientOrder.icd10Codes = '';

            for (var i = 0; i < vm.problems.length; i++) {
                if (i > 0) {
                    vm.patientOrder.icd10Codes += ','
                }
                vm.patientOrder.icd10Codes += vm.problems[i].diagnosis.icd10Code;
            }

            var req = {
                patientOrder: vm.patientOrder,
                ids: [],
                orderTplId: $stateParams.orderTpl ? $stateParams.orderTpl.id : null
            };

            if (vm.patients.length > 0) {
                for (var j = 0; j < vm.patients.length; j++) {
                    req.ids.push(vm.patients[j].id);
                }
            } else {
                req.ids.push(vm.patientOrder.patient.id);
            }

            PatientOrder.save_many(req, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess() {
            vm.isSaving = false;
            $state.go("patient-order-current", {orderType: vm.patientOrder.orderType});
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        vm.changeOrderType = changeOrderType;

        function changeOrderType() {
            if (vm.patientOrder.orderType == 'STANDING') {
                vm.patientList = Patient.getWithOutStanding({id: CoreService.getCurrentClinic().id});
            } else {
                vm.patientList = Patient.getAllByClinic({id: CoreService.getCurrentClinic().id});
            }

            vm.patients = [];

            vm.orderfrequencies = OrderFrequency.byordertype({orderType: vm.patientOrder.orderType}).$promise.then(function (data) {
                vm.orderfrequencies = data;
            });
        }

        vm.addPatientOrderTest = function () {
            var test = {
                renal: false,
                staringDate: null,
                id: null,
                orderFrequency: vm.orderfrequencies[0],
                patientOrderItems: [],
                diagnoses: []
            };

            vm.popupTest(test, -1);
        };

        vm.editPatientOrderTest = function (index) {
            vm.popupTest(vm.patientOrder.patientOrderTests[index], index);
        };

        vm.deletePatientOrderTest = function (index) {
            $uibModal.open({
                templateUrl: 'app/entities/patient-order-test/patient-order-test-delete-dialog.html',
                controller: 'PatientOrderTestDeleteNewController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    entity: {id: index}
                }
            }).result.then(function () {
                vm.patientOrder.patientOrderTests.splice(index, 1);

                vm.dtInstance.reloadData();
            }, function () {
            });
        };

        vm.showICD10 = function (index) {
            $uibModal.open({
                templateUrl: 'app/entities/diagnosis/diagnosis-dialog.html',
                controller: 'DiagnosisDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    entity: vm.patientOrder.patientOrderTests[index]
                }
            });
        };

        vm.popupTest = function (patientOrderTest, index) {
            $uibModal.open({
                templateUrl: 'app/entities/patient-order-test/patient-order-test-new-dialog.html',
                controller: 'PatientOrderTestNewDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: entity,
                    patientOrder: vm.patientOrder,
                    patientOrderTest: patientOrderTest,
                    problems: {problems: vm.problems}
                }
            }).result.then(function (result) {
                if (index < 0) {
                    vm.patientOrder.patientOrderTests.push(result.patientOrderTest);
                } else {
                    vm.patientOrder.patientOrderTests[index] = result.patientOrderTest;
                }
                vm.dtInstance.reloadData();
            }, function () {
            });
        };

        vm.dtInstance = {};

        vm.dtOptions = DTOptionsBuilder.fromFnPromise(function () {
            var defer = $q.defer();
            defer.resolve(vm.patientOrder.patientOrderTests);

            return defer.promise;
        }).withPaginationType('full_numbers').withBootstrap().withDOM('tip').withOption('paging', false).withOption('aaSorting', []).withOption('fnRowCallback',
            function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                $compile(nRow)($scope);
            });

        vm.dtColumns = [
            DTColumnBuilder.newColumn(null).withTitle('Test').renderWith(function (data, type, full) {
                return data.compendium.code;
            }),
            DTColumnBuilder.newColumn(null).withTitle('Description').renderWith(function (data, type, full) {
                return data.compendium.description;
            }),
            DTColumnBuilder.newColumn(null).withTitle('Frequency').renderWith(function (data, type, full) {
                return data.orderFrequency.name;
            }),
            DTColumnBuilder.newColumn(null).withTitle('Diagnosis (ICD10)').renderWith(function (data, type, full, meta) {
                return data.diagnoses != null && data.diagnoses.length == 1 ? data.diagnoses[0].icd10Code : data.diagnoses[0].icd10Code + showICD10Html(meta.row);
            }),
            DTColumnBuilder.newColumn(null).withTitle('Renal').renderWith(function (data, type, full) {
                return GUIUtils.colorHtmlYesNo(data.renal);
            }),
            DTColumnBuilder.newColumn(null).withTitle('Start Date').renderWith(function (data, type, full) {
                return moment(data.staringDate).format("MM/DD/YYYY");
            }),
            DTColumnBuilder.newColumn(null).withTitle('End Date').renderWith(function (data, type, full) {
                return moment(data.endDate).format("MM/DD/YYYY");
            }),
            DTColumnBuilder.newColumn(null).withTitle('Actions').withOption('width', '155px').notSortable()
                .renderWith(actionsHtml)
        ];

        function actionsHtml(data, type, full, meta) {
            var stButtons = '';

            stButtons += '<a class="btn-sm btn-warning" ng-click="vm.editPatientOrderTest(' + meta.row + ')">' +
                '   <i class="fa fa-edit"></i></a>&nbsp;';
            stButtons += '<a class="btn-sm btn-danger" ng-click="vm.deletePatientOrderTest(' + meta.row + ')">' +
                '   <i class="fa fa-trash"></i></a>';

            return stButtons;
        };

        function showICD10Html(row) {
            return '&nbsp;&nbsp;<a class="btn-sm btn-info" ng-click="vm.showICD10(' + row + ')">' +
                '   <i class="fa fa-list-ol"></i></a>';
        };

        vm.selectPatients = function () {
            if (vm.patientOrder.orderType == 'STANDING') {
                $uibModal.open({
                    templateUrl: 'app/entities/patient/patient-select-dialog.html',
                    controller: 'PatientStandingSelectDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        clinic: vm.clinic,
                        patients: {selected: vm.patients}
                    }
                }).result.then(function (result) {
                    vm.patientOrder.patient = result.patients[0];
                    vm.patients = result.patients;
                }, function () {
                });
            } else {
                $uibModal.open({
                    templateUrl: 'app/entities/patient/patient-select-dialog.html',
                    controller: 'PatientSelectDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        clinic: vm.clinic,
                        patients: {selected: vm.patients}
                    }
                }).result.then(function (result) {
                    vm.patientOrder.patient = result.patients[0];
                    vm.patients = result.patients;
                }, function () {
                });
            }
        }

        vm.f = function() {
            vm.patientOrder.patient = vm.patients[0];
        }
    }
})();
