(function() {
    'use strict';

    angular
        .module('dialysisApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('rpt-template-admin', {
            parent: 'entity',
            url: '/rpt-template-admin',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'Rpt Templates Admin'
            },
            ncyBreadcrumb: {
                label: 'Report Templates Admin',
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/rpt-template/rpt-template-admin.html',
                    controller: 'RptTemplateAdminController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
            }
        })
        .state('rpt-template', {
            parent: 'entity',
            url: '/rpt-template',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'Report 2.0'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/rpt-template/rpt-templates.html',
                    controller: 'RptTemplateController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
            }
        })
        .state('rpt-template-admin.new', {
            parent: 'rpt-template-admin',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/rpt-template/rpt-template-admin-dialog.html',
                    controller: 'RptTemplateAdminDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                name: null,
                                description: null,
                                type: null,
                                orientation: null,
                                active: true,
                                patientStatus: null,
                                modality: null,
                                shift: null,
                                diabetic: null,
                                allResult: false,
                                id: null,
                                rptCompendiumRanges: [],
                                vaccinated: true,
                                vaccines: [],
                                compendiums: []
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('rpt-template-admin', null, { reload: 'rpt-template-admin' });
                }, function() {
                    $state.go('rpt-template-admin');
                });
            }]
        })
        .state('rpt-template-admin.edit', {
            parent: 'rpt-template-admin',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/rpt-template/rpt-template-admin-dialog.html',
                    controller: 'RptTemplateAdminDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['RptTemplate', function(RptTemplate) {
                            return RptTemplate.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('rpt-template-admin', null, { reload: 'rpt-template-admin' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('rpt-template-admin.delete', {
            parent: 'rpt-template-admin',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/rpt-template/rpt-template-delete-dialog.html',
                    controller: 'RptTemplateDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['RptTemplate', function(RptTemplate) {
                            return RptTemplate.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('rpt-template-admin', null, { reload: 'rpt-template-admin' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
