(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('MachineMenuController', MachineMenuController);

    MachineMenuController.$inject = ['$stateParams', '$state', '$uibModal', 'CoreService'];

    function MachineMenuController($stateParams, $state, $uibModal, CoreService) {
        var machine = CoreService.getCurrentMachine();

        if (machine) {
            switch ($stateParams.statem) {
                case 'env-order':
                    $state.go("env-order", {"id": machine.id});
                    break;
                case 'latest-env-result':
                    $state.go("machine-env-result", {"id": machine.id});
                    break;
            }
        } else {
            switch ($stateParams.statem) {
                case 'latest-env-result' :
                    $state.go("latest-env-result");
                    break;
                default : {
                    $state.go("machine");
                    // $uibModal.open({
                    //     templateUrl: 'app/entities/machine/machine-menu-dialog.html',
                    //     controller: ['$uibModalInstance', function ($uibModalInstance) {
                    //         var vm = this;
                    //
                    //         vm.dashboard = dashboard;
                    //
                    //         function dashboard() {
                    //             $uibModalInstance.dismiss('cancel');
                    //             $state.go("home");
                    //         }
                    //
                    //         vm.machine = machine;
                    //
                    //         function machine() {
                    //             $uibModalInstance.dismiss('cancel');
                    //             $state.go("machine");
                    //         }
                    //     }],
                    //     controllerAs: 'vm',
                    //     backdrop: 'static',
                    //     size: 'sm'
                    // });
                }
            }
        }
    }
})();
