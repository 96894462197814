(function() {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('InfectionDialogController', InfectionDialogController);

    InfectionDialogController.$inject = ['$timeout', '$scope', '$uibModalInstance', 'infection', '$state',
        'Infection', 'Source', 'InfectionPrimaryAccess', 'DialyserType', 'Symptom'];

    function InfectionDialogController ($timeout, $scope, $uibModalInstance, infection, $state,
                                        Infection, Source, InfectionPrimaryAccess, DialyserType, Symptom) {
        var vm = this;

        vm.infection = infection;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.sources = Source.query();
        vm.infectionprimaryaccesses = InfectionPrimaryAccess.query();
        vm.dialysertypes = DialyserType.query();
        vm.symptoms = Symptom.query();
        vm.editing = $state.current.name != "infection.view";

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.infection.id !== null) {
                Infection.update(vm.infection, onSaveSuccess, onSaveError);
            } else {
                Infection.save(vm.infection, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('dialysisApp:infectionUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.suspectedDate = false;
        vm.datePickerOpenStatus.confirmedDate = false;
        vm.datePickerOpenStatus.hospitalizationDate = false;
        vm.datePickerOpenStatus.drInfectionDate = false;
        vm.datePickerOpenStatus.aStartedDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
