(function() {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('CompositeDialogController', CompositeDialogController);

    CompositeDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Composite',
        'Compendium', 'BillingFrequency', 'CoreService'];

    function CompositeDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Composite,
                                        Compendium, BillingFrequency, CoreService) {
        var vm = this;

        vm.composite = entity;
        vm.compendiums = [];
        vm.billingfrequencies = BillingFrequency.query();

        vm.clear = clear;
        vm.save = save;
        vm.onChangeDialysisModality = onChangeDialysisModality;

        if (vm.composite.id) {
            Compendium.byClinicCompositeDif({
                id: vm.composite.clinic.id, modality: vm.composite.dialysisModality
            }, function (result) {
                vm.compendiums = result;
                vm.compendiums.push(vm.composite.compendium);
            });
        } else {
            vm.composite.clinic = CoreService.getCurrentClinic();
        }

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.composite.id !== null) {
                Composite.update(vm.composite, onSaveSuccess, onSaveError);
            } else {
                Composite.save(vm.composite, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('dialysisApp:compositeUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function onChangeDialysisModality() {
            vm.composite.compendium = null;

            vm.compendiums = [];

            Compendium.byClinicCompositeDif({
                id: vm.composite.clinic.id, modality: vm.composite.dialysisModality
            }, function (result) {
                vm.compendiums = result;
            });
        }
    }
})();
