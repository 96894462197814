(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('PatientHealthDialogController', PatientHealthDialogController);

    PatientHealthDialogController.$inject = ['$uibModalInstance', '$scope', 'clinic', 'Patient', '$q',
        'DTColumnBuilder', 'DTOptionsBuilder', '$filter', '$compile', 'patientResultHealth', 'patientResultHealthItem'];

    function PatientHealthDialogController($uibModalInstance, $scope, clinic, Patient, $q,
                                           DTColumnBuilder, DTOptionsBuilder, $filter, $compile, patientResultHealth, patientResultHealthItem) {
        var vm = this;
        vm.clinic = clinic;
        vm.patientResultHealth = patientResultHealth;
        vm.patientResultHealthItem = patientResultHealthItem;

        vm.clear = function () {
            $uibModalInstance.dismiss('cancel');
        };

        vm.patientsList = [];
        vm.search = search;
        vm.dtInstance = {};

        vm.dtOptions = DTOptionsBuilder.fromFnPromise(function () {
            var defer = $q.defer();
            if (vm.isFilter) {
                defer.resolve(vm.patientsList);
                vm.isFilter = false;
            } else if (!vm.searchQuery || vm.searchQuery == '') {
                Patient.byHealthParams({
                    clinicId: vm.clinic.id,
                    patientResultHealth: patientResultHealth,
                    patientResultHealthItem: patientResultHealthItem
                }, function (result) {
                    vm.patientsList = result;
                    defer.resolve(result);
                });
            } else {
                defer.resolve($filter('filter')(vm.patientsList, vm.searchQuery, undefined));
            }
            return defer.promise;
        }).withPaginationType('full_numbers').withBootstrap().withOption('aaSorting', [[0, 'asc']]).withDOM('tip')
            .withOption('createdRow', function (row, data, dataIndex) {
                $compile(angular.element(row).contents())($scope);
            })
            .withOption('headerCallback', function (header) {
                if (!vm.headerCompiled) {
                    vm.headerCompiled = true;
                    $compile(angular.element(header).contents())($scope);
                }
            });

        vm.dtColumns = [
            DTColumnBuilder.newColumn(null).withTitle('Name').renderWith(function (data, type, full) {
                return data.lastName + ', ' + data.firstName + ' ' + (data.middleInitial ? data.middleInitial : "");
            }),
            DTColumnBuilder.newColumn(null).withTitle('DOB').renderWith(function (data, type, full) {
                return moment(data.dob).format("MM/DD/YYYY");
            }),
            DTColumnBuilder.newColumn('gender').withTitle('Gender'),
        ];

        function search() {
            vm.dtInstance.reloadData();
        };
    }
})();
