(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider', '$breadcrumbProvider', 'IdleProvider', 'IDLE'];

    function stateConfig($stateProvider, $breadcrumbProvider, IdleProvider, IDLE) {

        /**
         * Config the BreadCrumb
         */

        $breadcrumbProvider.setOptions({
            prefixStateName: 'home',
            templateUrl: 'app/components/tpl/breadcrumb.tpl.html'
        });

        $stateProvider
            .state('app', {
                abstract: true,
                views: {
                    'headBar@': {
                        templateUrl: 'app/layouts/header/header.tpl.html',
                        controller: 'HeaderController',
                        controllerAs: 'vm'
                    },
                    'sideBar@': {
                        templateUrl: 'app/layouts/sidebar/sidebar.tpl.html',
                        controller: 'SidebarController',
                        controllerAs: 'vm'
                    },
                    'footer@': {
                        templateUrl: 'app/layouts/footer/footer.tpl.html',
                        controller: 'FooterController',
                        controllerAs: 'vm'
                    },
                    'controlBar@': {
                        templateUrl: 'app/layouts/controlbar/controlsidebar.tpl.html',
                        controller: 'ControlBarController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    authorize: ['Auth',
                        function (Auth) {
                            return Auth.authorize();
                        }
                    ],
                    employee: ['Employee', function (Employee) {
                        return Employee.employeeAccount().$promise;
                    }],
                    clinics: ['Clinic', function (Clinic) {
                        return Clinic.query().$promise;
                    }]
                }
            });

        IdleProvider.idle(IDLE.idle);
        IdleProvider.timeout(IDLE.timeout);
    }
})();
