(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('PatientOrderTestNewDialogController', PatientOrderTestNewDialogController);

    PatientOrderTestNewDialogController.$inject = ['CoreService', 'DateUtils', 'problems', 'CompendiumSearch', 'DiagnosisSearch', 'OrderFrequency', 'patientOrder', 'patientOrderTest', 'PatientOrder', '$uibModalInstance'];

    function PatientOrderTestNewDialogController(CoreService, DateUtils, problems, CompendiumSearch, DiagnosisSearch, OrderFrequency, patientOrder, patientOrderTest, PatientOrder, $uibModalInstance) {
        var vm = this;

        vm.patientOrder = patientOrder;
        vm.patientOrderTest = patientOrderTest;
        vm.is_new = vm.patientOrderTest.patientOrderItems.length == 0;
        angular.copy(problems.problems, vm.problems = []);
        angular.copy(problems.problems, vm.problemsChk = []);
        vm.clinic = CoreService.getCurrentClinic();

        if (!vm.patientOrder.patient.clinic) {
            vm.patientOrder.patient.clinic = vm.clinic;
        }

        if (vm.patientOrder.orderType == 'STANDING') {
            vm.patientOrderTest.endDate = DateUtils.convertLocalDateFromServer(vm.clinic.standingEnd);
        }

        var days = !vm.patientOrder.patient.drawDays || vm.patientOrder.patient.drawDays == '0000000' ? vm.clinic.drawDays : vm.patientOrder.patient.drawDays;

        vm.form = {};
        vm.formProblem = {};
        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.endDate = false;
        vm.datePickerOpenStatus.staringDate = false;

        vm.startOptions = {
            minDate: new Date(),
            dateDisabled: disabled,
            startingDay: 1
        }

        vm.endOptions = {
            minDate: new Date(),
            // dateDisabled: disabled,
            startingDay: 1
        }

        vm.change = function () {
            if (vm.patientOrderTest.staringDate && (vm.patientOrder.orderType == 'ONE_TIME' || vm.patientOrderTest.endDate) &&
                vm.patientOrderTest.orderFrequency && vm.patientOrderTest.compendium) {

                vm.scheduleTest();
            }

            vm.endOptions.minDate = vm.patientOrderTest.staringDate;
        }

        function disabled(date) {
            if (vm.patientOrder.orderType == "ONE_TIME") {
                return false;
            }

            var match = false;
            if (date.date.getDay() == 0) {
                //sunday js(0) -> java(6)
                match = date.mode === 'day' && days[6] == "0";
            } else {
                //others days
                match = date.mode === 'day' && days[date.date.getDay() - 1] == "0";
            }

            if (!match) {
                switch (vm.clinic.pDrawWeek) {
                    case 'FIRST_HALF_WEEK': {
                        if (date.date.getDate() < 8) {
                            match = false;
                        } else {
                            match = true;
                        }

                        break;
                    }
                    case 'FIRST_FULL_WEEK': {
                        var x = date.date.getDate() + 1 - (date.date.getDay() == 0 ? 7 : date.date.getDay());
                        match = x <= 0 || x >= 8;

                        break;
                    }
                    case 'SECOND_FULL_WEEK': {
                        var x = date.date.getDate() + 1 - (date.date.getDay() == 0 ? 7 : date.date.getDay()) - 7;
                        match = x <= 0 || x >= 8;

                        break;
                    }
                }
            }

            return match;
        }

        vm.clear = function () {
            $uibModalInstance.dismiss('cancel');
        }

        vm.openCalendar = openCalendar;
        vm.orderfrequencies = [];

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        vm.compendiums = [];

        vm.getCompendium = getCompendium;

        function getCompendium(query) {
            if (query && query.length > 0) {
                CompendiumSearch.query({query: query}, function (result) {
                    vm.compendiums = result;
                });
            }
        }

        vm.diagnoses = [];

        vm.getDiagnoses = getDiagnoses;

        function getDiagnoses(query) {
            if (query && query.length > 0) {
                DiagnosisSearch.query({query: query}, function (result) {
                    vm.diagnoses = result;
                });
            }
        }

        vm.scheduleTest = scheduleTest;

        function scheduleTest() {
            var orderCopy = {};
            angular.copy(vm.patientOrder, orderCopy);
            angular.copy([vm.patientOrderTest], orderCopy.patientOrderTests);

            PatientOrder.schedule(orderCopy, function (data) {
                vm.patientOrderTest = data.patientOrderTests[0];

                vm.alerts = [];
                vm.alerts.push({msg: 'Dates may have been adjusted!'});
            }, function () {
                vm.alerts = [];
                vm.alerts.push({type: 'danger', msg: 'There was an error planning the test, check the dates!'});
            });
        }

        changeOrderType();
        vm.changeOrderType = changeOrderType;

        function changeOrderType() {
            vm.orderfrequencies = OrderFrequency.byordertype({orderType: vm.patientOrder.orderType}).$promise.then(function (data) {
                vm.orderfrequencies = data;
            });
        }

        vm.accept = function () {
            $uibModalInstance.close({patientOrderTest: vm.patientOrderTest, is_new: vm.is_new});
        }

        vm.addProblemDiagnosis = function (index) {
            var i = vm.patientOrderTest.diagnoses.indexOf(vm.problems[index].diagnosis);

            if (vm.problemsChk[index].in) {
                if (i == -1) {
                    vm.patientOrderTest.diagnoses.push(vm.problems[index].diagnosis);
                }
            } else {
                if (i > -1) {
                    vm.patientOrderTest.diagnoses.splice(i, 1);
                }
            }

            vm.change();
        }

        vm.alerts = [];

        vm.closeAlert = function(index) {
            vm.alerts.splice(index, 1);
        };
    }
})();
