(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('clinic-schedule-pdetail-barcode', {
                parent: 'entity',
                url: '/pbarcode/{barcode}',
                ncyBreadcrumb: {
                    label: 'Patient Barcode Detail'
                },
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'Schedule Details'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/clinic-schedule-detail/patient-barcode-detail.html',
                        controller: 'PatientBarcodeDetailController',
                        controllerAs: 'vm'
                    },
                    'results@clinic-schedule-pdetail-barcode': {
                        templateUrl: 'app/entities/clinic-schedule-detail/patient-barcode-result-dets.html',
                        controller: 'PatientResultDetController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    patientResult: ['$stateParams', 'PatientResult', function ($stateParams, PatientResult) {
                        return PatientResult.byBarcode({barcode: $stateParams.barcode});
                    }],
                    barcode: ['$stateParams', 'PatientBarcode', function ($stateParams, PatientBarcode) {
                        return PatientBarcode.get({barcode: $stateParams.barcode}).$promise;
                    }],
                    patient: ['Patient', '$stateParams', function (Patient, $stateParams) {
                        return Patient.byBarcode({barcode: $stateParams.barcode}).$promise;
                    }]
                }
            })
            .state('clinic-schedule-edetail-barcode', {
                parent: 'entity',
                url: '/ebarcode/{barcode}',
                ncyBreadcrumb: {
                    label: 'Machine Barcode Detail'
                },
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'Schedule Details'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/clinic-schedule-detail/machine-barcode-detail.html',
                        controller: 'MachineBarcodeDetailController',
                        controllerAs: 'vm'
                    },
                    'results@clinic-schedule-edetail-barcode': {
                        templateUrl: 'app/entities/clinic-schedule-detail/patient-barcode-result-dets.html',
                        controller: 'EnvResultDetController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    envResult: ['$stateParams', 'EnvResult', function ($stateParams, EnvResult) {
                        return EnvResult.byBarcode({barcode: $stateParams.barcode});
                    }],
                    barcode: ['$stateParams', 'MachineBarcode', function ($stateParams, MachineBarcode) {
                        return MachineBarcode.get({barcode: $stateParams.barcode}).$promise;
                    }],
                    machine: ['Machine', '$stateParams', function (Machine, $stateParams) {
                        return Machine.byBarcode({barcode: $stateParams.barcode}).$promise;
                    }]
                }
            })
    }
})();
