(function() {
    'use strict';

    angular
        .module('dialysisApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('env-order-detail.test-detail', {
                parent: 'env-order-detail',
                url: '/{tid}/detail2',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_SUPERVISOR', 'ROLE_NURSE', 'ROLE_ENVIRONMENTAL', 'ROLE_DOCTOR'],
                    pageTitle: 'EnvTest'
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/env-test/env-test-dialog.html',
                        controller: 'EnvTestDialogController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            envTest: ['EnvTest', function (EnvTest) {
                                return EnvTest.with_all({id: $stateParams.tid}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('env-order-detail', null, {reload: 'env-order-detail'});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
    }

})();
