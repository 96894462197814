(function() {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('VaccinationDetailController', VaccinationDetailController);

    VaccinationDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Vaccination', 'Patient', 'Vaccin'];

    function VaccinationDetailController($scope, $rootScope, $stateParams, previousState, entity, Vaccination, Patient, Vaccin) {
        var vm = this;

        vm.vaccination = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('dialysisApp:vaccinationUpdate', function(event, result) {
            vm.vaccination = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
