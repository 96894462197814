(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('env-order', {
                parent: 'machine-detail',
                url: '/orders',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_SUPERVISOR', 'ROLE_NURSE', 'ROLE_ENVIRONMENTAL', 'ROLE_DOCTOR'],
                    pageTitle: 'Site Orders'
                },
                ncyBreadcrumb: {
                    label: 'Site Orders',
                    parent: 'machine-detail'
                },
                views: {
                    'content@machine-detail': {
                        templateUrl: 'app/entities/env-order/env-orders.html',
                        controller: 'EnvOrderController',
                        controllerAs: 'vm'
                    }
                }
            })
            .state('env-order-detail', {
                parent: 'env-order',
                url: '/{oid}/details',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_SUPERVISOR', 'ROLE_NURSE', 'ROLE_ENVIRONMENTAL', 'ROLE_DOCTOR'],
                    pageTitle: 'EnvOrder'
                },
                ncyBreadcrumb: {
                    label: 'Order Details'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/env-order/env-order-detail.html',
                        controller: 'EnvOrderDetailController',
                        controllerAs: 'vm'
                    },
                    'order-tests@env-order-detail': {
                        templateUrl: 'app/entities/env-test/env-tests.html',
                        controller: 'EnvTestController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    order: ['$stateParams', 'EnvOrder', function ($stateParams, EnvOrder) {
                        return EnvOrder.get({id: $stateParams.oid}).$promise;
                    }]
                }
            })
            .state('env-order-new', {
                parent: 'env-order',
                url: '/new',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_SUPERVISOR', 'ROLE_NURSE', 'ROLE_ENVIRONMENTAL', 'ROLE_DOCTOR'],
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/env-order/env-order-new.html',
                        controller: 'EnvOrderNewController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    order: ['$stateParams', function ($stateParams) {
                        return {
                            id: null,
                            envTests: [],
                            ordStatus: 'SCHEDULED'
                        };
                    }]
                }
            })
            .state('env-order-new-all', {
                parent: 'machine',
                url: '/new-orders',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_SUPERVISOR', 'ROLE_NURSE', 'ROLE_ENVIRONMENTAL', 'ROLE_DOCTOR'],
                },
                ncyBreadcrumb: {
                    label: 'Site Orders For Selected Machines',
                    parent: 'machine'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/env-order/env-order-all-new.html',
                        controller: 'EnvOrderNewAllController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    machines: null,
                    allMachines: null,
                    orderTpl: null
                },
                resolve: {
                    order: ['$stateParams', function ($stateParams) {
                        var tpl = $stateParams.orderTpl;

                        if (tpl) {
                            return tpl.envOrderJson;
                        }

                        return {
                            id: null,
                            envTests: [],
                            ordStatus: 'SCHEDULED'
                        };
                    }],
                    machines: ['$stateParams', function ($stateParams) {
                        return $stateParams.machines;
                    }],
                    allMachines: ['$stateParams', function ($stateParams) {
                        return $stateParams.allMachines;
                    }]
                }
            })
            .state('env-order-schedule.detail', {
                parent: 'patient-order-schedule',
                url: '/{oid}',
                ncyBreadcrumb: {
                    label: 'Schedule Detail',
                    parent: 'patient-order-schedule'
                },
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_SUPERVISOR', 'ROLE_NURSE', 'ROLE_ENVIRONMENTAL', 'ROLE_DOCTOR'],
                    pageTitle: 'Schedule'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/patient-order/schedule/patient-order-schedule-detail.html',
                        controller: 'PatientOrderScheduleDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    patientOrder: ['$stateParams', 'PatientOrder', function ($stateParams, PatientOrder) {
                        return PatientOrder.get({id: $stateParams.oid}).$promise;
                    }]
                }
            });
    }
})();
