(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('LabNotificationsAreaController', LabNotificationsAreaController);

    LabNotificationsAreaController.$inject = ['clinic', 'LabNotification', 'CoreService', 'showNotifications'];

    function LabNotificationsAreaController(clinic, LabNotification, CoreService, showNotifications) {
        var vm = this;
        vm.notifications = [];
        vm.showNotifications = showNotifications;

        vm.dismiss = dismiss;

        LabNotification.findAllActiveByClinic({id: clinic.id}, function (result) {
            vm.notifications = result;

            if (vm.showNotifications == undefined) {
                CoreService.setStorage('showNotifications', true);
            }
        });

        function dismiss(index) {
            vm.notifications.splice(index, 1);
        }
    }
})();
