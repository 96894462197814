(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('EnvResultAbnormalController', EnvResultAbnormalController);

    EnvResultAbnormalController.$inject = ['EnvResult', 'lodash', 'moment', '$scope', 'clinic'];

    function EnvResultAbnormalController(EnvResult, _, moment, $scope, clinic) {
        var vm = this;

        vm.abnormalNotReviewed = 0;
        vm.labels = [];
        vm.data = [
            []
        ];
        vm.totalCount = 0;

        vm.getResults = getResults;

        vm.abnormalNotReviewed = EnvResult.countAbnormalNotReviewed({id: clinic.id});

        getResults();

        function getResults() {
            EnvResult.countAbnormalNotReviewedLast7Days({
                id: clinic.id
            }, function (results) {
                _.forEach(results, function (result) {
                    vm.labels.push(moment(result[1]).format($scope.displayDateformat));
                    vm.data[0].push(result[0]);
                    vm.totalCount += result[0];
                });
            });
        }

        vm.series = ['Abnormal Results'];
        vm.colors = ['#45b7cd', '#ff6384', '#ff8e72'];
    }
})();
