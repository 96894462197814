(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('RptTemplateAdminDialogController', RptTemplateAdminDialogController);

    RptTemplateAdminDialogController.$inject = ['$timeout', '$scope', '$uibModalInstance', 'entity', 'RptTemplate',
        'RptCategorySearch', 'CoreService', 'ROLES', 'Compendium', 'lodash', 'RptLanguage', 'VaccineSearch'];

    function RptTemplateAdminDialogController($timeout, $scope, $uibModalInstance, entity, RptTemplate,
                                              RptCategorySearch, CoreService, ROLES, Compendium, _, RptLanguage, VaccineSearch) {
        var vm = this;

        vm.rptTemplate = entity;
        vm.clinic = CoreService.getCurrentClinic();
        vm.rptCategories = RptCategorySearch.query({
            query: {
                bool: {
                    must: getFilter()
                }
            }
        });
        vm.compendiums = Compendium.query();
        vm.rptLanguages = RptLanguage.query();
        vm.rptCompendiumRange = {compendiums: [], below: null, above: null};
        vm.vaccines = VaccineSearch.query({
            query: {
                bool: {
                    must: [{term: {"delStatus": false}}]
                }
            }
        });
        vm.form = {};
        vm.modalities = ['HEMO_DIALYSIS', 'PERITONEAL_DIALYSIS'];

        vm.clear = clear;
        vm.save = save;
        vm.getFilter = getFilter;
        vm.addRange = addRange;
        vm.deleteRange = deleteRange;
        vm.cloneRange = cloneRange;

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            if (vm.rptTemplate.id !== null) {
                RptTemplate.update(vm.rptTemplate, onSaveSuccess, onSaveError);
            } else {
                RptTemplate.save(vm.rptTemplate, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('dialysisApp:rptTemplateUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function getFilter() {
            var must = [];
            must.push({term: {"delStatus": false}});

            if (CoreService.currentHasEmployeeRole(ROLES.ROLE_ADMIN)) {
                must.push({
                    bool: {
                        should: [
                            {not: {exists: {"field": "clinic"}}},
                            {term: {"clinic.id": vm.clinic.id}}
                        ]
                    }
                });
            } else {
                must.push({term: {"clinic.id": vm.clinic.id}});
            }

            return must;
        }

        function addRange() {
            _.forEach(vm.rptCompendiumRange.compendiums, function (compendium) {
                vm.rptTemplate.rptCompendiumRanges.push({
                    compendium: compendium,
                    belowEq: angular.copy(vm.rptCompendiumRange.belowEq),
                    aboveEq: angular.copy(vm.rptCompendiumRange.aboveEq),
                    index: vm.rptTemplate.rptCompendiumRanges.length
                });
            });

            vm.rptCompendiumRange.compendiums = [];
        }

        function deleteRange(index) {
            vm.rptTemplate.rptCompendiumRanges.splice(index, 1);

            reindex();
        }

        function cloneRange(index) {
            vm.rptTemplate.rptCompendiumRanges.splice(index, 0, angular.copy(vm.rptTemplate.rptCompendiumRanges[index]));

            reindex();
        }

        function reindex() {
            _.forEach(vm.rptTemplate.rptCompendiumRanges, function (range, index) {
                range.index = index;
            })
        }
    }
})();
