(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('PatientSelectFilter', PatientSelectFilter);

    PatientSelectFilter.$inject = ['$scope', '$state', 'CoreService', 'Patient', 'DataUtils', 'ROLES'];

    function PatientSelectFilter($scope, $state, CoreService, Patient, DataUtils, ROLES) {
        var vm = this;
        vm.patients = [];
        vm.currentClinic = CoreService.getCurrentClinic();
        vm.patient = CoreService.getCurrentPatient();

        vm.importAccess = ROLES.ROLE_SUPERVISOR;

        vm.filterPatients = filterPatients;
        vm.importPatients = importPatients;

        function filterPatients(query) {
            if (query && query.length > 1) {
                vm.currentClinic = CoreService.getCurrentClinic();
                Patient._4filter({
                    query: {
                        bool: {
                            should: [
                                {match: {firstName: {query: query, fuzziness: 1}}},
                                {match: {lastName: {query: query, fuzziness: 1}}}
                            ],
                            must: [
                                {term: {"clinic.id": vm.currentClinic.id}},
                                {term: {"status": false}},
                                {term: {"delStatus": false}}
                            ]
                        }
                    }
                }, function (result) {
                    vm.patients = result;
                });
            }
        }

        function importPatients($file) {
            if ($file) {
                DataUtils.toBase64($file, function (base64Data) {
                    var patientImport = {
                        clinicId: CoreService.getCurrentClinic().id,
                        file: base64Data
                    };
                    Patient.import(patientImport, onSaveSuccess, onSaveError);
                });
            }
        }

        function onSaveSuccess(result) {
            //$state.go("patient-detail", {"id": result.id});
            if (result.success) {
                alert('Importing patients was successfully.');
                $state.go("patient", null, {reload: 'patient'});
            } else {
                alert('Error importing patients.');
            }
        }

        function onSaveError(result) {
            //vm.isSaving = false;
            console.log('false');
        }

        $scope.$on('dialysisApp:patientSelected', function (event, result) {
            vm.patient = result;
            vm.patients = [vm.patient];

            if (vm.patient.clinic !== CoreService.getCurrentClinic()) {
                CoreService.setCurrentClinic(vm.patient.clinic);
                vm.currentClinic = vm.patient.clinic;
            }
        });

        vm.onSelectCallback = function (item, model) {
            vm.patient = model;

            if (model) {
                $state.go("patient-detail", {"id": model.id});
            }
        };

        vm.clearPatient = function () {
            vm.patient = null;
            CoreService.setCurrentPatient(null);
            $state.go("patient");
        };
    }
})();
