(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('PatientDetailController', PatientDetailController);

    PatientDetailController.$inject = ['entity', 'GUIUtils', '$sessionStorage'];

    function PatientDetailController(entity, GUIUtils, $sessionStorage) {

        var vm = this;
        vm.patient = entity;
        vm.patient.drawDays2 = GUIUtils.weekCodeStringToDays(entity.drawDays);
        vm.patient.treatmentDays2 = GUIUtils.weekCodeStringToDays(entity.treatmentDays);

        vm.carriers = [];
        vm.activeTab = $sessionStorage.activePatientTab ? ($sessionStorage.activePatientTab.id == entity.id ?
            $sessionStorage.activePatientTab.activeTab : 0) : 0;

        vm.form = {};

        vm.editing = false;

        vm.races = vm.patient.race ? [vm.patient.race] : [];
        vm.languages = vm.patient.language ? [vm.patient.language] : [];
        vm.physicians = [vm.patient.employee];
        vm.modalities = vm.patient.modality ? [vm.patient.modality] : [];
        vm.patienttypes = vm.patient.patientType ? [vm.patient.patientType] : [];
        vm.patienttemplates = vm.patient.patientTemplate ? [vm.patient.patientTemplate] : [];
        vm.countrystates = [];
        vm.insurancelevels = [];
        vm.insurancerelations = [];
        vm.insurancestypes = [];

        vm.diagnoses = [];

        for (var i = 0; i < vm.patient.insurances.length; i++) {
            if (vm.patient.insurances[i].insuranceLevel) {
                vm.insurancelevels.push(vm.patient.insurances[i].insuranceLevel);
            }
            if (vm.patient.insurances[i].insuranceRelation) {
                vm.insurancerelations.push(vm.patient.insurances[i].insuranceRelation);
            }
            if (vm.patient.insurances[i].insuranceType) {
                vm.insurancestypes.push(vm.patient.insurances[i].insuranceType);
            }
            if (vm.patient.insurances[i].state) {
                vm.countrystates.push(vm.patient.insurances[i].state);
            }
        }

        for (var i = 0; i < vm.patient.addresses.length; i++) {
            vm.countrystates.push(vm.patient.addresses[i].countryState);
        }

        vm.keepActiveTab = keepActiveTab;
        function keepActiveTab($index) {
            $sessionStorage.activePatientTab = {id: vm.patient.id, activeTab: $index};
        }
    }
})();
