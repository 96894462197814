(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('MissingKineticsTopBoxController', MissingKineticsTopBoxController);

    MissingKineticsTopBoxController.$inject = ['Kinetic', 'clinic'];

    function MissingKineticsTopBoxController(Kinetic, clinic) {
        var vm = this;

        vm.countMissingKinetics = 0;

        Kinetic.countMissing({id: clinic.id}, function (result) {
            vm.countMissingKinetics = result.value;
        });
    }
})();
