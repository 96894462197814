/**
 * Created by hermeslm on 10/2/16.
 */

(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('FooterController', FooterController);

    FooterController.$inject = ['AdminLTEService', '$timeout', '$rootScope'];

    function FooterController(AdminLTEService, $timeout, $rootScope) {
        var vm = this;

        $rootScope.$on('$viewContentLoading',
            function (event, viewConfig) {
                // Access to all the view config properties.
                // and one special property 'targetView'
                // viewConfig.targetView
                $timeout(function () {
                    $timeout(function () {
                        AdminLTEService.layoutFix();
                    }, 0);
                }, 0);
            });

    }
})();

