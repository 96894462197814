(function() {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('VaccinationDeleteController',VaccinationDeleteController);

    VaccinationDeleteController.$inject = ['$uibModalInstance', 'entity', 'Vaccination'];

    function VaccinationDeleteController($uibModalInstance, entity, Vaccination) {
        var vm = this;

        vm.vaccination = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            Vaccination.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
