(function() {
    'use strict';
    angular
        .module('dialysisApp')
        .factory('PatientOrder', PatientOrder);

    PatientOrder.$inject = ['$resource', 'DateUtils', 'DataUtils'];

    function PatientOrder ($resource, DateUtils, DataUtils) {
        var resourceUrl =  'api/patient-orders/:id';

        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.signatureDate = DateUtils.convertLocalDateFromServer(data.signatureDate);
                        data.endDate = DateUtils.convertLocalDateFromServer(data.endDate);
                    }

                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.signatureDate = DateUtils.convertLocalDateToServer(copy.signatureDate);
                    copy.endDate = DateUtils.convertLocalDateToServer(copy.endDate);
                    return angular.toJson(copy);
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.signatureDate = DateUtils.convertLocalDateToServer(copy.signatureDate);
                    copy.endDate = DateUtils.convertLocalDateToServer(copy.endDate);

                    return angular.toJson(copy);
                }
            },
            'save_many': {
                url: "api/patient-orders/save-many", method: 'POST',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.patientOrder.signatureDate = DateUtils.convertLocalDateToServer(copy.patientOrder.signatureDate);
                    copy.patientOrder.endDate = DateUtils.convertLocalDateToServer(copy.patientOrder.endDate);

                    return angular.toJson(copy);
                }
            },
            'active': {
                url: 'api/patient-orders/get-by-type-status/:type/:status/:id', method: 'GET', isArray: true
            },
            'no_active': {
                url: 'api/patient-orders/get-by-type-not-active/:type/:id', method: 'GET', isArray: true
            },
            'schedule': {
                url: 'api/patient-orders-schedule',  method: 'POST',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    if (copy.patientOrderTests) {
                        for (var i = 0; i < data.patientOrderTests.length; i++) {
                            copy.patientOrderTests[i].staringDate =  DateUtils.convertLocalDateToServer(copy.patientOrderTests[i].staringDate);
                            if (copy.patientOrderTests[i].endDate) {
                                copy.patientOrderTests[i].endDate =  DateUtils.convertLocalDateToServer(copy.patientOrderTests[i].endDate);
                            }
                        }
                    }

                    return angular.toJson(copy);
                },
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    data.signatureDate = DateUtils.convertLocalDateFromServer(data.signatureDate);
                    data.endDate = DateUtils.convertLocalDateFromServer(data.endDate);

                    for (var i = 0; i < data.patientOrderTests.length; i++) {
                        data.patientOrderTests[i].staringDate =  DateUtils.convertLocalDateFromServer(data.patientOrderTests[i].staringDate);
                        if (data.patientOrderTests[i].endDate) {
                            data.patientOrderTests[i].endDate =  DateUtils.convertLocalDateFromServer(data.patientOrderTests[i].endDate);
                        }

                        for (var j = 0; j < data.patientOrderTests[i].patientOrderItems.length; j++) {
                            data.patientOrderTests[i].patientOrderItems[j].scheduleDate =
                                DateUtils.convertLocalDateFromServer(data.patientOrderTests[i].patientOrderItems[j].scheduleDate);

                            data.patientOrderTests[i].patientOrderItems[j].collectedDate =
                                DateUtils.convertLocalDateFromServer(data.patientOrderTests[i].patientOrderItems[j].collectedDate);
                        }
                    }

                    return data;
                }
            },
            'hasStanding': {
                url: "api/patient-orders/has-standing/:id", method: 'GET',
                transformResponse: function (data) {
                    return data == "false" ? {value: false} : {value: true};
                }
            },
            'cancel': {url: "api/cancel-patient-order/:id", method: 'GET'},
            'finish': {url: "api/finish-patient-order/:id", method: 'GET'},
            'dashboardSchedule': {
                url: 'api/patient-orders/dashboard-schedule', method: 'POST', isArray: true,
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.date = DateUtils.convertLocalDateToServer(data.date);

                    return angular.toJson(copy);
                }
            },
            'getSchedule': {
                url: 'api/patient-orders/schedule-list', method: 'POST', isArray: true,
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.date = DateUtils.convertLocalDateToServer(data.date);

                    return angular.toJson(copy);
                }
            },
            'collect': {
                url: 'api/patient-order/collect', method: 'POST', isArray: true,
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.date = DateUtils.convertLocalDateToServer(data.date);

                    return angular.toJson(copy);
                }
            },
            'genBarcodes': {
                url: 'api/patient-order/generate-barcode', method: 'POST', isArray: true,
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.date = DateUtils.convertLocalDateToServer(data.date);

                    return angular.toJson(copy);
                }
            },
            'not_collected' : {url : "api/count-orders-not-collected-by-date/:id/:date", method: 'GET',
                transformResponse: function (data) {
                    return {value: data};
                }},
            'unsignedOrders' : {url : "api/patient-orders/unsigned/:id", method: 'GET', isArray: true},
            'signOrders': {url: 'api/patient-orders/sign', method: 'POST', isArray: true},
            'getBarcodeLabels': {url: 'api/patient-orders/get-barcode-labels', method: 'POST', isArray: true},
            'get_barcode': {url: 'api/patient-orders/get-barcode/:id/:date', method: 'GET'},
            'markDates': {url: "api/patient-orders/mark-dates", method: 'POST', isArray: true,
                transformRequest: function (data) {
                    var copy = angular.copy(data);

                    if (copy.date) {
                        copy.date = DateUtils.convertLocalDateToServer(copy.date);
                    }

                    return angular.toJson(copy);

                },
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);

                        for (var i = 0; i < data.length; i++) {
                            data[i] = {date: data[i].date, class: data[i].sent ? 'markSentClass' : 'markNotSentClass'};
                        }
                    }

                    return data;
                }
            },
            'byBarcode': {url: "api/patient-orders/by-barcode/:barcode", method: 'GET'},
            'changeDrawDay': {url: 'api/patient-orders/change-draw-day', method: 'POST', isArray: true},
            'pending': {
                url: 'api/patient-orders/pending', method: 'POST', isArray: true,
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.date = DateUtils.convertLocalDateToServer(data.date);

                    return angular.toJson(copy);
                }
            },
            'getManifest': {
                url: 'api/patient-orders/manifest', method: 'POST', isArray: false,
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.date = DateUtils.convertLocalDateToServer(copy.date);
                    return angular.toJson(copy);
                },
                transformResponse: function (data, headers) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.footer = function (currentPage, pageCount) {
                            return {
                                columns: [
                                    {
                                        text: 'Date: ' + moment().format('MM/DD/YYYY hh:mm') + '\n' +
                                        'Page ' + currentPage.toString() + ' of ' + pageCount,
                                        alignment: 'right',
                                        margin: [0, 0, 20, 0],
                                        fontSize: 8
                                    }
                                ]
                            };
                        };
                    }
                    return data;
                }
            },
            'countUnsigned': {url: 'api/patient-orders/count-unsigned/:id', method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data = {value: data};
                    }
                    return data;
                }
            },
            'getManifestPdf': {
                url: 'api/patient-orders/manifest_pdf',
                method: 'POST',
                responseType: 'arraybuffer',
                transformResponse: function (data, headers) {
                    DataUtils.downloadAndOpenPDFFile(data, headers);
                }
            },
            'getBarcodeLabelsPdf': {
                url: 'api/patient-orders/barcode_labels_pdf',
                method: 'POST',
                responseType: 'arraybuffer',
                transformResponse: function (data, headers) {
                    DataUtils.downloadAndOpenPDFFile(data, headers);
                }
            },
            'cancelRequisition': {
                url: 'api/patient-orders/cancelRequisition',
                method: 'POST'
            },
            'changeDrawDayV2': {url: 'api/patient-orders/change-draw-day-v2', method: 'POST'},
            'validateManifest': {url: 'api/patient-orders/validate-manifest', method: 'POST'}
        });
    }
})();
