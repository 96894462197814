(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('PdAdequacyAddController', PdAdequacyAddController);

    PdAdequacyAddController.$inject = ['PdAdequacy', 'CoreService', 'patients', '$state', 'dialogs'];

    function PdAdequacyAddController(PdAdequacy, CoreService, patients, $state, $dialogs) {
        var vm = this;
        vm.clinic = CoreService.getCurrentClinic();
        vm.missing = [];
        vm.editForm = {};

        if (!patients || patients.length == 0) {
            $state.go("pd-adequacy-patients", null, {reload: true});
        } else {
            vm.patients = patients;
            var ids = [];

            for (var i = 0; i < vm.patients.length; i++) {
                ids.push(vm.patients[i].id);
            }

            vm.missing = PdAdequacy.missing({ids: ids});

            for (var i = 0; i < vm.missing.length; i++) {
                vm.datePickerOpenStatus.begin.push(false);
                vm.datePickerOpenStatus.end.push(false);
            }
        }

        vm.openCalendar = openCalendar;
        function openCalendar(field, index) {
            vm.datePickerOpenStatus[field][index] = true;
        }

        vm.datePickerOpenStatus = {
            begin: [],
            end: []
        };

        vm.save = function () {
            var pdAdequacies = [];

            for (var i = 0; i < vm.missing.length; i++) {
                var pdAdequacy = {};
                angular.copy(vm.missing[i], pdAdequacy);

                //TODO This code must be fixed, this is only a tmp solution
                if((pdAdequacy.intCode == 1072 || pdAdequacy.urineVolume !== null) &&
                    pdAdequacy.pdFluidOut !== null && pdAdequacy.height !== null && pdAdequacy.dryWeigh !== null && pdAdequacy.patientAge !== null){
                    pdAdequacies.push(pdAdequacy);
                }
            }

            if (pdAdequacies.length > 0) {
                PdAdequacy.save({pdAdequacies: pdAdequacies}, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess() {
            vm.isSaving = false;
            $state.go("pd-adequacy-patients", null, {reload: true});
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        vm.remove = function (index) {
            vm.missing.splice(index, 1);
        }
    }
})();
