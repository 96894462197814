(function() {
    'use strict';
    angular
        .module('dialysisApp')
        .factory('EnvOrder', EnvOrder);

    EnvOrder.$inject = ['$resource', 'DateUtils', 'DataUtils'];

    function EnvOrder ($resource, DateUtils, DataUtils) {
        var resourceUrl =  'api/env-orders/:id';

        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.endDate = DateUtils.convertLocalDateFromServer(data.endDate);
                    }

                    return data;
                }
            },
            'update': { method:'PUT' },
            'schedule': {
                url: 'api/env-orders-schedule',  method: 'POST',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    if (copy.envTests) {
                        for (var i = 0; i < data.envTests.length; i++) {
                            copy.envTests[i].staringDate =  DateUtils.convertLocalDateToServer(copy.envTests[i].staringDate);
                            if (copy.envTests[i].endDate) {
                                copy.envTests[i].endDate =  DateUtils.convertLocalDateToServer(copy.envTests[i].endDate);
                            }
                        }
                    }

                    return angular.toJson(copy);
                },
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    data.signatureDate = DateUtils.convertLocalDateFromServer(data.signatureDate);
                    data.endDate = DateUtils.convertLocalDateFromServer(data.endDate);

                    for (var i = 0; i < data.envTests.length; i++) {
                        data.envTests[i].staringDate =  DateUtils.convertLocalDateFromServer(data.envTests[i].staringDate);
                        if (data.envTests[i].endDate) {
                            data.envTests[i].endDate =  DateUtils.convertLocalDateFromServer(data.envTests[i].endDate);
                        }

                        for (var j = 0; j < data.envTests[i].schedules.length; j++) {
                            data.envTests[i].schedules[j].scheduleDate =
                                DateUtils.convertLocalDateFromServer(data.envTests[i].schedules[j].scheduleDate);
                        }
                    }

                    return data;
                }
            },
            'collect': {
                url: 'api/env-order/collect', method: 'POST', isArray: true,
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.date = DateUtils.convertLocalDateToServer(data.date);

                    return angular.toJson(copy);
                }
            },
            'collect2': {
                url: 'api/env-order/collect2', method: 'POST', isArray: true,
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.date = DateUtils.convertLocalDateToServer(data.date);

                    return angular.toJson(copy);
                }
            },
            'not_collected' : {url : "api/count-env-orders-not-collected-by-date/:id/:date", method: 'GET',
                transformResponse: function (data) {
                    return {value: data};
                }},
            'byMachine': {url : "api/env-orders/machine/:id", method: 'GET', isArray: true},
            'save_many': {
                url: "api/env-orders/save-many", method: 'POST',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.envOrder.signatureDate = DateUtils.convertLocalDateToServer(copy.envOrder.signatureDate);
                    copy.envOrder.endDate = DateUtils.convertLocalDateToServer(copy.envOrder.endDate);

                    return angular.toJson(copy);
                }
            },
            'changeDrawDay': {url: 'api/env-orders/change-draw-day', method: 'POST', isArray: true,
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.date = DateUtils.convertLocalDateToServer(data.date);
                    copy.newDate = DateUtils.convertLocalDateToServer(data.newDate);

                    return angular.toJson(copy);
                }
            },
            'getBarcodeLabels': {url: 'api/env-orders/get-barcode-labels', method: 'POST', isArray: true},
            'get_barcode': {url: 'api/env-orders/get-barcode/:id/:date', method: 'GET'},
            'dashboardSchedule': {
                url: 'api/env-orders/dashboard-schedule', method: 'POST', isArray: true,
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.date = DateUtils.convertLocalDateToServer(data.date);

                    return angular.toJson(copy);
                }
            },
            'getSchedule': {
                url: 'api/env-orders/schedule-list', method: 'POST', isArray: true,
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.date = DateUtils.convertLocalDateToServer(data.date);

                    return angular.toJson(copy);
                }
            },
            'markDates': {url: "api/env-orders/mark-dates", method: 'POST', isArray: true,
                transformRequest: function (data) {
                    var copy = angular.copy(data);

                    if (copy.date) {
                        copy.date = DateUtils.convertLocalDateToServer(copy.date);
                    }

                    return angular.toJson(copy);

                },
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);

                        for (var i = 0; i < data.length; i++) {
                            data[i] = {date: data[i].date, class: data[i].sent ? 'markSentClass' : 'markNotSentClass'};
                        }
                    }

                    return data;
                }
            },
            'getManifestPdf': {
                url: 'api/env-orders/manifest_pdf',
                method: 'POST',
                responseType: 'arraybuffer',
                transformResponse: function (data, headers) {
                    DataUtils.downloadAndOpenPDFFile(data, headers);
                }
            },
            'getBarcodeLabelsPdf': {
                url: 'api/env-orders/barcode_labels_pdf',
                method: 'POST',
                responseType: 'arraybuffer',
                transformResponse: function (data, headers) {
                    DataUtils.downloadAndOpenPDFFile(data, headers);
                }
            },
            'getBarcodeLabelsPdf2': {
                url: 'api/env-orders/barcode_labels_pdf2',
                method: 'POST',
                responseType: 'arraybuffer',
                transformResponse: function (data, headers) {
                    DataUtils.downloadAndOpenPDFFile(data, headers);
                }
            },
            'cancelRequisition': { url: 'api/env-orders/cancel_requisition', method: 'POST', isArray: true},
            'changeDrawDayV2': {url: 'api/env-orders/change-draw-day-v2', method: 'POST', isArray: true}
        });
    }
})();
