(function() {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('CompendiumSpecialPriceDeleteController',CompendiumSpecialPriceDeleteController);

    CompendiumSpecialPriceDeleteController.$inject = ['$uibModalInstance', 'entity', 'CompendiumSpecialPrice'];

    function CompendiumSpecialPriceDeleteController($uibModalInstance, entity, CompendiumSpecialPrice) {
        var vm = this;

        vm.compendiumSpecialPrice = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            CompendiumSpecialPrice.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
