(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('EnvResultLatestController', EnvResultLatestController);

    EnvResultLatestController.$inject = ['$scope', 'EnvResultSearch', 'EnvResultFile', 'clinic', '$q',
        'DTColumnBuilder', 'DTOptionsBuilder', 'GUIUtils', '$filter', '$compile', 'filterRes',
        'PopupService', 'DataTablesService', 'CoreService'];

    function EnvResultLatestController($scope, EnvResultSearch, EnvResultFile, clinic, $q,
                                       DTColumnBuilder, DTOptionsBuilder, GUIUtils, $filter,
                                       $compile, filterRes, PopupService, DataTablesService, CoreService) {

        var vm = this;

        vm.title = 'Latest Site Results';
        vm.entityClassHumanized = 'Latest Sites Results';
        vm.entityStateName = 'env-result';
        vm.currentMonth = false;
        vm.envResults = [];
        vm.dtInstance = {};
        vm.clinic = clinic;
        vm.filterRes = filterRes;
        vm.selected = {};
        vm.selectAll = false;
        var titleHtml = '<input type="checkbox" ng-model="vm.selectAll" ng-change="vm.toggleAll(vm.selected, vm.selectAll)">';
        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.start = false;
        vm.datePickerOpenStatus.end = false;
        vm.isFilter = false;

        vm.search = search;
        vm.toggleAll = DataTablesService.toggleAll;
        vm.toggleOne = DataTablesService.toggleOne;
        vm.printPDFs = printPDFs;
        vm.openCalendar = openCalendar;
        vm.updateStatusButtonFilter = updateStatusButtonFilter;
        vm.updateCurrentMonthFilter = updateCurrentMonthFilter;
        vm.showFiltersPanel = showFiltersPanel;
        vm.clear = clear;
        vm.getFilter = getFilter;
        vm.details = details;

        angular.element('.filter-box').hide();

        function showFiltersPanel() {
            angular.element('.filter-box').toggleClass('active');

            if (angular.element('.filter-box').hasClass('active')) {
                angular.element('.toggle-filters').addClass('open');
                angular.element('.filter-box').slideDown();
            } else {
                angular.element('.toggle-filters').removeClass('open');
                angular.element('.filter-box').slideUp();
            }
        }

        vm.dtOptions = DTOptionsBuilder.fromFnPromise(function () {
            var defer = $q.defer();

            if (!vm.searchQuery || vm.searchQuery == '') {
                EnvResultSearch.query(
                    {
                        query: {
                            bool: {
                                must: getFilter()
                            }
                        },
                        clinic: clinic.id
                    }, function (result) {
                        vm.envResults = result;
                        defer.resolve(vm.envResults);
                    });
            } else {
                defer.resolve($filter('filter')(vm.envResults, vm.searchQuery, undefined));
            }

            vm.selected = {};
            vm.selectAll = false;
            return defer.promise;
        }).withPaginationType('full_numbers')
            .withBootstrap()
            .withDOM('ltp')
            .withLanguage({
                "paginate": {
                    "first": '<i class="fa fa-angle-double-left" aria-hidden="true"></i>',
                    "previous": '<i class="fa fa-arrow-circle-left" aria-hidden="true"></i>',
                    "last": '<i class="fa fa-angle-double-right" aria-hidden="true"></i>',
                    "next": '<i class="fa fa-arrow-circle-right" aria-hidden="true"></i>'
                }
            })
            .withOption('aaSorting', [[6, 'desc'], [2, 'asc']])
            .withOption('lengthMenu', [[10, 15, 25, 50, -1], [10, 15, 25, 50, "All"]])
            .withOption('headerCallback', function (header) {
                if (!vm.headerCompiled) {
                    vm.headerCompiled = true;
                    $compile(angular.element(header).contents())($scope);
                }
            })
            .withOption('fnRowCallback',
                function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                    $compile(nRow)($scope);
                });

        vm.dtColumns = [
            DTColumnBuilder.newColumn(null).withTitle(titleHtml).withOption('width', '25px').notSortable().renderWith(function (data, type, full, meta) {
                vm.selected[full.id] = false;

                return '<input type="checkbox" ng-model="vm.selected[' + data.id + ']" ng-click="vm.toggleOne(vm.selected, vm.selectAll)">';
            }),
            DTColumnBuilder.newColumn('id').withTitle('ID'),
            DTColumnBuilder.newColumn('machine').withTitle('Machine'),
            DTColumnBuilder.newColumn(null).withTitle('Barcode').renderWith(function (data, type, full) {
                return data.accessionNumber ? data.accessionNumber : '';
            }),
            DTColumnBuilder.newColumn(null).withTitle('Order ID').renderWith(function (data, type, full) {
                return data.orderId ? data.orderId : "";
            }),
            DTColumnBuilder.newColumn(null).withTitle('Collection Date').renderWith(function (data, type, full) {
                return moment(data.collectionDate).format("MM/DD/YYYY");
            }),
            DTColumnBuilder.newColumn(null).withTitle('Collection Date').notVisible().renderWith(function (data, type, full) {
                return moment(data.collectionDate).format("YYYYMMDD");
            }),
            DTColumnBuilder.newColumn(null).withTitle('Result Date').renderWith(function (data, type, full) {
                return moment(data.createdDate).format("MM/DD/YYYY");
            }),
            DTColumnBuilder.newColumn('').withTitle('Alert').withOption('width', '155px').notSortable()
                .renderWith(abnormalHtml),
            DTColumnBuilder.newColumn('status').withTitle('Status').renderWith(statusHtml),
            DTColumnBuilder.newColumn('').withTitle('Actions').withOption('class', 'col').withOption('width', '175px').notSortable()
                .renderWith(actionsHtml)
        ];

        function abnormalHtml(data, type, full, meta) {
            if (full.abnormal) {
                return GUIUtils.getStatusTemplate('ABNORMAL', 'danger');
            } else {
                return GUIUtils.getStatusTemplate('NORMAL', 'success');
            }
        }

        function statusHtml(data, type, full, meta) {
            if (full.status == 'PARTIAL') {
                return GUIUtils.getStatusTemplate(full.status, 'warning');
            } else {
                return GUIUtils.getStatusTemplate(full.status, 'primary');
            }
        }

        function actionsHtml(data, type, full, meta) {
            var stButtons = '';

            stButtons += '<a class="dt-btn warning"  ng-click="vm.details(' + full.id + ')" uib-tooltip="View Details"><i class="fa fa-file-text" aria-hidden="true"></i></a>';

            stButtons += '<a class="dt-btn danger" ng-click="vm.printPDFs(' + full.id + ')" uib-tooltip="View PDF"><i class="fa fa-file-pdf-o" aria-hidden="true"></i></a>';

            return stButtons;
        }

        function search() {
            CoreService.setStorage('filterEnvironmental', vm.filterRes);
            vm.dtInstance.reloadData();
        }

        function getFilter() {
            var must = [];
            must.push({
                bool: {
                    "should": [
                        {"term": {"clinicId": vm.clinic.id}},
                        {"term": {"clinic.id": vm.clinic.id}}
                    ]
                }
            });
            must.push({term: {"delStatus": false}});
            must.push({exists: {"field": "accessionNumber"}});

            if (vm.filterRes.name != null && vm.filterRes.name.length > 0) {
                must.push({match: {machine: {query: vm.filterRes.name + "%"}}});
            }

            if (vm.filterRes.status != null && vm.filterRes.status.length > 0) {
                must.push({match: {status: {query: vm.filterRes.status}}});
            }

            if (vm.filterRes.start) {
                must.push({range: {collectionDate: {gte: moment(vm.filterRes.start).format("YYYY-MM-DD")}}});
            }

            if (vm.filterRes.end) {
                must.push({range: {collectionDate: {lte: moment(vm.filterRes.end).format("YYYY-MM-DD")}}});
            }

            if (vm.filterRes.barcode != null && vm.filterRes.barcode.length > 0) {
                must.push({match: {accessionNumber: {query: vm.filterRes.barcode}}});
            }

            if (vm.filterRes.abnormal != null && vm.filterRes.abnormal.length > 0) {
                must.push({match: {abnormal: {query: vm.filterRes.abnormal}}});
            }

            if (vm.filterRes.reviewedStatus != null && vm.filterRes.reviewedStatus.length > 0) {
                must.push({term: {reviewedStatus: vm.filterRes.reviewedStatus}});
            }

            return must;
        }

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function clear() {
            vm.filterRes = CoreService.initEnvResultFilter();
            CoreService.setStorage('filterEnvironmental', vm.filterRes);
            vm.dtInstance.reloadData();
        }

        function printPDFs(id) {
            var ids;

            if (id) {
                ids = [id];
            } else {
                ids = DataTablesService.getSelectedIds(vm.selected);
            }

            if (ids.length > 0) {
                EnvResultFile.getAllInOnePdf({ids: ids});
            }
        }

        function updateStatusButtonFilter(status) {
            vm.filterRes.status = status;
            CoreService.setStorage('filterEnvironmental', vm.filterRes);
            vm.dtInstance.reloadData();
        }

        function updateCurrentMonthFilter() {
            if (vm.currentMonth) {
                var nowdate = new Date();
                var monthStartDay = new Date(nowdate.getFullYear(), nowdate.getMonth(), 1);
                var monthEndDay = new Date(nowdate.getFullYear(), nowdate.getMonth() + 1, 0);
                vm.filterRes.start = monthStartDay;
                vm.filterRes.end = monthEndDay;
            } else {
                vm.filterRes = CoreService.initEnvResultFilter();
            }
            CoreService.setStorage('filterEnvironmental', vm.filterRes);
            vm.dtInstance.reloadData();
        }

        function details(id) {
            var envResult = vm.envResults.find(function (el) {
                return el.id === id;
            });

            PopupService.showEnvResultDetPopup(envResult);
        }
    }
})();
