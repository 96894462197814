(function() {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('DashboardSelectDateDialogController', DashboardSelectDateDialogController);

    DashboardSelectDateDialogController.$inject = ['$uibModalInstance', 'PatientOrder', 'EnvOrder', 'isPatient', 'CoreService', '$rootScope', 'date', 'clinic'];

    function DashboardSelectDateDialogController($uibModalInstance, PatientOrder, EnvOrder, isPatient, CoreService, $rootScope, date, clinic) {
        var vm = this;

        vm.isPatient = isPatient.value;
        vm.date = date;
        vm.clinic = clinic;
        vm.options = {
            initDate: new Date(),
            showWeeks: true
        };

        vm.rmConfig1 = {
            mondayStart: false,
            initState: "month", /* decade || year || month */
            maxState: "decade",
            minState: "month",
            decadeSize: 12,
            monthSize: 42, /* "auto" || fixed nr. (35 or 42) */
            min: new Date("2016-01-01"),
            max: null,
            format: "yyyy-MM-dd",
            markDates: vm.isPatient ? markPatientDates : markEnvDates
        };

        vm.clear = clear;
        vm.selectDate = selectDate;
        vm.markPatientDates = markPatientDates;
        vm.markEnvDates = markEnvDates;

        function selectDate() {
            vm.isPatient ? CoreService.setStorage('activeScheduleDate', vm.date) : CoreService.setStorage('activeScheduleDateEnv', vm.date);
            $uibModalInstance.close({value: vm.date});
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function markPatientDates(oDate) {
            return vm.clinic ? PatientOrder.markDates({clinicId: vm.clinic.id, date: oDate}) : [];
        }

        function markEnvDates(oDate) {
            return vm.clinic ? EnvOrder.markDates({clinicId: vm.clinic.id, date: oDate}) : [];
        }
    }
})();
