(function () {
    'use strict';

    angular
        .module('dialysisApp', [
            'ngStorage',
            // 'tmh.dynamicLocale',
            // 'pascalprecht.translate',
            'ngResource',
            'ngCookies',
            'ngAria',
            'ngCacheBuster',
            'ngFileUpload',
            'ui.bootstrap',
            'ui.bootstrap.datetimepicker',
            'ui.router',
            'infinite-scroll',
            'ds.objectDiff',
            'udt',
            // jhipster-needle-angularjs-add-module JHipster will add new module here
            'angular-loading-bar',
            'ui.checkbox',
            'ncy-angular-breadcrumb',
            'datatables',
            'datatables.factory',
            'datatables.bootstrap',
            'datatables.colreorder',
            'datatables.columnfilter',
            'ui.toggle',
            'ui.mask',
            'angularMoment',
            'ui.select',
            'nzToggle',
            'ngSanitize',
            'rmDatepicker',
            'webcam',
            'chart.js',
            'nvd3',
            'ngIdle',
            'dialogs.main',
            'dialogs.default-translations',
            'rm2Datepicker',
            'ui.calendar',
            'pdfjsViewer',
            'ngLodash',
            'mgo-angular-wizard',
            'ods-lib',
            'ods-lib-old',
            'toastr',
            'rm3Datepicker',
            'moment-picker',
            'mgo-angular-wizard'
        ]).config(['toastrConfig', function (toastrConfig) {
            angular.extend(toastrConfig, {
                allowHtml: true,
                closeButton: true,
                closeHtml: '<button>&times;</button>',
                extendedTimeOut: 1000,
                autoDismiss: false,
                maxOpened: 5,
                iconClasses: {
                    error: 'toast-error',
                    info: 'toast-info',
                    success: 'toast-success',
                    warning: 'toast-warning'
                },
                messageClass: 'toast-message',
                onHidden: null,
                onShown: null,
                onTap: null,
                progressBar: true,
                tapToDismiss: true,
                newestOnTop: true,
                positionClass: 'toast-bottom-right',
                timeOut: 5000,
                titleClass: 'toast-title',
                toastClass: 'toast'
            });
        }])
        .run(run);

    run.$inject = ['stateHandler', '$rootScope'];

    function run(stateHandler, $rootScope) {
        stateHandler.initialize();

        $rootScope.dateformat = "MM/dd/yyyy";
        $rootScope.displayDateformat = "MM/DD/YYYY";
        $rootScope.displayAmDateTimeFormat = "MM/DD/YYYY hh:mm A";
    }
})();
