(function() {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('BillingFrequencyDetailController', BillingFrequencyDetailController);

    BillingFrequencyDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'BillingFrequency'];

    function BillingFrequencyDetailController($scope, $rootScope, $stateParams, previousState, entity, BillingFrequency) {
        var vm = this;

        vm.billingFrequency = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('dialysisApp:billingFrequencyUpdate', function(event, result) {
            vm.billingFrequency = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
