(function() {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('LabBundleBillingCodeController', LabBundleBillingCodeController);

    LabBundleBillingCodeController.$inject = ['dialogs', '$state', 'LabBundleBillingCode', '$q', 'DTColumnBuilder' , 'DTOptionsBuilder', 'GUIUtils'];

    function LabBundleBillingCodeController ($dialogs, $state, LabBundleBillingCode, $q, DTColumnBuilder, DTOptionsBuilder, GUIUtils) {
        var vm = this;

        vm.labBundleBillingCodes = [];

        vm.search = search;
        vm.loadAll = loadAll;
        vm.init = init;
        vm.dtInstance = {};

        vm.dtOptions = DTOptionsBuilder.fromFnPromise(function () {
            var defer = $q.defer();
            if (!vm.searchQuery || vm.searchQuery == '') {
                LabBundleBillingCode.query(function (result) {
                    vm.labBundleBillingCodes = result;
                    defer.resolve(result);
                });
            } else {
                defer.resolve($filter('filter')(vm.races, vm.searchQuery, undefined));
            }
            return defer.promise;
        }).withPaginationType('full_numbers').withBootstrap().withDOM('tip');

        vm.dtColumns = [
            DTColumnBuilder.newColumn('testCode').withTitle('Test Code'),
            DTColumnBuilder.newColumn(null).withTitle('Actions').withOption('width', '155px').notSortable()
                .renderWith(actionsHtml)
        ];

        function loadAll() {
            Race.query(function (result) {
                vm.labBundleBillingCodes = result;
                vm.searchQuery = null;
            });
        }

        function actionsHtml(data, type, full, meta) {
            return GUIUtils.getActionsTemplate(data, $state.current.name, ['update', 'delete']);
        }

        function search() {
            vm.dtInstance.reloadData();
        }

        function init() {
            var dlg = $dialogs.confirm('Please Confirm','Reset all Codes? All current codes will be removed', {size: 'md'});
            dlg.result.then(function(btn){
                LabBundleBillingCode.init2(function () {
                    vm.dtInstance.reloadData();
                });
            });
        }
    }
})();
