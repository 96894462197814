(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('CompendiumReportGroupController', CompendiumReportGroupController);

    CompendiumReportGroupController.$inject = ['$scope', '$state', 'CompendiumReportGroup', 'CompendiumReportGroupSearch', '$q', 'DTColumnBuilder', 'DTOptionsBuilder', 'GUIUtils', '$filter', '$compile'];

    function CompendiumReportGroupController($scope, $state, CompendiumReportGroup, CompendiumReportGroupSearch, $q, DTColumnBuilder, DTOptionsBuilder, GUIUtils, $filter, $compile) {
        var vm = this;
        vm.title = 'Compendium Report Groups';
        vm.entityClassHumanized = 'Compendium Report Group';
        vm.entityStateName = 'compendium-report-group';

        vm.compendiumReportGroups = [];
        vm.search = search;
        vm.loadAll = loadAll;
        vm.dtInstance = {};

        vm.dtOptions = DTOptionsBuilder.fromFnPromise(function () {
            var defer = $q.defer();
            if (!vm.searchQuery || vm.searchQuery == '') {
                CompendiumReportGroup.query(function (result) {
                    vm.compendiumReportGroups = result;
                    defer.resolve(result);
                });
            } else {
                defer.resolve($filter('filter')(vm.compendiumReportGroups, vm.searchQuery, undefined));
            }
            return defer.promise;
        }).withPaginationType('full_numbers').withBootstrap().withDOM('tip')/*.withOption('scrollX', '100%')*/
            .withOption('fnRowCallback', function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                $compile(nRow)($scope);
            });

        vm.dtColumns = [
            DTColumnBuilder.newColumn('name').withTitle('Name'),
            DTColumnBuilder.newColumn(null).withTitle('Actions').withOption('width', '155px').notSortable()
                .renderWith(actionsHtml)
        ];

        function loadAll() {
            CompendiumReportGroup.query(function (result) {
                vm.compendiumReportGroups = result;
                vm.searchQuery = null;
            });
        }

        function actionsHtml(data, type, full, meta) {
            return GUIUtils.getActionsTemplate(data, $state.current.name, ['all']);
        }

        function search() {
            vm.dtInstance.reloadData();
        }
    }
})();
