/**
 * Created by hermeslm on 10/2/16.
 */

(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('HeaderController', HeaderController);

    HeaderController.$inject = ['employee', '$state', 'Auth', 'Principal', 'APP',
        'CoreService', '$rootScope', 'EMPLOYEE_TYPE', 'Idle', '$uibModal', 'IDLE', '$uibModalStack'];

    function HeaderController(employee, $state, Auth, Principal, APP, CoreService,
                              $rootScope, EMPLOYEE_TYPE, Idle, $uibModal, IDLE, $uibModalStack) {
        var vm = this;

        vm.appName = APP.APP_NAME;
        vm.appSuffixName = APP.APP_SUFFIX_NAME;
        vm.appShortName = APP.APP_SHORT_NAME;
        vm.account = null;
        vm.clinics = [];
        vm.currentClinic = null;
        vm.avatar = null;
        vm.memberSince = null;

        vm.isAuthenticated = Principal.isAuthenticated;
        vm.logout = logout;
        vm.$state = $state;

        vm.account = employee.user;
        vm.isAuthenticated = Principal.isAuthenticated;
        vm.employee = employee;
        vm.employee.avatar = CoreService.getAvatar(employee.gender);
        CoreService.setCurrentEmployee(vm.employee);

        function logout() {
            Auth.logout();
            CoreService.clearSession();
            $state.go('login');
        }

        vm.countdown = IDLE.timeout;

        closeModals();
        function closeModals() {
            if (vm.warning) {
                vm.warning.close();
                vm.warning = null;
            }
        }

        $rootScope.$on('IdleStart', function() {
            vm.warning = $uibModal.open({
                templateUrl: 'warning-dialog.html',
                windowClass: 'modal-danger'
            });
        });

        $rootScope.$on('IdleEnd', function() {
            closeModals();

            vm.countdown = IDLE.timeout;
        });

        $rootScope.$on('IdleTimeout', function() {
            $uibModalStack.dismissAll();

            logout();
        });

        Idle.watch();
    }
})();

