(function() {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('EnvOrderDeleteController',EnvOrderDeleteController);

    EnvOrderDeleteController.$inject = ['$uibModalInstance', 'entity', 'EnvOrder'];

    function EnvOrderDeleteController($uibModalInstance, entity, EnvOrder) {
        var vm = this;

        vm.envOrder = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            EnvOrder.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
