(function () {
    'use strict';
    angular
        .module('dialysisApp')
        .factory('Kinetic', Kinetic);

    Kinetic.$inject = ['$resource', 'DateUtils'];

    function Kinetic($resource, DateUtils) {
        var resourceUrl = 'api/kinetics/:id';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.beginDate = DateUtils.convertDateTimeFromServer(data.beginDate);
                        data.endDate = DateUtils.convertDateTimeFromServer(data.endDate);
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                // transformRequest: function (data) {
                //     var copy = angular.copy(data);
                //     copy.beginDate = DateUtils.convertLocalDateToServer(copy.beginDate);
                //     copy.endDate = DateUtils.convertLocalDateToServer(copy.endDate);
                //
                //     return angular.toJson(copy);
                // }
            },
            'save': {'url': 'api/kinetics/missing-info/save-all',
                method: 'POST',
                transformRequest: function (data) {
                    var copy = angular.copy(data);

                    for (var i = 0; i < data.length; i++) {
                        copy[i].beginDate = DateUtils.convertLocalDateToServer(copy.beginDate);
                        copy[i].endDate = DateUtils.convertLocalDateToServer(copy.endDate);
                    }

                    return angular.toJson(copy);
                }
            },
            'skip': {'url': 'api/kinetics/skip/:barcode',
                method: 'GET',
            },
            'patients': {'url': 'api/kinetics/patients/:id', method: 'GET', isArray: true},
            'missing': {url: 'api/kinetics/missing-info', method: 'POST', isArray: true,
                transformResponse: function (data) {
                if (data) {
                    data = angular.fromJson(data);

                    for (var i = 0; i < data.length; i++){
                        data[i].kg = true;
                        data[i].hhmm = true;
                    }
                }

                return data;
            }},
            'countMissing' : {url : 'api/kinetics/count-missing/:id', method: 'GET',
                transformResponse: function (data) {
                    return {value: data};
                }
            },
        });
    }
})();
