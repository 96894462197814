(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('pd-adequacy-patients', {
                parent: 'entity',
                url: '/pd-adequacy-patients',
                data: {
                    authorities: ['ROLE_SUPERVISOR', 'ROLE_NURSE', 'ROLE_DOCTOR'],
                    pageTitle: 'Kinetics'
                },
                ncyBreadcrumb: {
                    label: 'PD Adequacy',
                    parent: 'clinic'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/pd-adequacy/pd-adequacy-patients.html',
                        controller: 'PdAdequacyPatientsController',
                        controllerAs: 'vm'
                    }
                }
            })
            .state('pd-adequacy-add', {
                parent: 'pd-adequacy-patients',
                url: '/pd-adequacy-add',
                data: {
                    authorities: ['ROLE_SUPERVISOR', 'ROLE_NURSE', 'ROLE_DOCTOR'],
                    pageTitle: 'Pd Adequacy Add'
                },
                ncyBreadcrumb: {
                    label: 'Pd Adequacy Add',
                },
                params: {
                    patients: null,
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/pd-adequacy/pd-adequacy-add.html',
                        controller: 'PdAdequacyAddController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    patients: ['$stateParams', function ($stateParams) {
                        return $stateParams.patients;
                    }]
                }
            })
    }
})();
