(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('latest-env-result', {
                parent: 'entity',
                url: '/latest-env-result',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_SUPERVISOR', 'ROLE_NURSE', 'ROLE_ENVIRONMENTAL', 'ROLE_DOCTOR'],
                    pageTitle: 'Latest Environmental Results'
                },
                ncyBreadcrumb: {
                    label: 'Latest Site Results',
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/env-result/env-result-latest.html',
                        controller: 'EnvResultLatestController',
                        controllerAs: 'vm'
                    },
                    'select-filter@latest-env-result': {
                        templateUrl: 'app/entities/machine/machine-select-filter.html',
                        controller: 'MachineSelectFilter',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    filter: null
                },
                resolve: {
                    clinic: ['CoreService', function (CoreService) {
                        return CoreService.getCurrentClinic();
                    }],
                    filterRes: ['$stateParams', 'CoreService', function ($stateParams, CoreService) {
                        if ($stateParams.filter) {
                            return CoreService.readEnvResultFilter($stateParams.filter)
                        } else {
                            var filterPatient = CoreService.getStorage('filterEnvironmental');

                            if (filterPatient == undefined) {
                                return CoreService.initEnvResultFilter();
                            } else {
                                return CoreService.readEnvResultFromLocalStore(filterPatient);
                            }
                        }
                    }]
                }
            })
            .state('machine-env-result', {
                parent: 'machine-detail',
                url: '/results',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_SUPERVISOR', 'ROLE_NURSE', 'ROLE_ENVIRONMENTAL', 'ROLE_DOCTOR'],
                    pageTitle: 'Site Results'
                },
                ncyBreadcrumb: {
                    label: 'Results',
                    parent: 'machine-detail'
                },
                views: {
                    'content@machine-detail': {
                        templateUrl: 'app/entities/env-result/env-result.html',
                        controller: 'EnvResultController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    filterRes: function () {
                        return {
                            name: null,
                            start: null,
                            end: null,
                            status: null
                        }
                    },
                }
            })
            .state('env-result-update-dates', {
                parent: 'entity',
                url: '/env-result-update-dates',
                data: {
                    authorities: ['ROLE_ADMIN'],
                    pageTitle: 'Update Environmental Result Dates'
                },
                ncyBreadcrumb: {
                    label: 'Update Result Dates',
                    parent: 'latest-env-result'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/env-result/env-result-update-dates.html',
                        controller: 'EnvResultUpdateDatesController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                }
            })
            .state('env-result-delete', {
                parent: 'entity',
                url: '/env-result-delete',
                data: {
                    authorities: ['ROLE_ADMIN'],
                    pageTitle: 'Delete env result'
                },
                ncyBreadcrumb: {
                    label: 'Delete env result',
                    parent: 'entity'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/env-result/env-result-delete-dialog.html',
                        controller: 'EnvResultDeleteController',
                        controllerAs: 'vm'
                    }
                }
            })
    }
})();
