(function() {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('PatientGroupDialogController', PatientGroupDialogController);

    PatientGroupDialogController.$inject = ['$timeout', '$scope', '$uibModalInstance', 'entity', 'PatientGroup', 'clinic', 'Patient'];

    function PatientGroupDialogController ($timeout, $scope, $uibModalInstance, entity, PatientGroup, clinic, Patient) {
        var vm = this;

        vm.patientGroup = entity;
        vm.clear = clear;
        vm.save = save;
        vm.patients = Patient.getAllByClinic({id : vm.patientGroup.clinic.id});

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.patientGroup.id !== null) {
                PatientGroup.update(vm.patientGroup, onSaveSuccess, onSaveError);
            } else {
                PatientGroup.save(vm.patientGroup, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('dialysisApp:patientGroupUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }
    }
})();
