(function() {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('MedFrequencyToDetailController', MedFrequencyToDetailController);

    MedFrequencyToDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'MedFrequencyTo', 'Medication', 'Corporation'];

    function MedFrequencyToDetailController($scope, $rootScope, $stateParams, previousState, entity, MedFrequencyTo, Medication, Corporation) {
        var vm = this;

        vm.medFrequencyTo = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('dialysisApp:medFrequencyToUpdate', function(event, result) {
            vm.medFrequencyTo = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
