(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('dashboard-admin', {
                parent: 'entity',
                url: '/admin',
                ncyBreadcrumb: {
                    label: 'Home',
                    parent: 'app'
                },
                data: {
                    pageTitle: 'Dialysis',
                    authorities: ['ROLE_ADMIN']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/dashboard/dashboard-admin2.html',
                        controller: 'DashboardAdminController',
                        controllerAs: 'vm'
                    },
                    'schedule@dashboard-admin': {
                        templateUrl: 'app/entities/dashboard/dashboard-admin/all-orders-schedule.html',
                        controller: 'DashboardAdminAllOrdersScheduleController',
                        controllerAs: 'vm'
                    },
                    'patients@dashboard-admin': {
                        templateUrl: 'app/entities/dashboard/dashboard-admin/patient-order-schedule.html',
                        controller: 'DashboardAdminPatientOrderScheduleController',
                        controllerAs: 'vm'
                    },
                    'machines@dashboard-admin': {
                        templateUrl: 'app/entities/dashboard/dashboard-admin/env-order-schedule.html',
                        controller: 'DashboardAdminEnvOrderScheduleController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    date: ['CoreService', function (CoreService) {
                        return new Date(CoreService.getStorage('activeScheduleDate'));
                    }],
                    dashboard: function () {
                        return {
                            critical: 0,
                            testNotPerformed: 0,
                            unassignedResult: 0,
                            unsignedOrdersByDoctor: 0,
                            unsignedOrders: 0,
                            finalResult: 0,
                            partialResult: 0,
                            resultNotReceived: -1,
                            missingInfo: 0,
                            invoice: 0,

                            printedBarcodes: 0,
                            unPrintedBarcodes: 0,
                            printedWaterLabel: -1,
                            unPrintedWaterLabel: -1,

                            hdPatients: 0,
                            hdPatientsPer: 0,
                            pdPatients: 0,
                            pdPatientsPer: 0,
                            patients: 0,
                            machines: 0,

                            sentPatientsBarcodes: 0,
                            unsendPatientsBarcodes: 0,
                            sentEnvBarcodes: 0,
                            unsendEnvBarcodes: 0,
                            unsendBarcodes: 0,
                            sentKinetics: 0,
                            unsendKinetics: 0,
                            missingInfoTotal: 0,
                            patientsTotal: 0,
                            machinesTotal: 0,
                            corporationsTotal: 0,
                            clinicsTotal: 0
                        }
                    },
                    hasPrinter: ['DYMO', function (DYMO) {
                        return DYMO.loadPrinters();
                    }],
                },
            })
            .state('dashboard-env', {
                parent: 'entity',
                url: '/environmental',
                ncyBreadcrumb: {
                    label: 'Home',
                    parent: 'app'
                },
                data: {
                    pageTitle: 'Dialysis',
                    authorities: ['ROLE_ENVIRONMENTAL']
                },
                params: {
                    state: 'dashboard-env'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/dashboard/dashboard-env.html',
                        controller: 'DashboardEnvController',
                        controllerAs: 'vm'
                    },
                    'schedule@dashboard-env': {
                        templateUrl: 'app/entities/dashboard/dashboard-env/all-orders-schedule.html',
                        controller: 'DashboardAllOrdersScheduleController',
                        controllerAs: 'vm'
                    },
                    'machines@dashboard-env': {
                        templateUrl: 'app/entities/dashboard/dashboard-doc/env-order-schedule.html',
                        controller: 'DashboardEnvOrderScheduleController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    date: ['$stateParams', function ($stateParams) {
                        return $stateParams.date ? moment($stateParams.date).format("Y/MM/DD") : moment(new Date()).format("Y/MM/DD");
                    }],
                    hasPrinter: ['DYMO', function (DYMO) {
                        return DYMO.loadPrinters();
                    }],
                    dashboard: function () {
                        return {
                            critical: 0,
                            testNotPerformed: 0,
                            unassignedResult: 0,
                            unsignedOrdersByDoctor: 0,
                            unsignedOrders: 0,
                            finalResult: 0,
                            partialResult: 0,
                            resultNotReceived: -1,
                            missingInfo: 0,
                            invoice: 0,

                            printedBarcodes: 0,
                            unPrintedBarcodes: 0,
                            printedWaterLabel: -1,
                            unPrintedWaterLabel: -1,

                            hdPatients: 0,
                            hdPatientsPer: 0,
                            pdPatients: 0,
                            pdPatientsPer: 0,
                            patients: 0,
                            machines: 0
                        }
                    }
                }
            })
            .state('dashboard2', {
                parent: 'entity',
                url: '/dashboard-v2',
                ncyBreadcrumb: {
                    label: ' ',
                    parent: 'app'
                },
                data: {
                    authorities: ['ROLE_SUPERVISOR', 'ROLE_NURSE', 'ROLE_DOCTOR'],
                    pageTitle: 'Dialysis'
                },
                params: {
                    state: 'dashboard-doc'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/dashboard/dashboard2.html',
                        controller: 'Dashboard2Controller',
                        controllerAs: 'vm'
                    },
                    'critical-results@dashboard2': {
                        templateUrl: 'app/entities/dashboard/layout/critical-results-top-box.html',
                        controller: 'CriticalResultsTopBoxController',
                        controllerAs: 'vm'
                    },
                    'unsigned-orders@dashboard2': {
                        templateUrl: 'app/entities/dashboard/layout/unsigned-orders-box.html',
                        controller: 'UnsignedOrdersBoxController',
                        controllerAs: 'vm'
                    },
                    'pending-results@dashboard2': {
                        templateUrl: 'app/entities/dashboard/layout/pending-results-top-box.html',
                        controller: 'PendingResultsTopBoxController',
                        controllerAs: 'vm'
                    },
                    'unassigned-results@dashboard2': {
                        templateUrl: 'app/entities/dashboard/layout/unassigned-results-top-box.html',
                        controller: 'UnassignedResultsTopBoxController',
                        controllerAs: 'vm'
                    },
                    'abnormal-patient-results@dashboard2': {
                        templateUrl: 'app/entities/dashboard/layout/patient-result-abnormal.html',
                        controller: 'PatientResultAbnormalController',
                        controllerAs: 'vm'
                    },
                    'abnormal-env-results@dashboard2': {
                        templateUrl: 'app/entities/dashboard/layout/env-result-abnormal.html',
                        controller: 'EnvResultAbnormalController',
                        controllerAs: 'vm'
                    },
                    'patient-order-schedule@dashboard2': {
                        templateUrl: 'app/entities/dashboard/dashboard2/patient-order-schedule2.html',
                        controller: 'DashboardPatientOrderSchedule2Controller',
                        controllerAs: 'vm'
                    },
                    'env-order-schedule@dashboard2': {
                        templateUrl: 'app/entities/dashboard/dashboard2/env-order-schedule2.html',
                        controller: 'DashboardEnvOrderSchedule2Controller',
                        controllerAs: 'vm'
                    },
                    'missing-kinetics@dashboard2': {
                        templateUrl: 'app/entities/dashboard/layout/missing-kinetics-top-box.html',
                        controller: 'MissingKineticsTopBoxController',
                        controllerAs: 'vm'
                    },
                    'missing-pd-adequacy@dashboard2': {
                        templateUrl: 'app/entities/dashboard/layout/missing-pd-adequacy-box.html',
                        controller: 'MissingPDAdequacyBoxController',
                        controllerAs: 'vm'
                    },
                    'lab-notifications-area@dashboard2': {
                        templateUrl: 'app/entities/dashboard/layout/lab-notifications-area.html',
                        controller: 'LabNotificationsAreaController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    clinic: ['CoreService', function (CoreService) {
                        return CoreService.getCurrentClinic();
                    }],
                    activeTab: ['CoreService', function (CoreService) {
                        return CoreService.getStorage('activeScheduleTab');
                    }],
                    date: ['CoreService', function (CoreService) {
                        return new Date(CoreService.getActiveScheduleDate());
                    }],
                    dateEnv: ['CoreService', function (CoreService) {
                        return new Date(CoreService.getActiveScheduleDateEnv());
                    }],
                    openModalCritical: ['CoreService', function (CoreService) {
                        return CoreService.getStorage('openModalCritical');
                    }],
                    showNotifications: ['CoreService', function (CoreService) {
                        return CoreService.getStorage('showNotifications');
                    }],
                    hasPrinter: ['DYMO', function (DYMO) {
                        return DYMO.loadPrinters();
                    }],
                }
            })
            .state('dashboard-doc', {
                parent: 'entity',
                url: '/dashboard',
                ncyBreadcrumb: {
                    label: 'Home',
                    parent: 'app'
                },
                data: {
                    authorities: ['ROLE_SUPERVISOR', 'ROLE_NURSE', 'ROLE_DOCTOR'],
                    pageTitle: 'Dialysis'
                },
                params: {
                    state: 'dashboard-doc'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/dashboard/dashboard-doc.html',
                        controller: 'DashboardDocController',
                        controllerAs: 'vm'
                    },
                    'schedule@dashboard-doc': {
                        templateUrl: 'app/entities/dashboard/dashboard-doc/all-orders-schedule.html',
                        controller: 'DashboardAllOrdersScheduleController',
                        controllerAs: 'vm'
                    },
                    'patients@dashboard-doc': {
                        templateUrl: 'app/entities/dashboard/dashboard-doc/patient-order-schedule.html',
                        controller: 'DashboardPatientOrderScheduleController',
                        controllerAs: 'vm'
                    },
                    'machines@dashboard-doc': {
                        templateUrl: 'app/entities/dashboard/dashboard-doc/env-order-schedule.html',
                        controller: 'DashboardEnvOrderScheduleController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    date: ['CoreService', function (CoreService) {
                        return new Date(CoreService.getStorage('activeScheduleDate'));
                    }],
                    hasPrinter: ['DYMO', function (DYMO) {
                        return DYMO.loadPrinters();
                    }],
                    dashboard: function () {
                        return {
                            critical: 0,
                            testNotPerformed: 0,
                            unassignedResult: 0,
                            unsignedOrdersByDoctor: 0,
                            unsignedOrders: 0,
                            finalResult: 0,
                            partialResult: 0,
                            resultNotReceived: -1,
                            missingInfo: 0,
                            invoice: 0,
                            pendingResult: 0,

                            printedBarcodes: 0,
                            unPrintedBarcodes: 0,
                            printedWaterLabel: -1,
                            unPrintedWaterLabel: -1,

                            hdPatients: 0,
                            hdPatientsPer: 0,
                            pdPatients: 0,
                            pdPatientsPer: 0,
                            patients: 0,
                            machines: 0,
                        }
                    }
                }
            });
    }
})();
