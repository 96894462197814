(function() {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('InvoiceEnvironmentalDialogController', InvoiceEnvironmentalDialogController);

    InvoiceEnvironmentalDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'InvoiceEnvironmental'];

    function InvoiceEnvironmentalDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, InvoiceEnvironmental) {
        var vm = this;

        vm.invoiceEnvironmental = entity;
        vm.datePickerOpenStatus = {};

        vm.clear = clear;
        vm.openCalendar = openCalendar;
        vm.save = save;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;

            InvoiceEnvironmental.adjust(vm.invoiceEnvironmental, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess (result) {
            $scope.$emit('dialysisApp:invoiceEnvironmentalUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.date = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
