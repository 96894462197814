(function() {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('CompendiumSpecialPriceController', CompendiumSpecialPriceController);

    CompendiumSpecialPriceController.$inject = ['$scope', '$state', 'CompendiumSpecialPrice', 'GUIUtils',
        'CompendiumSpecialPriceSearch', 'CoreService', 'DTOptionsBuilder', 'DTColumnBuilder', '$q'];

    function CompendiumSpecialPriceController ($scope, $state, CompendiumSpecialPrice, GUIUtils,
                                               CompendiumSpecialPriceSearch, CoreService, DTOptionsBuilder, DTColumnBuilder, $q) {
        var vm = this;

        vm.compendiumSpecialPrices = [];
        vm.clinic = CoreService.getCurrentClinic();
        vm.dtInstance = {};

        vm.search = search;

        vm.dtOptions = DTOptionsBuilder.fromFnPromise(function () {
            var defer = $q.defer();
            if (!vm.searchQuery || vm.searchQuery == '') {
                CompendiumSpecialPriceSearch.query({
                    query: {
                        bool: {
                            must: [
                                {term: {"clinic.id": vm.clinic.id}},
                                {term: {"delStatus": false}}
                            ]
                        }
                    }
                }, function (result) {
                    vm.compendiumSpecialPrices = result;
                    defer.resolve(result);
                });
            } else {
                defer.resolve($filter('filter')(vm.compendiumSpecialPrices, vm.searchQuery, undefined));
            }
            return defer.promise;
        }).withPaginationType('full_numbers').withBootstrap().withDOM('tip');

        vm.dtColumns = [
            DTColumnBuilder.newColumn('id').withTitle('ID'),
            DTColumnBuilder.newColumn('compendium.code').withTitle('Code'),
            DTColumnBuilder.newColumn('compendium.description').withTitle('Description'),
            DTColumnBuilder.newColumn(null).withTitle('Price').renderWith(function (data, type, full) {
                return "$" + data.price;
            }),
            DTColumnBuilder.newColumn(null).withTitle('Actions').withOption('width', '155px').notSortable()
                .renderWith(actionsHtml)
        ];

        function actionsHtml(data, type, full, meta) {
            return GUIUtils.getActionsTemplate(data, $state.current.name, ['update', 'delete']);
        }

        function search() {
            vm.dtInstance.reloadData();
        }
    }
})();
