(function() {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('LoginController', LoginController);

    LoginController.$inject = ['$rootScope', '$state', '$timeout', 'Auth', 'APP'];

    function LoginController ($rootScope, $state, $timeout, Auth, APP) {
        var vm = this;

        $rootScope.background = 'background-image: url(content/images/background.jpg); ' +
            'background-repeat: no-repeat; background-size:cover'

        vm.authenticationError = false;
        vm.errorMessage = 'Please check your credentials and try again.';
        vm.cancel = cancel;
        vm.credentials = {};
        vm.login = login;
        vm.password = null;
        vm.register = register;
        vm.rememberMe = false;
        vm.requestResetPassword = requestResetPassword;
        vm.username = null;

        vm.appName = APP.APP_NAME;
        vm.appSuffixName = APP.APP_SUFFIX_NAME;

        $timeout(function (){angular.element('#username').focus();});

        function cancel () {
            vm.credentials = {
                username: null,
                password: null,
                rememberMe: true
            };
            vm.authenticationError = false;
            $uibModalInstance.dismiss('cancel');
        }

        function login (event) {
            event.preventDefault();
            Auth.login({
                username: vm.username,
                password: vm.password,
                rememberMe: vm.rememberMe
            }).then(function () {
                vm.authenticationError = false;
                // $uibModalInstance.close();
                if ($state.current.name === 'register' || $state.current.name === 'activate' ||
                    $state.current.name === 'finishReset' || $state.current.name === 'requestReset') {
                }

                $rootScope.$broadcast('authenticationSuccess');

                // previousState was set in the authExpiredInterceptor before being redirected to login modal.
                // since login is succesful, go to stored previousState and clear previousState
                if (Auth.getPreviousState()) {
                    var previousState = Auth.getPreviousState();
                    Auth.resetPreviousState();
                    $state.go(previousState.name, previousState.params);
                }
                $state.go('home');
                // $state.go('maintenance');
            }).catch(function (error) {
                vm.authenticationError = true;
                vm.errorMessage = error.data.AuthenticationException;
            });
        }

        function register () {
            // $uibModalInstance.dismiss('cancel');
            $state.go('register');
        }

        function requestResetPassword () {
            // $uibModalInstance.dismiss('cancel');
            $state.go('requestReset');
        }
    }
})();
