(function() {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('MedDosageUnitController', MedDosageUnitController);

    MedDosageUnitController.$inject = ['$scope', '$state', 'MedDosageUnit', 'MedDosageUnitSearch', '$q', 'DTColumnBuilder' , 'DTOptionsBuilder', 'GUIUtils', '$filter', '$compile'];

    function MedDosageUnitController ($scope, $state, MedDosageUnit, MedDosageUnitSearch, $q, DTColumnBuilder, DTOptionsBuilder, GUIUtils, $filter, $compile ) {
        var vm = this;
        vm.title = 'Med Dosage Units';
        vm.entityClassHumanized = 'Med Dosage Unit';
        vm.entityClass = 'med-dosage-unit';

        vm.medDosageUnits = [];
        vm.search = search;
        vm.loadAll = loadAll;
        vm.dtInstance = {};

        vm.dtOptions = DTOptionsBuilder.fromFnPromise(function() {
            var defer = $q.defer();
            if (!vm.searchQuery || vm.searchQuery == '') {
                  MedDosageUnit.query(function(result) {
                  vm.medDosageUnits = result;
                  defer.resolve(result);
                });
            }else {
                defer.resolve($filter('filter')(vm.medDosageUnits, vm.searchQuery, undefined));
            }
            return defer.promise;
        }).withPaginationType('full_numbers').withBootstrap().withDOM('tip')/*.withOption('scrollX', '100%')*/
        .withOption('fnRowCallback', function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
        $compile(nRow)($scope);
        });

        vm.dtColumns = [
              DTColumnBuilder.newColumn('name').withTitle('Name'),
            DTColumnBuilder.newColumn(null).withTitle('Actions').withOption('width', '155px').notSortable()
                           .renderWith(actionsHtml)
        ];

        function loadAll() {
            MedDosageUnit.query(function(result) {
                vm.medDosageUnits = result;
                vm.searchQuery = null;
            });
        }

        function actionsHtml(data, type, full, meta){
            return GUIUtils.getActionsTemplate(data, $state.current.name, ['all']);
        }

        function search() {
        vm.dtInstance.reloadData();
        }

    }
})();
