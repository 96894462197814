(function() {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('BundleDeleteController',BundleDeleteController);

    BundleDeleteController.$inject = ['$uibModalInstance', 'entity', 'Bundle'];

    function BundleDeleteController($uibModalInstance, entity, Bundle) {
        var vm = this;

        vm.bundle = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            Bundle.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
