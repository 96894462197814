(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('MedicationController', MedicationController);

    MedicationController.$inject = ['entity', '$scope', '$state', 'Medication', 'MedicationSearch', '$q', 'DTColumnBuilder', 'DTOptionsBuilder', 'GUIUtils', '$filter', '$compile'];

    function MedicationController(entity, $scope, $state, Medication, MedicationSearch, $q, DTColumnBuilder, DTOptionsBuilder, GUIUtils, $filter, $compile) {
        var vm = this;
        vm.title = 'Medications';
        vm.entityClassHumanized = 'Medication';
        vm.entityClass = 'medication';

        vm.medications = [];
        vm.search = search;
        vm.dtInstance = {};
        vm.patient = entity;

        vm.dtOptions = DTOptionsBuilder.fromFnPromise(function () {
            var defer = $q.defer();
            if (!vm.searchQuery || vm.searchQuery == '') {
                Medication.getAllByPatientId({id: vm.patient.id}, function (result) {
                    vm.medications = result;
                    defer.resolve(result);
                });
            } else {
                defer.resolve($filter('filter')(vm.medications, vm.searchQuery, undefined));
            }
            return defer.promise;
        }).withPaginationType('full_numbers').withBootstrap().withDOM('tip')/*.withOption('scrollX', '100%')*/
            .withOption('fnRowCallback', function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                $compile(nRow)($scope);
            });

        vm.dtColumns = [
            DTColumnBuilder.newColumn('mType').withTitle('Type'),
            DTColumnBuilder.newColumn('brand').withTitle('Brand'),
            DTColumnBuilder.newColumn('dosage').withTitle('Dosage'),
            DTColumnBuilder.newColumn(null).withTitle('Units').renderWith(function (data, type, full) {
                return data.unit.name;
            }),
            DTColumnBuilder.newColumn(null).withTitle('Actions').withOption('width', '155px').notSortable()
                .renderWith(actionsHtml)
        ];

        function actionsHtml(data, type, full, meta) {
            var stButtons = '';

            stButtons += '<a class="btn-sm btn-warning" ui-sref="medication-edit({mid:' + data.id + '})">' +
                '   <i class="fa fa-edit"></i></a>&nbsp;';

            if (vm.patient.status) {
                stButtons += '<a class="btn-sm btn-danger" ui-sref="medication-delete({mid:' + data.id + '})">' +
                    '   <i class="fa fa-trash"></i></a>';
            }

            return stButtons;
        }

        function search() {
            vm.dtInstance.reloadData();
        }

    }
})();
