(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('DashboardPatientOrderSchedule2Controller', DashboardPatientOrderSchedule2Controller);

    DashboardPatientOrderSchedule2Controller.$inject = ['PopupService', 'CoreService', '$rootScope', 'OdsReportsService',
        '$uibModal', '$stateParams', 'dialogs', 'toastr',
        '$compile', '$q', '$scope', 'GUIUtils', 'PatientOrder', 'DTOptionsBuilder',
        'DTColumnBuilder', 'DateUtils', 'DataTablesService', 'clinic', 'date', 'lodash',
        '$filter', 'hasPrinter'];

    function DashboardPatientOrderSchedule2Controller(PopupService, CoreService, $rootScope, OdsReportsService,
                                                      $uibModal, $stateParams, $dialogs, toastr,
                                                      $compile, $q, $scope, GUIUtils, PatientOrder, DTOptionsBuilder,
                                                      DTColumnBuilder, DateUtils, DataTablesService, clinic, date, _,
                                                      $filter, hasPrinter) {
        var vm = this;

        vm.clinic = clinic;
        vm.dtInstance = {};
        vm.selectedDate = date;
        vm.selected = {};
        vm.selectAll = false;
        vm.hasPrinter = hasPrinter;

        vm.search = search;

        var titleHtml = '<input type="checkbox" ng-model="vm.selectAll" ng-change="vm.toggleAll(vm.selected, vm.selectAll)">';
        vm.statuses = {
            'MANIFESTED': {label: 'Manifested', template: 'success'},
            'PRINTED': {label: 'Printed', template: 'warning'},
            'SCHEDULED': {label: 'Scheduled', template: 'info'},
            'CANCELED': {label: 'Cancelled', template: 'danger'}
        };

        vm.showCalendar = showCalendar;
        vm.getManifestPdf = getManifestPdf;
        vm.getBarcodeLabelsPdf = getBarcodeLabelsPdf;
        vm.cancelRequisition = cancelRequisition;
        vm.showChangeDrawDayCalendar = showChangeDrawDayCalendar;

        vm.toggleAll = DataTablesService.toggleAll;
        vm.toggleOne = DataTablesService.toggleOne;
        vm.setUniqueIds = DataTablesService.setUniqueIds;
        vm.modalPendingResults = modalPendingResults;

        vm.dtOptions = DTOptionsBuilder.fromFnPromise(function () {
            var defer = $q.defer();

            vm.selected = {};
            vm.selectAll = false;

            if (!vm.searchQuery || vm.searchQuery == '') {
                if (vm.clinic) {
                    PatientOrder.getSchedule({
                        clinicId: vm.clinic.id,
                        date: vm.selectedDate,
                        shift: -1
                    }, function (result) {
                        vm.orders = vm.setUniqueIds(result);
                        defer.resolve(result);
                    });
                } else {
                    defer.resolve(vm.orders);
                }
            } else {
                defer.resolve($filter('filter')(vm.orders, vm.searchQuery, undefined));
            }

            return defer.promise;
        }).withPaginationType('full_numbers')
            .withBootstrap()
            .withOption('aaSorting', [[1, 'asc'], [3, 'asc']])
            .withDOM('tip')
            .withLanguage({
                "paginate": {
                    "first": '<i class="fa fa-angle-double-left" aria-hidden="true"></i>',
                    "previous": '<i class="fa fa-arrow-circle-left" aria-hidden="true"></i>',
                    "last": '<i class="fa fa-angle-double-right" aria-hidden="true"></i>',
                    "next": '<i class="fa fa-arrow-circle-right" aria-hidden="true"></i>'
                }
            })
            .withOption('fnRowCallback',
                function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                    $compile(nRow)($scope);
                })
            .withOption('headerCallback', function (header) {
                if (!vm.headerCompiled) {
                    vm.headerCompiled = true;
                    $compile(angular.element(header).contents())($scope);
                }
            });

        vm.dtColumns = [
            DTColumnBuilder.newColumn(null).withTitle(titleHtml).withOption('width', '25px').notSortable().renderWith(function (data, type, full, meta) {
                vm.selected[full.id] = false;
                return '<input type="checkbox" ng-model="vm.selected[' + "'" + full.id + "'" + ']" ng-click="vm.toggleOne(vm.selected, vm.selectAll)">';
            }),
            DTColumnBuilder.newColumn(null).withTitle('Patient').renderWith(function (data, type, full) {
                return data.patientLastName + ', ' + data.patientFirstName + (data.patientMiddleName ? ' ' + data.patientMiddleName : "");
            }),
            DTColumnBuilder.newColumn(null).withTitle('DOB').renderWith(function (data, type, full) {
                return moment(data.dob).format("MM/DD/YYYY");
            }),
            DTColumnBuilder.newColumn(null).withTitle('SpecimenId').renderWith(function (data, type, full) {
                return data.barcode ? data.barcode : '';
            }),
            DTColumnBuilder.newColumn(null).withTitle('Status').renderWith(orderStatusHtml),
            DTColumnBuilder.newColumn(null).withTitle('Actions').withOption('width', '55px').withOption('class', 'col-btn').notSortable().renderWith(actionsHtml)
        ];

        function orderStatusHtml(data, type, full) {
            var patientStatus = vm.statuses[data.status];
            return GUIUtils.getStatusTemplate(patientStatus.label, patientStatus.template);
        }

        function actionsHtml(data, type, full, meta) {
            var stButtons = '';

            stButtons += '<a uib-tooltip="See Details" class="btn btn-default btn-sm details" ng-click="vm.modalPendingResults(' + data.id + ')">' +
                '   <i class="fa fa-external-link" aria-hidden="true"></i></>&nbsp;';

            return stButtons;
        }

        function search() {
            vm.dtInstance.reloadData();
        }

        function modalPendingResults(index) {
            PopupService.showPatientBarcodePopup(vm.orders[index].barcode, vm.orders[index]);
        }

        function showCalendar() {
            $uibModal.open({
                templateUrl: 'app/entities/dashboard/dashboard2/dashboard2-select-day.dialog.html',
                controller: 'DashboardSelectDateDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    date: vm.selectedDate,
                    isPatient: {value: true},
                    clinic: vm.clinic
                }
            }).result.then(function (result) {
                vm.selectedDate = result.value;
                vm.searchQuery = '';
                vm.dtInstance.reloadData();
            });
        }

        function showChangeDrawDayCalendar() {
            $uibModal.open({
                templateUrl: 'app/entities/dashboard/dashboard2/dashboard2-select-day.dialog.html',
                controller: 'DashboardSelectDateDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    date: vm.selectedDate,
                    isPatient: {value: true},
                    clinic: vm.clinic
                }
            }).result.then(function (result) {
                vm.searchQuery = '';
                changeDrawDay(result.value);
            });
        }

        function changeDrawDay(newDrawDay) {
            var newDrawDays = [];

            for (var id in vm.selected) {
                if (vm.selected[id] && _.includes(['SCHEDULED', 'PRINTED'], vm.orders[id].status)) {
                    newDrawDays.push(
                        {
                            patientId: vm.orders[id].patientId,
                            date: vm.orders[id].scheduleDate,
                            physicianId: vm.orders[id].physicianId,
                            status: vm.orders[id].status,
                            newDate: DateUtils.convertLocalDateToServer(newDrawDay)
                        }
                    );
                }
            }

            if (newDrawDays.length > 0) {
                var dlg = $dialogs.confirm('Please Confirm', 'Do you want to change the draw day for selected requisitions?', {size: 'md'});

                dlg.result.then(function (btn) {
                    PatientOrder.changeDrawDayV2({drawDays: newDrawDays}, function () {
                        vm.dtInstance.reloadData();
                    });
                });
            } else {
                toastr.error('There is not requisition selected');
            }
        }

        function getManifestPdf() {
            var barcodes = getSelectedBarcodes();

            if (barcodes.length > 0) {
                PatientOrder.validateManifest({drawDays: barcodes}, function (result) {
                    if (result.barcodes.length > 0) {
                        $dialogs.error("Error. Pending items for print", "This item can't be manifested, all items must be printed first. " + _.join(result.barcodes, ', '));
                    } else {
                        PatientOrder.getManifestPdf({clinicId: clinic.id, drawDays: barcodes}, function () {
                            vm.searchQuery = '';
                            vm.dtInstance.reloadData();
                        }, function () {
                            toastr.error('"Error manifesting orders"');
                        });
                    }
                });
            } else {
                toastr.error('There is not requisition selected or printed');
            }
        }

        function getSelectedBarcodes() {
            var patientBarcodes = [];

            for (var id in vm.selected) {
                if (vm.selected[id] && vm.orders[id].barcode != null && vm.orders[id].status != 'SCHEDULED') {
                    patientBarcodes.push(
                        {
                            date: DateUtils.convertLocalDateToServer(vm.selectedDate),
                            barcode: vm.orders[id].barcode,
                        }
                    );
                }
            }

            return patientBarcodes;
        }

        function getBarcodeLabelsPdf() {
            var patientBarcodes = [];

            for (var id in vm.selected) {
                if (vm.selected[id]) {
                    patientBarcodes.push(
                        {
                            patientId: vm.orders[id].patientId,
                            date: DateUtils.convertLocalDateToServer(vm.selectedDate),
                            physicianId: vm.orders[id].physicianId,
                            barcode: vm.orders[id].barcode,
                            status: vm.orders[id].status
                        }
                    );
                }
            }

            if (patientBarcodes.length > 0) {
                vm.isSaving = true;

                PatientOrder.getBarcodeLabelsPdf({drawDays: patientBarcodes}, function () {
                    vm.searchQuery = '';
                    vm.dtInstance.reloadData();
                    vm.isSaving = false;
                }, function () {
                    toastr.error('"Error collecting orders"');
                    vm.isSaving = false;
                });
            } else {
                toastr.error('There is not requisition selected');
                vm.dtInstance.reloadData();
                vm.isSaving = false;
            }
        }

        function cancelRequisition() {
            var patientBarcodes = [];

            for (var id in vm.selected) {
                if (vm.selected[id]) {
                    patientBarcodes.push(
                        {
                            patientId: vm.orders[id].patientId,
                            date: DateUtils.convertLocalDateToServer(vm.selectedDate),
                            physicianId: vm.orders[id].physicianId,
                            barcode: vm.orders[id].barcode,
                            status: vm.orders[id].status
                        }
                    );
                }
            }

            if (patientBarcodes.length > 0) {
                var dlg = $dialogs.confirm('Please Confirm', 'Are you sure you want to Cancel Requisition?', {size: 'md'});
                dlg.result.then(function (btn) {
                    PatientOrder.cancelRequisition({drawDays: patientBarcodes}, function () {
                        vm.searchQuery = '';
                        vm.dtInstance.reloadData();
                    }, function () {
                        toastr.error('"Error collecting orders"');
                    });
                });
            } else {
                toastr.error('There is not requisition selected');
            }
        }
    }
})();
