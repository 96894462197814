(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('SignController', SignController);

    SignController.$inject = ['JSignature', 'CoreService', 'Employee', '$state', 'previousState'];

    function SignController(JSignature, CoreService, Employee, $state, previousState) {
        var vm = this;

        vm.previousState = previousState;
        vm.result = {
            agree: false,
            signed: true
        }

        vm.save = save;

        vm.getData = getData;
        vm.enable = enable;
        vm.disable = disable;

        //We check if the employee has signed we redirect to the previous state
        var employee = CoreService.getCurrentEmployee();
        if (employee.hasSigned) {
            $state.go(vm.previousState.name);
        }

        function save() {
            if (!JSignature.getData(JSignature.exportTypes.NATIVE).length) {
                alert("Please sign and then press finish!!!");
            } else {
                var sign = JSignature.getData(JSignature.exportTypes.IMAGE_PNG_BASE64);

                employee.hasSigned = true;
                employee.specialist.signature = sign;

                Employee.update(employee, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {

            Employee.employeeAccount(function (result) {
                CoreService.setCurrentEmployee(result);
                $state.go("home");
            });
        }

        function onSaveError() {
            vm.isError = false;
        }

        function getData() {
            var data = JSignature.getData(JSignature.exportTypes.IMAGE_SVG_XML_BASE64);
            var i = new Image();
            i.src = 'data:' + data[0] + ',' + data[1];
            vm.hash = 'data:' + data[0] + ',' + data[1];
        }

        function enable() {
            JSignature.enable();
        }

        function disable() {
            JSignature.disable();
        }
    }
})
();
