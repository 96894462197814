(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('DashboardPatientOrderScheduleController', DashboardPatientOrderScheduleController);

    DashboardPatientOrderScheduleController.$inject = ['CoreService', 'hasPrinter', '$rootScope', 'OdsReportsService',
        '$uibModal', 'DYMO', '$stateParams', 'dialogs',
        '$compile', '$q', '$scope', 'GUIUtils', 'PatientOrder', 'DTOptionsBuilder', 'DTColumnBuilder', 'DateUtils', 'date'];

    function DashboardPatientOrderScheduleController(CoreService, hasPrinter, $rootScope, OdsReportsService,
                                                     $uibModal, DYMO, $stateParams, $dialogs,
                                                     $compile, $q, $scope, GUIUtils, PatientOrder, DTOptionsBuilder, DTColumnBuilder, DateUtils, date) {
        var vm = this;
        vm.clinic = CoreService.getCurrentClinic();

        vm.orders = [];
        vm.search = search;
        vm.dtInstance = {};
        vm.barCode = {};
        vm.oDate1 = date;
        vm.hasPrinter = hasPrinter;

        vm.selected = {};
        vm.selectAll = false;
        vm.toggleAll = toggleAll;
        vm.toggleOne = toggleOne;
        vm.getManifest = getManifest;

        vm.barcodes = [];

        var titleHtml = '<input type="checkbox" ng-model="vm.selectAll" ng-change="vm.toggleAll()">';

        vm.dtOptions = DTOptionsBuilder.fromFnPromise(function () {
            var defer = $q.defer();

            if (vm.clinic) {
                PatientOrder.dashboardSchedule({clinicId: vm.clinic.id, date: vm.oDate1, shift: -1}, function (result) {
                    vm.orders = setUniqueIds(result);
                    defer.resolve(vm.orders);

                    if (vm.hasPrinter) {
                        if (vm.barcodes.length > 0) {
                            $rootScope.$broadcast('dialysisApp:dashboardRedrawPatientCalendar' + $stateParams.state);

                            PatientOrder.getBarcodeLabels({barcodes: vm.barcodes}, function (result) {
                                DYMO.printPatientBarcodes(result);

                                vm.barcodes = [];
                            });
                        }
                    }

                    vm.selected = {};
                    vm.selectAll = false;


                });
            } else {
                defer.resolve(vm.orders);
            }

            return defer.promise;
        }).withBootstrap().withDOM('tip')
            .withOption('createdRow', function (row, data, dataIndex) {
                $compile(angular.element(row).contents())($scope);
            })
            .withOption('aaSorting', [[1, 'asc']])
            .withOption('headerCallback', function (header) {
                if (!vm.headerCompiled) {
                    vm.headerCompiled = true;
                    $compile(angular.element(header).contents())($scope);
                }
            });

        vm.dtColumns = [
            DTColumnBuilder.newColumn(null).withTitle(titleHtml).withOption('width', '25px').notSortable().renderWith(function (data, type, full, meta) {
                vm.selected[full.id] = false;

                return '<input type="checkbox" ng-model="vm.selected[' + data.id + ']" ng-click="vm.toggleOne()">';
            }),
            DTColumnBuilder.newColumn(null).withTitle('Patient Name').renderWith(function (data, type, full) {
                return data.lastName + ', ' + data.firstName + ' ' + data.middleName;
            }),
            DTColumnBuilder.newColumn(null).withTitle('D.O.B').withOption('width', '110px').renderWith(function (data, type, full) {
                return moment(data.dob).format("MM/DD/YYYY");
            }),
            DTColumnBuilder.newColumn(null).withTitle('Barcode').renderWith(function (data, type, full) {
                return data.barcode ? showBarcode(data) : '';
            }),
            DTColumnBuilder.newColumn(null).withTitle('Tests').withOption('width', '90px').renderWith(function (data, type, full, meta) {
                return showCompendiumHtml(data.id);
            }),
            DTColumnBuilder.newColumn(null).withTitle('Collected').withOption('width', '80px').renderWith(function (data, type, full) {
                return GUIUtils.colorHtmlYesNo(data.collected);
            })
        ];

        vm.printLabels = function () {
            var patientBarcodes = [];

            for (var id in vm.selected) {
                if (vm.selected[id]) {
                    if (vm.orders[id].barcode == null) {
                        patientBarcodes.push(
                            {
                                patientId: vm.orders[id].patientId,
                                date: DateUtils.convertLocalDateToServer(vm.oDate1),
                                physicianId: vm.orders[id].pId
                            }
                        );
                    }
                }
            }

            if (patientBarcodes.length > 0) {
                vm.isSaving = true;

                PatientOrder.genBarcodes({drawDays: patientBarcodes}, function (result) {
                    vm.barcodes = getSelectedBarcodes().concat(result);

                    vm.dtInstance.reloadData();
                    vm.isSaving = false;
                }, function () {
                    alert("Error collecting orders");
                    vm.isSaving = false;
                });
            } else {
                vm.barcodes = getSelectedBarcodes();
                vm.dtInstance.reloadData();
                vm.isSaving = false;
            }
        }

        vm.changeDrawDay = function () {
            var dlg = $dialogs.confirm('Please Confirm', 'Are you sure you want to change the draw day? Items with results or kinetcs data will not be changed', {size: 'md'});
            dlg.result.then(function (btn) {
                vm.isSaving = true;
                $uibModal.open({
                    templateUrl: 'app/entities/window-picker/date-picker-dialog.html',
                    controller: 'DatePickerDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'sm',
                    resolve: {
                        date: new Date()
                    }
                }).result.then(function (result) {
                    var patientBarcodes = [];

                    for (var id in vm.selected) {
                        if (vm.selected[id]) {
                            if (vm.orders[id].barcode) {
                                patientBarcodes.push({
                                    patientId: vm.orders[id].patientId,
                                    barcode: vm.orders[id].barcode,
                                    newDate: DateUtils.convertLocalDateToServer(result.date)
                                });
                            } else {
                                patientBarcodes.push({
                                    patientId: vm.orders[id].patientId,
                                    barcode: null,
                                    date: vm.orders[id].scheduleDate,
                                    newDate: DateUtils.convertLocalDateToServer(result.date),
                                    physicianId: vm.orders[id].pId
                                });
                            }
                        }
                    }

                    if (patientBarcodes.length > 0) {
                        vm.isSaving = false;

                        PatientOrder.changeDrawDay({drawDays: patientBarcodes}, function (result) {
                            vm.barcodes = result;
                            vm.dtInstance.reloadData();
                            vm.isSaving = false;
                            $rootScope.$broadcast('dialysisApp:dashboardRedrawPatientCalendar' + $stateParams.state);
                        }, function () {
                            alert("Error change draw days orders");
                            vm.isSaving = false;
                        });
                    } else {
                        vm.barcodes = getSelectedBarcodes();
                        vm.dtInstance.reloadData();
                        vm.isSaving = false;
                    }
                }, function () {
                    vm.isSaving = false;
                });
            });
        }

        function toggleAll() {
            for (var id in vm.selected) {
                if (vm.selected.hasOwnProperty(id)) {
                    vm.selected[id] = vm.selectAll;
                }
            }
        }

        function toggleOne() {
            for (var id in vm.selected) {
                if (vm.selected.hasOwnProperty(id)) {
                    if (!vm.selected[id]) {
                        vm.selectAll = false;
                        return;
                    }
                }
            }
            vm.selectAll = true;
        }

        function search() {
            vm.dtInstance.reloadData();
        }

        $scope.$on('dialysisApp:dashboardCalendarUpdate', function (event, result) {
            vm.oDate1 = result;
            vm.selected = {};
            vm.selectAll = false;
            vm.search();
        });

        function showBarcode(row) {
            return '<a ui-sref="' + "clinic-schedule-pdetail-barcode({barcode:'" + row.barcode + "'})" + '">' + row.barcode + '</a>';
        }

        function showCompendiumHtml(row) {
            return '<a class="btn-sm btn-info" ng-click="vm.showCompendiums(' + row + ')">' +
                '   <i class="fa fa-list-ol"></i></a>';
        };

        vm.showCompendiums = function (index) {
            $uibModal.open({
                templateUrl: 'app/entities/compendium/compendium-list-dialog.html',
                controller: 'CompendiumListDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    compendiums: ['Compendium', function (Compendium) {
                        if (vm.orders[index].barcode) {
                            return Compendium.by_patient_with_barcode({barcode: vm.orders[index].barcode}).$promise;
                        } else {
                            return Compendium.by_patient_without_barcode({
                                patient: vm.orders[index].patientId,
                                date: DateUtils.convertLocalDateToServer(vm.oDate1),
                                physician: vm.orders[index].pId
                            }).$promise;
                        }
                    }]
                }
            });
        };

        function getSelectedIds() {
            var patients = [];

            for (var id in vm.selected) {
                if (vm.selected[id]) {
                    patients.push(vm.orders[id].patientId);
                }
            }

            return patients;
        }

        function setUniqueIds(data) {
            for (var i = 0; i < data.length; i++) {
                data[i].id = i;
            }

            return data;
        }

        function getSelectedBarcodes() {
            var barcodes = [];

            for (var id in vm.selected) {
                if (vm.selected[id]) {
                    if (vm.orders[id].barcode != null) {
                        barcodes.push(vm.orders[id].barcode);
                    }
                }
            }

            return barcodes;
        }

        function getManifest() {
            PatientOrder.getManifest({clinicId: vm.clinic.id, date: vm.oDate1}, function (data) {
                $rootScope.$broadcast('dialysisApp:dashboardRedrawPatientCalendar' + $stateParams.state);
                OdsReportsService.forceDownloadAndOpenPDFObject(data);
            });
        }
    }
})();
