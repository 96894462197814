(function() {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('DialyserTypeDialogController', DialyserTypeDialogController);

    DialyserTypeDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'DialyserType', 'Infection', 'Corporation'];

    function DialyserTypeDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, DialyserType, Infection, Corporation) {
        var vm = this;

        vm.dialyserType = entity;
        vm.clear = clear;
        vm.save = save;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.dialyserType.id !== null) {
                DialyserType.update(vm.dialyserType, onSaveSuccess, onSaveError);
            } else {
                DialyserType.save(vm.dialyserType, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('dialysisApp:dialyserTypeUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }
    }
})();
