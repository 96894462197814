(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('AccessController', AccessController);

    AccessController.$inject = ['GUIUtils', '$compile', '$scope', 'AccessSearch', 'entity', '$q', 'DTColumnBuilder', 'DTOptionsBuilder', '$filter', 'moment'];

    function AccessController(GUIUtils, $compile, $scope, AccessSearch, entity, $q, DTColumnBuilder, DTOptionsBuilder, $filter, moment) {
        var vm = this;

        vm.patient = entity;
        vm.accesses = [];
        vm.search = search;
        vm.dtInstance = {};

        vm.dtOptions = DTOptionsBuilder.fromFnPromise(function () {
            var defer = $q.defer();
            if (!vm.searchQuery || vm.searchQuery == '') {
                var must = [];
                must.push({term: {"patient.id": vm.patient.id}});
                must.push({term: {"delStatus": false}});

                AccessSearch.query({
                    query: {
                        bool: {
                            must: must
                        }
                    }
                }, function (result) {
                    vm.accesses = result;
                    defer.resolve(result);
                });
            } else {
                defer.resolve($filter('filter')(vm.accesses, vm.searchQuery, undefined));
            }
            return defer.promise;
        }).withPaginationType('full_numbers').withBootstrap().withDOM('tip').withOption('fnRowCallback',
            function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                $compile(nRow)($scope);
            });

        vm.dtColumns = [
            DTColumnBuilder.newColumn('aType'),
            DTColumnBuilder.newColumn(null).withTitle('Placement Date').renderWith(function (data, type, full) {
                return data.placementDate ? moment(data.placementDate).format("MM/DD/YYYY") : '';
            }),
            DTColumnBuilder.newColumn(null).withTitle('Active').renderWith(function (data, type, full) {
                return data.isActive ? GUIUtils.getStatusTemplate('Yes', 'success') : GUIUtils.getStatusTemplate('No', 'danger');
            }),
            DTColumnBuilder.newColumn(null).withTitle('Primary').renderWith(function (data, type, full) {
                return data.isPrimary ? GUIUtils.getStatusTemplate('Yes', 'success') : GUIUtils.getStatusTemplate('No', 'danger');
            }),
            DTColumnBuilder.newColumn(null).withTitle('Actions').withOption('width', '155px').notSortable()
                .renderWith(actionsHtml)
        ];

        function actionsHtml(data, type, full, meta){
            var stButtons = '';

            stButtons += '<a class="btn-sm btn-info" ui-sref="access-view({aid:' + data.id + '})">' +
                '   <i class="fa fa-eye"></i></a>&nbsp;';

            if (vm.patient.status) {
                stButtons += '<a class="btn-sm btn-danger" ui-sref="access-delete({aid:' + data.id + '})">' +
                    '   <i class="fa fa-trash"></i></a>';
            }

            return stButtons;
        }

        function search() {
            vm.dtInstance.reloadData();
        }
    }
})();
