(function() {
    'use strict';

    angular
        .module('ods-lib-old')
        .constant('ReportParamType', {
            "DATE": "DATE",
            "TEXT": "TEXT",
            "NUMBER": "NUMBER",
            "LIST": "LIST",
            "SINGLE_SELECT": "SINGLE_SELECT",
            "MULTI_SELECT": "MULTI_SELECT",
            "TABLE_SELECT": "TABLE_SELECT"
        });
})();
