(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('MachineSelectFilter', MachineSelectFilter);

    MachineSelectFilter.$inject = ['clinic', '$state', 'Machine', 'CoreService', '$uibModal'];

    function MachineSelectFilter(clinic, $state, Machine, CoreService, $uibModal) {
        var vm = this;
        vm.title = 'Sites';
        vm.entityClassHumanized = 'Sites';
        vm.entityClass = 'machine';

        vm.machines = [{id: -1, name: '<b>All Machines</b>', location: 'All locations', mType: 'All types'}];
        vm.machinesOrigin = [];

        Machine.get_all_active({"id": clinic.id
        // MachineSearch.query({
        //     query: {
        //         bool: {
        //             must: [
        //                 {term: {"clinic.id": clinic.id}},
        //                 {term: {"delStatus": false}},
        //                 {term: {"mStatus": true}}
        //             ]
        //         }
        //     }
        }, function (result) {
            vm.machinesOrigin = result;
            result.forEach(function (item) {
                vm.machines.push(item);
            })
        });

        vm.machine = CoreService.getCurrentMachine();

        vm.selectMachine = function () {
            if (vm.machine.id == -1) {
                $uibModal.open({
                    templateUrl: 'app/entities/machine/machine-select-dialog.html',
                    controller: 'MachineSelectDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        clinic: clinic,
                        machines: {selected: []}
                    }
                }).result.then(function (result) {
                    $state.go("env-order-new-all", {machines: result.machines, allMachines: vm.machinesOrigin}, {reload: true});
                }, function () {
                    vm.machine = CoreService.getCurrentMachine();
                });
            } else {
                CoreService.setCurrentMachine(vm.machine);
                $state.go("env-order", {id: vm.machine.id}, {reload: true});
            }
        };

        vm.removeMachine = function () {
            CoreService.removeCurrentMachine();
            $state.go("machine", {}, {reload: true});
        };
    }
})();
