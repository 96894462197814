(function() {
    'use strict';

    angular
        .module('dialysisApp')
        .factory('EnvTestSearch', EnvTestSearch);

    EnvTestSearch.$inject = ['$resource'];

    function EnvTestSearch($resource) {
        var resourceUrl =  'api/_search/env-tests/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
