(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('PatientStatusController', PatientStatusController);

    PatientStatusController.$inject = ['$compile', '$scope', 'PatientStatus', 'entity', '$q', 'DTColumnBuilder', 'DTOptionsBuilder', '$filter', 'moment', '$rootScope'];

    function PatientStatusController($compile, $scope, PatientStatus, entity, $q, DTColumnBuilder, DTOptionsBuilder, $filter, moment, $rootScope) {
        var vm = this;

        vm.patient = entity;
        vm.patientStatuses = [];
        vm.search = search;
        vm.dtInstance = {};

        vm.dtOptions = DTOptionsBuilder.fromFnPromise(function () {
            var defer = $q.defer();
            if (!vm.searchQuery || vm.searchQuery == '') {
                PatientStatus.getAllByPatientId({id: vm.patient.id}, function (result) {
                    vm.patientStatuses = result;
                    defer.resolve(result);
                });
            } else {
                defer.resolve($filter('filter')(vm.patientStatuses, vm.searchQuery, undefined));
            }
            return defer.promise;
        }).withPaginationType('full_numbers').withBootstrap().withDOM('tip').withOption('fnRowCallback',
            function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                $compile(nRow)($scope);
            });

        vm.dtColumns = [
            DTColumnBuilder.newColumn('clinic.name').withTitle('Clinic'),
            DTColumnBuilder.newColumn('startDate').withTitle('Start Date').renderWith(function (data, type, full) {
                return data ? moment(data).format($rootScope.displayDateformat) : "";
            }),
            DTColumnBuilder.newColumn('endDate').withTitle('End Date').renderWith(function (data, type, full) {
                return data ? moment(data).format($rootScope.displayDateformat) : "";
            }),
            DTColumnBuilder.newColumn('endStatus').withTitle('End Status'),
            DTColumnBuilder.newColumn(null).withTitle('Actions').withOption('width', '155px').notSortable()
                .renderWith(actionsHtml)
        ];

        function actionsHtml(data, type, full, meta) {
            var stButtons = '';

            stButtons += '<a class="btn-sm btn-warning" ui-sref="patient-status.view({psid:' + data.id + '})">' +
                '   <i class="fa fa-edit"></i></a>&nbsp;';

            if (vm.patient.status) {
                stButtons += '<a class="btn-sm btn-danger" ui-sref="patient-status.delete({psid:' + data.id + '})">' +
                    '   <i class="fa fa-trash"></i></a>';
            }

            return stButtons;
        }

        function search() {
            vm.dtInstance.reloadData();
        }
    }
})();
