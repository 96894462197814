(function () {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('RptMetadataController', RptMetadataController);

    RptMetadataController.$inject = ['$scope', '$state', 'RptMetadata', 'RptMetadataSearch', '$q', 'DTColumnBuilder', 'DTOptionsBuilder', 'GUIUtils', '$filter', '$compile'];

    function RptMetadataController($scope, $state, RptMetadata, RptMetadataSearch, $q, DTColumnBuilder, DTOptionsBuilder, GUIUtils, $filter, $compile) {
        var vm = this;
        vm.title = 'Rpt Metadata';
        vm.entityClassHumanized = 'Rpt Metadata';
        vm.entityStateName = 'rpt-metadata';

        vm.rptMetadata = [];
        vm.search = search;
        vm.loadAll = loadAll;
        vm.dtInstance = {};

        vm.dtOptions = DTOptionsBuilder.fromFnPromise(function () {
            var defer = $q.defer();
            if (!vm.searchQuery || vm.searchQuery == '') {
                RptMetadata.query(function (result) {
                    vm.rptMetadata = result;
                    defer.resolve(result);
                });
            } else {
                defer.resolve($filter('filter')(vm.rptMetadata, vm.searchQuery, undefined));
            }
            return defer.promise;
        }).withPaginationType('full_numbers').withBootstrap().withDOM('tip')/*.withOption('scrollX', '100%')*/
            .withOption('fnRowCallback', function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                $compile(nRow)($scope);
            });

        vm.dtColumns = [
            DTColumnBuilder.newColumn('intCode').withTitle('IntCode'),
            DTColumnBuilder.newColumn('code').withTitle('Code'),
            DTColumnBuilder.newColumn('description').withTitle('Description'),
            DTColumnBuilder.newColumn(null).withTitle('Actions').withOption('width', '155px').notSortable()
                .renderWith(actionsHtml)
        ];

        function loadAll() {
            RptMetadata.query(function (result) {
                vm.rptMetadata = result;
                vm.searchQuery = null;
            });
        }

        function actionsHtml(data, type, full, meta) {
            return GUIUtils.getActionsTemplate(data, $state.current.name, ['all']);
        }

        function search() {
            vm.dtInstance.reloadData();
        }

    }
})();
