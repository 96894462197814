(function() {
    'use strict';

    angular
        .module('dialysisApp')
        .controller('DashboardAdminController', DashboardAdminController);

    DashboardAdminController.$inject = ['$interval', 'Dashboard', '$scope', 'dashboard'];

    function DashboardAdminController ($interval, Dashboard, $scope, dashboard) {
        var vm = this;
        vm.dashboard = dashboard;

        reload();

        var myInterval = $interval(function(){
            reload();
        }, 120000);

        function reload() {
            Dashboard.admin(function(result) {
                vm.dashboard = result;
            });
        }

        $scope.$on('$destroy', function(){
            $interval.cancel(myInterval)
        })
    }
})();
