(function() {
    'use strict';
    angular
        .module('dialysisApp')
        .factory('Hospitalization', Hospitalization);

    Hospitalization.$inject = ['$resource', 'DateUtils'];

    function Hospitalization ($resource, DateUtils) {
        var resourceUrl =  'api/hospitalizations/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.admissionDate = DateUtils.convertLocalDateFromServer(data.admissionDate);
                        data.dischargeDate = DateUtils.convertLocalDateFromServer(data.dischargeDate);
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.admissionDate = DateUtils.convertLocalDateToServer(copy.admissionDate);
                    copy.dischargeDate = DateUtils.convertLocalDateToServer(copy.dischargeDate);
                    return angular.toJson(copy);
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    copy.admissionDate = DateUtils.convertLocalDateToServer(copy.admissionDate);
                    copy.dischargeDate = DateUtils.convertLocalDateToServer(copy.dischargeDate);
                    return angular.toJson(copy);
                }
            },
            'getAllByPatientId': { 'url': 'api/hospitalizations/patient/:id', method: 'GET', isArray: true},
        });
    }
})();
